.p-land-search {  

  .sectionLine {
    width: 100%;
    height: 1px;
    background-color: #D0D0D0;
    @media (--mobile){
      width: 100vw;
      margin-left: -3rem;
    }
  }

  .s-heading {
    padding-bottom: 10rem;
    @media (--mobile){
      padding-bottom: 12rem;
    }

    &__inner {
      text-align: center;
      @media (--mobile){
        text-align: left;
      }

      h2 {
        font-size: 3.8rem;
        font-weight: 600;
        letter-spacing: 0.1em;
        line-height: 1.4;
        color: #000000;
        @media (--mobile){
          font-size: 2.3rem;
        }
      }

      p {
        display: block;
        width: fit-content;
        margin-inline: auto;
        font-size: 2rem;
        font-weight: 500;
        letter-spacing: 0.05em;
        line-height: 1.8;
        color: #171717;
        border-bottom: 1px solid #D0D0D0;
        padding-bottom: 1rem;
        @media (--mobile){
          font-size: 1.6rem;
          
        }

        &:nth-of-type( 1 ){
          margin-top: 9.7rem;
          @media (--mobile){
            margin-top: 7rem;
          }
        }
        &:nth-of-type( n + 2 ){
          margin-top: 5rem;
          @media (--mobile){
            margin-top: 4rem;
          }
        }
      }
    }
  }

  .s-sheet {
    margin-top: 11.5rem;

    &__ttl {
      margin-top: 11rem;
    }
    
    &__head {
      max-width: 96rem;
      width: 100%;
      margin-inline: auto;
      
      & > h3 {
        font-size: 1.8rem;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 1.7;
        color: #333333;
        border: 1.5px solid #8A8A8A;
        display: block;
        width: fit-content;
        padding: 2.8rem 3.5rem;
        margin-inline: auto;
        @media (--mobile){
          font-size: 1.7rem;
          padding: 2.5rem 1rem;
        }
      }

      & > p {
        display: block;
        margin-top: 3rem;
        font-size: 1.6rem;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 1.8;
        color: #333333;
        &:nth-of-type(2){
          margin-top: 3rem !important;
        }
      }
    }

    &__content {
      margin-top: 7.5rem;
      @media (--mobile){
        margin-top: 5rem;
      }
      
      & > p {
        font-size: 1.6rem;
        font-weight: 400;
        letter-spacing: 0.05em;
        line-height: 1.8;
        color: #333333;
        &:nth-of-type( n + 2 ){
          margin-top: 3rem;
        }
        &:last-of-type {
          margin-top: 11rem;
          @media (--mobile){
            margin-top: 3rem;
          }
        }
      }

      &-img {
        width: 80rem;
        margin-inline: auto;
        margin-top: 13rem;
        @media (--mobile){
          width: 100%;
          margin-top: 5rem;
        }
      }
    }
  }

  .s-merit {
    margin-top: 10rem;
    padding-bottom: 18rem;
    @media (--mobile){
      margin-top: 8rem;
      padding-bottom: 12rem;
    }

    &__lead {
      font-size: 1.5rem;
      font-weight: 400;
      letter-spacing: 0em;
      line-height: 2.2;
      color: #333333;
      display: block;
      margin-top: 5rem;
      @media (--mobile){
        margin-top: 3.5rem;
      }
    }

    ul {
      margin-top: 8rem;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 7rem 9rem;
      @media (--mobile){
        margin-top: 6rem;
        grid-template-columns: repeat(1, 1fr);
        gap: 5rem 0;
        
      }

      li {
      
        & > div {
          display: flex;
          align-items: flex-end;
          border-bottom: 1px solid #D0D0D0;
          padding-bottom: 1rem;

          small {
            font-size: 1.4rem;
            font-weight: 400;
            letter-spacing: 0.1em;
            line-height: 1.4;
            color: #171717;
          }

          span {
            display: block;
            font-weight: 400;
            letter-spacing: 0em;
            line-height: 1;
            color: #333333;
            &:nth-of-type( 1 ){
              font-size: 2.2rem;
              margin-left: 1rem;
            }
            &:nth-of-type( 2 ){
              font-size: 1.6rem;
              margin-left: 2rem;
            }
          }
        }

        p {
          display: block;
          margin-top: 3rem;
          font-size: 1.5rem;
          font-weight: 400;
          letter-spacing: 0em;
          line-height: 2;
          color: #171717;
          @media (--mobile){
            margin-top: 2.2rem;
          }
        }
      }
    }

    &__graph {
      margin-top: 10rem;
      @media (--mobile){
        margin-top: 8rem;
      }

      span {
        display: block;
        width: 100%;
        font-size: 2rem;
        font-weight: 600;
        letter-spacing: 0.05em;
        line-height: 1.2;
        color: #FFFFFF;
        background-color: #262727;
        padding: 2.7rem 0;
        text-align: center;
        @media (--mobile){
          padding: 3rem;
          font-size: 2rem;
          width: 100vw;
          margin-left: -3rem;
          text-align: left;
          line-height: 1.8;
        }
      }

      figure {
        margin-top: 6rem;
        padding: 0 2rem;
        @media (--mobile){
          margin-top: 4rem;
          padding: 0;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }

  .s-check {
    margin-top: 27rem;
    padding-bottom: 18rem;
    @media (--mobile){
      margin-top: 8rem;
      padding-bottom: 12rem;
    }

    &__lead {
      margin-top: 7rem;
      display: flex;
      flex-direction: column;
      gap: 4rem 0;
      @media (--mobile){
        margin-top: 5rem;
        gap: 3rem 0;
      }
      
      p {
        font-size: 1.6rem;
        font-weight: 400;
        letter-spacing: 0em;
        line-height: 1.8;
        color: #333333;
      }
    }

    ul {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 8.5rem 10rem;
      margin-top: 10rem;
      @media (--mobile){
        grid-template-columns: repeat(1, 1fr);
        gap: 5rem 0;
        margin-top: 7rem;
      }

      li {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        figure {
          width: 100%;
          height: auto;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }

        span {
          font-size: 2rem;
          font-weight: 500;
          letter-spacing: 0em;
          line-height: 1.8;
          color: #333333;
          text-align: center;
          display: block;
          &:nth-of-type( n + 3 ){
            margin-top: 2.3rem;
          }
          @media (--mobile){
            font-size: 1.8rem;
            text-align: left;
          }
        }
        &:nth-of-type( -n + 3 ){
          span {
            margin-top: 2.3rem;
          }
        }
      }
    }

    &__link {
      margin-top: 15rem;
      @media (--mobile){
        margin-top: 5rem;
      }
    
      a {
        display: flex;
        align-items: center;
        width: 38rem;
        margin-inline: auto;
        justify-content: center;
        gap: 0 1.4rem;
        font-size: 2rem;
        font-weight: 500;
        letter-spacing: 0.05em;
        color: #333333;
        border: 2px solid #333333;
        padding: 2.5rem 0;
        transition: .3s;
        @media (--mobile){
          width: 100%;
          margin-top: 4rem;
          font-size: 1.6rem;
          padding: 2rem 0;
        }

        span {
          display: flex;
          width: 3rem;
          height: 3rem;
          justify-content: center;
          align-items: center;
          border: 1px solid #C4C4C4;
          border-radius: 50%;
          transition: .3s;

          svg {
            
          }
        }
        @media (--large){
          &:hover {
            background-color: #333333;
            color: #fff;

            span {
              border: 1px solid #fff;

              svg {
                path {
                  stroke: #fff;
                }
              }
            }
          }
        }
      }
    }
  }

  .s-flow {
    margin-top: 11rem;
    padding-bottom: 18rem;
    @media (--mobile){
      margin-top: 8rem;
      padding-bottom: 12rem;
    }

    &__ttl {
      margin-top: 8.5rem;

      h3 {
        font-size: 2rem;
        font-weight: 600;
        letter-spacing: 0.05em;
        line-height: 1.8;
        color: #FFFFFF;
        background-color: #262727;
        text-align: center;
        padding: 2.7rem 0;
        @media (--mobile){
          width: 100vw;
          margin-left: -3rem;
          padding: 2.7rem 3rem;
          text-align: left;
        }
      }
    }

    &__table {
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      margin-top: 6rem;
      padding: 0 3rem;
      gap: 0 2rem;
      @media (--mobile){
        padding: 0;
      }

      & > div {
        &:nth-of-type( 1 ){
          
        }
        
        &:nth-of-type( 2 ){
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          & > div{
            
            &:nth-of-type( 1 ){
              background-color: #D0D0D0;
              text-align: center;
              height: 84.5rem;
              display: flex;
              justify-content: center;
              align-items: center;
              @media (--mobile){
                height: 83rem;
              }

              p {
                display: block;
                font-size: 1.8rem;
                font-weight: 400;
                letter-spacing: 0.05em;
                color: #1A1311;
                writing-mode: vertical-lr;
                @media (--mobile){
                  font-size: 1.6rem;
                }

                span {
                  text-orientation: upright;
                }
              }
            }
            
            &:nth-of-type( 2 ){
              height: 24.5rem;
              width: 5.6rem;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: #D0D0D0;
              @media (--mobile){
                height: 20.5rem;
              }

              p {
                display: block;
                font-size: 1.8rem;
                font-weight: 400;
                letter-spacing: 0.05em;
                color: #1A1311;
                writing-mode: vertical-lr;
                text-align: center;
                @media (--mobile){
                  font-size: 1.6rem;
                }
    
                span {
                  text-orientation: upright;
                }
              }
            }
          }
        }
      }
      
      .left {
        width: 15rem;
      }
      .center,
      .right {
        width: 40rem;
        padding: 2rem;
      }


      &-head {
        display: flex;

        .left {
          background-color: #262727;
          text-align: center;

          &.bg-white {
            background-color: #fff !important;
          }
        }

        .center {
          border-right: 1px solid #fff;
        }

        .center,
        .right {
          background-color: #262727;
          font-size: 1.8rem;
          font-weight: 400;
          letter-spacing: 0em;
          line-height: 1.2;
          color: #fff;
          text-align: center;
          padding: 1rem 0;
          @media (--mobile){
            font-size: 1.6rem;
          }
        }
      }

      &-body {
        display: flex;
        align-items: stretch;

        .left {
          background-color: #262727;
          text-align: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
          border-bottom: 1px solid #FFFFFF;

          &.bg-white {
            background-color: #fff !important;
          }

          &:nth-of-type( 1 ){
            padding: 6rem 0;
          }

          &:nth-of-type( 1 ){
            padding: 5rem 0;
          }

          small {
            font-size: 1.2rem;
            font-weight: 400;
            letter-spacing: 0.1em;
            line-height: 1.4;
            color: #fff;
            text-align: center;
          }

          span {
            font-size: 1.8rem;
            font-weight: 400;
            letter-spacing: 0em;
            line-height: 1.8;
            color: #fff;
            @media (--mobile){
              font-size: 1.6rem;
            }
          }
        }

        .center { 
          border-right: 1px solid #D0D0D0;
          border-bottom: 1px solid #D0D0D0;

          p {
            font-size: 1.5rem;
            font-weight: 400;
            letter-spacing: 0em;
            line-height: 2;
            color: #171717;
            @media (--mobile){
              font-size: 1.4rem;
            }

            &:nth-of-type( n + 2 ){
              margin-top: 1.2rem;
            }
            
            &.dot {
              position: relative;
              padding-left: 1.2rem;
              &::before {
                position: absolute;
                content: "";
                top: 1.3rem;
                left: 0;
                width: 0.5rem;
                height: 0.5rem;
                border-radius: 50%;
                background-color: #171717;
              }
            }
          }

          a {
            font-size: 1.5rem;
            font-weight: 400;
            letter-spacing: 0em;
            line-height: 2.1;
            color: #171717;
            display: block;
            width: fit-content;
            @media (--mobile){
              font-size: 1.4rem;
            }

            &.tel,
            &.line,
            &.mail {
              position: relative;
              padding-left: 3rem;
              &::before {
                position: absolute;
                content: "" ;
                top: 50%;
                transform: translateY(-50%);
                left: 0;
                width: 2.2rem;
                height: 2rem;
              }
            }

            &.tel {
              margin-top: 2.2rem;
              &::before {
                background: url(./../images/land-search/icon-tel.png) no-repeat center / contain;
              }
            }

            &.line {
              &::before {
                background: url(./../images/land-search/icon-line.png) no-repeat center / contain;
              }
            }

            &.mail {
              &::before {
                background: url(./../images/land-search/icon-mail.png) no-repeat center / contain;
              }
            }
          }
        }

        .right {
          border-right: 1px solid #D0D0D0;
          border-bottom: 1px solid #D0D0D0;

          p {
            font-size: 1.5rem;
            font-weight: 400;
            letter-spacing: 0em;
            line-height: 2;
            color: #171717;
            &:nth-of-type( n + 2 ){
              margin-top: 1.2rem;
            }

            &.ttl {
              color: #fff !important;
              text-align: center;
              background-color: #171717;
              border-radius: 3rem;
              display: block;
              width: 100%;
              padding: 1rem 0;
              margin-bottom: 1.5rem;
            }
            
            &.dot {
              position: relative;
              padding-left: 1.2rem;
              &::before {
                position: absolute;
                content: "";
                top: 1.3rem;
                left: 0;
                width: 0.5rem;
                height: 0.5rem;
                border-radius: 50%;
                background-color: #171717;
              }
            }
          }
        }
      }
    }
  }

  .s-case {
    margin-top: 11rem;
    padding-bottom: 18rem;
    @media (--mobile){
      margin-top: 8rem;
      padding-bottom: 12rem;
    }

    &__group {
      &:nth-of-type( n + 2 ){
        margin-top: 12rem;
        @media (--mobile){
          margin-top: 10rem;
        }
      }
      &:nth-of-type( 1 ){
        .s-case__heading {
          margin-top: 8.5rem;
          @media (--mobile){
            margin-top: 6rem;
          }
        }
      }
    }

    &__heading {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 0 8rem;
      align-items: center;
      @media (--mobile){
        grid-template-columns: repeat(1, 1fr);
        gap: 3.5rem 0;
      }

      &-left {
        width: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      &-right {
        width: 100%;

        h4 {
          font-size: 2.8rem;
          font-weight: 400;
          letter-spacing: 0em;
          line-height: 1.7;
          color: #333333;
          border-bottom: 1px solid #D0D0D0;
          @media (--mobile){
            font-size: 2.2rem;
          }
        }

        p {
          display: block;
          margin-top: 4rem;
          font-size: 2rem;
          font-weight: 400;
          letter-spacing: 0em;
          line-height: 1.2;
          color: #333333;
          @media (--mobile){
            font-size: 1.8rem;
          }
        }

        ul {
          margin-top: 2.4rem;
          li {
            position: relative;
            font-size: 1.5rem;
            font-weight: 400;
            letter-spacing: 0em;
            line-height: 1.6;
            color: #171717;
            padding-left: 1.2rem;
            &::before {
              position: absolute;
              top: 0.7rem;
              left: 0;
              content: "";
              width: 0.5rem;
              height: 0.5rem;
              background-color: #171717;
              border-radius: 50%;
            }
            &:nth-of-type( n + 2 ){
              margin-top: 1.6rem;
            }
          }
        }
      }
    }

    &__point {
      margin-top: 10rem;
      display: flex;
      @media (--mobile){
        margin-top: 6rem;
        flex-direction: column;
      }

      &-left {
        width: 50%;
        @media (--mobile){
          width: 100%;
        }

        & > span {
          position: relative;
          display: block;
          width: 100%;
          font-size: 2rem;
          font-weight: 600;
          letter-spacing: 0em;
          line-height: 1.2;
          color: #171717;
          background-color: #D0D0D0;
          text-align: center;
          padding: 2.3rem 0;
          @media (--mobile){
            font-size: 1.8rem;
          }
          &::before {
            position: absolute;
            top: 0;
            right: -1.4rem;
            content: "";
            border-top: 3.5rem solid transparent;
            border-bottom: 3.5rem solid transparent;
            border-left: 1.4rem solid #D0D0D0;
            z-index: 1;
            @media (--mobile){
              display: none;
            }
          }
        }

        &-img {
          margin-top: 6rem;
          padding-left: 5rem;
          margin-right: 4rem;
          @media (--mobile){
            margin-top: 4rem;
            padding-left: 0;
            margin-right: 0;
          }

          figure {
            width: 100%;
            height: auto;
            &:nth-of-type( 2 ){
              margin-top: 4rem;
              @media (--mobile){
                margin-top: 2rem;
              }
            }

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }

        ul {
          border: 1px solid #D0D0D0;
          margin: 6rem 4rem 0 5rem;
          padding: 2.5rem;
          @media (--mobile){
            margin: 2rem 0rem 0 0rem;
            padding: 2rem;
          }

          li {
            position: relative;
            padding-left: 1.5rem;
            font-size: 1.5rem;
            font-weight: 400;
            letter-spacing: 0em;
            line-height: 1.4;
            color: #171717;
            &::before {
              position: absolute;
              content: "";
              top: 1rem;
              left: 0;
              width: 0.5rem;
              height: 0.5rem;
              border-radius: 50%;
              background-color: #171717;
            }
            &:nth-of-type( n + 2 ){
              margin-top: 2rem;
            }
          }
        }
      }

      &-right {
        width: 50%;
        @media (--mobile){
          width: 100%;
          margin-top: 6rem;
        }

        & > span {
          position: relative;
          display: block;
          width: 100%;
          font-size: 2rem;
          font-weight: 600;
          letter-spacing: 0em;
          line-height: 1.2;
          color: #FFFFFF;
          background-color: #262727;
          text-align: center;
          padding: 2.3rem 0;
          @media (--mobile){
            font-size: 1.8rem;
          }
          &::before {
            position: absolute;
            top: 0;
            right: -1.4rem;
            content: "";
            border-top: 3.5rem solid transparent;
            border-bottom: 3.5rem solid transparent;
            border-left: 1.4rem solid #262727;
            z-index: 1;
            @media (--mobile){
              display: none;
            }
          }
        }

        &-img {
          margin-top: 6rem;
          padding-right: 5rem;
          margin-left: 4rem;
          @media (--mobile){
            margin-top: 4rem;
            padding-right: 0;
            margin-left: 0;
          }

          figure {
            width: 100%;
            height: auto;
            &:nth-of-type( 2 ){
              margin-top: 4rem;
              @media (--mobile){
                margin-top: 2rem;
              }
            }

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }
        ul {
          border: 1px solid #D0D0D0;
          margin: 6rem 5rem 0 4rem;
          padding: 2.5rem;
          @media (--mobile){
            margin: 2rem 0 0 0;
            padding: 2rem;
          }

          li {
            position: relative;
            padding-left: 1.5rem;
            font-size: 1.5rem;
            font-weight: 400;
            letter-spacing: 0em;
            line-height: 1.4;
            color: #171717;
            &::before {
              position: absolute;
              content: "";
              top: 1rem;
              left: 0;
              width: 0.5rem;
              height: 0.5rem;
              border-radius: 50%;
              background-color: #171717;
            }
            &:nth-of-type( n + 2 ){
              margin-top: 2rem;
            }
          }
        }
      }
    }

    &__episode {
      margin-top: 9rem;
      @media (--mobile){
        margin-top: 6rem;
      }

      h3 {
        
      }

      p {
        font-size: 1.6rem;
        font-weight: 400;
        letter-spacing: 0em;
        line-height: 1.9;
        color: #333333;
        margin-top: 4rem;
        display: block;
        @media (--mobile){
          margin-top: 3.5rem;
        }
      }

      a {
        margin-top: 7rem;
        display: flex;
        align-items: center;
        width: 38rem;
        margin-inline: auto;
        justify-content: center;
        gap: 0 1.4rem;
        font-size: 2rem;
        font-weight: 500;
        letter-spacing: 0.05em;
        color: #333333;
        border: 2px solid #333333;
        padding: 2.5rem 0;
        transition: .3s;
        @media (--mobile){
          width: 100%;
          margin-top: 4rem;
          font-size: 1.6rem;
          padding: 2rem 0;
        }

        span {
          display: flex;
          width: 3rem;
          height: 3rem;
          justify-content: center;
          align-items: center;
          border: 1px solid #C4C4C4;
          border-radius: 50%;
          transition: .3s;

          svg {
            
          }
        }
        @media (--large){
          &:hover {
            background-color: #333333;
            color: #fff;

            span {
              border: 1px solid #fff;

              svg {
                path {
                  stroke: #fff;
                }
              }
            }
          }
        }
      }
    }
  }

  .s-qa {
    margin-top: 10rem;
    @media (--mobile){
      margin-top: 8rem;
    }

    &__container {
      margin-top: 8rem;
    }

    &__wrap {
      padding-bottom: 2rem;
      border-top: 1px solid #D0D0D0;
      
      &:last-of-type {
        border-bottom: 1px solid #D0D0D0;
      }
    }

    &__q {
      position: relative;
      display: flex;
      align-items: start;
      cursor: pointer;
      padding-top: 5rem;
      padding-bottom: 3rem;
      &::before {
        position: absolute;
        content: "";
        top: 5rem;
        right: 3rem;
        width: 2rem;
        height: 2rem;
        border-right: 1px solid #707070;
        border-bottom: 1px solid #707070;
        transform: rotate(45deg);
        transition: .3s;
        @media (--mobile){
          top: 50%;
          transform: translateY(-50%) rotate(45deg);
          right: 0;
          width: 1.7rem;
          height: 1.7rem;
        }
      }
      @media (--mobile){
        padding-right: 4rem;
        adding-top: 4rem;
      }

      &.open {
        &::before {
          transform: rotate(-135deg);
          @media (--mobile){
            top: 55%;
            transform: translateY(-50%) rotate(-135deg);
          }
        }
      }

      span {
        display: block;
        width: 3.2rem;
        height: 3.6rem;
        flex-shrink: 0;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      p {
        display: block;
        margin-top: 0.5rem;
        margin-left: 3.6rem;
        font-size: 1.6rem;
        font-weight: 400;
        letter-spacing: 0em;
        line-height: 1.4;
        color: #333333;
        @media (--mobile){
          margin-left: 1.5rem;
        }
      }
    }

    &__a {
      display: none;
      p {
        display: block;
        margin-top: 0.5rem;
        padding: 0 8rem;
        font-size: 1.6rem;
        font-weight: 400;
        letter-spacing: 0em;
        line-height: 2;
        color: #333333;
        &:nth-of-type( n + 2 ){
          margin-top: 3.5rem;
          @media (--mobile){
            margin-top: 3rem;
          }
        }
        &:last-of-type {
          padding-bottom: 2rem;
        }
        &.pb-0 {
          padding-bottom: 0 !important;
        }
        @media (--mobile){
          padding: 0;
        }
      }

      ul {
        padding: 0 8rem;
        @media (--mobile){
          padding: 0;
        }
        li {
          position: relative;
          display: block;
          font-size: 1.6rem;
          font-weight: 400;
          letter-spacing: 0em;
          line-height: 2;
          color: #333333;
          padding-left: 1.5rem;

          &::before {
            position: absolute;
            content: "";
            top: 1.5rem;
            left: 0;
            width: 0.5rem;
            height: 0.5rem;
            border-radius: 50%;
            background-color: #333333;
          }
        }
      }
    }

    &__more {
      margin-top: 8.5rem;
      @media (--mobile){
        margin-top: 6.5rem;
      }

      a {
        font-size: 1.6rem;
        font-weight: 400;
        letter-spacing: 0em;
        line-height: 1.4;
        color: #333333;
        padding-bottom: 0.5rem;
        border-bottom: 1px solid #D0D0D0;
        display: block;
        width: fit-content;
        margin-inline: auto;
        transition: .3s;
        @media (--large){
          &:hover {
            opacity: 0.5;
          }
        }
      }
    }

    &__btn {
      margin-top: 7rem;
      display: flex;
      align-items: center;
      width: 38rem;
      margin-inline: auto;
      justify-content: center;
      gap: 0 1.4rem;
      font-size: 2rem;
      font-weight: 500;
      letter-spacing: 0.05em;
      color: #333333;
      border: 2px solid #333333;
      padding: 2.5rem 0;
      transition: .3s;
      @media (--mobile){
        width: 100%;
        margin-top: 4rem;
        font-size: 1.6rem;
        padding: 2rem 0;
      }

      span {
        display: flex;
        width: 3rem;
        height: 3rem;
        justify-content: center;
        align-items: center;
        border: 1px solid #C4C4C4;
        border-radius: 50%;
        transition: .3s;

        svg {
          
        }
      }
      @media (--large){
        &:hover {
          background-color: #333333;
          color: #fff;

          span {
            border: 1px solid #fff;

            svg {
              path {
                stroke: #fff;
              }
            }
          }
        }
      }
    }
  }
}

.scroll-hint-icon.scroll-hint-icon-white {
  display: none;
  @media (--mobile){
    display: block;
  }
}

.scroll-hint-icon {
  top: 35rem !important;
  width: 13rem !important;
  height: 9rem !important;
}