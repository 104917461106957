.c-marquee {
  animation-name: animationMarquee;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-duration: 90s;
  align-items: center;
  display: flex;
  @media (--mobile) {
    animation-duration: 60s;
  }
  li {
    white-space: nowrap;
  }
}
