.p-restaurant-detail{
	&-content{
		width: 98rem;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
		@media (--mobile) {
			width: 100%;
			display: block;
		}
		&__text{
			width: 68rem;
			@media (--mobile) {
				width: 100%;
				margin-top: 2rem;
			}
			p{
				font-size: 1.6rem;
				line-height: 3.2rem;
				margin-bottom: 4rem;
				@media (--mobile) {
					margin-bottom: 2rem;
				}
	
				&:last-child{
					margin-bottom: 0;
				}
			}
		}
	}
	&__img{
		margin-top: 12rem;
		margin-bottom: 12rem;
		@media (--mobile) {
			margin-top: 5rem;
			margin-bottom: 5rem;
		}
	}

	&-num{
		width: 98rem;
		margin: 0 auto;
		@media (--mobile) {
			width: 100%;
		}

		.c-leftTtl{
			margin-bottom: 4rem;
			@media (--mobile) {
				margin-bottom: 2rem;
			}
		}
		&__title{
			font-size: 2rem;
			margin-bottom: 3rem;
			@media (--mobile) {
				font-size: 1.8rem;
				margin-bottom: 1.5rem;
			}
			span{
				font-size: 1.8rem;
				font-family: 'EB Garamond', serif;
				margin-right: 0.7rem;
			}
		}
		&__text{
			margin-bottom: 7rem;
			font-size: 1.6rem;
			line-height: 3.2rem;
			&:last-of-type{
				margin-bottom: 0;
			}
			@media (--mobile) {
				margin-bottom: 3rem;
			}
		}
	}
	&-cell{
		width: 98rem;
		margin: 0 auto;
		@media (--mobile) {
			width: 100%;
		}

		&__title{
			font-size: 2rem;
			margin-bottom: 3rem;
			@media (--mobile) {
				font-size: 1.8rem;
				margin-bottom: 1.5rem;
			}
		}
		&__text{
			margin-bottom: 7rem;
			font-size: 1.6rem;
			line-height: 3.2rem;
			&:last-of-type{
				margin-bottom: 0;
			}
			@media (--mobile) {
				margin-bottom: 3rem;
			}
		}
	}
	&-left{
		display: flex;
		margin-top: 12rem;
		margin-bottom: 12rem;
		align-items: center;
    justify-content: space-between;
		@media (--mobile) {
			display: block;	
			margin-top: 6rem;
			margin-bottom: 6rem;
		}
		&__img{
			width: 56rem;
			@media (--mobile) {
				width: 100%;
				margin-bottom: 2rem;
			}
		}
		&__content{
			width: 48rem;
			@media (--mobile) {
				width: 100%;
			}
		}
		&__title{
			font-size: 2.6rem;
			margin-bottom: 5rem;
			@media (--mobile) {
				font-size: 2rem;
				margin-bottom: 2rem;
			}
		}
		&__text{
			p{
				font-size: 1.6rem;
				line-height: 3.2rem;
				margin-bottom: 4rem;
				@media (--mobile) {
					margin-bottom: 2rem;
				}
				&:last-of-type{
					margin-bottom: 0;
				}
			}
		}
	}
	&-gallery{
		margin-top: 8rem;
		margin-bottom: 8rem;
		@media (--mobile) {
			margin-top: 4rem;
			margin-bottom: 4rem;
		}
		ul{
			width: 118rem;
			display: flex;
			flex-wrap: wrap;
			@media (--mobile) {
				width: 100%;
			}
			li{
				width: 18rem;
				margin-right: 2rem;
				margin-bottom: 2rem;
				@media (--mobile) {
					width: 47%;
				}
				&:nth-child(6n){
					margin-right: 0;
				}
				@media (--mobile) {
					&:nth-child(2n){
						margin-right: 0;
					}
				}
			}
		}
	}
	&-layout{
		&__img{
			width: 82rem;
			margin: 0 auto 9rem auto;
			@media (--mobile) {
				width: 100%;
				margin-bottom: 4rem;
			}
		}

		dl{
			display: flex;
			flex-wrap: wrap;
			margin-top: 8rem;
			@media (--mobile) {
				display: block;
				margin-top: 4rem;
			}
		}
		dt{
			width: 20%;
			font-size: 1.6rem;
			font-weight: bold;
			padding: 4rem 0;
			border-bottom: 1px solid #D0D0D0;
			display: flex;
			align-items: flex-start;
			padding-left: 3rem;
			// margin-right: 10rem;
			&:first-of-type{
				border-top: 1px solid #D0D0D0;
				@media (--mobile) {
					border: none;
				}
			}
			@media (--mobile) {
				width: 100%;
				display: block;
				padding: 0;
				border: none;
			}
		}
		dd{
			width: 80%;
			font-size: 1.6rem;
			padding: 4rem 0;
			border-bottom: 1px solid #D0D0D0;
			line-height: 3.2rem;
			&:first-of-type{
				border-top: 1px solid #D0D0D0;
				@media (--mobile) {
					border: none;
				}
			}
			@media (--mobile) {
				width: 100%;
				border: none;
				padding: 2rem 0 4rem 0;
			}
		}


	}
	&-interview{
		width: 118rem;
		margin: 12rem auto 0 auto;
		display: flex;
		justify-content: space-between;
		@media (--mobile) {
			width: 100%;
			margin-top: 6rem;
			display: block;
		}


		&__title{
			font-size: 3.2rem;
			font-family: 'EB Garamond', serif;
			font-weight: Regular;
			@media (--mobile) {
				font-size: 2.5rem;
				text-align: center;
				margin-bottom: 2rem;
			}

			.jp{
				font-size: 1.5rem;
				margin-bottom: 1.4rem;
				display: block;
				@media (--mobile) {
					margin-bottom: 1rem;
					font-size: 1.2rem;
				}

			}
		}
		&__content{
			display: flex;
	    flex-wrap: wrap;
  	  justify-content: flex-end;
		}
		&__cell{
			width: 78rem;
			display: flex;
			border-bottom: 1px solid #D0D0D0;
			padding-bottom: 4rem;
			margin-bottom: 4rem;
			padding: 0 2rem 4rem 2rem;
			@media (--mobile) {
				width: 100%;
				display: block;
			}

		}
		&__cell:nth-child(even){
			flex-direction: row-reverse;
			.p-restaurant-detail-interview__text{
				margin-right: 0rem;
				margin-left: 3rem;
				@media (--mobile) {
					margin-left: 0;
				}

			}
		}
		&__text{
			width: 55rem;
			margin-right: 3rem;
			@media (--mobile) {
				width: 100%;
				margin-right: 0;
				margin-bottom: 3rem;
			}

		}
		&__heading{
			font-size: 2rem;
			margin-bottom: 1.4rem;
		}
		&__txt{
			font-size: 1.6rem;
			line-height: 3.2rem;
			margin-bottom: 1.3rem;
		}
		&__name{
			font-size: 2rem;
			margin-bottom: 2.5rem;
			span{
				font-size: 1.3rem;
				margin-right: 1.6rem;
			}
			span.city{
				font-size: 1.3rem;
				color: #7B7B7B;
				position: relative;
				margin-left: 2rem;
				&::before{
					position: absolute;
					display: block;
					content: "";
					width: 1px;
					height: 1.3rem;
					background-color: #7B7B7B;
					left: -1rem;
					top: 0;
				}
			}
		}
		&__more{
			display: flex;
			justify-content: flex-end;
			font-size: 1.6rem;
			margin-top: -2.2rem;
			@media (--mobile) {
				margin-top: 1rem;
			}

			img{
				margin-left: 1.5rem;
			}
		}
		&__img{
			width: calc(100% - 55rem - 3rem);
			@media (--mobile) {
				width: 100%;
				text-align: center;
			}

		}
	}
	.c-backBtn{
		width: 1080px;
		margin: 6rem auto 0 auto;
	}
}


