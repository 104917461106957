/**
* wordpress
* WYSIWYG compatchble style
*/
.c-wp-post{

  &_title {

  }
  &_date {

  }
  &_article {
    p {
    //  line-height: 2.4rem;
    //  margin:1.6rem 0;
    }
    //font-styles
    strong {
      font-weight:bold;
    }
    em {
      font-style: italic;
    }
    ul {
      margin-left: 1em;
      display: block;
      list-style-type: disc !important;
      margin-before: 1em;
      margin-after: 1em;
      margin-start: 0px;
      margin-end: 0px;
      padding-start: 40px;
    }
    ol {
      display: block;
      list-style-type: decimal !important;
      margin-before: 1em;
      margin-after: 1em;
      margin-start: 0px;
      margin-end: 0px;
      padding-start: 40px;
    }
    li {
      display: list-item;
      text-align: match-parent;
    }
    img {
      width: auto;
    }
    //position
    .alignleft {
      display: inline;
      float: left;
    }

    .alignright {
      display: inline;
      float: right;
    }

    .aligncenter {
      display: block;
      margin-right: auto;
      margin-left: auto;
    }

    blockquote.alignleft,
    .wp-caption.alignleft,
    img.alignleft {
      margin: 0.4em 1.6em 1.6em 0;
    }

    blockquote.alignright,
    .wp-caption.alignright,
    img.alignright {
      margin: 0.4em 0 1.6em 1.6em;
    }

    blockquote.aligncenter,
    .wp-caption.aligncenter,
    img.aligncenter {
      clear: both;
      margin-top: 0.4em;
      margin-bottom: 1.6em;
    }

    .wp-caption.alignleft,
    .wp-caption.alignright,
    .wp-caption.aligncenter {
      margin-bottom: 1.2em;
    }
  }
}
