@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500&family=Oswald:wght@400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Shippori+Mincho:wght@400;500;600&display=swap");
/**
 * Custom media queries
 * @example
 * @media (--mobile) {
 *    styles for mobile viewport
 * }
 */
/**
 * for mobile viewoport
 */
@import "normalize.css";
@import "swiper/swiper-bundle.min.css";
@import "modaal/dist/css/modaal.min.css";
@import url("https://fonts.googleapis.com/css2?family=EB+Garamond:wght@400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Shippori+Mincho+B1:wght@400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@500&Shippori+Mincho+B1&display=swap");
@custom-media --mini (max-width: 320px);
@custom-media --phone (max-width: 519px);
@custom-media --tablet (min-width: 520px) and (max-width: 959px);
@custom-media --mobile (max-width: 959px);
@custom-media --large (min-width: 960px);
/**
 * for tablet and Pc. large viewport
 */
@custom-media --large (min-width: 960px);
@custom-media --desktop(min-width: 1280px);
@custom-media --menu (max-width: 1280px);
/** http://bourbon.io/docs/#timing-functions */
/** example @include transition(all 5s $ease-in-circ);*/
/**
transition timing-function:
ease - cubic-bezier(0.25, 0.1, 0.25, 1.0) を指定したのと同じ（開始と完了を滑らかにする）（初期値）
linear - cubic-bezier(0.0, 0.0, 1.0, 1.0) を指定したのと同じ（一定）
ease-in - cubic-bezier(0.42, 0, 1.0, 1.0) を指定したのと同じ（ゆっくり始まる）
ease-out - cubic-bezier(0, 0, 0.58, 1.0) を指定したのと同じ（ゆっくり終わる）
ease-in-out - cubic-bezier(0.42, 0, 0.58, 1.0) を指定したのと同じ（ゆっくり始まってゆっくり終わる）
*/
/**
 * text ellipsis for block multiline
 */
/*
 unsupport IE, Edge, Fx

@mixin text-ellipsis-block($rows) {
  display: box;
  box-orient: vertical;
  line-clamp: $rows;
  overflow: hidden;
}
*/
/**
 * element size
 */
/**
 * position layout
 */
/**
 * images
 */
/**
 * xd line-height
 */
/**
 * PC VW
 */
/**
* Variables
*/
/** extend */
@media (--large) {
  .ex-link {
    opacity: 1;
    transition: opacity 0.2s linear;
  }
  .ex-link:hover {
    opacity: 0.5;
  }
}
@keyframes scroll {
  0% {
    bottom: -0.5rem;
  }
  50% {
    bottom: 0.5rem;
  }
  100% {
    bottom: -0.5rem;
  }
}
.js-inview[data-inview=fade] {
  opacity: 0;
  transition: opacity 1s;
}
.js-inview[data-inview=fade].is-inview {
  opacity: 1;
}
.js-inview[data-inview=fade-top] {
  opacity: 0;
  transform: translateY(30px);
  transition: opacity 1s, transform 1s;
}
.js-inview[data-inview=fade-top].is-inview {
  opacity: 1;
  transform: translateY(0);
}
.js-inview[data-inview=fade-left] {
  opacity: 0;
  transform: translateX(-30px);
  transition: opacity 1.5s, transform 1.5s;
}
.js-inview[data-inview=fade-left].is-inview {
  opacity: 1;
  transform: translateX(0);
}
.js-inview[data-inview=fade-right] {
  opacity: 0;
  transform: translateX(30px);
  transition: opacity 1.5s, transform 1.5s;
}
.js-inview[data-inview=fade-right].is-inview {
  opacity: 1;
  transform: translateX(0);
}
.js-inview[data-inview=fade-bottom] {
  opacity: 0;
  transform: translateY(-30px);
  transition: opacity 1.5s, transform 1.5s;
}
.js-inview[data-inview=fade-bottom].is-inview {
  opacity: 1;
  transform: translateY(0);
}
.js-inview[data-inview=scale] {
  transform: scale(1.2);
  transition: transform 2s cubic-bezier(0.3, 0.1, 0.1, 1);
}
.js-inview[data-inview=scale].is-inview {
  transform: scale(1);
}
.js-inview[data-inview=btn] a::before,
.js-inview[data-inview=btn] button::before {
  transform: scale(0, 1);
  transform-origin: left top;
  transition: transform 0.4s;
}
.js-inview[data-inview=btn] span {
  opacity: 0;
  transform: translateY(10px);
  transition: opacity 1.5s 0.3s, transform 1.5s 0.3s;
}
.js-inview[data-inview=btn] i {
  opacity: 0;
  transform: translateY(10px);
  transition: opacity 1.5s 0.3s, transform 1.5s 0.3s;
}
.js-inview[data-inview=btn].is-inview a::before,
.js-inview[data-inview=btn].is-inview button::before {
  transform: scale(1, 1);
}
.js-inview[data-inview=btn].is-inview span {
  opacity: 1;
  transform: translateY(0);
}
.js-inview[data-inview=btn].is-inview i {
  opacity: 1;
  transform: translateY(0);
}
.js-inview[data-inview=btn-border] a::after,
.js-inview[data-inview=btn-border] button::after {
  transform: scale(0, 1);
  transform-origin: left top;
  transition: transform 0.4s;
}
.js-inview[data-inview=btn-border] span {
  opacity: 0;
  transform: translateY(10px);
  transition: opacity 1.5s 0.3s, transform 1.5s 0.3s;
}
.js-inview[data-inview=btn-border].is-inview a::after,
.js-inview[data-inview=btn-border].is-inview button::after {
  transform: scale(1, 1);
}
.js-inview[data-inview=btn-border].is-inview span {
  opacity: 1;
  transform: translateY(0);
}
.js-inview[data-inview=bg]::after {
  content: "";
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(1, 1);
  transform-origin: right top;
  transition: transform 0.4s;
  width: 100%;
}
.js-inview[data-inview=bg].is-inview::after {
  transform: scale(0, 1);
}
.js-inview[data-inview=bg-white]::after {
  background-color: #fff;
  content: "";
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(1, 1);
  transform-origin: right top;
  transition: transform 0.4s;
  width: 100%;
}
.js-inview[data-inview=bg-white].is-inview::after {
  transform: scale(0, 1);
}
.js-inview[data-inview=bg-scale]::before {
  transform: translate(-50%, -50%) scale(0.5);
  transform-origin: center center;
  transition: transform 1.5s;
}
.js-inview[data-inview=bg-scale].is-inview::before {
  transform: translate(-50%, -50%) scale(1);
}
.js-inview[data-inview=clip] {
  clip-path: inset(0 0 0 100%);
  transition: clip-path 1.5s 0.3s;
}
.js-inview[data-inview=clip].is-inview {
  clip-path: inset(0 0 0 0);
}
.js-inview-photo[data-inview=scale-small] {
  transform: scale(1.5);
  transition: transform 1s;
}
.is-inview .js-inview-photo[data-inview=scale-small] {
  transform: scale(1);
}

.js-inview-photo-img[data-inview=scale-small] {
  transform: translateX(-50%) scale(1.5);
  transition: transform 1s;
}
.is-inview .js-inview-photo-img[data-inview=scale-small] {
  transform: translateX(-50%) scale(1);
}

.js-inview-eachItem[data-inview=fade] {
  opacity: 0;
  transition: opacity 1.5s;
}
.js-inview-eachItem[data-inview=fade].is-inview {
  opacity: 1;
}
.js-inview-eachItem[data-inview=fade-top] {
  opacity: 0;
  transform: translateY(30px);
  transition: opacity 1.5s, transform 1.5s;
}
.js-inview-eachItem[data-inview=fade-top].is-inview {
  opacity: 1;
  transform: translateY(0);
}
.js-inview-eachItem[data-inview=fade-bottom] {
  opacity: 0;
  transform: translateY(-30px);
  transition: opacity 1.5s, transform 1.5s;
}
.js-inview-eachItem[data-inview=fade-bottom].is-inview {
  opacity: 1;
  transform: translateY(0);
}
.js-inview-eachItem[data-inview=fade-right] {
  opacity: 0;
  transform: translateX(30px);
  transition: opacity 1.5s, transform 1.5s;
}
.js-inview-eachItem[data-inview=fade-right].is-inview {
  opacity: 1;
  transform: translateX(0);
}
.js-inview-eachItem[data-inview=btn] a::before,
.js-inview-eachItem[data-inview=btn] button::before {
  transform: scale(0, 1);
  transform-origin: left top;
  transition: transform 0.4s;
}
.js-inview-eachItem[data-inview=btn] span {
  opacity: 0;
  transform: translateY(10px);
  transition: opacity 1.5s 0.3s, transform 1.5s 0.3s;
}
.js-inview-eachItem[data-inview=btn].is-inview a::before,
.js-inview-eachItem[data-inview=btn].is-inview button::before {
  transform: scale(1, 1);
}
.js-inview-eachItem[data-inview=btn].is-inview span {
  opacity: 1;
  transform: translateY(0);
}
.js-inview-eachItem[data-inview=btn-border] a::after,
.js-inview-eachItem[data-inview=btn-border] button::after {
  transform: scale(0, 1);
  transform-origin: left top;
  transition: transform 0.4s;
}
.js-inview-eachItem[data-inview=btn-border] span {
  opacity: 0;
  transform: translateY(10px);
  transition: opacity 1.5s 0.3s, transform 1.5s 0.3s;
}
.js-inview-eachItem[data-inview=btn-border].is-inview a::after,
.js-inview-eachItem[data-inview=btn-border].is-inview button::after {
  transform: scale(1, 1);
}
.js-inview-eachItem[data-inview=btn-border].is-inview span {
  opacity: 1;
  transform: translateY(0);
}
.js-inview-eachItem[data-inview=list-border]::before {
  transform: scale(0, 1);
  transform-origin: left top;
  transition: transform 1.5s;
}
.js-inview-eachItem[data-inview=list-border] span,
.js-inview-eachItem[data-inview=list-border] figure,
.js-inview-eachItem[data-inview=list-border] a {
  opacity: 0;
  transform: translateY(30px);
  transition: opacity 1.5s, transform 1.5s;
}
.js-inview-eachItem[data-inview=list-border] i {
  opacity: 0;
  transform: translateX(-20px) rotate(45deg);
  transition: opacity 1.5s, transform 1.5s;
}
.js-inview-eachItem[data-inview=list-border].is-inview::before {
  transform: scale(1);
}
.js-inview-eachItem[data-inview=list-border].is-inview span,
.js-inview-eachItem[data-inview=list-border].is-inview figure,
.js-inview-eachItem[data-inview=list-border].is-inview a {
  opacity: 1;
  transform: translateY(0);
}
.js-inview-eachItem[data-inview=list-border].is-inview i {
  opacity: 1;
  transform: translateX(-10px) rotate(45deg);
}
.js-inview-eachItem[data-inview=clip] {
  clip-path: inset(0 100% 0 0);
  transition: clip-path 1.5s 0.3s;
}
.js-inview-eachItem[data-inview=clip].is-inview {
  clip-path: inset(0 0 0 0);
}
.js-inview-eachItem-fast[data-inview=fade-top] {
  opacity: 0;
  transform: translateY(10px);
  transition: opacity 1.5s, transform 1.5s;
}
.js-inview-eachItem-fast[data-inview=fade-top].is-inview {
  opacity: 1;
  transform: translateY(0);
}
.js-inview-eachItem-delay[data-inview=fade] {
  opacity: 0;
  transition: transform 1.5s;
}
.js-inview-eachItem-delay[data-inview=fade].is-inview {
  opacity: 1;
  transform: translateY(0);
}
.js-inview-eachItem-delay[data-inview=fade-top] {
  opacity: 0;
  transform: translateY(30px);
  transition: opacity 1.5s, transform 1.5s;
}
.js-inview-eachItem-delay[data-inview=fade-top].is-inview {
  opacity: 1;
  transform: translateY(0);
}
.js-inview-eachItem-delay[data-inview=fade-right] {
  opacity: 0;
  transform: translateX(30px);
  transition: opacity 1.5s, transform 1.5s;
}
.js-inview-eachItem-delay[data-inview=fade-right].is-inview {
  opacity: 1;
  transform: translateY(0);
}
.js-inview-eachItem-delay[data-inview=fade-bottom] {
  opacity: 0;
  transform: translateY(-30px);
  transition: opacity 1.5s, transform 1.5s;
}
.js-inview-eachItem-delay[data-inview=fade-bottom].is-inview {
  opacity: 1;
  transform: translateY(0);
}
.js-inview-eachItem-delay[data-inview=clip] {
  clip-path: inset(0 0 0 100%);
  transition: clip-path 1.5s 0.3s;
}
.js-inview-eachItem-delay[data-inview=clip].is-inview {
  clip-path: inset(0 0 0 0);
}

.js-inview-all[data-inview=btn] a::before,
.js-inview-all[data-inview=btn] button::before {
  transform: scale(0, 1);
  transform-origin: left top;
  transition: transform 0.4s;
}
.js-inview-all[data-inview=btn] span {
  opacity: 0;
  transform: translateY(10px);
  transition: opacity 1.5s 0.3s, transform 1.5s 0.3s;
}
.js-inview-all[data-inview=btn].is-inview a::before,
.js-inview-all[data-inview=btn].is-inview button::before {
  transform: scale(1, 1);
}
.js-inview-all[data-inview=btn].is-inview span {
  opacity: 1;
  transform: translateY(0);
}
.js-inview-all[data-inview=btn-border] a::after,
.js-inview-all[data-inview=btn-border] button::after {
  transform: scale(0, 1);
  transform-origin: left top;
  transition: transform 0.4s;
}
.js-inview-all[data-inview=btn-border] span {
  opacity: 0;
  transform: translateY(10px);
  transition: opacity 1.5s 0.3s, transform 1.5s 0.3s;
}
.js-inview-all[data-inview=btn-border].is-inview a::after,
.js-inview-all[data-inview=btn-border].is-inview button::after {
  transform: scale(1, 1);
}
.js-inview-all[data-inview=btn-border].is-inview span {
  opacity: 1;
  transform: translateY(0);
}
.js-inview-all[data-inview=clip] {
  clip-path: inset(0 0 0 100%);
  transition: clip-path 1.5s 0.3s;
}
.js-inview-all[data-inview=clip].is-inview {
  clip-path: inset(0 0 0 0);
}

_:-ms-lang(x)::-ms-backdrop, .js-inview-photo[data-inview=scale-small] {
  transform: scale(1);
}

_:-ms-lang(x)::-ms-backdrop, .js-inview-photo-img[data-inview=scale-small] {
  transform: translateX(-50%) scale(1);
}

/* == @import "../node_modules/normalize.css/normalize.css"; */
[v-cloak] {
  display: none;
}

html {
  font-size: 62.5%;
  background: #fff;
}
@media (--mobile) {
  html {
    font-size: 2.6666666667vw;
  }
}

body {
  font-family: "Shippori Mincho", serif;
  font-size: 1rem;
  letter-spacing: 0.15em;
  font-feature-settings: "palt";
  line-height: 1.5;
}

a {
  color: #000000;
  text-decoration: none;
}

img {
  max-width: 100%;
}

svg {
  width: 100%;
}

*, *:before, *:after {
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6, div, dl, dt, dd, p {
  margin: 0;
  padding: 0;
}

ul, ol, li {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

em {
  font-style: initial;
}

img {
  vertical-align: bottom;
}

figure {
  margin: 0;
  padding: 0;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

@media (--mobile) {
  html, body {
    width: 100%;
    height: 100%;
  }
  h1, h2, h3, h4, h5, h6, div, dl, dt, dd, p {
    margin: 0;
    padding: 0;
  }
  img {
    height: auto;
    max-height: 100%;
    max-width: 100%;
    width: auto;
  }
}
@media (--large) {
  .sp {
    display: none !important;
  }
  [href*="tel:"] {
    pointer-events: none;
  }
}
@media (--mobile) {
  .pc {
    display: none !important;
  }
}
input, textarea, button {
  appearance: none;
  border: none;
  background: none;
  outline: none;
}

.l-footer {
  position: relative;
  overflow: hidden;
  z-index: 2;
}
.l-footer__bg {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  transform: scale(1.3);
}
.l-footer__bg img {
  height: 100%;
  width: 100%;
  transform: translateY(10rem);
  object-fit: cover;
}
.l-footer .f-contact {
  position: relative;
  z-index: 1;
  margin: 0 0 0 auto;
  padding: 8rem 0;
}
@media (--mobile) {
  .l-footer .f-contact {
    width: auto;
    padding: 5rem 2rem;
  }
}
.l-footer .f-contact__inner {
  width: 100rem;
  margin-left: auto;
  margin-right: auto;
}
@media (--mobile) {
  .l-footer .f-contact__inner {
    width: auto;
  }
}
.l-footer .f-contact__ttl {
  text-align: center;
  color: #fff;
  font-size: 1.6rem;
  font-weight: lighter;
}
@media (--mobile) {
  .l-footer .f-contact__ttl {
    line-height: 1.8;
  }
}
.l-footer .f-contact__ttl strong {
  display: block;
  font-size: 1.8rem;
  letter-spacing: 0.15em;
}
.l-footer .f-contact__ttl span {
  display: inline-block;
}
@media (--mobile) {
  .l-footer .f-contact__ttl span {
    display: inline-block;
    width: 21rem;
  }
  .l-footer .f-contact__ttl span img {
    width: 100%;
  }
}
.l-footer .f-contact__txt {
  text-align: center;
  color: #fff;
  letter-spacing: 0.2em;
  font-size: 1.8rem;
  margin-top: 3.2rem;
}
@media (--mobile) {
  .l-footer .f-contact__txt {
    line-height: 2;
  }
}
.l-footer .f-contact__flex-wrap {
  display: flex;
  justify-content: space-between;
  margin-top: 4rem;
}
@media (--mobile) {
  .l-footer .f-contact__flex-wrap {
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
.l-footer .f-contact__flex-wrap--ele {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 14rem;
  text-align: center;
  font-size: 1.6rem;
  color: #fff;
}
@media (--mobile) {
  .l-footer .f-contact__flex-wrap--ele {
    height: 9rem;
    padding-top: 0;
  }
  .l-footer .f-contact__flex-wrap--ele:last-of-type {
    width: 100%;
  }
}
.l-footer .f-contact__flex-wrap--ele figure {
  width: 3.6rem;
  min-height: 4rem;
  margin-left: auto;
  margin-right: auto;
}
@media (--mobile) {
  .l-footer .f-contact__flex-wrap--ele figure {
    min-height: auto;
  }
}
.l-footer .f-contact__flex-wrap--ele figure svg path {
  transition: 0.3s fill;
  fill: #fff;
}
.l-footer .f-contact__flex-wrap a {
  background: rgba(0, 0, 0, 0.6);
}
.l-footer .f-contact__flex-wrap--mail, .l-footer .f-contact__flex-wrap--line {
  width: 32rem;
  transition: 0.3s background, 0.3s color;
}
@media (--mobile) {
  .l-footer .f-contact__flex-wrap--mail, .l-footer .f-contact__flex-wrap--line {
    display: flex;
    align-items: center;
    width: 15.7rem;
    height: 13rem;
    padding-top: 1rem;
  }
  .l-footer .f-contact__flex-wrap--mail figure, .l-footer .f-contact__flex-wrap--line figure {
    margin-bottom: 0.5rem;
    height: 3rem;
  }
  .l-footer .f-contact__flex-wrap--mail figure svg, .l-footer .f-contact__flex-wrap--line figure svg {
    height: 2rem;
  }
}
.l-footer .f-contact__flex-wrap--mail:hover, .l-footer .f-contact__flex-wrap--line:hover {
  background: rgba(255, 255, 255, 0.7);
  color: #171717;
}
.l-footer .f-contact__flex-wrap--mail:hover svg path, .l-footer .f-contact__flex-wrap--line:hover svg path {
  fill: #171717;
}
.l-footer .f-contact__flex-wrap--line svg {
  width: 4rem;
  position: relative;
  top: -0.4rem;
}
@media (--mobile) {
  .l-footer .f-contact__flex-wrap--line figure {
    position: relative;
    top: -0.5rem;
  }
  .l-footer .f-contact__flex-wrap--line figure svg {
    height: 3rem;
    position: relative;
    top: -0.2rem;
  }
}
@media (--large) {
  .l-footer .f-contact__flex-wrap--tel {
    width: 32rem;
  }
}
@media (--mobile) {
  .l-footer .f-contact__flex-wrap--tel {
    margin-top: 2rem !important;
    height: 11rem;
    padding-top: 1rem;
  }
}
.l-footer .f-contact__flex-wrap--tel figure {
  width: 23.7rem;
  margin-bottom: 0.6rem;
}
.l-footer .f-menu {
  position: relative;
  background: rgba(23, 23, 23, 0.8);
  display: flex;
  justify-content: center;
  padding-top: 11.5rem;
  padding-bottom: 11.5rem;
  z-index: 1;
}
@media (--mobile) {
  .l-footer .f-menu {
    display: block;
    margin-top: 0;
    padding-top: 4rem;
    padding-bottom: 8rem;
    padding-left: 4rem;
    padding-right: 4rem;
  }
}
.l-footer .f-menu__box {
  min-width: 8rem;
}
@media (--large) {
  .l-footer .f-menu__logo {
    margin-right: 9.8rem;
    width: 11rem;
  }
}
@media (--mobile) {
  .l-footer .f-menu__logo {
    width: 7rem;
  }
}
.l-footer .f-menu__logo svg {
  width: 11rem;
}
@media (--mobile) {
  .l-footer .f-menu__logo svg {
    width: 7rem;
  }
}
.l-footer .f-menu__logo path {
  fill: #fff;
}
.l-footer .f-menu__logo p {
  font-size: 1.2rem;
  color: #9B9B9B;
  margin-top: 2rem;
}
.l-footer .f-menu__sns {
  display: flex;
}
@media (--mobile) {
  .l-footer .f-menu__sns {
    flex-direction: row !important;
    justify-content: left !important;
  }
}
.l-footer .f-menu__sns a {
  display: block;
}
@media (--mobile) {
  .l-footer .f-menu__sns a {
    display: flex;
  }
}
.l-footer .f-menu__sns a:nth-child(n+2) {
  margin-left: 1.4rem;
}
.l-footer .f-menu__sns a svg {
  width: 100%;
}
@media (--mobile) {
  .l-footer .f-menu__sns a svg {
    width: auto;
  }
}
.l-footer .f-menu__right {
  width: 90rem;
}
@media (--mobile) {
  .l-footer .f-menu__right {
    width: 100%;
    margin-top: 3.3rem;
  }
}
.l-footer .f-menu__listWrap {
  display: flex;
}
@media (--mobile) {
  .l-footer .f-menu__listWrap {
    display: block;
  }
}
@media (--large) {
  .l-footer .f-menu__listWrap ul {
    width: 34rem;
    margin-right: 4.2rem;
  }
}
.l-footer .f-menu__listWrap ul:last-of-type {
  margin-right: 0;
}
@media (--mobile) {
  .l-footer .f-menu__listWrap ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: column;
  }
  .l-footer .f-menu__listWrap ul.sp-m-top {
    margin-top: 3rem;
  }
  .l-footer .f-menu__listWrap ul.sp-m-top-mini {
    margin-top: 3rem;
  }
}
.l-footer .f-menu__listWrap ul li {
  font-weight: lighter;
}
@media (--mobile) {
  .l-footer .f-menu__listWrap ul li {
    width: 100%;
  }
}
.l-footer .f-menu__listWrap ul li, .l-footer .f-menu__listWrap ul li * {
  color: #fff;
}
@media (--large) {
  .l-footer .f-menu__listWrap ul li:nth-of-type(n + 2) {
    margin-top: 2.2rem;
  }
}
@media (--mobile) {
  .l-footer .f-menu__listWrap ul li:nth-of-type(n + 2) {
    margin-top: 1.5rem;
  }
}
@media (--mobile) {
  .l-footer .f-menu__listWrap ul li.sp-w-all {
    width: 100%;
    margin-top: 5rem !important;
  }
  .l-footer .f-menu__listWrap ul li.sp-w-all + li {
    margin-top: 2.5rem;
  }
}
.l-footer .f-menu__listWrap ul li a {
  display: block;
  position: relative;
  min-height: 2.4rem;
  display: inline-block;
}
@media (--mobile) {
  .l-footer .f-menu__listWrap ul li a {
    font-size: 1.3rem;
  }
}
.l-footer .f-menu__listWrap ul li a::before {
  content: "";
  margin: auto;
  position: absolute;
  left: 0;
  bottom: -0.2rem;
  transition: transform 0.3s, -webkit-transform 0.3s;
  transform: scale(0, 1);
  transform-origin: right center;
  width: 100%;
  height: 0.1rem;
  background: rgba(255, 255, 255, 0.7);
}
.l-footer .f-menu__listWrap ul li a:hover::before {
  transform: scale(1, 1);
  transform-origin: left center;
}
.l-footer .f-menu__listWrap ul li.tsukushi, .l-footer .f-menu__listWrap ul li.tsukushi * {
  color: #BABABA;
  font-size: 1.4rem;
  font-family: inherit;
}
@media (--mobile) {
  .l-footer .f-menu__listWrap ul li.tsukushi, .l-footer .f-menu__listWrap ul li.tsukushi * {
    font-size: 1.3rem;
  }
}
.l-footer .f-menu__listWrap ul li.tsukushi::after, .l-footer .f-menu__listWrap ul li.tsukushi *::after {
  border-color: #BABABA;
}
.l-footer .f-menu__mainList {
  margin-right: 10rem !important;
}
@media (--mobile) {
  .l-footer .f-menu__mainList {
    margin-right: 0 !important;
  }
}
.l-footer .f-menu__mainList button, .l-footer .f-menu__mainList a {
  font-size: 1.7rem;
  padding: 0;
}
@media (--mobile) {
  .l-footer .f-menu__mainList button, .l-footer .f-menu__mainList a {
    font-size: 2.4rem !important;
  }
}
.l-footer .f-menu__subList {
  font-size: 1.3rem;
}
@media (--mobile) {
  .l-footer .f-menu__subList {
    margin-top: 3rem;
  }
}
.l-footer .f-menu__subList:nth-child(n+2) li {
  margin-top: 1.6rem !important;
}
.l-footer .f-menu__firstsub {
  margin-right: 10rem !important;
}
@media (--mobile) {
  .l-footer .f-menu__firstsub {
    margin-right: 0 !important;
  }
}
.l-footer .f-menu__acco-event {
  pointer-events: inherit;
  position: relative;
}
@media (--large) {
  .l-footer .f-menu__acco-event {
    pointer-events: none;
  }
}
.l-footer .f-menu__acco-event.open .drawer-works-box::before {
  transform: rotate(0deg);
}
.l-footer .f-menu__acco {
  display: flex;
  justify-content: space-between;
  margin-left: 1rem;
}
.l-footer .f-menu__acco li {
  margin-top: 1.5rem !important;
}
.l-footer .f-menu__acco li a {
  font-size: 1.4rem !important;
}
@media (--large) {
  .l-footer .f-menu__acco-inner {
    display: block;
  }
}
@media (--mobile) {
  .l-footer .f-menu__acco-inner {
    display: none;
  }
}
.l-footer .f-menu__sns {
  display: flex;
  justify-content: flex-start;
  margin-top: 3.5rem;
}
@media (--mobile) {
  .l-footer .f-menu__sns {
    margin-bottom: 1.5rem;
  }
}
.l-footer .f-menu__sns ul {
  display: flex;
  margin-bottom: 1.3rem;
}
@media (--mobile) {
  .l-footer .f-menu__sns ul {
    margin-bottom: 0;
  }
}
.l-footer .f-menu__sns ul li {
  margin-right: 1.5rem;
}
.l-footer .f-menu__sns ul li a {
  transition: 0.3s opacity;
}
.l-footer .f-menu__sns ul li a:hover {
  opacity: 0.6;
}
.l-footer .f-menu__other {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding-top: 3rem;
  border-top: 1px solid #707070;
}
.l-footer .f-menu__other ul {
  display: flex;
  align-items: center;
}
@media (--mobile) {
  .l-footer .f-menu__other ul {
    flex-wrap: wrap;
  }
}
.l-footer .f-menu__other ul li a {
  color: #707070;
  font-size: 1.2rem;
  border-right: 1px solid #707070;
  padding: 0.2rem 1.4rem;
  transition: 0.5s;
}
.l-footer .f-menu__other ul li a:hover {
  color: #fff;
}
.l-footer .f-menu__other ul li:first-of-type a {
  padding-left: 0;
}
@media (--mobile) {
  .l-footer .f-menu__other ul li:nth-of-type(3) a {
    padding-left: 0;
  }
  .l-footer .f-menu__other ul li:nth-of-type(n + 3) {
    margin-top: 1rem;
  }
}
.l-footer .f-menu__other p {
  font-size: 1.2rem;
  color: #707070;
}
@media (--mobile) {
  .l-footer .f-menu__other p {
    white-space: nowrap;
  }
}
.l-footer .f-menu__contact {
  color: #fff;
}
.l-footer .f-menu__contact .drawer__bottomWrap path {
  fill: #fff;
}
.l-footer .f-menu__contact .drawer__contact-text {
  color: #fff;
}
.l-footer .f-menu__copy {
  margin-top: 2rem;
  color: #fff;
  font-size: 1.6rem;
}

.l-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 997;
  background-color: transparent;
  height: 8rem;
  padding: 0 2rem;
  display: flex;
  transition: 0.3s;
  width: 100%;
}
.l-header a {
  display: inline-block;
}
.l-header.top-active path {
  transition: 0.3s;
  fill: #fff;
}
.l-header.is-active {
  background-color: #fff;
}
.l-header.is-active.top-active {
  background-color: #fff;
}
.l-header.is-active.top-active path {
  fill: #171717;
}
@media (--mobile) {
  .l-header {
    height: 6rem;
  }
}
.l-header__logo {
  width: 7rem;
  display: flex;
  align-items: flex-start;
  transform: none;
}
@media (--mobile) {
  .l-header__logo {
    justify-content: center;
  }
}
.l-header__logo a {
  display: flex;
  align-items: flex-start;
}
.l-header__logo path {
  fill: #171717;
}
.l-header__drawer {
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  font-family: "EB Garamond", serif;
  font-weight: lighter;
  cursor: pointer;
  color: #fff;
  width: 18rem;
  height: 8rem;
  background: #171717;
  z-index: 999;
}
@media (--mobile) {
  .l-header__drawer {
    width: 15.3rem;
    height: 6rem;
  }
}
.l-header__drawer strong {
  text-align: center;
  width: 10rem;
  display: inline-block;
}
@media (--mobile) {
  .l-header__drawer strong {
    width: 9rem;
  }
}
.l-header__drawer div {
  position: relative;
  top: -0.2rem;
  height: 1.2rem;
  width: 2rem;
  width: calc(100% - 10rem);
}
@media (--mobile) {
  .l-header__drawer div {
    width: calc(100% - 9rem);
  }
}
.l-header__drawer div::before {
  content: "";
  position: absolute;
  height: 2.8rem;
  width: 0.1rem;
  background: #727272;
  top: 50%;
  transform: translateY(-50%);
}
.l-header__drawer div span {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  width: 2rem;
  height: 0.2rem;
  background: #fff;
  border-radius: 9999px;
  transition: 0.5s;
}
.l-header__drawer div span:first-of-type {
  top: 0;
}
.is-active .l-header__drawer div span:first-of-type {
  transform: translateX(-50%) translateY(-50%) rotate(30deg);
  top: 50%;
}

.l-header__drawer div span:last-of-type {
  bottom: 0;
}
.is-active .l-header__drawer div span:last-of-type {
  transform: translateX(-50%) translateY(50%) rotate(-30deg);
  bottom: 50%;
}

.l-header__reserve {
  transition: 0.3s;
  position: fixed;
  top: 0;
  right: 18rem;
  padding: 0 3rem;
  border-right: 1px solid #727272;
  height: 8rem;
  background: #171717;
  z-index: 999;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.6rem;
}
.l-header__reserve:hover {
  opacity: 0.7;
}
.l-header__reserve2 {
  transition: 0.3s;
  position: fixed;
  top: 0;
  right: 39.8rem;
  padding: 0 3rem;
  border-right: 1px solid #727272;
  height: 8rem;
  background: #171717;
  z-index: 999;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.6rem;
}
.l-header__reserve2:hover {
  opacity: 0.7;
}
.l-header__fixedBanner {
  position: fixed;
  z-index: 99;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  opacity: 0;
  transition: 0.5s;
  pointer-events: none;
}
.l-header__fixedBanner.is-active {
  opacity: 1;
  pointer-events: inherit;
}
.l-header__fixedBanner > div {
  height: 5rem;
  position: relative;
}
.l-header__fixedBanner > div > a {
  width: 100%;
  height: 100%;
  display: flex;
  background: #171717;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  font-size: 1.4rem;
  line-height: 1.3;
}
.l-header__fixedBanner > div::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 1px;
  background: #727272;
  top: 99%;
  left: 0;
}
.l-header__fixedBanner > ul {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.l-header__fixedBanner > ul > li {
  position: relative;
}
.l-header__fixedBanner > ul > li a {
  width: 100%;
  background: #171717;
  height: 5rem;
  justify-content: center;
  display: flex;
  align-items: center;
  color: #fff;
  text-align: center;
  font-size: 1.4rem;
  line-height: 1.3;
}
.l-header__fixedBanner > ul > li:last-child::before {
  position: absolute;
  content: "";
  width: 1px;
  height: 100%;
  background: #727272;
  top: 0;
  left: 0;
}

.drawer {
  overflow-y: scroll;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 998;
  background: #fff;
  opacity: 0;
  transition: 1s;
  pointer-events: none;
  min-height: 100vh;
}
@media (--mobile) {
  .drawer {
    min-height: 100vh;
    padding-bottom: 12rem;
    top: 0;
    left: 0;
    overflow-y: scroll;
    height: 100vh;
    align-items: flex-start;
  }
}
.drawer.is-active {
  opacity: 1;
  pointer-events: inherit;
}
.drawer-works-wrap {
  position: relative;
}
.drawer-works-icon {
  position: absolute;
  left: 10rem;
  top: 0px;
  transform: scale(0.6);
}
@media (--mobile) {
  .drawer-works-icon {
    left: 10rem;
    transform: scale(0.7);
  }
}
.drawer-works-box {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  color: #333;
  line-height: 1;
  width: 1em;
  height: 0.08em;
  background: currentColor;
  position: relative;
  margin-bottom: 0.6rem;
}
.drawer-works-box::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: inherit;
  border-radius: inherit;
  transform: rotate(90deg);
  transition: 0.3s;
}
.drawer-works-acco {
  font-size: 2.2rem;
  padding: 0;
  position: relative;
  color: #333;
  cursor: pointer;
}
.drawer-works-acco::before {
  content: "";
  margin: auto;
  position: absolute;
  left: 0;
  bottom: -0.2rem;
  transition: transform 0.3s, -webkit-transform 0.3s;
  transform: scale(0, 1);
  transform-origin: right center;
  width: 100%;
  height: 0.1rem;
  background: #171717;
}
@media (--large) {
  .drawer-works-acco:hover::before {
    transform: scale(1, 1);
    transform-origin: left center;
  }
}
@media (--mobile) {
  .drawer-works-acco {
    font-size: 2.2rem;
    padding-bottom: 0;
  }
}
.drawer-works-acco.open .drawer-works-box::before {
  transform: rotate(0);
}
.drawer-works {
  display: flex;
  margin-top: 1.2rem;
  margin-left: 1rem;
  display: none;
}
@media (--mobile) {
  .drawer-works {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
.drawer-works ul {
  display: flex;
}
@media (--mobile) {
  .drawer-works ul {
    flex-direction: column;
  }
}
.drawer-works li {
  margin-top: 0 !important;
  font-size: 1.4rem;
}
.drawer-works li:nth-child(n+2) {
  margin-left: 3rem;
}
@media (--mobile) {
  .drawer-works li:nth-child(n+2) {
    margin-left: 0;
    margin-top: 1rem !important;
  }
}
.drawer__logo {
  display: inline-block;
  margin-bottom: 3.3rem;
}
@media (--mobile) {
  .drawer__logo {
    display: flex;
    justify-content: left;
    margin-bottom: 2rem;
  }
  .drawer__logo a {
    display: block;
  }
  .drawer__logo a svg {
    width: auto;
  }
}
.drawer__inner {
  width: 112.5rem;
  padding-left: 10rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: 1s;
  transform: translateX(-10rem);
  position: relative;
  padding-bottom: 11rem;
  margin-top: 4rem;
}
@media (--mobile) {
  .drawer__inner {
    width: 100%;
    align-items: flex-start;
    padding-left: 0;
    padding-bottom: 0;
    margin-top: 2.8rem;
  }
}
.is-active .drawer__inner {
  transform: translateX(0);
}

.drawer__left {
  position: relative;
  width: 38rem;
  height: 50rem;
  overflow: hidden;
}
@media (--mobile) {
  .drawer__left {
    display: none;
  }
}
@media (--large) {
  .drawer__left {
    overflow-y: hidden;
  }
  .drawer__left img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    transform: scale(1.5);
    transition: 2s transform;
    z-index: 1;
  }
  .is-active .drawer__left img {
    transform: scale(1);
  }
  .drawer__left img:nth-of-type(n + 2) {
    z-index: 2;
    opacity: 0;
    transition: 0.5s;
  }
  .drawer__left img:nth-of-type(n + 2).is-active {
    opacity: 1;
  }
}
@media (--large) {
  .drawer__right {
    width: 69rem;
  }
}
@media (--mobile) {
  .drawer__right {
    padding: 0rem 4rem 1.8rem;
    width: 100%;
  }
}
.drawer__top {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
.drawer__top-item {
  line-height: 1.2;
}
.drawer__top-item:nth-child(n+2) {
  margin-top: 1.4rem;
}
@media (--mobile) {
  .drawer__top-item:nth-child(n+2) {
    margin-top: 1.3rem;
  }
}
.drawer__top ul {
  margin-right: 8.5rem;
}
.drawer__top ul:last-child {
  margin-right: 0;
}
.drawer__top ul li:nth-of-type(n + 2) {
  margin-top: 2rem;
}
.drawer__topList {
  display: flex;
  flex-wrap: wrap;
}
@media (--mobile) {
  .drawer__topList {
    justify-content: space-between;
  }
}
.drawer__topList li {
  margin-right: 8.5rem;
}
@media (--mobile) {
  .drawer__topList li {
    margin-right: 0;
  }
}
.drawer__topList li:nth-of-type(3n) {
  margin-right: 0;
}
.drawer__topList li:nth-of-type(n + 4) {
  margin-top: 2rem;
}
@media (--mobile) {
  .drawer__topList li:nth-of-type(n + 4) {
    margin-top: inherit;
  }
}
@media (--mobile) {
  .drawer__topList li:nth-of-type(n + 3) {
    margin-top: 2rem;
  }
}
@media (--mobile) {
  .drawer__top li, .drawer__topList li, .drawer__middleWrap ul li {
    width: 100%;
  }
}
.drawer__top li, .drawer__top li a, .drawer__topList li, .drawer__topList li a, .drawer__middleWrap ul li, .drawer__middleWrap ul li a {
  font-size: 1.3rem;
}
@media (--mobile) {
  .drawer__top li, .drawer__top li a, .drawer__topList li, .drawer__topList li a, .drawer__middleWrap ul li, .drawer__middleWrap ul li a {
    font-size: 1.2rem;
  }
}
.drawer__top li a, .drawer__topList li a, .drawer__middleWrap ul li a {
  display: inline-block;
  position: relative;
}
.drawer__top li a.drawer__top-link, .drawer__topList li a.drawer__top-link, .drawer__middleWrap ul li a.drawer__top-link {
  font-size: 2.2rem;
  display: inline-block;
  line-height: 1;
}
@media (--mobile) {
  .drawer__top li a.drawer__top-link, .drawer__topList li a.drawer__top-link, .drawer__middleWrap ul li a.drawer__top-link {
    font-size: 2.2rem;
  }
}
.drawer__top li a::before, .drawer__topList li a::before, .drawer__middleWrap ul li a::before {
  content: "";
  margin: auto;
  position: absolute;
  left: 0;
  bottom: -0.2rem;
  transition: transform 0.3s, -webkit-transform 0.3s;
  transform: scale(0, 1);
  transform-origin: right center;
  width: 100%;
  height: 0.1rem;
  background: #171717;
}
@media (--large) {
  .drawer__top li a:hover::before, .drawer__topList li a:hover::before, .drawer__middleWrap ul li a:hover::before {
    transform: scale(1, 1);
    transform-origin: left center;
  }
}
.drawer__middleWrap {
  margin-top: 4.5rem;
  display: flex;
}
@media (--mobile) {
  .drawer__middleWrap {
    margin-top: 3rem !important;
  }
}
.drawer__middleWrap-item a {
  font-size: 1.2rem !important;
}
@media (--mobile) {
  .drawer__middleWrap {
    margin-top: 3rem;
    display: flex;
    justify-content: space-between;
  }
}
@media (--mobile) {
  .drawer__middleWrap ul {
    width: 100%;
  }
}
@media (--large) {
  .drawer__middleWrap ul:nth-of-type(n + 2) {
    margin-left: 8.5rem;
  }
}
.drawer__middleWrap ul li:nth-of-type(n + 2) {
  margin-top: 0.6rem;
}
.drawer__middleWrap ul li a::after {
  transform: translateY(-50%) rotate(45deg);
}
@media (--mobile) {
  .drawer__middleWrap ul li {
    width: 100%;
  }
}
.drawer__snsWrap {
  margin-top: 3.7rem;
}
@media (--mobile) {
  .drawer__snsWrap {
    margin-top: 2.2rem;
  }
}
.drawer__snsWrap ul {
  display: flex;
  align-items: center;
}
.drawer__snsWrap ul li a.h-ig svg {
  width: 2rem;
}
.drawer__snsWrap ul li a svg {
  width: auto;
}
.drawer__snsWrap ul li a svg path {
  fill: #333;
}
.drawer__snsWrap ul li:nth-child(n+2) {
  margin-left: 1.7rem;
}
.drawer__bottomWrap {
  display: flex;
  align-items: center;
  margin-top: 2rem;
  flex-wrap: wrap;
}
.drawer__bottomWrap .drawer__box {
  width: 100%;
}
@media (--mobile) {
  .drawer__bottomWrap {
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 1.5rem;
  }
}
.drawer__mail, .drawer__line {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15rem;
  height: 8rem;
  border: 1px solid #707070;
  font-size: 1.4rem;
  padding-top: 0.3rem;
  transition: 0.3s background, 0.3s color;
}
@media (--mobile) {
  .drawer__mail, .drawer__line {
    text-align: center;
    width: 46%;
    margin: 0;
    padding-top: 0.5rem;
    height: 8rem;
  }
}
.drawer__mail figure, .drawer__line figure {
  height: 1.6rem;
}
@media (--mobile) {
  .drawer__mail figure, .drawer__line figure {
    height: 1.3rem;
  }
}
.drawer__mail figure svg, .drawer__line figure svg {
  height: 100%;
}
.drawer__mail figure path, .drawer__line figure path {
  transition: 0.3s fill;
  fill: #171717;
}
@media (--mobile) {
  .drawer__mail p, .drawer__line p {
    line-height: 1.4;
  }
}
.drawer__mail:hover, .drawer__line:hover {
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
}
.drawer__mail:hover path, .drawer__line:hover path {
  fill: #fff;
}
.drawer__mail figure svg {
  height: 1.6rem;
}
@media (--mobile) {
  .drawer__mail figure svg {
    height: 1.6rem;
  }
}
.drawer__line {
  margin-left: 1.9rem;
  margin-right: 2.5rem;
}
.drawer__line figure svg {
  height: 2.3rem;
}
@media (--mobile) {
  .drawer__line figure svg {
    position: relative;
    top: -0.2rem;
    height: 2.3rem;
  }
}
.drawer__tel {
  font-size: 1.4rem;
  margin-left: 7rem;
  margin-top: 3rem;
}
@media (--large) {
  .drawer__tel {
    pointer-events: none;
  }
}
@media (--mobile) {
  .drawer__tel {
    width: 100%;
    text-align: center;
    margin-top: 2rem;
    margin-left: 0;
  }
}
.drawer__tel small {
  font-size: 1.2rem;
}
@media (--mobile) {
  .drawer__tel small {
    display: inline-block;
    margin-top: 1rem;
  }
}
.drawer__tel svg {
  height: 2.4rem;
}
.drawer__contact-text {
  font-size: 1.2rem;
}
@media (--mobile) {
  .drawer__contact-text {
    font-size: 1.1rem;
  }
}

html.is-active {
  overflow: hidden;
}

/**
 * main.scss
 */
.p-error__txt {
  font-size: 1.6rem;
  line-height: 3.2rem;
  margin-top: 5rem;
  text-align: center;
  margin-bottom: 5rem;
}
.p-error__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  margin: 0 auto;
}
.p-error__btn a {
  width: 30rem;
  padding: 2rem 2rem;
  border: 1px solid #D0D0D0;
  text-align: center;
  transition: all 0.3s;
}
.p-error__btn a:hover {
  border: 1px solid #FFF;
  background-color: #D0D0D0;
}

.p-beauty.keyword .c-title {
  border-bottom: 1px solid #D0D0D0;
  padding-bottom: 7rem;
}
@media (--mobile) {
  .p-beauty.keyword .c-title {
    padding-bottom: 4rem;
  }
}

.p-beauty {
  overflow-x: hidden;
}
.p-beauty .keyword {
  width: 118rem;
  margin: 10rem auto 0 auto;
}
@media (--mobile) {
  .p-beauty .keyword {
    width: auto;
    padding: 0 2rem;
    margin-top: 4rem;
  }
}
.p-beauty-content {
  width: 100rem;
  margin-left: 10rem;
}
@media (--mobile) {
  .p-beauty-content {
    width: 100%;
    margin-left: 0;
  }
}
.p-beauty-content__text {
  margin-top: 4rem;
  margin-bottom: 8rem;
  font-size: 1.5rem;
  line-height: 3.2rem;
}
@media (--mobile) {
  .p-beauty-content__text {
    margin-bottom: 4rem;
  }
}
.p-beauty-content__keyword {
  font-size: 1.5rem;
  margin-bottom: 12rem;
}
@media (--mobile) {
  .p-beauty-content__keyword {
    margin-bottom: 8rem;
  }
}
.p-beauty-content__keyword h3 {
  margin-bottom: 3rem;
}
@media (--mobile) {
  .p-beauty-content__keyword h3 {
    margin-bottom: 1.5rem;
  }
}
.p-beauty-content__keyword ul {
  display: flex;
  flex-wrap: wrap;
  margin-top: -1rem;
}
@media (--mobile) {
  .p-beauty-content__keyword ul {
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-left: -2rem;
    margin-right: -2rem;
    padding: 0 2rem;
  }
}
.p-beauty-content__keyword ul li {
  font-size: 1.4rem;
  color: #7B7B7B;
  margin-right: 1.4rem;
  white-space: nowrap;
  margin-top: 1rem;
}
.p-beauty-content__keyword ul li:last-child {
  margin-right: 0;
}
.p-beauty-content__keyword ul li a {
  display: block;
  position: relative;
}
.p-beauty-content__keyword ul li a::before {
  content: "";
  margin: auto;
  position: absolute;
  left: 0;
  bottom: -0.2rem;
  transition: transform 0.3s, -webkit-transform 0.3s;
  -webkit-transform: scale(0, 1);
  transform: scale(0, 1);
  -webkit-transform-origin: right center;
  transform-origin: right center;
  width: 100%;
  height: 0.1rem;
  background: #171717;
}
.p-beauty-content__keyword ul li a:hover::before {
  transform: scale(1, 1);
  transform-origin: left center;
}
.p-beauty ul.p-beauty-cell {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 auto;
}
@media (--large) {
  .p-beauty ul.p-beauty-cell {
    width: 118rem;
    margin-top: 7rem;
  }
}
@media (--mobile) {
  .p-beauty ul.p-beauty-cell {
    margin-right: calc(50% - 50vw);
    margin-left: calc(50% - 50vw);
    margin-top: 5rem;
  }
}
.p-beauty ul.p-beauty-cell li.p-beauty-list {
  width: 53.6rem;
}
@media (--mobile) {
  .p-beauty ul.p-beauty-cell li.p-beauty-list {
    width: 100%;
    margin-bottom: 4rem;
  }
}
.p-beauty-list {
  position: relative;
  margin-bottom: 8rem;
}
.p-beauty-list__img {
  display: block;
  position: relative;
  margin-bottom: 2.4rem;
  width: 53.6rem;
  height: 36rem;
  overflow: hidden;
}
@media (--mobile) {
  .p-beauty-list__img {
    width: 100%;
    height: 20rem;
  }
}
.p-beauty-list__img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  transition: 1s all;
}
.p-beauty-list__img img:hover {
  transform: scale(1.05);
  transition: 1s all;
}
.p-beauty-list__txt {
  position: absolute;
  right: 3rem;
  top: 31rem;
  z-index: 9;
  font-size: 1.3rem;
  color: #171717;
  color: #FFF;
}
@media (--mobile) {
  .p-beauty-list__txt {
    right: 2rem;
    top: 16rem;
  }
}
@media (--mobile) {
  .p-beauty-list__text {
    padding: 0 3rem;
  }
}
.p-beauty-list__title {
  display: block;
  font-size: 1.8rem;
  margin-bottom: 1.7rem;
  transition: 0.3s opacity;
}
.p-beauty-list__title:hover {
  opacity: 0.6;
}
.p-beauty-list ul {
  display: flex;
  flex-wrap: wrap;
  margin-top: -1rem;
}
.p-beauty-list ul li {
  margin-right: 0.8rem;
  font-size: 1.2rem;
  color: #707070;
  border: 1px solid #CECECE;
  margin-top: 1rem;
}
.p-beauty-list ul li a {
  display: block;
  padding: 6px 8px;
  color: #7B7B7B;
  transition: 0.3s all;
}
@media (--mobile) {
  .p-beauty-list ul li a {
    padding: 0.3rem 0.4rem;
  }
}
.p-beauty-list ul li a:hover {
  background: #7B7B7B;
  color: #FFFFFF;
}
.p-beauty-voice {
  width: 118rem;
  margin: 12rem auto 0 auto;
  border-top: 1px solid #D0D0D0;
  padding-top: 12rem;
  display: flex;
  justify-content: space-between;
}
@media (--mobile) {
  .p-beauty-voice {
    width: 100%;
    margin-top: 6rem;
    padding-top: 6rem;
    display: block;
  }
}
.p-beauty-voice__title {
  font-size: 3.2rem;
  font-family: "EB Garamond", serif;
  font-weight: Regular;
}
@media (--mobile) {
  .p-beauty-voice__title {
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 2rem;
  }
}
.p-beauty-voice__title .jp {
  font-size: 1.5rem;
  margin-bottom: 1.4rem;
  display: block;
}
@media (--mobile) {
  .p-beauty-voice__title .jp {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
}
.p-beauty-voice__content {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.p-beauty-voice__cell {
  width: 78rem;
  display: flex;
  border-bottom: 1px solid #D0D0D0;
  padding-bottom: 4rem;
  margin-bottom: 4rem;
  padding: 0 2rem 4rem 2rem;
}
@media (--mobile) {
  .p-beauty-voice__cell {
    width: 100%;
    padding: 0 1rem 4rem 1rem;
    display: block;
  }
}
.p-beauty-voice__cell:nth-child(even) {
  flex-direction: row-reverse;
}
.p-beauty-voice__cell:nth-child(even) .p-beauty-voice__text {
  margin-right: 0rem;
  margin-left: 3rem;
}
@media (--mobile) {
  .p-beauty-voice__cell:nth-child(even) .p-beauty-voice__text {
    margin-left: 0;
  }
}
.p-beauty-voice__text {
  width: 55rem;
  margin-right: 3rem;
}
@media (--mobile) {
  .p-beauty-voice__text {
    width: 100%;
    margin-right: 0;
    margin-bottom: 3rem;
  }
}
.p-beauty-voice__heading {
  font-size: 2rem;
  margin-bottom: 1.4rem;
  font-weight: bold;
}
@media (--mobile) {
  .p-beauty-voice__heading {
    font-size: 1.8rem;
    white-space: nowrap;
  }
}
.p-beauty-voice__txt {
  font-size: 1.5rem;
  line-height: 3.2rem;
  margin-bottom: 1.3rem;
}
@media (--mobile) {
  .p-beauty-voice__txt {
    margin-bottom: 3rem;
    font-size: 1.3rem;
  }
}
.p-beauty-voice__name {
  font-size: 1.5rem;
}
@media (--mobile) {
  .p-beauty-voice__name {
    display: flex;
    flex-direction: column;
  }
}
.p-beauty-voice__name span.city {
  font-size: 1.3rem;
  color: #7B7B7B;
  position: relative;
  margin-left: 2rem;
}
@media (--mobile) {
  .p-beauty-voice__name span.city {
    margin-left: 0;
    font-size: 1.1rem;
  }
}
.p-beauty-voice__name span.city::before {
  position: absolute;
  display: block;
  content: "";
  width: 1px;
  height: 1.3rem;
  background-color: #7B7B7B;
  left: -1rem;
  top: 50%;
  transform: translateY(-50%);
}
@media (--mobile) {
  .p-beauty-voice__name span.city::before {
    content: none;
  }
}
.p-beauty-voice__more {
  display: flex;
  justify-content: flex-end;
  font-size: 1.5rem;
  margin-top: -2.2rem;
}
@media (--mobile) {
  .p-beauty-voice__more {
    margin-top: 4rem !important;
  }
}
.p-beauty-voice__more a, .p-beauty-voice__more span {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 1.5rem;
}
.p-beauty-voice__more a svg, .p-beauty-voice__more span svg {
  width: 2.4rem;
  margin-left: 1.5rem;
  transition: 0.3s all;
}
@media (--mobile) {
  .p-beauty-voice__more a svg, .p-beauty-voice__more span svg {
    margin-left: 1.6rem;
  }
}
.p-beauty-voice__more a svg circle:first-of-type, .p-beauty-voice__more span svg circle:first-of-type {
  fill: #fff;
}
.p-beauty-voice__more a svg, .p-beauty-voice__more a svg *, .p-beauty-voice__more span svg, .p-beauty-voice__more span svg * {
  transition: 0.3s;
}
.p-beauty-voice__more a:hover svg circle:first-of-type, .p-beauty-voice__more span:hover svg circle:first-of-type {
  fill: #171717;
}
.p-beauty-voice__more a:hover svg path, .p-beauty-voice__more span:hover svg path {
  stroke: #fff;
}
@media (--mobile) {
  .p-beauty-voice__more {
    margin-top: 1rem;
  }
}
.p-beauty-voice__more img {
  margin-left: 1.5rem;
}
.p-beauty-voice__img {
  width: calc(100% - 55rem - 3rem);
}
@media (--mobile) {
  .p-beauty-voice__img {
    width: 100%;
    text-align: center;
  }
}
.p-beauty-keyword {
  width: 118rem;
  margin: 12rem auto 0 auto;
  padding-top: 8rem;
  border-top: 1px solid #D0D0D0;
}
@media (--mobile) {
  .p-beauty-keyword {
    width: 100%;
    margin-top: 6rem;
    padding-top: 4rem;
  }
}
.p-beauty-keyword__title {
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 3rem;
}
.p-beauty-keyword__list ul {
  display: flex;
  justify-content: center;
}
.p-beauty-keyword__list ul li {
  font-size: 1.4rem;
  color: #7B7B7B;
  margin-right: 2.4rem;
}
.p-beauty-keyword__list ul li:last-child {
  margin-right: 0;
}

.p-beauty.keyword .p-beauty-voice {
  margin-top: 8rem;
}
@media (--mobile) {
  .p-beauty.keyword .p-beauty-voice {
    margin-top: 4rem;
  }
}

@media (--large) {
  .p-blog__content ul.c-newsList li a div p {
    width: 18.5rem;
  }
  .p-blog__content ul.c-newsList li a .ttl {
    width: calc(100% - 18.5rem);
  }
}

.p-50 {
  padding: 0 5rem;
}
@media (--mobile) {
  .p-50 {
    padding: 0;
  }
}

.p-company .c-mv {
  position: relative;
  overflow: hidden;
  height: 38vw;
}
@media (--mobile) {
  .p-company .c-mv {
    height: 56vw;
  }
}
.p-company .c-mv video {
  width: 100%;
}
.p-company .c-mv .js-sound {
  position: absolute;
  bottom: 3rem;
  right: 3rem;
  height: 7rem;
  width: 7rem;
  border-radius: 999rem;
  background: rgba(255, 255, 255, 0.4);
}
@media (--mobile) {
  .p-company .c-mv .js-sound {
    bottom: 2rem;
    right: 2rem;
    height: 5rem;
    width: 5rem;
  }
}
.p-company .c-mv .js-sound svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) translateX(-0.3rem);
  opacity: 0;
  width: 5rem;
}
.p-company .c-mv .js-sound svg * {
  fill: #000;
}
.p-company .c-mv .js-sound svg.sound_on {
  opacity: 1;
}
.p-company .c-mv .js-sound svg.sound_off {
  opacity: 0;
}
@media (--mobile) {
  .p-company .c-mv .js-sound svg {
    width: 3rem;
    transform: translate(-50%, -50%) translateX(0rem);
  }
}
.p-company .c-mv .js-sound.is-active .sound_on {
  opacity: 0;
}
.p-company .c-mv .js-sound.is-active .sound_off {
  opacity: 1;
}
.p-company .c-mv.company-mv {
  height: auto !important;
}
.p-company-message {
  display: flex;
  margin-left: 10rem;
  margin-bottom: 10rem;
}
@media (--mobile) {
  .p-company-message {
    display: block;
    margin-left: 0;
  }
}
.p-company-message__content {
  margin-right: 16rem;
}
@media (--mobile) {
  .p-company-message__content {
    margin-right: 0;
    margin-bottom: 2rem;
  }
}
.p-company-message__title {
  font-size: 2.6rem;
  margin-bottom: 2rem;
}
.p-company-message__title span {
  font-size: 1.4rem;
  font-family: "EB Garamond", serif;
  font-weight: Regular;
  display: block;
  margin-bottom: 1.4rem;
}
@media (--mobile) {
  .p-company-message__title {
    text-align: center;
  }
}
.p-company-message__text {
  width: 48rem;
  margin-top: 4rem;
}
@media (--mobile) {
  .p-company-message__text {
    width: 100%;
    margin-top: 2rem;
  }
}
.p-company-message__text p {
  font-size: 1.5rem;
  line-height: 3.2rem;
  margin-bottom: 3rem;
}
.p-company-message__name {
  font-size: 1.5rem;
}
.p-company-overview {
  margin-bottom: 12rem;
}
@media (--mobile) {
  .p-company-overview {
    margin-bottom: 0rem;
  }
}
.p-company-overview__title {
  font-size: 2.6rem;
  text-align: center;
  margin-bottom: 7rem;
}
.p-company-overview__title span {
  font-size: 1.4rem;
  font-family: "EB Garamond", serif;
  font-weight: Regular;
  display: block;
  margin-bottom: 1.4rem;
}
.p-company-overview__content {
  width: 98rem;
  margin: 7rem auto 0 auto;
}
@media (--mobile) {
  .p-company-overview__content {
    width: 100%;
    margin-top: 3rem;
  }
}
.p-company-overview__content dl {
  display: flex;
  flex-wrap: wrap;
}
@media (--mobile) {
  .p-company-overview__content dl {
    display: block;
  }
}
.p-company-overview__content dt {
  width: 20%;
  font-size: 1.5rem;
  font-weight: bold;
  padding: 4rem 0;
  border-bottom: 1px solid #D0D0D0;
  display: flex;
  align-items: flex-start;
  padding-left: 3rem;
  padding-top: 4.6rem;
}
.p-company-overview__content dt:first-of-type {
  border-top: 1px solid #D0D0D0;
}
@media (--mobile) {
  .p-company-overview__content dt:first-of-type {
    padding-top: 2rem;
  }
}
@media (--mobile) {
  .p-company-overview__content dt {
    width: 100%;
    display: block;
    padding: 0;
    border: none;
    padding-top: 2rem;
  }
}
.p-company-overview__content dd {
  width: 80%;
  font-size: 1.5rem;
  padding: 4rem 0;
  border-bottom: 1px solid #D0D0D0;
  line-height: 3.2rem;
}
.p-company-overview__content dd:first-of-type {
  border-top: 1px solid #D0D0D0;
}
@media (--mobile) {
  .p-company-overview__content dd:first-of-type {
    border: none;
  }
}
@media (--mobile) {
  .p-company-overview__content dd {
    border-bottom: 1px solid #D0D0D0 !important;
    width: 100%;
    border: none;
    padding: 0.5rem 0rem 2rem 0;
    line-height: 1.5;
  }
}
.p-company-overview__content dd .content-list {
  padding-left: 2rem;
  display: flex;
  flex-direction: column;
  gap: 0rem 0.5rem;
}
@media (--mobile) {
  .p-company-overview__content dd .content-list {
    width: 100%;
    margin-top: 0.5rem;
  }
}
.p-company-overview__content dd .content-list span {
  display: block;
  font-size: 1.4rem;
  line-height: 1.8;
}
@media (--mobile) {
  .p-company-overview__content dd .content-list span:last-child {
    width: auto !important;
  }
}
.p-company-other {
  display: flex;
}
@media (--mobile) {
  .p-company-other {
    margin-bottom: 3rem;
  }
}
.p-company-staff {
  width: 56rem;
  height: 31.5rem;
}
.p-company-staff img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (--mobile) {
  .p-company-staff {
    width: 100%;
    height: 21.5rem;
  }
}
.p-company-youtube {
  width: 56rem;
  margin: 0 auto 12rem auto;
}
@media (--mobile) {
  .p-company-youtube {
    display: none;
  }
}
.p-company-map {
  margin-right: calc(50% - 50vw);
  margin-left: calc(50% - 50vw);
}
.p-company-map__content {
  overflow: hidden;
  margin-bottom: 4rem;
}
.p-company-map__content iframe {
  height: 45rem;
  width: 100%;
}
@media (--mobile) {
  .p-company-map__content iframe {
    height: 25rem;
  }
}
.p-company-map__text {
  font-size: 1.5rem;
  line-height: 3.2rem;
  width: 98rem;
  margin: 0 auto;
}
@media (--mobile) {
  .p-company-map__text {
    width: 100%;
    padding: 0 3rem;
  }
}

@media (--mobile) {
  .p-concept-main {
    overflow-x: hidden;
  }
}
.p-concept-about {
  width: 98rem;
  margin: 0 auto 12rem auto;
}
@media (--mobile) {
  .p-concept-about {
    width: 100%;
    margin-bottom: 6rem;
  }
}
.p-concept-about__title {
  font-size: 3.2rem;
  text-align: center;
  margin-bottom: 12rem;
}
@media (--mobile) {
  .p-concept-about__title {
    font-size: 2.6rem;
    margin-bottom: 4rem;
    text-align: left;
  }
}
@media (--mobile) {
  .p-concept-about .c-leftTtl strong {
    font-size: 1.8rem !important;
  }
}
.p-concept-about-ttl {
  margin-bottom: 8rem !important;
}
@media (--mobile) {
  .p-concept-about-ttl {
    margin-bottom: 4rem !important;
  }
}
.p-concept-about__ttl-text {
  font-size: 1.5rem;
}
@media (--mobile) {
  .p-concept-about__ttl-text {
    font-size: 1.3rem;
  }
}
.p-concept-about__ttl-text p {
  line-height: 2.2;
}
.p-concept-about__ttl-text p:nth-child(n+2) {
  margin-top: 4rem;
}
@media (--mobile) {
  .p-concept-about__ttl-text p:nth-child(n+2) {
    margin-top: 1rem;
  }
}
.p-concept-about .c-leftTtl {
  margin-bottom: 9rem;
}
@media (--mobile) {
  .p-concept-about .c-leftTtl {
    margin-bottom: 6rem;
  }
}
@media (--mobile) {
  .p-concept-about__inner {
    display: block;
  }
}
.p-concept-about__img {
  width: 59rem;
}
@media (--mobile) {
  .p-concept-about__img {
    width: auto;
    margin-bottom: 2rem;
  }
}
.p-concept-about__txt {
  margin-right: 9rem;
}
@media (--mobile) {
  .p-concept-about__txt {
    margin-right: 0rem;
  }
}
.p-concept-about__subtitle {
  font-size: 2.6rem;
  color: #333333;
  margin-bottom: 4.8rem;
}
.p-concept-about__subtitle span.en {
  font-size: 1.2rem;
  font-family: "EB Garamond", serif;
  font-weight: Regular;
  display: block;
  margin-bottom: 1.4rem;
}
@media (--mobile) {
  .p-concept-about__subtitle span.en {
    margin-bottom: 1.2rem;
  }
}
@media (--mobile) {
  .p-concept-about__subtitle {
    text-align: center;
  }
}
.p-concept-about__list-ttl {
  margin-top: 15rem;
}
@media (--mobile) {
  .p-concept-about__list-ttl {
    margin-top: 6rem;
  }
}
.p-concept-about__list-ttl p {
  font-size: 2rem;
}
.p-concept-about__list-ttl p span {
  display: block;
  font-size: 1.5rem;
  margin-top: 3rem;
}
@media (--mobile) {
  .p-concept-about__list-ttl p span {
    margin-top: 2rem;
    font-size: 1.3rem;
  }
}
.p-concept-about__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 6rem;
  margin-bottom: 5rem;
}
@media (--mobile) {
  .p-concept-about__list {
    margin-top: 4rem;
    margin-bottom: 0rem;
  }
}
.p-concept-about__list p {
  width: 46rem;
  margin-bottom: 3rem;
  font-size: 1.6rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid #D0D0D0;
}
@media (--mobile) {
  .p-concept-about__list p {
    width: 100%;
    display: flex;
    align-items: flex-start;
    white-space: nowrap;
    font-size: 1.2rem;
  }
  .p-concept-about__list p:last-child {
    margin-bottom: 0;
  }
}
.p-concept-about__list p span {
  font-size: 1.4rem;
  font-family: "EB Garamond", serif;
  font-weight: Regular;
  margin-right: 1rem;
}
.p-concept-about__text {
  width: 98rem;
  margin: 0 auto;
  font-size: 1.5rem;
  line-height: 3.2rem;
}
.p-concept-about__text p {
  margin-bottom: 4rem;
}
.p-concept-about__text p:last-child {
  margin-bottom: 0;
}
@media (--mobile) {
  .p-concept-about__text {
    width: 100%;
  }
}
.p-concept-thought {
  margin-bottom: 20rem;
}
@media (--mobile) {
  .p-concept-thought {
    margin-bottom: 6rem;
  }
}
.p-concept-thought .c-sideImg {
  margin-right: calc(50% - 50vw);
  margin-left: calc(50% - 50vw);
}
@media (--mobile) {
  .p-concept-thought .c-sideImg div {
    margin-right: calc(50% - 50vw);
    margin-left: calc(50% - 50vw);
  }
}
.p-concept-thought .c-sideImg figcaption {
  position: relative;
  z-index: 20;
  color: #fff;
}
@media (--mobile) {
  .p-concept-thought .c-sideImg figcaption {
    margin-top: 3rem;
  }
}
.p-concept-thought .p-concept-thought-bg {
  justify-content: end;
  background-repeat: no-repeat;
  background-size: cover;
  height: 40vw;
  max-height: 50rem;
  min-height: 62rem;
  position: relative;
}
@media (--mobile) {
  .p-concept-thought .p-concept-thought-bg {
    margin-left: -8vw;
    margin-right: -8vw;
    min-width: initial;
    min-height: initial;
    height: auto;
  }
}
@media (--mobile) {
  .p-concept-thought .p-concept-thought-ttl strong {
    font-size: 2.6rem !important;
  }
}
.p-concept-thought .p-concept-thought-text {
  width: 45%;
  height: 100%;
  padding-left: 7rem !important;
  padding-top: 10rem;
}
@media (--mobile) {
  .p-concept-thought .p-concept-thought-text {
    width: 100%;
    padding-left: 2rem !important;
    padding-right: 2rem !important;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}
.p-concept-thought .p-concept-thought-text p {
  font-size: 1.3rem !important;
  margin-top: 3rem !important;
}
.p-concept-thought .p-concept-thought-text p span {
  font-size: 1.6rem;
}
.p-concept-thought .p-concept-thought-text .c-leftTtl strong {
  line-height: 1;
  margin-top: 0;
}
.p-concept-thought .cover-grey {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(34, 34, 34, 0.8);
  z-index: -1;
}
@media (--mobile) {
  .p-concept-thought .cover-grey {
    z-index: 0;
  }
}
.p-concept-list {
  margin-bottom: 10rem;
}
@media (--mobile) {
  .p-concept-list {
    padding-bottom: 0rem;
    margin-bottom: 6rem;
  }
}
.p-concept-list .img-wrap {
  height: 54rem;
  margin-right: calc(50% - 50vw);
  margin-left: calc(50% - 50vw);
  position: relative;
  background: url("../images/concept/bg-about.jpg");
  display: flex;
  align-items: center;
}
.p-concept-list .img-wrap::before {
  content: "";
  position: absolute;
  height: 40rem;
  left: 0;
  width: 100%;
  backdrop-filter: grayscale(0.9) brightness(60%);
}
@media (--mobile) {
  .p-concept-list .img-wrap::before {
    height: 95%;
  }
}
@media (--mobile) {
  .p-concept-list .img-wrap {
    padding: 5rem 0;
    height: 100%;
    background-size: cover;
  }
}
.p-concept-list ul {
  display: flex;
  justify-content: space-between;
  color: #FFF;
  width: 118rem;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}
@media (--mobile) {
  .p-concept-list ul {
    width: auto;
    padding: 0 3rem;
    display: block;
  }
}
.p-concept-list ul li {
  width: 27%;
}
@media (--mobile) {
  .p-concept-list ul li {
    width: 100%;
    margin-right: 0;
    margin-bottom: 8rem;
  }
}
.p-concept-list ul li:last-child {
  margin-right: 0rem;
}
@media (--mobile) {
  .p-concept-list ul li:last-child {
    margin-bottom: 0rem;
  }
}
.p-concept-list__img {
  margin-bottom: 3rem;
}
@media (--mobile) {
  .p-concept-list__img {
    margin-bottom: 6rem;
  }
}
.p-concept-list__title {
  font-size: 2rem;
  font-family: "EB Garamond", serif;
  font-weight: Regular;
  text-align: center;
  margin-bottom: 2rem;
}
@media (--mobile) {
  .p-concept-list__title {
    margin-bottom: 4rem;
  }
}
.p-concept-list__text {
  font-size: 1.5rem;
  line-height: 3.2rem;
}
.p-concept-important-ttl {
  width: 107rem;
  margin: 0 auto;
}
.p-concept-important-ttl strong {
  font-size: 2rem !important;
}
@media (--mobile) {
  .p-concept-important .c-centerTtl {
    margin-bottom: 6rem;
  }
}
.p-concept-important__inner {
  margin-top: 6rem;
}
@media (--mobile) {
  .p-concept-important__inner {
    margin-top: 3rem;
  }
}
.p-concept-important__title {
  font-size: 2.6rem;
  text-align: center;
  margin-bottom: 5rem;
}
.p-concept-important__title span {
  font-size: 1.4rem;
  font-family: "EB Garamond", serif;
  font-weight: Regular;
  margin-bottom: 1.4rem;
  display: block;
}
@media (--mobile) {
  .p-concept-important__title span {
    margin-bottom: 1.2rem;
  }
}
.p-concept-important__inner ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
@media (--mobile) {
  .p-concept-important__inner ul {
    display: block;
  }
}
.p-concept-important__inner ul li {
  width: 48rem;
  margin-right: 10rem;
  margin-bottom: 7rem;
}
@media (--mobile) {
  .p-concept-important__inner ul li {
    width: 100%;
    margin-bottom: 6rem;
  }
}
.p-concept-important__inner ul li:nth-child(2n) {
  margin-right: 0rem;
}
.p-concept-important__inner ul li:nth-child(5n) {
  margin-bottom: 0rem;
}
@media (--mobile) {
  .p-concept-important__inner ul li:nth-child(5n) {
    margin-bottom: 6rem;
  }
}
.p-concept-important__inner ul li:nth-child(6n) {
  margin-bottom: 0rem;
}
.p-concept-important__subtitle {
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid #D0D0D0;
  font-size: 2rem;
}
@media (--mobile) {
  .p-concept-important__subtitle {
    padding-bottom: 2rem;
    margin-bottom: 2rem;
    letter-spacing: 0.05em;
    display: flex;
    align-content: stretch;
    align-items: flex-start;
  }
}
.p-concept-important__subtitle span {
  font-size: 1.4rem;
  font-family: "EB Garamond", serif;
  font-weight: Regular;
  margin-right: 1rem;
}
@media (--mobile) {
  .p-concept-important__subtitle span {
    padding-top: 0.5rem;
  }
}
.p-concept-important__text {
  font-size: 1.5rem;
  line-height: 3.2rem;
}
@media (--mobile) {
  .p-concept .mt--9 {
    margin-top: -9rem !important;
  }
}
.p-concept .h-500 {
  height: 20rem !important;
}

.p-contact-list__content {
  width: 98rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
@media (--mobile) {
  .p-contact-list__content {
    width: 100%;
    display: block;
  }
}
.p-contact-list__title {
  width: 25rem;
  font-size: 2.6rem;
}
@media (--mobile) {
  .p-contact-list__title {
    width: 100%;
    font-size: 2rem;
    margin-bottom: 2rem;
  }
}
.p-contact-list__title .en {
  display: block;
  font-family: "EB Garamond", serif;
  font-size: 1.4rem;
  margin-bottom: 1.7rem;
}
.p-contact-list__faq {
  width: 58rem;
  border-top: 1px solid #D0D0D0;
  border-bottom: 1px solid #D0D0D0;
  font-size: 2rem;
  position: relative;
}
@media (--mobile) {
  .p-contact-list__faq {
    width: 100%;
  }
}
.p-contact-list__faq a {
  display: block;
  padding: 4rem 0;
}
.p-contact-list__faq::after {
  border-right: solid 1px #707070;
  border-top: solid 1px #707070;
  content: "";
  display: block;
  width: 1.8rem;
  height: 1.8rem;
  position: absolute;
  right: 3rem;
  top: 40%;
  transform: rotate(45deg);
  pointer-events: none;
}
@media (--mobile) {
  .p-contact-list__faq::after {
    width: 1.2rem;
    height: 1.2rem;
    top: 45%;
    right: 2rem;
  }
}

.p-contact .c-inner {
  display: flex;
  justify-content: center;
}
.p-contact .c-inner .c-leftTtl strong {
  font-size: 2.6rem;
  margin-bottom: 2rem;
}
@media (--mobile) {
  .p-contact .c-inner {
    display: block;
  }
  .p-contact .c-inner .input, .p-contact .c-inner .confirm {
    font-size: 1.5rem;
  }
}
.p-contact__left {
  padding-right: 13rem;
}
.p-contact__left span {
  font-size: 1.5rem;
}
@media (--mobile) {
  .p-contact__left {
    padding-right: 0;
  }
}
.p-contact__left p {
  font-size: 1.5rem;
  margin-top: 4.2rem;
}
.p-contact__right {
  margin-top: 9.2rem;
  width: 58rem;
}
@media (--mobile) {
  .p-contact__right {
    width: 100%;
    margin-top: 5rem;
  }
}
.p-contact__right dl:nth-of-type(n + 2) {
  margin-top: 4rem;
}
.p-contact__right dl dt {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
}
@media (--mobile) {
  .p-contact__right dl dt {
    font-size: 1.4rem;
  }
}
.p-contact__right dl dt span {
  display: inline-block;
  background: #171717;
  color: #fff;
  font-size: 1.2rem;
  padding: 0.3rem 0.5rem;
  margin-left: 1rem;
}
.p-contact__right dl dd {
  margin-top: 1.8rem;
  font-size: 1.5rem;
}
@media (--mobile) {
  .p-contact__right dl dd {
    font-size: 1.4rem;
  }
}
.p-contact__right dl dd input, .p-contact__right dl dd textarea {
  width: 100%;
  display: inline-block;
  border-bottom: 1px solid #707070;
  height: 4.8rem;
  font-size: 1.5rem;
}
@media (--mobile) {
  .p-contact__right dl dd input, .p-contact__right dl dd textarea {
    font-size: 1.4rem;
  }
}
.p-contact__right dl dd input::placeholder, .p-contact__right dl dd textarea::placeholder {
  color: rgba(0, 0, 0, 0.5);
}
.p-contact__right dl dd textarea {
  height: 16.6rem;
}
.p-contact__right dl dd.content-select {
  position: relative;
  width: 100%;
}
.p-contact__right dl dd.content-select .error {
  position: absolute;
  left: 0;
  bottom: -2.4rem;
}
.p-contact__right dl dd select {
  border: 0px;
  outline: none;
  width: 100%;
}
.p-contact__right .method {
  display: flex;
  border-bottom: 1px solid #707070;
  padding-bottom: 2rem;
  margin-top: 3rem;
}
@media (--mobile) {
  .p-contact__right .method {
    border: none;
    padding-bottom: 0rem;
  }
}
.p-contact__right .method label {
  position: relative;
}
.p-contact__right .method label span {
  margin-left: 4rem;
  margin-right: 4rem;
}
.p-contact__right .method input {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 2.5rem;
  width: 2.5rem;
  border-bottom: 0px;
}
.p-contact__right .method input::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  border: 0.1rem solid #D0D0D0;
}
.p-contact__right .method input::before {
  content: "";
  opacity: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  background: url(../images/common/ic_check.png) no-repeat center center/1.8rem;
}
.p-contact__right .method input:checked::before {
  opacity: 1;
}
.p-contact__right .agree {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 6.5rem;
  font-size: 1.5rem;
  padding-left: 4.5rem;
}
.p-contact__right .agree .privacy-text {
  padding-left: 4rem;
}
@media (--mobile) {
  .p-contact__right .agree .privacy-text {
    font-size: 1.3rem;
  }
}
.p-contact__right .agree .error {
  position: absolute;
  top: 3rem;
}
@media (--mobile) {
  .p-contact__right .agree .error {
    top: 2.4rem;
  }
}
@media (--mobile) {
  .p-contact__right .agree {
    font-size: 1.3rem;
  }
}
.p-contact__right .agree a {
  text-decoration: underline;
  letter-spacing: 0.2em;
}
.p-contact__right .agree input {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-25%);
  height: 2.5rem;
  width: 2.5rem;
}
.p-contact__right .agree input::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  border: 0.1rem solid #D0D0D0;
}
.p-contact__right .agree input::before {
  content: "";
  opacity: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  background: url(../images/common/ic_check.png) no-repeat center center/1.8rem;
}
.p-contact__right .agree input:checked::before {
  opacity: 1;
}
.p-contact__right .agree .mwform-checkbox-field-text {
  display: none;
}
.p-contact__right .btn_wrap {
  margin-top: 6rem;
}
.p-contact__right .btn_wrap input {
  transition: 0.5s;
  width: 100%;
  height: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #171717;
  color: #fff;
  padding: 0;
  font-size: 2rem;
  border: 0.2rem solid #171717;
  transition: 0.5s background, 0.5s color;
}
.p-contact__right .btn_wrap input:hover {
  background: #fff;
  color: #171717;
}
.p-contact .privacy-text {
  padding-left: 4rem;
}

.mw_wp_form_complete .p-contact__right {
  margin-top: 0;
  font-size: 1.5rem;
  line-height: 3.2rem;
}

.mw_wp_form_confirm .method {
  border: none;
}
.mw_wp_form_confirm .agree {
  display: none;
}
.mw_wp_form_confirm .btn_wrap {
  display: flex;
  justify-content: space-between;
}
.mw_wp_form_confirm .btn_wrap input {
  width: calc(50% - 1rem);
  height: 8rem;
}
.mw_wp_form_confirm .btn_wrap input[name=submitBack] {
  background: #707070;
  border: 0.2rem solid #707070;
}
.mw_wp_form_confirm .btn_wrap input[name=submitBack]:hover {
  background: #fff;
  color: #707070;
}
.mw_wp_form_confirm .p-contact__left .input {
  display: none;
}

.mw_wp_form_input .p-contact__left .confirm {
  display: none;
}

.p-cost-price {
  padding-left: 10rem;
  padding-bottom: 16rem;
  margin-bottom: 7rem;
  border-bottom: 1px solid #D0D0D0;
  position: relative;
}
@media (--mobile) {
  .p-cost-price {
    padding-left: 0;
    padding-bottom: 10rem;
  }
}
.p-cost-price__title {
  font-size: 2.6rem;
  margin-bottom: 2.4rem;
}
@media (--mobile) {
  .p-cost-price__title {
    text-align: center;
  }
}
.p-cost-price__title span {
  font-size: 1.4rem;
  font-family: "EB Garamond", serif;
  font-weight: Regular;
  display: block;
  margin-bottom: 1.4rem;
}
.p-cost-price__text {
  margin-top: 4rem;
}
.p-cost-price__text p {
  font-size: 1.5rem;
  line-height: 3.2rem;
}
@media (--mobile) {
  .p-cost-price__text {
    margin-top: 2rem;
  }
}
.p-cost-case {
  display: flex;
  justify-content: space-around;
  padding-bottom: 11rem;
  margin-bottom: 11rem;
  border-bottom: 1px solid #D0D0D0;
}
@media (--mobile) {
  .p-cost-case {
    flex-direction: column;
    padding-bottom: 6rem;
    margin-bottom: 7rem;
  }
}
.p-cost-case__cell {
  width: 40rem;
  position: relative;
}
@media (--mobile) {
  .p-cost-case__cell {
    width: 100%;
    margin-bottom: 9rem;
  }
  .p-cost-case__cell:last-of-type {
    margin-bottom: 3rem;
  }
}
.p-cost-case__title {
  font-size: 2.4rem;
  margin-bottom: 2.5rem;
  line-height: 3rem;
}
@media (--mobile) {
  .p-cost-case__title {
    font-size: 2rem;
  }
}
.p-cost-case__title span {
  font-size: 1.4rem;
  display: block;
}
.p-cost-case__text {
  font-size: 1.5rem;
  line-height: 3.2rem;
}
.p-cost-case__block {
  display: flex;
  margin-bottom: 3rem;
}
@media (--large) {
  .p-cost-case__block {
    min-height: 9rem;
  }
}
.p-cost-case__block.center {
  align-items: center;
}
@media (--large) {
  .p-cost-case__block.center {
    align-items: flex-start;
  }
  .p-cost-case__block.center .p-cost-case__txt {
    padding-top: 1rem;
  }
}
.p-cost-case__subtitle {
  margin-top: 1.2rem;
  margin-right: 3rem;
}
.p-cost-case__subtitle span {
  white-space: nowrap;
  background-color: #171717;
  color: #FFF;
  padding: 1rem;
}
.p-cost-case__btn {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 50%;
  transform: translate(-50%, 50px);
}
.p-cost-case__btn.small {
  width: 33%;
  bottom: 26%;
}
@media (--mobile) {
  .p-cost-case__btn.small {
    width: 100%;
    bottom: 85px;
  }
}
.p-cost-case__btn a {
  border: 0.2rem solid #171717;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  transition: 0.5s background, 0.5s color;
}
.p-cost-case__btn a:hover {
  background-color: #171717;
  color: #FFF;
}
.p-cost-flow__more {
  font-size: 1.6rem;
  padding: 1rem;
  border: 0.1rem solid #D0D0D0;
  position: relative;
}
.p-cost-flow__more span .icon-plus {
  color: #171717;
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
}
.p-cost-flow__more span .icon-plus:before,
.p-cost-flow__more span .icon-plus:after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 1.5rem;
  height: 0.1rem;
  background-color: #171717;
}
.p-cost-flow__more span .icon-plus:after {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.p-cost-flow__more.open .icon-plus::after {
  content: none;
}
.p-cost-flow__about {
  display: flex;
  font-size: 1.6rem;
  margin-bottom: 1rem;
}
@media (--mobile) {
  .p-cost-flow__about {
    display: block;
  }
}
.p-cost-flow__about p {
  margin-bottom: 0 !important;
  margin-right: 1rem;
}
.p-cost-flow__btn {
  font-size: 1.5rem;
  border-bottom: 0.1rem solid #707070;
  padding-bottom: 1rem;
  display: inline-block;
}
@media (--mobile) {
  .p-cost-flow__btn {
    margin-top: 1rem;
  }
}
.p-cost-flow__btn a {
  display: flex;
  align-items: center;
}
.p-cost-flow__btn a:hover svg circle:first-of-type {
  fill: rgb(23, 23, 23);
}
.p-cost-flow__btn a:hover svg path {
  stroke: #FFF;
}
.p-cost-flow__btn svg {
  width: 3rem;
  height: 3rem;
  margin-left: 1rem;
}
.p-cost-flow__btn svg circle:first-of-type {
  fill: rgba(255, 255, 255, 0);
  transition: 0.5s fill;
}
.p-cost-flow__btn svg path {
  stroke: #171717;
}
.p-cost-flow__btn svg, .p-cost-flow__btn svg * {
  transition: 0.5s;
}
.p-cost-flow__title {
  font-size: 2.6rem;
  margin-bottom: 6rem;
  padding-top: 2rem;
}
@media (--mobile) {
  .p-cost-flow__title {
    margin-bottom: 2.4rem;
    padding-top: 5.4rem;
  }
  .p-cost-flow__title.p-cost-after {
    margin-bottom: 1.5rem !important;
  }
}
.p-cost-flow__title span {
  font-size: 1.4rem;
  font-family: "EB Garamond", serif;
  font-weight: Regular;
  display: block;
  margin-bottom: 1.4rem;
  text-align: left;
}
@media (--mobile) {
  .p-cost-flow__title span {
    margin-bottom: 0;
  }
}
.p-cost-flow__text {
  font-size: 1.5rem;
  line-height: 3.2rem;
  margin-top: 8rem;
  margin-bottom: 8rem;
  text-align: left;
}
@media (--mobile) {
  .p-cost-flow__text {
    margin-top: 1.5rem;
    margin-bottom: 0rem;
    text-align: left;
  }
}
.p-cost-flow__contents {
  display: flex;
  justify-content: space-between;
}
.p-cost-flow__contents:last-child {
  margin-top: 12rem;
}
@media (--mobile) {
  .p-cost-flow__contents:last-child {
    margin-top: 0rem;
  }
}
@media (--mobile) {
  .p-cost-flow__contents {
    display: block;
    width: 100%;
  }
}
.p-cost-flow ul {
  position: relative;
}
@media (--mobile) {
  .p-cost-flow ul {
    width: 100%;
  }
}
.p-cost-flow ul.free li {
  position: relative;
}
.p-cost-flow ul.free li::before {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  position: absolute;
  content: "無料";
  background-color: #171717;
  color: #FFF;
  font-size: 1.2rem;
  left: -50px;
  top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (--mobile) {
  .p-cost-flow ul.free li::before {
    top: 6.6rem;
    z-index: 4;
    left: -0.1rem;
    z-index: 4;
  }
}
.p-cost-flow ul::after {
  content: "";
  display: inline-block;
  width: 1px;
  height: 100%;
  background-color: #D0D0D0;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, -50%) translateX(-37rem);
}
@media (--mobile) {
  .p-cost-flow ul::after {
    transform: translate(-50%, -50%) translateX(-14rem);
  }
}
.p-cost-flow ul li {
  display: flex;
}
@media (--mobile) {
  .p-cost-flow ul li.step6 {
    min-height: 20rem;
  }
  .p-cost-flow ul li.step8 {
    min-height: 30rem;
  }
  .p-cost-flow ul li.step13 {
    min-height: 40rem;
  }
  .p-cost-flow ul li.step15 {
    min-height: 26rem;
  }
}
.p-cost-flow ul li:last-child {
  margin-bottom: 0;
}
@media (--mobile) {
  .p-cost-flow ul li.turn {
    padding-bottom: 0;
    margin-bottom: 0rem;
  }
}
.p-cost-flow__step {
  margin-right: 4rem;
  font-size: 1.8rem;
  font-family: "EB Garamond", serif;
  font-weight: Regular;
  position: relative;
}
.p-cost-flow__step div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) translateY(3rem);
  white-space: nowrap;
  font-size: 1.4rem;
  text-align: center;
  border: 1px solid #CECECE;
  padding: 0.8rem 1.5rem;
  background-color: #FFF;
  z-index: 2;
  color: #707070;
}
.p-cost-flow__step div.first {
  transform: translate(-50%, -50%) translateY(-9rem);
}
@media (--mobile) {
  .p-cost-flow__step div.first {
    transform: translate(-50%, -50%) translateY(-6rem);
  }
}
.p-cost-flow__step div.second {
  transform: translate(-50%, -50%) translateY(9rem);
}
@media (--mobile) {
  .p-cost-flow__step div {
    writing-mode: vertical-rl;
    transform: translate(-50%, -50%) translateY(3rem);
    padding: 0.8rem 0.5rem;
    font-size: 1.2rem;
  }
}
.p-cost-flow__step div span {
  font-weight: bold;
}
.p-cost-flow__step div span.num {
  font-weight: normal;
}
@media (--mobile) {
  .p-cost-flow__step div span.num {
    text-combine-upright: all;
  }
}
@media (--mobile) {
  .p-cost-flow__step {
    margin-right: 2rem;
  }
}
.p-cost-flow__step p {
  background-color: #FFFFFF;
  z-index: 2;
  position: relative;
  padding: 1.7rem 0;
}
@media (--mobile) {
  .p-cost-flow__step p {
    padding: 0;
    padding-bottom: 0.5rem;
    font-size: 1.2rem;
    text-align: center;
    margin-left: 0.3rem;
  }
}
.p-cost-flow .p-cost-flow__step.last {
  background: #FFFFFF;
  padding-top: 1.7rem;
  z-index: 99;
  position: relative;
}
.p-cost-flow .p-cost-flow-ttl {
  text-align: left !important;
}
.p-cost-flow__content {
  width: 70rem;
  padding-top: 1.7rem;
  padding-bottom: 5rem;
}
.p-cost-flow__content.flex {
  display: flex;
}
@media (--mobile) {
  .p-cost-flow__content.flex {
    display: block;
  }
}
@media (--mobile) {
  .p-cost-flow__content {
    width: 100%;
    padding-top: 0;
    padding-bottom: 5rem;
  }
  .p-cost-flow__content.pb-0 {
    padding-bottom: 0;
  }
}
.p-cost-flow-contents {
  width: calc(100% - 25.8rem - 1rem);
  margin-right: 1rem;
}
@media (--mobile) {
  .p-cost-flow-contents {
    width: 100%;
    margin-right: 0;
    margin-bottom: 1rem;
  }
}
.p-cost-flow__img {
  width: 25.8rem;
}
@media (--mobile) {
  .p-cost-flow__img {
    display: none;
  }
}
.p-cost-flow__ttl {
  font-size: 2rem;
  color: #171717;
  font-weight: bold;
  margin-bottom: 1.5rem;
}
@media (--mobile) {
  .p-cost-flow__ttl {
    margin-bottom: 2rem;
  }
}
.p-cost-flow__ttl.last {
  padding-top: 1.5rem;
}
.p-cost-flow__ttl span {
  display: inline-block;
  font-size: 1.2rem;
  font-weight: normal;
  background-color: #EAEAEA;
  padding: 0.5rem;
  margin-top: 1rem;
}
@media (--mobile) {
  .p-cost-flow__ttl span {
    font-size: 1.3rem;
  }
}
.p-cost-flow__txt {
  font-size: 1.5rem;
  line-height: 3.2rem;
}
@media (--mobile) {
  .p-cost-flow__txt {
    margin-top: 1rem;
    display: none;
  }
}
@media (--large) {
  .p-cost-flow__txt img {
    display: none;
  }
}
.p-cost-flow__service {
  width: 82rem;
}
@media (--mobile) {
  .p-cost-flow__service {
    width: 100%;
  }
}
.p-cost-flow__service p {
  font-size: 1.5rem;
  line-height: 3.2rem;
  margin-bottom: 3rem;
}
@media (--mobile) {
  .p-cost .sp-mt-0 {
    margin-top: 0;
  }
}

.p-counseling-detail {
  position: relative;
  margin-top: 22rem;
}
.p-counseling-detail .white-out {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #FFF;
  animation-name: fadeout;
  animation-duration: 1s;
  z-index: 0;
  opacity: 0;
}
@keyframes fadeout {
  0% {
    opacity: 1;
    z-index: 10;
  }
  100% {
    z-index: 0;
    opacity: 0;
  }
}
.p-counseling-detail__ttl {
  text-align: center;
  font-size: 1.5rem;
}
.p-counseling-detail__ttl strong {
  display: block;
  font-size: 3.6rem;
  margin-top: 1.4rem;
  font-weight: normal;
  letter-spacing: 0.2em;
}
.p-counseling-detail__inner {
  display: flex;
  justify-content: space-between;
}
.p-counseling-detail__cell {
  width: 78rem;
  margin: 7rem auto 12rem auto;
}
@media (--mobile) {
  .p-counseling-detail__cell {
    width: 100%;
  }
}
.p-counseling-detail__side {
  width: 30rem;
}
.p-counseling-detail__side > div {
  position: sticky;
  top: 0;
  padding-top: 10rem;
  padding-right: 1rem;
  padding-bottom: 3rem;
  margin-top: -10rem;
  overflow-y: auto;
  height: 100vh;
}
.p-counseling-detail__side > div > ul {
  margin-bottom: 3rem;
}
.p-counseling-detail__side > div > ul > li {
  position: relative;
  font-size: 1.5rem;
  margin-bottom: 2rem;
}
.p-counseling-detail__side > div > ul > li:last-child {
  margin-bottom: 0;
}
.p-counseling-detail__side > div > ul > li span {
  font-size: 1.4rem;
  font-family: "EB Garamond", serif;
  margin-right: 2rem;
}
.p-counseling-detail__side > div > ul > li a {
  display: flex;
  font-size: 1.2rem;
  align-items: flex-start;
  padding-top: 2rem;
}
@media (--mobile) {
  .p-counseling-detail__side > div > ul > li a {
    padding: 2rem 4rem 2rem 2rem;
  }
}
.p-counseling-detail__side .paging {
  display: flex;
  justify-content: space-between;
}
.p-counseling-detail__side .paging .c-backBtn {
  margin-top: 0;
}
.p-counseling-detail__side .paging .c-moreBtn {
  margin-top: 0;
}
.p-counseling-detail__question {
  font-family: "EB Garamond", serif;
  font-weight: Regular;
  font-size: 2rem;
}
.p-counseling-detail .en {
  position: relative;
  display: block;
  text-align: center;
  margin-bottom: 4rem;
  color: #333333;
}
.p-counseling-detail .en::after {
  content: "";
  width: 78rem;
  height: 0.1rem;
  display: inline-block;
  background-color: #D0D0D0;
  position: absolute;
  bottom: -1rem;
  left: 0;
}
@media (--mobile) {
  .p-counseling-detail .en::after {
    width: 100%;
  }
}
.p-counseling-detail .jp {
  display: block;
  text-align: center;
  font-size: 2.6rem;
  margin-bottom: 1rem;
}
@media (--mobile) {
  .p-counseling-detail .jp {
    text-align: left;
    display: flex;
    justify-content: center;
    font-size: 2.2rem;
  }
}
.p-counseling-detail .user {
  display: block;
  font-size: 1.4rem;
  text-align: center;
  margin-bottom: 5rem;
}
@media (--mobile) {
  .p-counseling-detail .user {
    margin-bottom: 2rem;
  }
}
.p-counseling-detail__img {
  margin-bottom: 3rem;
}
@media (--mobile) {
  .p-counseling-detail__img {
    margin-bottom: 3rem;
  }
}
.p-counseling-detail__img figcaption {
  margin-top: 1.5rem;
  font-size: 1.2rem;
}
.p-counseling-detail__text {
  font-size: 1.5rem;
  line-height: 3.6rem;
  margin-bottom: 6rem;
}
@media (--mobile) {
  .p-counseling-detail__text {
    margin-bottom: 3rem;
  }
}
.p-counseling-detail__answer {
  font-family: "EB Garamond", serif;
  font-weight: Regular;
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 3rem;
  position: relative;
}
@media (--mobile) {
  .p-counseling-detail__answer {
    margin-bottom: 1rem;
  }
}
.p-counseling-detail__answer span {
  background-color: #FFF;
  position: relative;
  z-index: 2;
  padding-right: 1rem;
}
.p-counseling-detail__answer::after {
  position: absolute;
  border-top: 1px solid #D0D0D0;
  content: "";
  width: 100%;
  right: 0;
}
@media (--mobile) {
  .p-counseling-detail__answer::after {
    width: 100%;
  }
}
.p-counseling-detail__comment {
  font-family: "EB Garamond", serif;
  font-weight: Regular;
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 3rem;
  position: relative;
}
@media (--mobile) {
  .p-counseling-detail__comment {
    margin-bottom: 1rem;
  }
}
.p-counseling-detail__comment span {
  background-color: #FFF;
  position: relative;
  z-index: 2;
  padding-right: 1rem;
}
.p-counseling-detail__comment::after {
  position: absolute;
  border-top: 1px solid #D0D0D0;
  content: "";
  width: 100%;
  right: 0;
}
@media (--mobile) {
  .p-counseling-detail__comment::after {
    width: 100%;
  }
}
.p-counseling-detail__acco {
  border-bottom: 1px solid rgba(51, 51, 51, 0.1);
  padding-bottom: 1.5rem;
  position: relative;
  cursor: pointer;
}
.p-counseling-detail__acco.open::before {
  transform: rotate(-45deg);
}
.p-counseling-detail__acco::before {
  transition: 0.3s;
  width: 1.2rem;
  height: 1.2rem;
  content: "";
  position: absolute;
  border-right: solid 1px #707070;
  border-top: solid 1px #707070;
  top: 32%;
  right: 1rem;
  transform: translateY(-50%) rotate(135deg);
}
.p-counseling-detail__acco-list {
  display: none;
  padding-top: 1rem;
}

.p-counseling-accordion {
  width: 98rem;
  position: relative;
  margin: 0 auto;
}
@media (--mobile) {
  .p-counseling-accordion {
    width: 100%;
  }
}
.p-counseling-accordion__item {
  border-top: 1px solid #D0D0D0;
}
.p-counseling-accordion__item:last-of-type {
  border-bottom: 1px solid #D0D0D0;
}
.p-counseling-accordion__content {
  display: none;
}
.p-counseling-accordion__content ul li {
  position: relative;
  font-size: 1.5rem;
}
.p-counseling-accordion__content ul li.ttl {
  margin-bottom: 1rem;
}
.p-counseling-accordion__content ul li:last-child {
  margin-bottom: 4rem;
}
.p-counseling-accordion__content ul li span {
  font-size: 1.4rem;
  font-family: "EB Garamond", serif;
  margin-right: 2rem;
}
.p-counseling-accordion__content ul li a {
  display: block;
  padding: 0rem 0 3rem 3rem;
}
@media (--mobile) {
  .p-counseling-accordion__content ul li a {
    padding: 1rem 0rem 1rem 2rem;
  }
}
.p-counseling-accordion__content ul li::before {
  border-right: solid 1px #707070;
  border-top: solid 1px #707070;
  content: "";
  display: block;
  width: 0.8rem;
  height: 0.8rem;
  position: absolute;
  left: 0rem;
  top: 8px;
  transform: rotate(45deg);
}
@media (--mobile) {
  .p-counseling-accordion__content ul li::before {
    width: 0.8rem;
    height: 0.8rem;
    top: 1.7rem;
    right: 2rem;
  }
}
.p-counseling-accordion__content-link {
  display: flex !important;
}
.p-counseling-accordion__title {
  color: #333333;
  cursor: pointer;
  font-size: 2.6rem;
  padding: 5rem 0;
  position: relative;
  padding-left: 2rem;
}
@media (--mobile) {
  .p-counseling-accordion__title {
    padding: 3rem 3rem 3rem 2rem;
    font-size: 2rem;
  }
}
.p-counseling-accordion__title::after {
  border-right: solid 1px #707070;
  border-top: solid 1px #707070;
  content: "";
  display: block;
  width: 1.8rem;
  height: 1.8rem;
  position: absolute;
  right: 3rem;
  top: 38%;
  transform: rotate(135deg);
  transition: transform 0.3s ease-in-out, top 0.3s ease-in-out;
}
@media (--mobile) {
  .p-counseling-accordion__title::after {
    width: 1.2rem;
    height: 1.2rem;
    top: 40%;
    right: 2rem;
  }
}
.p-counseling-accordion__title.open::after {
  top: 45%;
  transform: rotate(-45deg);
}

.p-design-detail-content {
  width: 98rem;
  margin: 0 auto 12rem auto;
  display: flex;
  justify-content: space-between;
}
@media (--mobile) {
  .p-design-detail-content {
    width: 100%;
    display: block;
  }
}
.p-design-detail-content__text {
  width: 68rem;
}
@media (--mobile) {
  .p-design-detail-content__text {
    width: 100%;
    margin-top: 2rem;
  }
}
.p-design-detail-content__text p {
  font-size: 1.5rem;
  line-height: 3.2rem;
  margin-bottom: 4rem;
}
@media (--mobile) {
  .p-design-detail-content__text p {
    margin-bottom: 2rem;
  }
}
.p-design-detail-content__text p:last-child {
  margin-bottom: 0;
}
.p-design-detail .c-mv {
  height: 63vw;
  overflow: hidden;
}
.p-design-detail .c-mv img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.p-design-detail__img {
  margin-bottom: 12rem;
  text-align: center;
}
@media (--mobile) {
  .p-design-detail__img {
    margin-top: 5rem;
    margin-bottom: 5rem;
    margin-left: -3rem;
    margin-right: -3rem;
  }
}
.p-design-detail-num {
  width: 98rem;
  margin: 0 auto 12rem auto;
}
@media (--mobile) {
  .p-design-detail-num {
    width: 100%;
  }
}
.p-design-detail-num .c-leftTtl {
  margin-bottom: 4rem;
}
@media (--mobile) {
  .p-design-detail-num .c-leftTtl {
    margin-bottom: 2rem;
  }
}
.p-design-detail-num__title {
  font-size: 2rem;
  margin-bottom: 3rem;
}
@media (--mobile) {
  .p-design-detail-num__title {
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
  }
}
.p-design-detail-num__title span {
  font-size: 1.8rem;
  font-family: "EB Garamond", serif;
  margin-right: 0.7rem;
}
.p-design-detail-num__text {
  margin-bottom: 7rem;
  font-size: 1.5rem;
  line-height: 3.2rem;
}
.p-design-detail-num__text:last-of-type {
  margin-bottom: 0;
}
@media (--mobile) {
  .p-design-detail-num__text {
    margin-bottom: 3rem;
  }
}
.p-design-detail-cell {
  width: 98rem;
  margin: 0 auto 12rem auto;
}
@media (--mobile) {
  .p-design-detail-cell {
    width: 100%;
  }
}
.p-design-detail-cell .c-leftTtl {
  margin-bottom: 4rem;
}
@media (--mobile) {
  .p-design-detail-cell .c-leftTtl {
    margin-bottom: 2rem;
  }
}
.p-design-detail-cell__title {
  font-size: 2rem;
  margin-bottom: 3rem;
}
@media (--mobile) {
  .p-design-detail-cell__title {
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
  }
}
.p-design-detail-cell__text {
  margin-bottom: 7rem;
  font-size: 1.5rem;
  line-height: 3.2rem;
}
.p-design-detail-cell__text:last-of-type {
  margin-bottom: 0;
}
@media (--mobile) {
  .p-design-detail-cell__text {
    margin-bottom: 3rem;
  }
}
.p-design-detail-left {
  display: flex;
  margin-top: 12rem;
  margin-bottom: 12rem;
  align-items: center;
  justify-content: space-between;
}
@media (--mobile) {
  .p-design-detail-left {
    display: block;
    margin-top: 6rem;
    margin-bottom: 6rem;
  }
}
.p-design-detail-left__img {
  width: 56rem;
}
@media (--mobile) {
  .p-design-detail-left__img {
    width: 100%;
    margin-bottom: 8rem;
  }
}
.p-design-detail-left__content {
  width: 48rem;
}
@media (--mobile) {
  .p-design-detail-left__content {
    width: 100%;
    margin-bottom: 12rem;
  }
}
.p-design-detail-left__title {
  font-size: 2.6rem;
  margin-bottom: 5rem;
}
@media (--mobile) {
  .p-design-detail-left__title {
    font-size: 2rem;
    margin-bottom: 4rem;
  }
}
.p-design-detail-left__text p {
  font-size: 1.5rem;
  line-height: 3.2rem;
  margin-bottom: 4rem;
}
@media (--mobile) {
  .p-design-detail-left__text p {
    margin-bottom: 2rem;
  }
}
.p-design-detail-left__text p:last-of-type {
  margin-bottom: 0;
}
.p-design-detail-sketch {
  margin-bottom: 12rem;
}
.p-design-detail-sketch ul {
  margin-top: 7rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
@media (--mobile) {
  .p-design-detail-sketch ul {
    width: 100%;
    margin-top: 3rem;
  }
}
.p-design-detail-sketch ul li {
  width: 48%;
}
@media (--large) {
  .p-design-detail-sketch ul li:nth-child(n+3) {
    margin-top: 6.6rem;
  }
}
@media (--mobile) {
  .p-design-detail-sketch ul li {
    width: 100%;
  }
  .p-design-detail-sketch ul li:nth-child(n+2) {
    margin-top: 6rem;
  }
}
.p-design-detail-sketch ul li .img {
  width: 100%;
  height: auto;
}
.p-design-detail-sketch ul li .img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.p-design-detail-sketch ul li p {
  margin-top: 3rem;
  font-size: 1.4rem;
}
.p-design-detail-movie {
  margin-top: 4.5rem;
  margin-left: auto;
  margin-right: auto;
  width: 66%;
  height: 56rem;
}
.p-design-detail-movie iframe {
  width: 100%;
  height: 100%;
  display: block;
}
.p-design-detail .movie-link {
  margin: 4rem auto 8rem;
  padding-left: 10rem;
  padding-right: 12rem;
  border: 1px solid #171717;
  height: 8rem;
  width: 38rem;
  display: flex;
  font-size: 2rem;
  align-items: center;
  position: relative;
}
@media (--mobile) {
  .p-design-detail .movie-link {
    margin: 2.4rem auto 6rem;
    height: 5rem;
    font-size: 1.6rem;
    width: 70%;
    padding-left: 4rem;
    padding-right: 3rem;
  }
}
@media (--large) {
  .p-design-detail .movie-link:hover svg path {
    stroke: #fff;
  }
  .p-design-detail .movie-link:hover svg circle {
    fill: #111;
  }
}
.p-design-detail .movie-link span {
  position: absolute;
  top: 50%;
  right: 8rem;
  transform: translateY(-50%) translateY(0.5rem);
  width: 3rem;
  height: auto;
  display: block;
}
@media (--mobile) {
  .p-design-detail .movie-link span {
    right: 2rem;
    transform: translateY(-50%) translateY(0.2rem);
  }
}
.p-design-detail .movie-link span svg path, .p-design-detail .movie-link span svg circle {
  transition: 0.3s;
}
.p-design-detail-gallery {
  margin-top: 8rem;
  margin-bottom: 8rem;
}
@media (--mobile) {
  .p-design-detail-gallery {
    margin-top: 3rem;
    margin-bottom: 6rem;
    margin-right: -1rem;
    margin-left: -1rem;
  }
}
.p-design-detail-gallery ul {
  width: 118rem;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
}
@media (--mobile) {
  .p-design-detail-gallery ul {
    width: 100%;
  }
}
.p-design-detail-gallery ul li {
  width: 18rem;
  height: 18rem;
  margin-right: 2rem;
  margin-bottom: 2rem;
  overflow: hidden;
}
@media (--mobile) {
  .p-design-detail-gallery ul li {
    width: calc(33.3333333333% - 1rem);
    height: 10.4rem;
    margin: 0 0.5rem;
  }
  .p-design-detail-gallery ul li:nth-of-type(n + 4) {
    margin-top: 1rem;
  }
}
.p-design-detail-gallery ul li:nth-child(6n) {
  margin-right: 0;
}
.p-design-detail-gallery ul li a {
  height: 100%;
  width: 100%;
  display: inline-block;
}
.p-design-detail-gallery ul li a img {
  width: 18rem;
  height: 18rem;
  object-fit: cover;
  transition: 1s all;
}
@media (--mobile) {
  .p-design-detail-gallery ul li a img {
    width: 10.4rem;
    height: 10.4rem;
  }
}
.p-design-detail-gallery ul li a img:hover {
  transform: scale(1.05);
  transition: 1s all;
}
.p-design-detail-gallery-modal {
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  opacity: 0;
  transition: 0.3s opacity;
}
.p-design-detail-gallery-modal, .p-design-detail-gallery-modal * {
  pointer-events: none !important;
}
.p-design-detail-gallery-modal.is-active {
  opacity: 1;
}
.p-design-detail-gallery-modal.is-active, .p-design-detail-gallery-modal.is-active * {
  pointer-events: inherit !important;
}
.p-design-detail-gallery-modal .swiper-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  height: inherit;
  overflow: hidden;
}
@media (--mobile) {
  .p-design-detail-gallery-modal .swiper-modal {
    width: 100%;
  }
}
.p-design-detail-gallery-modal .swiper-slide, .p-design-detail-gallery-modal .swiper-wrapper {
  height: inherit;
}
.p-design-detail-gallery-modal img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.p-design-detail-gallery-modal img.w {
  max-width: inherit;
  width: 100%;
}
.p-design-detail-gallery-modal img.h {
  max-height: inherit;
  height: 100%;
}
@media (--mobile) {
  .p-design-detail-gallery-modal img.h {
    width: 100%;
    height: auto;
    max-height: inherit;
  }
}
.p-design-detail-gallery-modal .swiper-button-prev, .p-design-detail-gallery-modal .swiper-button-next {
  width: 4rem;
  height: 4rem;
  background-color: rgba(30, 30, 30, 0.8);
  border: 0.1rem solid #333333;
  outline: none;
}
@media (--mobile) {
  .p-design-detail-gallery-modal .swiper-button-prev, .p-design-detail-gallery-modal .swiper-button-next {
    width: 3rem;
    height: 3rem;
  }
}
.p-design-detail-gallery-modal .swiper-button-prev::after, .p-design-detail-gallery-modal .swiper-button-next::after {
  content: "";
  position: absolute;
  height: 1px;
  width: 2rem;
  background-color: #fff;
}
.p-design-detail-gallery-modal .swiper-button-prev::before, .p-design-detail-gallery-modal .swiper-button-next::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) translateX(15%) rotate(-135deg);
  height: 1.2rem;
  width: 1.2rem;
  border-right: 0.1rem solid #fff !important;
  border-top: 0.1rem solid #fff;
}
@media (--large) {
  .p-design-detail-gallery-modal .swiper-button-prev {
    left: 30%;
    transform: translateX(-28vw);
  }
  .p-design-detail-gallery-modal .swiper-button-prev::after {
    left: 1.1rem;
    top: 50%;
    transform: translateY(-50%);
  }
}
.p-design-detail-gallery-modal .swiper-button-prev::before {
  content: "";
  height: 1.2rem;
  width: 1.2rem;
  border-left: none;
  border-right: 0.1rem solid #333;
  left: 39%;
}
@media (--large) {
  .p-design-detail-gallery-modal .swiper-button-next {
    right: 30%;
    transform: translateX(28vw);
  }
  .p-design-detail-gallery-modal .swiper-button-next::after {
    left: 0.9rem;
    top: 50%;
    transform: translateY(-50%);
  }
}
.p-design-detail-gallery-modal .swiper-button-next::before {
  content: "";
  transform: translate(-50%, -50%) translateX(-15%) rotate(45deg);
  height: 1.2rem;
  width: 1.2rem;
  border-left: none;
  border-right: 0.1rem solid #333;
  left: 65%;
}
.p-design-detail-gallery-modal__overflow {
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.95);
}
.p-design-detail-gallery-modal__close {
  position: absolute;
  top: 0rem;
  right: 0rem;
  width: 4rem;
  height: 4rem;
  background-color: rgba(30, 30, 30, 0.6);
  outline: none;
  cursor: pointer;
  z-index: 9999;
  transition: 0.3s;
}
@media (--mobile) {
  .p-design-detail-gallery-modal__close {
    width: 3rem;
    height: 3rem;
  }
}
.p-design-detail-gallery-modal__close:hover {
  background-color: rgba(30, 30, 30, 0.8);
}
.p-design-detail-gallery-modal__close::after, .p-design-detail-gallery-modal__close::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  height: 2rem;
  width: 0.1rem;
  background: #fff;
}
.p-design-detail-gallery-modal__close::before {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.p-design-detail-layout__img {
  text-align: center;
  margin: 5rem auto 9rem auto;
}
@media (--mobile) {
  .p-design-detail-layout__img {
    margin-top: 8rem;
    margin-bottom: 12rem;
  }
}
.p-design-detail-summary dl {
  display: flex;
  flex-wrap: wrap;
  margin-top: 8rem;
}
@media (--mobile) {
  .p-design-detail-summary dl {
    display: block;
    margin-top: 8rem;
    margin-bottom: 12rem;
  }
}
.p-design-detail-summary dt {
  width: 20%;
  font-size: 1.5rem;
  font-weight: bold;
  padding: 3rem 0;
  border-bottom: 1px solid #D0D0D0;
  display: flex;
  align-items: center;
  padding-left: 3rem;
}
.p-design-detail-summary dt:first-of-type {
  border-top: 1px solid #D0D0D0;
}
@media (--mobile) {
  .p-design-detail-summary dt {
    width: 100%;
    display: block;
    padding: 1.5rem 0;
    border: none;
  }
}
.p-design-detail-summary dd {
  width: 80%;
  font-size: 1.5rem;
  padding: 3rem 0;
  border-bottom: 1px solid #D0D0D0;
  line-height: 3.2rem;
}
.p-design-detail-summary dd:first-of-type {
  border-top: 1px solid #D0D0D0;
}
@media (--mobile) {
  .p-design-detail-summary dd:first-of-type {
    border-bottom: 1px solid #D0D0D0;
    border-top: none;
  }
}
@media (--mobile) {
  .p-design-detail-summary dd {
    width: 100%;
    padding: 0rem 0 1.5rem 0;
  }
}
.p-design-detail-interview {
  width: 118rem;
  margin: 12rem auto 0 auto;
  display: flex;
  justify-content: space-between;
}
@media (--mobile) {
  .p-design-detail-interview {
    width: 100%;
    margin-top: 6rem;
    display: block;
  }
}
.p-design-detail-interview__title {
  font-size: 3.2rem;
  font-family: "EB Garamond", serif;
  font-weight: Regular;
  white-space: nowrap;
}
@media (--mobile) {
  .p-design-detail-interview__title {
    font-size: 2.5rem;
    text-align: left;
    margin-bottom: 6rem;
  }
}
.p-design-detail-interview__title .jp {
  font-size: 1.5rem;
  margin-bottom: 1.4rem;
  display: block;
}
@media (--mobile) {
  .p-design-detail-interview__title .jp {
    margin-bottom: 1rem;
    font-size: 1.2rem;
  }
}
.p-design-detail-interview__content {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.p-design-detail-interview__cell {
  width: 78rem;
  display: flex;
  border-bottom: 1px solid #D0D0D0;
  padding-bottom: 4rem;
  margin-bottom: 4rem;
  padding: 0 2rem 4rem 2rem;
}
@media (--mobile) {
  .p-design-detail-interview__cell {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    padding-bottom: 8rem;
    margin-bottom: 8rem;
  }
  .p-design-detail-interview__cell:last-child {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
  }
}
.p-design-detail-interview__cell:nth-child(even) {
  flex-direction: row-reverse;
}
@media (--mobile) {
  .p-design-detail-interview__cell:nth-child(even) {
    flex-direction: column-reverse;
  }
}
.p-design-detail-interview__cell:nth-child(even) .p-design-detail-interview__text {
  margin-right: 0rem;
  margin-left: 3rem;
}
@media (--mobile) {
  .p-design-detail-interview__cell:nth-child(even) .p-design-detail-interview__text {
    margin-left: 0;
  }
}
.p-design-detail-interview__text {
  width: 55rem;
  margin-right: 3rem;
}
@media (--mobile) {
  .p-design-detail-interview__text {
    width: 100%;
    margin-right: 0;
    margin-bottom: 0rem;
  }
}
.p-design-detail-interview__heading {
  font-size: 2rem;
  margin-bottom: 1.4rem;
}
.p-design-detail-interview__ttl {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 2.5rem;
}
.p-design-detail-interview__txt {
  font-size: 1.5rem;
  line-height: 3.2rem;
  margin-bottom: 1.5rem;
}
@media (--mobile) {
  .p-design-detail-interview__txt {
    margin-top: 1rem;
  }
}
.p-design-detail-interview__name {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
}
@media (--mobile) {
  .p-design-detail-interview__name {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    white-space: nowrap;
  }
}
.p-design-detail-interview__name span {
  font-size: 1.3rem;
  margin-right: 1.6rem;
}
.p-design-detail-interview__name span.position {
  position: relative;
  margin-right: 2rem;
  color: #7B7B7B;
  line-height: 1;
}
.p-design-detail-interview__name span.position::after {
  position: absolute;
  display: block;
  content: "";
  width: 1px;
  height: 1.3rem;
  background-color: #7B7B7B;
  right: -1rem;
  top: 50%;
  transform: translateY(-50%);
}
.p-design-detail-interview__name span.kana {
  margin-left: 1.2rem;
}
@media (--mobile) {
  .p-design-detail-interview__name span.kana {
    margin-right: 0;
    display: block;
  }
}
.p-design-detail-interview__name span.city {
  font-size: 1.3rem;
  color: #7B7B7B;
  position: relative;
  margin-left: 2rem;
  line-height: 1;
}
.p-design-detail-interview__name span.city::before {
  position: absolute;
  display: block;
  content: "";
  width: 1px;
  height: 1.3rem;
  background-color: #7B7B7B;
  left: -1rem;
  top: 50%;
  transform: translateY(-50%);
}
.p-design-detail-interview__more {
  display: flex;
  justify-content: flex-end;
  font-size: 1.5rem;
  margin-top: -2.2rem;
}
@media (--mobile) {
  .p-design-detail-interview__more {
    margin-top: 1rem;
  }
}
.p-design-detail-interview__more img {
  margin-left: 1.5rem;
}
.p-design-detail-interview__img {
  width: calc(100% - 55rem - 3rem);
}
@media (--mobile) {
  .p-design-detail-interview__img {
    width: 100%;
    text-align: center;
    margin-bottom: 3rem;
  }
}
.p-design-detail-traveling {
  padding: 6rem 15rem;
  border-top: 1px solid #D0D0D0;
  border-bottom: 1px solid #D0D0D0;
}
@media (--mobile) {
  .p-design-detail-traveling {
    padding: 6rem 0rem;
  }
}
.p-design-detail-traveling h3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 400;
}
.p-design-detail-traveling h3 small {
  font-size: 1.4rem;
}
.p-design-detail-traveling h3 span {
  font-size: 2.6rem;
  display: block;
  margin-top: 1rem;
}
@media (--mobile) {
  .p-design-detail-traveling h3 span {
    font-size: 2rem;
  }
}
.p-design-detail-traveling .traveling-content {
  margin-top: 6rem;
  display: flex;
  align-items: center;
  column-gap: 4.8rem;
}
@media (--mobile) {
  .p-design-detail-traveling .traveling-content {
    margin-top: 2rem;
    flex-direction: column;
  }
}
.p-design-detail-traveling .traveling-content p {
  font-size: 1.6rem;
  flex: 1;
}
@media (--mobile) {
  .p-design-detail-traveling .traveling-content__btnWrap {
    width: 100%;
    margin-top: 5rem;
  }
}
.p-design-detail-traveling .traveling-content__btnWrap a {
  display: flex;
  padding-left: 12rem;
  align-items: center;
  width: 47.3rem;
  position: relative;
  border: 1px solid #7E7E7E;
  font-size: 2rem;
  font-weight: 500;
}
@media (--large) {
  .p-design-detail-traveling .traveling-content__btnWrap a svg g {
    transition: 0.3s;
  }
  .p-design-detail-traveling .traveling-content__btnWrap a svg path {
    transition: 0.3s;
  }
  .p-design-detail-traveling .traveling-content__btnWrap a:hover svg g {
    fill: #111;
  }
  .p-design-detail-traveling .traveling-content__btnWrap a:hover svg path {
    stroke: #fff;
  }
}
@media (--mobile) {
  .p-design-detail-traveling .traveling-content__btnWrap a {
    width: 100%;
    padding-left: 7rem;
    height: 6rem !important;
    font-size: 1.6rem;
  }
}
.p-design-detail-traveling .traveling-content__btnWrap a.traveling {
  height: 7rem;
}
.p-design-detail-traveling .traveling-content__btnWrap a.contact {
  height: 10rem;
  margin-top: 2rem;
}
.p-design-detail-traveling .traveling-content__btnWrap a svg {
  position: absolute;
  width: 3rem;
  height: auto;
  top: 50%;
  transform: translateY(-50%) translateY(0.2rem);
  right: 12rem;
}
@media (--mobile) {
  .p-design-detail-traveling .traveling-content__btnWrap a svg {
    right: 3rem;
  }
}
.p-design-detail-youtube-img {
  width: 100%;
  height: 56rem;
  max-width: 93rem;
  margin: 5rem auto 0;
  position: relative;
  cursor: pointer;
  overflow: hidden;
}
@media (--large) {
  .p-design-detail-youtube-img:hover img {
    transform: scale(1.08);
  }
}
.p-design-detail-youtube-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.3s;
}
@media screen and (max-width: 767px) {
  .p-design-detail-youtube-img {
    height: 20rem;
    margin: 2rem auto 0;
  }
}
.p-design-detail-youtube-img h4 {
  color: #fff;
  font-size: 5.4rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) translateY(-6rem);
  z-index: 20;
  font-weight: 300;
}
@media screen and (max-width: 767px) {
  .p-design-detail-youtube-img h4 {
    font-size: 3rem;
    transform: translate(-50%, -50%) translateY(-3rem);
  }
}
.p-design-detail-youtube-img svg {
  display: block;
  width: 9rem;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) translateY(1rem);
  z-index: 20;
}
@media (--mobile) {
  .p-design-detail-youtube-img svg {
    width: 4rem;
    transform: translate(-50%, -50%) translateY(1rem);
  }
}
.p-design-detail-youtube-img::before {
  content: "";
  z-index: 20;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #000;
  opacity: 0.4;
}
.p-design-detail-youtube-movie {
  width: 70%;
  aspect-ratio: 5/3;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media screen and (max-width: 767px) {
  .p-design-detail-youtube-movie {
    width: 90%;
  }
}
.p-design-detail-youtube-bg {
  width: 75%;
  aspect-ratio: 5/3.1;
  position: absolute;
  background: #111;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}
@media screen and (max-width: 767px) {
  .p-design-detail-youtube-bg {
    display: none;
  }
}
.p-design-detail-youtube-close {
  cursor: pointer;
  width: 6rem;
  height: 6rem;
  background: #111;
  border-radius: 50%;
  position: absolute;
  top: -6rem;
  right: -6rem;
}
@media (--mobile) {
  .p-design-detail-youtube-close {
    width: 4rem;
    height: 4rem;
    top: -5rem;
    right: 0rem;
  }
  .p-design-detail-youtube-close span {
    font-size: 2.3rem;
  }
}
.p-design-detail-youtube-close span {
  color: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  font-size: 3rem;
  z-index: 30;
}

.js-gallery-movie-content {
  opacity: 0;
  pointer-events: none;
  transition: 0.3s;
  position: fixed;
  z-index: 200;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) translateY(-3rem);
  width: 100%;
  height: 100vh;
  z-index: 999;
}
.js-gallery-movie-content.is-open {
  opacity: 1;
  pointer-events: auto;
}
.js-gallery-movie-overlay {
  position: fixed;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  z-index: -1;
  cursor: pointer;
}

.p-design-detail-picker {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 40rem;
  height: 6rem;
  display: flex;
  z-index: 100;
}
@media (--mobile) {
  .p-design-detail-picker {
    width: 100%;
    height: 6rem;
  }
}
.p-design-detail-picker .picker-ttl {
  background: #171717;
  width: 10.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 1.6rem;
}
@media (--mobile) {
  .p-design-detail-picker .picker-ttl {
    width: 8rem;
    font-size: 1.4rem;
  }
}
.p-design-detail-picker .picker-text {
  flex: 1;
  border: 1px solid #171717;
  border-right: 0px;
  border-bottom: 0px;
  display: flex;
  padding-left: 1.6rem;
  align-items: center;
  font-size: 1.8rem;
  font-weight: bold;
  position: relative;
  letter-spacing: 0.05em;
  background: #fff;
}
@media (--mobile) {
  .p-design-detail-picker .picker-text {
    font-size: 1.6rem;
    border-left: 0px;
  }
}
.p-design-detail-picker .picker-text::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%) translateY(0.1rem) rotate(45deg);
  right: 2rem;
  width: 0.9rem;
  height: 0.9rem;
  border-top: 1px solid #707070;
  border-right: 1px solid #707070;
}

.modaal-gallery-control {
  border: 1px solid #171717;
  background-color: rgba(255, 255, 255, 0.3);
}

.modaal-gallery-control:after, .modaal-gallery-control:before {
  border-radius: 0;
  background: #171717;
  top: 1.5vw;
  left: 2.2vw;
  width: 1px;
  height: 9px;
}

.modaal-gallery-prev:after, .modaal-gallery-prev:before {
  left: 50%;
  top: 50%;
}

.modaal-gallery-prev:before {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.modaal-gallery-prev:after {
  transform: translate(-50%, -50%) rotate(45deg);
}

.modaal-gallery-next:after, .modaal-gallery-next:before {
  left: 50%;
  top: 50%;
}

.modaal-gallery-next:before {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.modaal-gallery-next:after {
  transform: translate(-50%, -50%) rotate(45deg);
}

.modaal-overlay {
  background: rgb(255, 255, 255) !important;
  opacity: 0.9 !important;
}

.modaal-close:after, .modaal-close:before {
  background: #171717;
  width: 1px;
  top: 50%;
  left: 50%;
}

.modaal-close:before {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.modaal-close:after {
  transform: translate(-50%, -50%) rotate(45deg);
}

.modaal-close:focus:after, .modaal-close:focus:before, .modaal-close:hover:after, .modaal-close:hover:before {
  background: #8B8B8B;
}

.modaal-gallery-control:focus, .modaal-gallery-control:hover {
  background: #C4C4C4;
  border: 1px solid #FFF;
}

.modaal-gallery-control:focus:after, .modaal-gallery-control:focus:before, .modaal-gallery-control:hover:after, .modaal-gallery-control:hover:before {
  background: #FFFFFF;
}

.modaal-wrapper .modaal-close {
  background-color: rgba(255, 255, 255, 0.3);
  border: 1px solid #171717;
}

.modaal-wrapper .modaal-close:focus, .modaal-wrapper .modaal-close:hover {
  background: #C4C4C4;
  border: 1px solid #FFF;
}

.modaal-close:focus:after, .modaal-close:focus:before, .modaal-close:hover:after, .modaal-close:hover:before {
  background: #FFFFFF;
}

@media only screen and (max-width: 1140px) {
  .modaal-gallery-control {
    background-color: rgba(255, 255, 255, 0.7);
  }
}
.modaal-gallery-control:before {
  display: none;
}

.modaal-gallery-control {
  width: 25px;
  height: 25px;
}

.modaal-close {
  width: 25px;
  height: 25px;
}

.modaal-close:after, .modaal-close:before {
  height: 13px;
}

.modaal-gallery-control:after {
  width: 0.5rem;
  height: 0.5rem;
  background: none !important;
  border-top: 0.1rem solid #171717;
  border-right: 0.1rem solid #171717;
  margin: 0;
  transform: translate(-75%, -50%) rotate(45deg);
}

.modaal-gallery-prev::after {
  transform: translate(-25%, -50%) rotate(-135deg);
}

.modaal-gallery-control:hover::after {
  border-color: #fff;
}

.c-side-right__grid a {
  pointer-events: none;
}

.p-design.keyword .c-title {
  border-bottom: 1px solid #D0D0D0;
  padding-bottom: 7rem;
}
@media (--mobile) {
  .p-design.keyword .c-title {
    padding-bottom: 4rem;
  }
}

.p-design .keyword {
  width: 118rem;
  margin: 10rem auto 0 auto;
}
@media (--mobile) {
  .p-design .keyword {
    width: auto;
    padding: 0 2rem;
    margin-top: 4rem;
  }
}
.p-design-content {
  width: 48rem;
  margin-left: 10rem;
}
@media (--mobile) {
  .p-design-content {
    width: 100%;
    margin-left: 0;
  }
}
.p-design-content__text {
  margin-top: 4rem;
  margin-bottom: 8rem;
  font-size: 1.5rem;
  line-height: 3.2rem;
}
@media (--mobile) {
  .p-design-content__text {
    margin-bottom: 4rem;
  }
}
.p-design-content__keyword {
  font-size: 1.5rem;
  margin-bottom: 12rem;
}
@media (--mobile) {
  .p-design-content__keyword {
    margin-bottom: 8rem;
  }
}
.p-design-content__keyword h3 {
  margin-bottom: 3rem;
}
@media (--mobile) {
  .p-design-content__keyword h3 {
    margin-bottom: 1.5rem;
  }
}
.p-design-content__keyword ul {
  display: flex;
  flex-wrap: wrap;
  margin-top: -1rem;
}
@media (--mobile) {
  .p-design-content__keyword ul {
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-left: -2rem;
    margin-right: -2rem;
    padding: 0 2rem;
  }
}
.p-design-content__keyword ul li {
  font-size: 1.4rem;
  color: #7B7B7B;
  margin-right: 1.4rem;
  white-space: nowrap;
  margin-top: 1rem;
}
.p-design-content__keyword ul li:last-child {
  margin-right: 0;
}
.p-design-content__keyword ul li a {
  display: block;
  position: relative;
}
.p-design-content__keyword ul li a::before {
  content: "";
  margin: auto;
  position: absolute;
  left: 0;
  bottom: -0.2rem;
  transition: transform 0.3s, -webkit-transform 0.3s;
  -webkit-transform: scale(0, 1);
  transform: scale(0, 1);
  -webkit-transform-origin: right center;
  transform-origin: right center;
  width: 100%;
  height: 0.1rem;
  background: #171717;
}
.p-design-content__keyword ul li a:hover::before {
  transform: scale(1, 1);
  transform-origin: left center;
}
.p-design ul.p-design-cell {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.p-design ul.p-design-cell li.p-design-list {
  width: 53.6rem;
}
@media (--mobile) {
  .p-design ul.p-design-cell li.p-design-list {
    width: 100%;
    margin-bottom: 4rem;
  }
}
.p-design-list {
  margin-bottom: 8rem;
}
.p-design-list__img {
  display: block;
  position: relative;
  margin-bottom: 2.4rem;
  width: 53.6rem;
  height: 36rem;
  overflow: hidden;
}
@media (--mobile) {
  .p-design-list__img {
    width: 33.5rem;
    height: 20rem;
  }
}
.p-design-list__img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  transition: 1s all;
}
.p-design-list__img img:hover {
  transform: scale(1.05);
}
.p-design-list__txt {
  position: absolute;
  right: 3rem;
  top: 31.5rem;
  z-index: 9;
  font-size: 1.3rem;
  color: #FFF;
  text-shadow: 0 0 2rem #000, 0 0 2rem #000;
}
@media (--mobile) {
  .p-design-list__txt {
    right: 2rem;
    top: 16.5rem;
  }
}
.p-design-list__title {
  display: block;
  font-size: 1.8rem;
  margin-bottom: 1.7rem;
  transition: 0.3s opacity;
}
.p-design-list__title:hover {
  opacity: 0.6;
}
.p-design-list ul {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-bottom: -1rem;
}
.p-design-list ul li {
  margin-right: 0.8rem;
  font-size: 1.2rem;
  color: #707070;
  border: 1px solid #CECECE;
  margin-bottom: 1rem;
}
.p-design-list ul li a {
  display: block;
  padding: 6px 8px;
  color: #7B7B7B;
  transition: 0.3s all;
  white-space: nowrap;
}
.p-design-list ul li a:hover {
  background: #7B7B7B;
  color: #FFFFFF;
}
.p-design-voice {
  width: 118rem;
  margin: 12rem auto 0 auto;
  border-top: 1px solid #D0D0D0;
  padding-top: 12rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media (--mobile) {
  .p-design-voice {
    width: 100%;
    margin-top: 6rem;
    padding-top: 6rem;
    display: block;
  }
}
.p-design-voice__title {
  font-size: 3.2rem;
  font-family: "EB Garamond", serif;
  font-weight: Regular;
}
@media (--mobile) {
  .p-design-voice__title {
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 2rem;
  }
}
.p-design-voice__title .jp {
  font-size: 1.5rem;
  margin-bottom: 1.4rem;
  display: block;
}
@media (--mobile) {
  .p-design-voice__title .jp {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
}
.p-design-voice__content {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.p-design-voice__cell {
  width: 78rem;
  display: flex;
  border-bottom: 1px solid #D0D0D0;
  padding-bottom: 4rem;
  margin-bottom: 4rem;
  padding: 0 2rem 4rem 2rem;
}
@media (--mobile) {
  .p-design-voice__cell {
    width: 100%;
    display: block;
  }
}
.p-design-voice__cell:nth-child(even) {
  flex-direction: row-reverse;
}
.p-design-voice__cell:nth-child(even) .p-design-voice__text {
  margin-right: 0rem;
  margin-left: 3rem;
}
@media (--mobile) {
  .p-design-voice__cell:nth-child(even) .p-design-voice__text {
    margin-left: 0;
  }
}
.p-design-voice__text {
  width: 55rem;
  margin-right: 3rem;
}
@media (--mobile) {
  .p-design-voice__text {
    width: 100%;
    margin-right: 0;
    margin-bottom: 3rem;
  }
}
.p-design-voice__heading {
  font-size: 2rem;
  margin-bottom: 1.4rem;
}
.p-design-voice__txt {
  font-size: 1.5rem;
  line-height: 3.2rem;
  margin-bottom: 1.3rem;
}
.p-design-voice__name {
  font-size: 1.5rem;
}
.p-design-voice__name span.city {
  font-size: 1.3rem;
  color: #7B7B7B;
  position: relative;
  margin-left: 2rem;
}
.p-design-voice__name span.city::before {
  position: absolute;
  display: block;
  content: "";
  width: 1px;
  height: 1.3rem;
  background-color: #7B7B7B;
  left: -1rem;
  top: 0;
}
.p-design-voice__more {
  display: flex;
  justify-content: flex-end;
  font-size: 1.5rem;
  margin-top: -2.2rem;
}
@media (--mobile) {
  .p-design-voice__more {
    margin-top: 1rem;
  }
}
.p-design-voice__more img {
  margin-left: 1.5rem;
}
.p-design-voice__more a {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  transition: 0.3s fill;
}
.p-design-voice__more a svg {
  width: 4.4rem;
}
.p-design-voice__more a:hover svg {
  fill: #000;
}
.p-design-voice__img {
  width: calc(100% - 55rem - 3rem);
}
@media (--mobile) {
  .p-design-voice__img {
    width: 100%;
    text-align: center;
  }
}
.p-design-keyword {
  width: 118rem;
  margin: 12rem auto 0 auto;
  padding-top: 8rem;
  border-top: 1px solid #D0D0D0;
}
@media (--mobile) {
  .p-design-keyword {
    width: 100%;
    margin-top: 6rem;
    padding-top: 4rem;
  }
}
.p-design-keyword__title {
  text-align: center;
  font-size: 1.6rem;
  margin-bottom: 3rem;
}
.p-design-keyword__list ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
@media (--mobile) {
  .p-design-keyword__list ul {
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: -1rem;
    padding: 0 2rem;
  }
}
.p-design-keyword__list ul li {
  font-size: 1.4rem;
  color: #7B7B7B;
  white-space: nowrap;
  margin: 0.5rem 1rem;
}
@media (--mobile) {
  .p-design-keyword__list ul li {
    margin-top: 1rem;
  }
}
.p-design-keyword__list ul li a {
  display: block;
  position: relative;
}
.p-design-keyword__list ul li a::before {
  content: "";
  margin: auto;
  position: absolute;
  left: 0;
  bottom: -0.2rem;
  transition: transform 0.3s, -webkit-transform 0.3s;
  -webkit-transform: scale(0, 1);
  transform: scale(0, 1);
  -webkit-transform-origin: right center;
  transform-origin: right center;
  width: 100%;
  height: 0.1rem;
  background: #171717;
}
.p-design-keyword__list ul li a:hover::before {
  transform: scale(1, 1);
  transform-origin: left center;
}

.p-design.keyword .p-design-voice {
  margin-top: 8rem;
}
@media (--mobile) {
  .p-design.keyword .p-design-voice {
    margin-top: 4rem;
  }
}

@media (--mobile) {
  .p-faq__inner {
    padding: 0 1.3em !important;
  }
}
.p-faq-accordion {
  width: 98rem;
  position: relative;
  margin: 0 auto;
}
@media (--mobile) {
  .p-faq-accordion {
    width: 100%;
  }
}
.p-faq-accordion__item {
  position: relative;
  margin-top: -1px;
}
.p-faq-accordion__item::after, .p-faq-accordion__item::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 0;
  height: 100%;
  pointer-events: none;
  border-top: 1px solid #D0D0D0;
  border-bottom: 1px solid #D0D0D0;
  transition: 1s;
}
.p-faq-accordion__item::after {
  width: 100%;
}
.p-faq-accordion__item::before {
  border-color: #5b5b5b;
  z-index: 1;
}
.p-faq-accordion__item:hover::before {
  width: 100%;
}
.p-faq-accordion__content {
  display: none;
}
.p-faq-accordion__content p {
  font-size: 1.5rem;
  line-height: 3.2rem;
  padding: 0rem 8rem 5rem 10rem;
}
@media (--mobile) {
  .p-faq-accordion__content p {
    padding: 0rem 2rem 5rem 2rem;
    font-size: 1.4rem;
  }
}
.p-faq-accordion__title {
  color: #333333;
  cursor: pointer;
  font-size: 1.5rem;
  padding: 5rem 0;
  position: relative;
  padding-left: 10rem;
}
@media (--mobile) {
  .p-faq-accordion__title {
    padding: 2.5rem 5rem;
  }
}
.p-faq-accordion__title::before {
  width: 3.2rem;
  height: 3.6rem;
  position: absolute;
  display: block;
  content: "";
  transform: translateY(-50%);
  background: url("../images/faq/icon-question.svg") no-repeat center center/contain;
  top: 50%;
  left: 3rem;
}
@media (--mobile) {
  .p-faq-accordion__title::before {
    left: 2rem;
    width: 1.6rem;
    height: 1.8rem;
    background-size: 1.6rem 1.8rem;
  }
}
.p-faq-accordion__title::after {
  border-right: solid 1px #707070;
  border-top: solid 1px #707070;
  content: "";
  display: block;
  width: 1.8rem;
  height: 1.8rem;
  position: absolute;
  right: 3rem;
  top: 38%;
  transform: rotate(135deg);
  transition: transform 0.3s ease-in-out, top 0.3s ease-in-out;
}
@media (--mobile) {
  .p-faq-accordion__title::after {
    width: 1.2rem;
    height: 1.2rem;
    top: 40%;
    right: 2rem;
  }
}
.p-faq-accordion__title.open::after {
  top: 45%;
  transform: rotate(-45deg);
}

.p-hairsalon-detail-content {
  width: 98rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
@media (--mobile) {
  .p-hairsalon-detail-content {
    width: 100%;
    display: block;
  }
}
.p-hairsalon-detail-content__text {
  width: 68rem;
}
@media (--mobile) {
  .p-hairsalon-detail-content__text {
    width: 100%;
    margin-top: 2rem;
  }
}
.p-hairsalon-detail-content__text p {
  font-size: 1.6rem;
  line-height: 3.2rem;
  margin-bottom: 4rem;
}
@media (--mobile) {
  .p-hairsalon-detail-content__text p {
    margin-bottom: 2rem;
  }
}
.p-hairsalon-detail-content__text p:last-child {
  margin-bottom: 0;
}
.p-hairsalon-detail__img {
  margin-top: 12rem;
  margin-bottom: 12rem;
}
@media (--mobile) {
  .p-hairsalon-detail__img {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
}
.p-hairsalon-detail-num {
  width: 98rem;
  margin: 0 auto;
}
@media (--mobile) {
  .p-hairsalon-detail-num {
    width: 100%;
  }
}
.p-hairsalon-detail-num .c-leftTtl {
  margin-bottom: 4rem;
}
@media (--mobile) {
  .p-hairsalon-detail-num .c-leftTtl {
    margin-bottom: 2rem;
  }
}
.p-hairsalon-detail-num__title {
  font-size: 2rem;
  margin-bottom: 3rem;
}
@media (--mobile) {
  .p-hairsalon-detail-num__title {
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
  }
}
.p-hairsalon-detail-num__title span {
  font-size: 1.8rem;
  font-family: "EB Garamond", serif;
  margin-right: 0.7rem;
}
.p-hairsalon-detail-num__text {
  margin-bottom: 7rem;
  font-size: 1.6rem;
  line-height: 3.2rem;
}
.p-hairsalon-detail-num__text:last-of-type {
  margin-bottom: 0;
}
@media (--mobile) {
  .p-hairsalon-detail-num__text {
    margin-bottom: 3rem;
  }
}
.p-hairsalon-detail-cell {
  width: 98rem;
  margin: 0 auto;
}
@media (--mobile) {
  .p-hairsalon-detail-cell {
    width: 100%;
  }
}
.p-hairsalon-detail-cell__title {
  font-size: 2rem;
  margin-bottom: 3rem;
}
@media (--mobile) {
  .p-hairsalon-detail-cell__title {
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
  }
}
.p-hairsalon-detail-cell__text {
  margin-bottom: 7rem;
  font-size: 1.6rem;
  line-height: 3.2rem;
}
.p-hairsalon-detail-cell__text:last-of-type {
  margin-bottom: 0;
}
@media (--mobile) {
  .p-hairsalon-detail-cell__text {
    margin-bottom: 3rem;
  }
}
.p-hairsalon-detail-left {
  display: flex;
  margin-top: 12rem;
  margin-bottom: 12rem;
  align-items: center;
  justify-content: space-between;
}
@media (--mobile) {
  .p-hairsalon-detail-left {
    display: block;
    margin-top: 6rem;
    margin-bottom: 6rem;
  }
}
.p-hairsalon-detail-left__img {
  width: 56rem;
}
@media (--mobile) {
  .p-hairsalon-detail-left__img {
    width: 100%;
    margin-bottom: 2rem;
  }
}
.p-hairsalon-detail-left__content {
  width: 48rem;
}
@media (--mobile) {
  .p-hairsalon-detail-left__content {
    width: 100%;
  }
}
.p-hairsalon-detail-left__title {
  font-size: 2.6rem;
  margin-bottom: 5rem;
}
@media (--mobile) {
  .p-hairsalon-detail-left__title {
    font-size: 2rem;
    margin-bottom: 2rem;
  }
}
.p-hairsalon-detail-left__text p {
  font-size: 1.6rem;
  line-height: 3.2rem;
  margin-bottom: 4rem;
}
@media (--mobile) {
  .p-hairsalon-detail-left__text p {
    margin-bottom: 2rem;
  }
}
.p-hairsalon-detail-left__text p:last-of-type {
  margin-bottom: 0;
}
.p-hairsalon-detail-gallery {
  margin-top: 8rem;
  margin-bottom: 8rem;
}
@media (--mobile) {
  .p-hairsalon-detail-gallery {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
}
.p-hairsalon-detail-gallery ul {
  width: 118rem;
  display: flex;
  flex-wrap: wrap;
}
@media (--mobile) {
  .p-hairsalon-detail-gallery ul {
    width: 100%;
  }
}
.p-hairsalon-detail-gallery ul li {
  width: 18rem;
  margin-right: 2rem;
  margin-bottom: 2rem;
}
@media (--mobile) {
  .p-hairsalon-detail-gallery ul li {
    width: 47%;
  }
}
.p-hairsalon-detail-gallery ul li:nth-child(6n) {
  margin-right: 0;
}
@media (--mobile) {
  .p-hairsalon-detail-gallery ul li:nth-child(2n) {
    margin-right: 0;
  }
}
.p-hairsalon-detail-layout__img {
  width: 82rem;
  margin: 0 auto 9rem auto;
}
@media (--mobile) {
  .p-hairsalon-detail-layout__img {
    width: 100%;
    margin-bottom: 4rem;
  }
}
.p-hairsalon-detail-layout dl {
  display: flex;
  flex-wrap: wrap;
  margin-top: 8rem;
}
@media (--mobile) {
  .p-hairsalon-detail-layout dl {
    display: block;
    margin-top: 4rem;
  }
}
.p-hairsalon-detail-layout dt {
  width: 20%;
  font-size: 1.6rem;
  font-weight: bold;
  padding: 4rem 0;
  border-bottom: 1px solid #D0D0D0;
  display: flex;
  align-items: flex-start;
  padding-left: 3rem;
}
.p-hairsalon-detail-layout dt:first-of-type {
  border-top: 1px solid #D0D0D0;
}
@media (--mobile) {
  .p-hairsalon-detail-layout dt:first-of-type {
    border: none;
  }
}
@media (--mobile) {
  .p-hairsalon-detail-layout dt {
    width: 100%;
    display: block;
    padding: 0;
    border: none;
  }
}
.p-hairsalon-detail-layout dd {
  width: 80%;
  font-size: 1.6rem;
  padding: 4rem 0;
  border-bottom: 1px solid #D0D0D0;
  line-height: 3.2rem;
}
.p-hairsalon-detail-layout dd:first-of-type {
  border-top: 1px solid #D0D0D0;
}
@media (--mobile) {
  .p-hairsalon-detail-layout dd:first-of-type {
    border: none;
  }
}
@media (--mobile) {
  .p-hairsalon-detail-layout dd {
    width: 100%;
    border: none;
    padding: 2rem 0 4rem 0;
  }
}
.p-hairsalon-detail-interview {
  width: 118rem;
  margin: 12rem auto 0 auto;
  display: flex;
  justify-content: space-between;
}
@media (--mobile) {
  .p-hairsalon-detail-interview {
    width: 100%;
    margin-top: 6rem;
    display: block;
  }
}
.p-hairsalon-detail-interview__title {
  font-size: 3.2rem;
  font-family: "EB Garamond", serif;
  font-weight: Regular;
}
@media (--mobile) {
  .p-hairsalon-detail-interview__title {
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 2rem;
  }
}
.p-hairsalon-detail-interview__title .jp {
  font-size: 1.5rem;
  margin-bottom: 1.4rem;
  display: block;
}
@media (--mobile) {
  .p-hairsalon-detail-interview__title .jp {
    margin-bottom: 1rem;
    font-size: 1.2rem;
  }
}
.p-hairsalon-detail-interview__content {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.p-hairsalon-detail-interview__cell {
  width: 78rem;
  display: flex;
  border-bottom: 1px solid #D0D0D0;
  padding-bottom: 4rem;
  margin-bottom: 4rem;
  padding: 0 2rem 4rem 2rem;
}
@media (--mobile) {
  .p-hairsalon-detail-interview__cell {
    width: 100%;
    display: block;
  }
}
.p-hairsalon-detail-interview__cell:nth-child(even) {
  flex-direction: row-reverse;
}
.p-hairsalon-detail-interview__cell:nth-child(even) .p-hairsalon-detail-interview__text {
  margin-right: 0rem;
  margin-left: 3rem;
}
@media (--mobile) {
  .p-hairsalon-detail-interview__cell:nth-child(even) .p-hairsalon-detail-interview__text {
    margin-left: 0;
  }
}
.p-hairsalon-detail-interview__text {
  width: 55rem;
  margin-right: 3rem;
}
@media (--mobile) {
  .p-hairsalon-detail-interview__text {
    width: 100%;
    margin-right: 0;
    margin-bottom: 3rem;
  }
}
.p-hairsalon-detail-interview__heading {
  font-size: 2rem;
  margin-bottom: 1.4rem;
}
.p-hairsalon-detail-interview__txt {
  font-size: 1.6rem;
  line-height: 3.2rem;
  margin-bottom: 1.3rem;
}
.p-hairsalon-detail-interview__name {
  font-size: 2rem;
  margin-bottom: 2.5rem;
}
.p-hairsalon-detail-interview__name span {
  font-size: 1.3rem;
  margin-right: 1.6rem;
}
.p-hairsalon-detail-interview__name span.city {
  font-size: 1.3rem;
  color: #7B7B7B;
  position: relative;
  margin-left: 2rem;
}
.p-hairsalon-detail-interview__name span.city::before {
  position: absolute;
  display: block;
  content: "";
  width: 1px;
  height: 1.3rem;
  background-color: #7B7B7B;
  left: -1rem;
  top: 0;
}
.p-hairsalon-detail-interview__more {
  display: flex;
  justify-content: flex-end;
  font-size: 1.6rem;
  margin-top: -2.2rem;
}
@media (--mobile) {
  .p-hairsalon-detail-interview__more {
    margin-top: 1rem;
  }
}
.p-hairsalon-detail-interview__more img {
  margin-left: 1.5rem;
}
.p-hairsalon-detail-interview__img {
  width: calc(100% - 55rem - 3rem);
}
@media (--mobile) {
  .p-hairsalon-detail-interview__img {
    width: 100%;
    text-align: center;
  }
}
.p-hairsalon-detail .c-backBtn {
  width: 1080px;
  margin: 6rem auto 0 auto;
}

.p-land-search .sectionLine {
  width: 100%;
  height: 1px;
  background-color: #D0D0D0;
}
@media (--mobile) {
  .p-land-search .sectionLine {
    width: 100vw;
    margin-left: -3rem;
  }
}
.p-land-search .s-heading {
  padding-bottom: 10rem;
}
@media (--mobile) {
  .p-land-search .s-heading {
    padding-bottom: 12rem;
  }
}
.p-land-search .s-heading__inner {
  text-align: center;
}
@media (--mobile) {
  .p-land-search .s-heading__inner {
    text-align: left;
  }
}
.p-land-search .s-heading__inner h2 {
  font-size: 3.8rem;
  font-weight: 600;
  letter-spacing: 0.1em;
  line-height: 1.4;
  color: #000000;
}
@media (--mobile) {
  .p-land-search .s-heading__inner h2 {
    font-size: 2.3rem;
  }
}
.p-land-search .s-heading__inner p {
  display: block;
  width: fit-content;
  margin-inline: auto;
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: 0.05em;
  line-height: 1.8;
  color: #171717;
  border-bottom: 1px solid #D0D0D0;
  padding-bottom: 1rem;
}
@media (--mobile) {
  .p-land-search .s-heading__inner p {
    font-size: 1.6rem;
  }
}
.p-land-search .s-heading__inner p:nth-of-type(1) {
  margin-top: 9.7rem;
}
@media (--mobile) {
  .p-land-search .s-heading__inner p:nth-of-type(1) {
    margin-top: 7rem;
  }
}
.p-land-search .s-heading__inner p:nth-of-type(n + 2) {
  margin-top: 5rem;
}
@media (--mobile) {
  .p-land-search .s-heading__inner p:nth-of-type(n + 2) {
    margin-top: 4rem;
  }
}
.p-land-search .s-sheet {
  margin-top: 11.5rem;
}
.p-land-search .s-sheet__ttl {
  margin-top: 11rem;
}
.p-land-search .s-sheet__head {
  max-width: 96rem;
  width: 100%;
  margin-inline: auto;
}
.p-land-search .s-sheet__head > h3 {
  font-size: 1.8rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.7;
  color: #333333;
  border: 1.5px solid #8A8A8A;
  display: block;
  width: fit-content;
  padding: 2.8rem 3.5rem;
  margin-inline: auto;
}
@media (--mobile) {
  .p-land-search .s-sheet__head > h3 {
    font-size: 1.7rem;
    padding: 2.5rem 1rem;
  }
}
.p-land-search .s-sheet__head > p {
  display: block;
  margin-top: 3rem;
  font-size: 1.6rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.8;
  color: #333333;
}
.p-land-search .s-sheet__head > p:nth-of-type(2) {
  margin-top: 3rem !important;
}
.p-land-search .s-sheet__content {
  margin-top: 7.5rem;
}
@media (--mobile) {
  .p-land-search .s-sheet__content {
    margin-top: 5rem;
  }
}
.p-land-search .s-sheet__content > p {
  font-size: 1.6rem;
  font-weight: 400;
  letter-spacing: 0.05em;
  line-height: 1.8;
  color: #333333;
}
.p-land-search .s-sheet__content > p:nth-of-type(n + 2) {
  margin-top: 3rem;
}
.p-land-search .s-sheet__content > p:last-of-type {
  margin-top: 11rem;
}
@media (--mobile) {
  .p-land-search .s-sheet__content > p:last-of-type {
    margin-top: 3rem;
  }
}
.p-land-search .s-sheet__content-img {
  width: 80rem;
  margin-inline: auto;
  margin-top: 13rem;
}
@media (--mobile) {
  .p-land-search .s-sheet__content-img {
    width: 100%;
    margin-top: 5rem;
  }
}
.p-land-search .s-merit {
  margin-top: 10rem;
  padding-bottom: 18rem;
}
@media (--mobile) {
  .p-land-search .s-merit {
    margin-top: 8rem;
    padding-bottom: 12rem;
  }
}
.p-land-search .s-merit__lead {
  font-size: 1.5rem;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 2.2;
  color: #333333;
  display: block;
  margin-top: 5rem;
}
@media (--mobile) {
  .p-land-search .s-merit__lead {
    margin-top: 3.5rem;
  }
}
.p-land-search .s-merit ul {
  margin-top: 8rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 7rem 9rem;
}
@media (--mobile) {
  .p-land-search .s-merit ul {
    margin-top: 6rem;
    grid-template-columns: repeat(1, 1fr);
    gap: 5rem 0;
  }
}
.p-land-search .s-merit ul li > div {
  display: flex;
  align-items: flex-end;
  border-bottom: 1px solid #D0D0D0;
  padding-bottom: 1rem;
}
.p-land-search .s-merit ul li > div small {
  font-size: 1.4rem;
  font-weight: 400;
  letter-spacing: 0.1em;
  line-height: 1.4;
  color: #171717;
}
.p-land-search .s-merit ul li > div span {
  display: block;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 1;
  color: #333333;
}
.p-land-search .s-merit ul li > div span:nth-of-type(1) {
  font-size: 2.2rem;
  margin-left: 1rem;
}
.p-land-search .s-merit ul li > div span:nth-of-type(2) {
  font-size: 1.6rem;
  margin-left: 2rem;
}
.p-land-search .s-merit ul li p {
  display: block;
  margin-top: 3rem;
  font-size: 1.5rem;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 2;
  color: #171717;
}
@media (--mobile) {
  .p-land-search .s-merit ul li p {
    margin-top: 2.2rem;
  }
}
.p-land-search .s-merit__graph {
  margin-top: 10rem;
}
@media (--mobile) {
  .p-land-search .s-merit__graph {
    margin-top: 8rem;
  }
}
.p-land-search .s-merit__graph span {
  display: block;
  width: 100%;
  font-size: 2rem;
  font-weight: 600;
  letter-spacing: 0.05em;
  line-height: 1.2;
  color: #FFFFFF;
  background-color: #262727;
  padding: 2.7rem 0;
  text-align: center;
}
@media (--mobile) {
  .p-land-search .s-merit__graph span {
    padding: 3rem;
    font-size: 2rem;
    width: 100vw;
    margin-left: -3rem;
    text-align: left;
    line-height: 1.8;
  }
}
.p-land-search .s-merit__graph figure {
  margin-top: 6rem;
  padding: 0 2rem;
}
@media (--mobile) {
  .p-land-search .s-merit__graph figure {
    margin-top: 4rem;
    padding: 0;
  }
}
.p-land-search .s-merit__graph figure img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.p-land-search .s-check {
  margin-top: 27rem;
  padding-bottom: 18rem;
}
@media (--mobile) {
  .p-land-search .s-check {
    margin-top: 8rem;
    padding-bottom: 12rem;
  }
}
.p-land-search .s-check__lead {
  margin-top: 7rem;
  display: flex;
  flex-direction: column;
  gap: 4rem 0;
}
@media (--mobile) {
  .p-land-search .s-check__lead {
    margin-top: 5rem;
    gap: 3rem 0;
  }
}
.p-land-search .s-check__lead p {
  font-size: 1.6rem;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 1.8;
  color: #333333;
}
.p-land-search .s-check ul {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8.5rem 10rem;
  margin-top: 10rem;
}
@media (--mobile) {
  .p-land-search .s-check ul {
    grid-template-columns: repeat(1, 1fr);
    gap: 5rem 0;
    margin-top: 7rem;
  }
}
.p-land-search .s-check ul li {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.p-land-search .s-check ul li figure {
  width: 100%;
  height: auto;
}
.p-land-search .s-check ul li figure img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.p-land-search .s-check ul li span {
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 1.8;
  color: #333333;
  text-align: center;
  display: block;
}
.p-land-search .s-check ul li span:nth-of-type(n + 3) {
  margin-top: 2.3rem;
}
@media (--mobile) {
  .p-land-search .s-check ul li span {
    font-size: 1.8rem;
    text-align: left;
  }
}
.p-land-search .s-check ul li:nth-of-type(-n + 3) span {
  margin-top: 2.3rem;
}
.p-land-search .s-check__link {
  margin-top: 15rem;
}
@media (--mobile) {
  .p-land-search .s-check__link {
    margin-top: 5rem;
  }
}
.p-land-search .s-check__link a {
  display: flex;
  align-items: center;
  width: 38rem;
  margin-inline: auto;
  justify-content: center;
  gap: 0 1.4rem;
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: 0.05em;
  color: #333333;
  border: 2px solid #333333;
  padding: 2.5rem 0;
  transition: 0.3s;
}
@media (--mobile) {
  .p-land-search .s-check__link a {
    width: 100%;
    margin-top: 4rem;
    font-size: 1.6rem;
    padding: 2rem 0;
  }
}
.p-land-search .s-check__link a span {
  display: flex;
  width: 3rem;
  height: 3rem;
  justify-content: center;
  align-items: center;
  border: 1px solid #C4C4C4;
  border-radius: 50%;
  transition: 0.3s;
}
@media (--large) {
  .p-land-search .s-check__link a:hover {
    background-color: #333333;
    color: #fff;
  }
  .p-land-search .s-check__link a:hover span {
    border: 1px solid #fff;
  }
  .p-land-search .s-check__link a:hover span svg path {
    stroke: #fff;
  }
}
.p-land-search .s-flow {
  margin-top: 11rem;
  padding-bottom: 18rem;
}
@media (--mobile) {
  .p-land-search .s-flow {
    margin-top: 8rem;
    padding-bottom: 12rem;
  }
}
.p-land-search .s-flow__ttl {
  margin-top: 8.5rem;
}
.p-land-search .s-flow__ttl h3 {
  font-size: 2rem;
  font-weight: 600;
  letter-spacing: 0.05em;
  line-height: 1.8;
  color: #FFFFFF;
  background-color: #262727;
  text-align: center;
  padding: 2.7rem 0;
}
@media (--mobile) {
  .p-land-search .s-flow__ttl h3 {
    width: 100vw;
    margin-left: -3rem;
    padding: 2.7rem 3rem;
    text-align: left;
  }
}
.p-land-search .s-flow__table {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  margin-top: 6rem;
  padding: 0 3rem;
  gap: 0 2rem;
}
@media (--mobile) {
  .p-land-search .s-flow__table {
    padding: 0;
  }
}
.p-land-search .s-flow__table > div:nth-of-type(2) {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.p-land-search .s-flow__table > div:nth-of-type(2) > div:nth-of-type(1) {
  background-color: #D0D0D0;
  text-align: center;
  height: 84.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (--mobile) {
  .p-land-search .s-flow__table > div:nth-of-type(2) > div:nth-of-type(1) {
    height: 83rem;
  }
}
.p-land-search .s-flow__table > div:nth-of-type(2) > div:nth-of-type(1) p {
  display: block;
  font-size: 1.8rem;
  font-weight: 400;
  letter-spacing: 0.05em;
  color: #1A1311;
  writing-mode: vertical-lr;
}
@media (--mobile) {
  .p-land-search .s-flow__table > div:nth-of-type(2) > div:nth-of-type(1) p {
    font-size: 1.6rem;
  }
}
.p-land-search .s-flow__table > div:nth-of-type(2) > div:nth-of-type(1) p span {
  text-orientation: upright;
}
.p-land-search .s-flow__table > div:nth-of-type(2) > div:nth-of-type(2) {
  height: 24.5rem;
  width: 5.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #D0D0D0;
}
@media (--mobile) {
  .p-land-search .s-flow__table > div:nth-of-type(2) > div:nth-of-type(2) {
    height: 20.5rem;
  }
}
.p-land-search .s-flow__table > div:nth-of-type(2) > div:nth-of-type(2) p {
  display: block;
  font-size: 1.8rem;
  font-weight: 400;
  letter-spacing: 0.05em;
  color: #1A1311;
  writing-mode: vertical-lr;
  text-align: center;
}
@media (--mobile) {
  .p-land-search .s-flow__table > div:nth-of-type(2) > div:nth-of-type(2) p {
    font-size: 1.6rem;
  }
}
.p-land-search .s-flow__table > div:nth-of-type(2) > div:nth-of-type(2) p span {
  text-orientation: upright;
}
.p-land-search .s-flow__table .left {
  width: 15rem;
}
.p-land-search .s-flow__table .center,
.p-land-search .s-flow__table .right {
  width: 40rem;
  padding: 2rem;
}
.p-land-search .s-flow__table-head {
  display: flex;
}
.p-land-search .s-flow__table-head .left {
  background-color: #262727;
  text-align: center;
}
.p-land-search .s-flow__table-head .left.bg-white {
  background-color: #fff !important;
}
.p-land-search .s-flow__table-head .center {
  border-right: 1px solid #fff;
}
.p-land-search .s-flow__table-head .center,
.p-land-search .s-flow__table-head .right {
  background-color: #262727;
  font-size: 1.8rem;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 1.2;
  color: #fff;
  text-align: center;
  padding: 1rem 0;
}
@media (--mobile) {
  .p-land-search .s-flow__table-head .center,
  .p-land-search .s-flow__table-head .right {
    font-size: 1.6rem;
  }
}
.p-land-search .s-flow__table-body {
  display: flex;
  align-items: stretch;
}
.p-land-search .s-flow__table-body .left {
  background-color: #262727;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px solid #FFFFFF;
}
.p-land-search .s-flow__table-body .left.bg-white {
  background-color: #fff !important;
}
.p-land-search .s-flow__table-body .left:nth-of-type(1) {
  padding: 6rem 0;
}
.p-land-search .s-flow__table-body .left:nth-of-type(1) {
  padding: 5rem 0;
}
.p-land-search .s-flow__table-body .left small {
  font-size: 1.2rem;
  font-weight: 400;
  letter-spacing: 0.1em;
  line-height: 1.4;
  color: #fff;
  text-align: center;
}
.p-land-search .s-flow__table-body .left span {
  font-size: 1.8rem;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 1.8;
  color: #fff;
}
@media (--mobile) {
  .p-land-search .s-flow__table-body .left span {
    font-size: 1.6rem;
  }
}
.p-land-search .s-flow__table-body .center {
  border-right: 1px solid #D0D0D0;
  border-bottom: 1px solid #D0D0D0;
}
.p-land-search .s-flow__table-body .center p {
  font-size: 1.5rem;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 2;
  color: #171717;
}
@media (--mobile) {
  .p-land-search .s-flow__table-body .center p {
    font-size: 1.4rem;
  }
}
.p-land-search .s-flow__table-body .center p:nth-of-type(n + 2) {
  margin-top: 1.2rem;
}
.p-land-search .s-flow__table-body .center p.dot {
  position: relative;
  padding-left: 1.2rem;
}
.p-land-search .s-flow__table-body .center p.dot::before {
  position: absolute;
  content: "";
  top: 1.3rem;
  left: 0;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background-color: #171717;
}
.p-land-search .s-flow__table-body .center a {
  font-size: 1.5rem;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 2.1;
  color: #171717;
  display: block;
  width: fit-content;
}
@media (--mobile) {
  .p-land-search .s-flow__table-body .center a {
    font-size: 1.4rem;
  }
}
.p-land-search .s-flow__table-body .center a.tel, .p-land-search .s-flow__table-body .center a.line, .p-land-search .s-flow__table-body .center a.mail {
  position: relative;
  padding-left: 3rem;
}
.p-land-search .s-flow__table-body .center a.tel::before, .p-land-search .s-flow__table-body .center a.line::before, .p-land-search .s-flow__table-body .center a.mail::before {
  position: absolute;
  content: "";
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 2.2rem;
  height: 2rem;
}
.p-land-search .s-flow__table-body .center a.tel {
  margin-top: 2.2rem;
}
.p-land-search .s-flow__table-body .center a.tel::before {
  background: url(./../images/land-search/icon-tel.png) no-repeat center/contain;
}
.p-land-search .s-flow__table-body .center a.line::before {
  background: url(./../images/land-search/icon-line.png) no-repeat center/contain;
}
.p-land-search .s-flow__table-body .center a.mail::before {
  background: url(./../images/land-search/icon-mail.png) no-repeat center/contain;
}
.p-land-search .s-flow__table-body .right {
  border-right: 1px solid #D0D0D0;
  border-bottom: 1px solid #D0D0D0;
}
.p-land-search .s-flow__table-body .right p {
  font-size: 1.5rem;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 2;
  color: #171717;
}
.p-land-search .s-flow__table-body .right p:nth-of-type(n + 2) {
  margin-top: 1.2rem;
}
.p-land-search .s-flow__table-body .right p.ttl {
  color: #fff !important;
  text-align: center;
  background-color: #171717;
  border-radius: 3rem;
  display: block;
  width: 100%;
  padding: 1rem 0;
  margin-bottom: 1.5rem;
}
.p-land-search .s-flow__table-body .right p.dot {
  position: relative;
  padding-left: 1.2rem;
}
.p-land-search .s-flow__table-body .right p.dot::before {
  position: absolute;
  content: "";
  top: 1.3rem;
  left: 0;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background-color: #171717;
}
.p-land-search .s-case {
  margin-top: 11rem;
  padding-bottom: 18rem;
}
@media (--mobile) {
  .p-land-search .s-case {
    margin-top: 8rem;
    padding-bottom: 12rem;
  }
}
.p-land-search .s-case__group:nth-of-type(n + 2) {
  margin-top: 12rem;
}
@media (--mobile) {
  .p-land-search .s-case__group:nth-of-type(n + 2) {
    margin-top: 10rem;
  }
}
.p-land-search .s-case__group:nth-of-type(1) .s-case__heading {
  margin-top: 8.5rem;
}
@media (--mobile) {
  .p-land-search .s-case__group:nth-of-type(1) .s-case__heading {
    margin-top: 6rem;
  }
}
.p-land-search .s-case__heading {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0 8rem;
  align-items: center;
}
@media (--mobile) {
  .p-land-search .s-case__heading {
    grid-template-columns: repeat(1, 1fr);
    gap: 3.5rem 0;
  }
}
.p-land-search .s-case__heading-left {
  width: 100%;
}
.p-land-search .s-case__heading-left img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.p-land-search .s-case__heading-right {
  width: 100%;
}
.p-land-search .s-case__heading-right h4 {
  font-size: 2.8rem;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 1.7;
  color: #333333;
  border-bottom: 1px solid #D0D0D0;
}
@media (--mobile) {
  .p-land-search .s-case__heading-right h4 {
    font-size: 2.2rem;
  }
}
.p-land-search .s-case__heading-right p {
  display: block;
  margin-top: 4rem;
  font-size: 2rem;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 1.2;
  color: #333333;
}
@media (--mobile) {
  .p-land-search .s-case__heading-right p {
    font-size: 1.8rem;
  }
}
.p-land-search .s-case__heading-right ul {
  margin-top: 2.4rem;
}
.p-land-search .s-case__heading-right ul li {
  position: relative;
  font-size: 1.5rem;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 1.6;
  color: #171717;
  padding-left: 1.2rem;
}
.p-land-search .s-case__heading-right ul li::before {
  position: absolute;
  top: 0.7rem;
  left: 0;
  content: "";
  width: 0.5rem;
  height: 0.5rem;
  background-color: #171717;
  border-radius: 50%;
}
.p-land-search .s-case__heading-right ul li:nth-of-type(n + 2) {
  margin-top: 1.6rem;
}
.p-land-search .s-case__point {
  margin-top: 10rem;
  display: flex;
}
@media (--mobile) {
  .p-land-search .s-case__point {
    margin-top: 6rem;
    flex-direction: column;
  }
}
.p-land-search .s-case__point-left {
  width: 50%;
}
@media (--mobile) {
  .p-land-search .s-case__point-left {
    width: 100%;
  }
}
.p-land-search .s-case__point-left > span {
  position: relative;
  display: block;
  width: 100%;
  font-size: 2rem;
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 1.2;
  color: #171717;
  background-color: #D0D0D0;
  text-align: center;
  padding: 2.3rem 0;
}
@media (--mobile) {
  .p-land-search .s-case__point-left > span {
    font-size: 1.8rem;
  }
}
.p-land-search .s-case__point-left > span::before {
  position: absolute;
  top: 0;
  right: -1.4rem;
  content: "";
  border-top: 3.5rem solid transparent;
  border-bottom: 3.5rem solid transparent;
  border-left: 1.4rem solid #D0D0D0;
  z-index: 1;
}
@media (--mobile) {
  .p-land-search .s-case__point-left > span::before {
    display: none;
  }
}
.p-land-search .s-case__point-left-img {
  margin-top: 6rem;
  padding-left: 5rem;
  margin-right: 4rem;
}
@media (--mobile) {
  .p-land-search .s-case__point-left-img {
    margin-top: 4rem;
    padding-left: 0;
    margin-right: 0;
  }
}
.p-land-search .s-case__point-left-img figure {
  width: 100%;
  height: auto;
}
.p-land-search .s-case__point-left-img figure:nth-of-type(2) {
  margin-top: 4rem;
}
@media (--mobile) {
  .p-land-search .s-case__point-left-img figure:nth-of-type(2) {
    margin-top: 2rem;
  }
}
.p-land-search .s-case__point-left-img figure img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.p-land-search .s-case__point-left ul {
  border: 1px solid #D0D0D0;
  margin: 6rem 4rem 0 5rem;
  padding: 2.5rem;
}
@media (--mobile) {
  .p-land-search .s-case__point-left ul {
    margin: 2rem 0rem 0 0rem;
    padding: 2rem;
  }
}
.p-land-search .s-case__point-left ul li {
  position: relative;
  padding-left: 1.5rem;
  font-size: 1.5rem;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 1.4;
  color: #171717;
}
.p-land-search .s-case__point-left ul li::before {
  position: absolute;
  content: "";
  top: 1rem;
  left: 0;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background-color: #171717;
}
.p-land-search .s-case__point-left ul li:nth-of-type(n + 2) {
  margin-top: 2rem;
}
.p-land-search .s-case__point-right {
  width: 50%;
}
@media (--mobile) {
  .p-land-search .s-case__point-right {
    width: 100%;
    margin-top: 6rem;
  }
}
.p-land-search .s-case__point-right > span {
  position: relative;
  display: block;
  width: 100%;
  font-size: 2rem;
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 1.2;
  color: #FFFFFF;
  background-color: #262727;
  text-align: center;
  padding: 2.3rem 0;
}
@media (--mobile) {
  .p-land-search .s-case__point-right > span {
    font-size: 1.8rem;
  }
}
.p-land-search .s-case__point-right > span::before {
  position: absolute;
  top: 0;
  right: -1.4rem;
  content: "";
  border-top: 3.5rem solid transparent;
  border-bottom: 3.5rem solid transparent;
  border-left: 1.4rem solid #262727;
  z-index: 1;
}
@media (--mobile) {
  .p-land-search .s-case__point-right > span::before {
    display: none;
  }
}
.p-land-search .s-case__point-right-img {
  margin-top: 6rem;
  padding-right: 5rem;
  margin-left: 4rem;
}
@media (--mobile) {
  .p-land-search .s-case__point-right-img {
    margin-top: 4rem;
    padding-right: 0;
    margin-left: 0;
  }
}
.p-land-search .s-case__point-right-img figure {
  width: 100%;
  height: auto;
}
.p-land-search .s-case__point-right-img figure:nth-of-type(2) {
  margin-top: 4rem;
}
@media (--mobile) {
  .p-land-search .s-case__point-right-img figure:nth-of-type(2) {
    margin-top: 2rem;
  }
}
.p-land-search .s-case__point-right-img figure img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.p-land-search .s-case__point-right ul {
  border: 1px solid #D0D0D0;
  margin: 6rem 5rem 0 4rem;
  padding: 2.5rem;
}
@media (--mobile) {
  .p-land-search .s-case__point-right ul {
    margin: 2rem 0 0 0;
    padding: 2rem;
  }
}
.p-land-search .s-case__point-right ul li {
  position: relative;
  padding-left: 1.5rem;
  font-size: 1.5rem;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 1.4;
  color: #171717;
}
.p-land-search .s-case__point-right ul li::before {
  position: absolute;
  content: "";
  top: 1rem;
  left: 0;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background-color: #171717;
}
.p-land-search .s-case__point-right ul li:nth-of-type(n + 2) {
  margin-top: 2rem;
}
.p-land-search .s-case__episode {
  margin-top: 9rem;
}
@media (--mobile) {
  .p-land-search .s-case__episode {
    margin-top: 6rem;
  }
}
.p-land-search .s-case__episode p {
  font-size: 1.6rem;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 1.9;
  color: #333333;
  margin-top: 4rem;
  display: block;
}
@media (--mobile) {
  .p-land-search .s-case__episode p {
    margin-top: 3.5rem;
  }
}
.p-land-search .s-case__episode a {
  margin-top: 7rem;
  display: flex;
  align-items: center;
  width: 38rem;
  margin-inline: auto;
  justify-content: center;
  gap: 0 1.4rem;
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: 0.05em;
  color: #333333;
  border: 2px solid #333333;
  padding: 2.5rem 0;
  transition: 0.3s;
}
@media (--mobile) {
  .p-land-search .s-case__episode a {
    width: 100%;
    margin-top: 4rem;
    font-size: 1.6rem;
    padding: 2rem 0;
  }
}
.p-land-search .s-case__episode a span {
  display: flex;
  width: 3rem;
  height: 3rem;
  justify-content: center;
  align-items: center;
  border: 1px solid #C4C4C4;
  border-radius: 50%;
  transition: 0.3s;
}
@media (--large) {
  .p-land-search .s-case__episode a:hover {
    background-color: #333333;
    color: #fff;
  }
  .p-land-search .s-case__episode a:hover span {
    border: 1px solid #fff;
  }
  .p-land-search .s-case__episode a:hover span svg path {
    stroke: #fff;
  }
}
.p-land-search .s-qa {
  margin-top: 10rem;
}
@media (--mobile) {
  .p-land-search .s-qa {
    margin-top: 8rem;
  }
}
.p-land-search .s-qa__container {
  margin-top: 8rem;
}
.p-land-search .s-qa__wrap {
  padding-bottom: 2rem;
  border-top: 1px solid #D0D0D0;
}
.p-land-search .s-qa__wrap:last-of-type {
  border-bottom: 1px solid #D0D0D0;
}
.p-land-search .s-qa__q {
  position: relative;
  display: flex;
  align-items: start;
  cursor: pointer;
  padding-top: 5rem;
  padding-bottom: 3rem;
}
.p-land-search .s-qa__q::before {
  position: absolute;
  content: "";
  top: 5rem;
  right: 3rem;
  width: 2rem;
  height: 2rem;
  border-right: 1px solid #707070;
  border-bottom: 1px solid #707070;
  transform: rotate(45deg);
  transition: 0.3s;
}
@media (--mobile) {
  .p-land-search .s-qa__q::before {
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
    right: 0;
    width: 1.7rem;
    height: 1.7rem;
  }
}
@media (--mobile) {
  .p-land-search .s-qa__q {
    padding-right: 4rem;
    adding-top: 4rem;
  }
}
.p-land-search .s-qa__q.open::before {
  transform: rotate(-135deg);
}
@media (--mobile) {
  .p-land-search .s-qa__q.open::before {
    top: 55%;
    transform: translateY(-50%) rotate(-135deg);
  }
}
.p-land-search .s-qa__q span {
  display: block;
  width: 3.2rem;
  height: 3.6rem;
  flex-shrink: 0;
}
.p-land-search .s-qa__q span img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.p-land-search .s-qa__q p {
  display: block;
  margin-top: 0.5rem;
  margin-left: 3.6rem;
  font-size: 1.6rem;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 1.4;
  color: #333333;
}
@media (--mobile) {
  .p-land-search .s-qa__q p {
    margin-left: 1.5rem;
  }
}
.p-land-search .s-qa__a {
  display: none;
}
.p-land-search .s-qa__a p {
  display: block;
  margin-top: 0.5rem;
  padding: 0 8rem;
  font-size: 1.6rem;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 2;
  color: #333333;
}
.p-land-search .s-qa__a p:nth-of-type(n + 2) {
  margin-top: 3.5rem;
}
@media (--mobile) {
  .p-land-search .s-qa__a p:nth-of-type(n + 2) {
    margin-top: 3rem;
  }
}
.p-land-search .s-qa__a p:last-of-type {
  padding-bottom: 2rem;
}
.p-land-search .s-qa__a p.pb-0 {
  padding-bottom: 0 !important;
}
@media (--mobile) {
  .p-land-search .s-qa__a p {
    padding: 0;
  }
}
.p-land-search .s-qa__a ul {
  padding: 0 8rem;
}
@media (--mobile) {
  .p-land-search .s-qa__a ul {
    padding: 0;
  }
}
.p-land-search .s-qa__a ul li {
  position: relative;
  display: block;
  font-size: 1.6rem;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 2;
  color: #333333;
  padding-left: 1.5rem;
}
.p-land-search .s-qa__a ul li::before {
  position: absolute;
  content: "";
  top: 1.5rem;
  left: 0;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background-color: #333333;
}
.p-land-search .s-qa__more {
  margin-top: 8.5rem;
}
@media (--mobile) {
  .p-land-search .s-qa__more {
    margin-top: 6.5rem;
  }
}
.p-land-search .s-qa__more a {
  font-size: 1.6rem;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 1.4;
  color: #333333;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #D0D0D0;
  display: block;
  width: fit-content;
  margin-inline: auto;
  transition: 0.3s;
}
@media (--large) {
  .p-land-search .s-qa__more a:hover {
    opacity: 0.5;
  }
}
.p-land-search .s-qa__btn {
  margin-top: 7rem;
  display: flex;
  align-items: center;
  width: 38rem;
  margin-inline: auto;
  justify-content: center;
  gap: 0 1.4rem;
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: 0.05em;
  color: #333333;
  border: 2px solid #333333;
  padding: 2.5rem 0;
  transition: 0.3s;
}
@media (--mobile) {
  .p-land-search .s-qa__btn {
    width: 100%;
    margin-top: 4rem;
    font-size: 1.6rem;
    padding: 2rem 0;
  }
}
.p-land-search .s-qa__btn span {
  display: flex;
  width: 3rem;
  height: 3rem;
  justify-content: center;
  align-items: center;
  border: 1px solid #C4C4C4;
  border-radius: 50%;
  transition: 0.3s;
}
@media (--large) {
  .p-land-search .s-qa__btn:hover {
    background-color: #333333;
    color: #fff;
  }
  .p-land-search .s-qa__btn:hover span {
    border: 1px solid #fff;
  }
  .p-land-search .s-qa__btn:hover span svg path {
    stroke: #fff;
  }
}

.scroll-hint-icon.scroll-hint-icon-white {
  display: none;
}
@media (--mobile) {
  .scroll-hint-icon.scroll-hint-icon-white {
    display: block;
  }
}

.scroll-hint-icon {
  top: 35rem !important;
  width: 13rem !important;
  height: 9rem !important;
}

.p-line-about {
  width: 98rem;
  border-bottom: 1px solid #D0D0D0;
  padding-bottom: 8rem;
  margin: 0 auto 12rem auto;
}
@media (--mobile) {
  .p-line-about {
    width: 100%;
  }
}
.p-line-about__title {
  font-size: 3.2rem;
  text-align: center;
  margin-bottom: 12rem;
}
@media (--mobile) {
  .p-line-about__title {
    font-size: 2.6rem;
    margin-bottom: 4rem;
    text-align: left;
  }
}
.p-line-about .c-leftTtl {
  margin-bottom: 9rem;
}
@media (--mobile) {
  .p-line-about .c-leftTtl {
    margin-bottom: 6rem;
  }
}
@media (--mobile) {
  .p-line-about__inner {
    display: block;
  }
}
.p-line-about__img {
  width: 59rem;
}
@media (--mobile) {
  .p-line-about__img {
    width: auto;
    margin-bottom: 2rem;
  }
}
.p-line-about__txt {
  margin-right: 9rem;
}
@media (--mobile) {
  .p-line-about__txt {
    margin-right: 0rem;
  }
}
.p-line-about__subtitle {
  font-size: 2.6rem;
  color: #333333;
  margin-bottom: 4.8rem;
}
.p-line-about__subtitle span.en {
  font-size: 1.2rem;
  font-family: "EB Garamond", serif;
  font-weight: Regular;
  display: block;
  margin-bottom: 1.4rem;
}
@media (--mobile) {
  .p-line-about__subtitle span.en {
    margin-bottom: 1.2rem;
  }
}
@media (--mobile) {
  .p-line-about__subtitle {
    text-align: center;
  }
}
.p-line-about__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 4rem;
  margin-bottom: 5rem;
}
@media (--mobile) {
  .p-line-about__list {
    margin-top: 2rem;
    margin-bottom: 8rem;
  }
}
.p-line-about__list p {
  width: 46rem;
  margin-bottom: 3rem;
  font-size: 1.8rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid #D0D0D0;
  letter-spacing: 0.05em;
}
@media (--mobile) {
  .p-line-about__list p {
    width: 100%;
    display: flex;
    align-items: center;
  }
  .p-line-about__list p:last-child {
    margin-bottom: 0;
  }
}
.p-line-about__list p span {
  font-size: 1.4rem;
  font-family: "EB Garamond", serif;
  font-weight: Regular;
  margin-right: 1rem;
}
.p-line-about__text {
  width: 98rem;
  margin: 0 auto;
  font-size: 1.5rem;
  line-height: 3.2rem;
}
.p-line-about__text p {
  margin-bottom: 4rem;
}
@media (--mobile) {
  .p-line-about__text p {
    letter-spacing: 1.6px;
  }
}
.p-line-about__text p:last-child {
  margin-bottom: 0;
}
@media (--mobile) {
  .p-line-about__text {
    width: 100%;
  }
}
.p-line-flow {
  margin-top: 8rem;
}
@media (--mobile) {
  .p-line-flow {
    margin-top: 4rem;
  }
}
.p-line-flow p.mainTxt {
  font-size: 1.5rem;
  line-height: 3.2rem;
  text-align: center;
  margin-bottom: 8rem;
}
@media (--mobile) {
  .p-line-flow p.mainTxt {
    margin-bottom: 4rem;
  }
}
.p-line-flow .mainTxt-detail {
  display: block;
  white-space: nowrap;
}
.p-line-flow__step {
  margin-right: 4rem;
  font-size: 1.8rem;
  font-family: "EB Garamond", serif;
  font-weight: Regular;
}
@media (--mobile) {
  .p-line-flow__step {
    width: 38%;
    margin-right: 1rem;
  }
}
.p-line-flow__step p {
  background-color: #FFFFFF;
  z-index: 99;
  position: relative;
  padding: 1.7rem 0;
}
@media (--mobile) {
  .p-line-flow__step p {
    padding: 0;
    margin-bottom: 0.5rem;
  }
}
.p-line-flow .p-line-flow__step.last {
  background: #FFFFFF;
  z-index: 99;
  position: relative;
}
.p-line-flow__content {
  width: 50rem;
  padding-top: 1.7rem;
}
@media (--mobile) {
  .p-line-flow__content {
    width: 100%;
    padding-top: 0;
  }
}
.p-line-flow__content p {
  font-size: 1.5rem;
  line-height: 3.2rem;
}
@media (--mobile) {
  .p-line-flow__content p {
    letter-spacing: 1.6px;
    font-size: 1.4rem;
  }
}
.p-line-flow__content .title {
  font-size: 1.6rem;
  line-height: 2.9rem;
  margin-bottom: 1.5rem;
  letter-spacing: 0;
}
@media (--mobile) {
  .p-line-flow__content .title {
    font-size: 1.4rem;
  }
}
.p-line-flow__content .title span {
  font-weight: bold;
}
.p-line-flow__content .qrcode {
  margin-top: -1rem;
  margin-left: -1rem;
}
.p-line-flow__content .img_line {
  width: 27.8rem;
  margin-bottom: 3rem;
}
@media (--mobile) {
  .p-line-flow__content .img_line {
    width: 100%;
  }
}
.p-line-flow__content .line_id {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
}
.p-line-flow__content .line_id span {
  background-color: #F0F0F0;
  padding: 1rem 3rem;
  display: inline;
}
.p-line-flow__box {
  display: flex;
  justify-content: space-between;
  margin-top: 3.5rem;
  margin-bottom: 4rem;
}
@media (--mobile) {
  .p-line-flow__box {
    display: block;
  }
}
.p-line-flow__left {
  width: 46%;
}
@media (--mobile) {
  .p-line-flow__left {
    width: 100%;
    margin-bottom: 4rem;
  }
}
.p-line-flow__right {
  width: 46%;
}
@media (--mobile) {
  .p-line-flow__right {
    width: 100%;
  }
}
.p-line-flow__ttl {
  font-size: 2rem;
  color: #171717;
  font-weight: bold;
  margin-bottom: 1.5rem;
}
@media (--mobile) {
  .p-line-flow__ttl {
    margin-bottom: 4rem;
  }
}
.p-line-flow__ttl.last {
  padding-top: 1.5rem;
}
.p-line-flow__txt {
  font-size: 1.5rem;
  line-height: 3.2rem;
  margin-bottom: 2.5rem;
}
.p-line-flow__contents {
  display: flex;
  justify-content: center;
}
.p-line-flow__contents:last-child {
  margin-top: 12rem;
}
@media (--mobile) {
  .p-line-flow__contents {
    display: block;
    width: 100%;
  }
}
.p-line-flow ul {
  position: relative;
}
@media (--mobile) {
  .p-line-flow ul {
    width: 100%;
  }
}
.p-line-flow ul::after {
  content: "";
  display: inline-block;
  width: 1px;
  height: 100%;
  background-color: #D0D0D0;
  position: absolute;
  top: 0;
  left: 35px;
  z-index: 1;
}
@media (--mobile) {
  .p-line-flow ul::after {
    left: 30px;
  }
}
.p-line-flow ul li {
  display: flex;
  margin-bottom: 5rem;
}
@media (--mobile) {
  .p-line-flow ul li {
    margin-bottom: 10rem;
  }
}
.p-line-flow ul li:last-child {
  margin-bottom: 0;
}
.p-line-flow ul li.turn {
  padding-bottom: 10rem;
}
@media (--mobile) {
  .p-line-flow ul li.turn {
    padding-bottom: 0;
    margin-bottom: 8rem;
  }
}

.p-link__content {
  width: 98rem;
  margin: 0 auto 7rem auto;
}
@media (--mobile) {
  .p-link__content {
    width: 100%;
    margin-bottom: 5rem;
  }
}
.p-link__content h3 {
  font-size: 2rem;
  margin-bottom: 2.5rem;
  padding-bottom: 3rem;
  border-bottom: 1px solid #D0D0D0;
}
@media (--mobile) {
  .p-link__content h3 {
    margin-bottom: 2rem;
    padding-bottom: 1.5rem;
  }
}
.p-link__content ul li {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  position: relative;
  padding-left: 1.5rem;
}
.p-link__content ul li::after {
  content: "";
  display: inline-block;
  position: absolute;
  left: 0rem;
  top: 0.8rem;
  width: 0.7rem;
  height: 0.7rem;
  border-bottom: 1px solid #000000;
  border-right: 1px solid #000000;
  transform: rotateZ(-45deg);
}

.p-movies .list-container {
  flex: 1;
}
.p-movies .list-container .movies-img__ttl {
  font-size: 1.8rem;
}
@media (--mobile) {
  .p-movies .list-cat {
    padding: 0 3rem;
  }
}
.p-movies .movies-modal {
  opacity: 0;
  pointer-events: none;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transition: 0.3s;
}
.p-movies .movies-modal.is-active {
  opacity: 1;
  pointer-events: auto;
}
.p-movies .movies-modal__overlay {
  position: absolute;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  z-index: -1;
}
.p-movies .movies-modal__content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  aspect-ratio: 5/3;
}
@media (--mobile) {
  .p-movies .movies-modal__content {
    width: 90%;
  }
}
.p-movies .movies-modal__content iframe {
  width: 100%;
  height: 100%;
  display: inline-block;
}
.p-movies .movies-modal__bg {
  width: 75%;
  aspect-ratio: 5/3.1;
  position: absolute;
  background: #111;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}
@media screen and (max-width: 767px) {
  .p-movies .movies-modal__bg {
    display: none;
  }
}
.p-movies .movies-modal__close {
  cursor: pointer;
  width: 6rem;
  height: 6rem;
  background: #111;
  border-radius: 50%;
  position: absolute;
  top: -6rem;
  right: -6rem;
}
@media (--mobile) {
  .p-movies .movies-modal__close {
    width: 4rem;
    height: 4rem;
    top: -5rem;
    right: 0rem;
  }
  .p-movies .movies-modal__close span {
    font-size: 2.3rem;
  }
}
.p-movies .movies-modal__close span {
  color: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  font-size: 3rem;
  z-index: 30;
}

.p-detail .c-title {
  margin-bottom: 6rem;
  padding-bottom: 7rem;
  border-bottom: 1px solid #D0D0D0;
}
@media (--mobile) {
  .p-detail .c-title {
    margin-bottom: 3rem;
    padding-bottom: 3rem;
  }
}
.p-detail__inner {
  width: 78rem;
  margin-left: auto;
  margin-right: auto;
}
@media (--mobile) {
  .p-detail__inner {
    width: 100%;
  }
}
.p-detail__ttl {
  font-size: 2.4rem;
  letter-spacing: 0.2em;
  font-weight: lighter;
}
@media (--mobile) {
  .p-detail__ttl {
    font-size: 2.2rem;
  }
}
.p-detail__catWrap {
  margin-top: 4.3rem;
  display: flex;
  justify-content: space-between;
}
@media (--mobile) {
  .p-detail__catWrap {
    margin-top: 2.3rem;
  }
}
.p-detail__cat {
  font-size: 1.3rem;
  border-bottom: 1px solid #C9C9C9;
}
.p-detail__date {
  font-size: 1.4rem;
  color: #7B7B7B;
}
.p-detail__mv {
  margin-top: 4rem;
}
@media (--mobile) {
  .p-detail__mv {
    margin-top: 2rem;
  }
}
.p-detail__mv.event {
  height: 50rem;
}
@media (--mobile) {
  .p-detail__mv.event {
    height: 21rem;
  }
}
.p-detail__mv.event img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.p-detail__btnWrap {
  display: flex;
  justify-content: space-between;
  margin-top: 5rem;
}
.p-detail__btnWrap > * {
  margin-top: 0 !important;
}
.p-detail__content {
  margin-top: 6rem;
  font-size: 1.5rem;
  line-height: 2;
}
@media (--mobile) {
  .p-detail__content {
    margin-top: 4rem;
  }
}
.p-detail__content * {
  max-width: 100%;
}
.p-detail__content img {
  margin-bottom: 0.5em;
  margin-top: 1.6em;
  display: block;
}
.p-detail__content .wp-caption-text {
  font-size: 1.2rem;
}
.p-detail__content h2 {
  font-size: 1.25em;
  font-weight: 700;
  color: #171717;
  line-height: 1.5;
  padding-bottom: 0.2em;
  border-bottom: 2px solid;
  margin-bottom: 2em;
}
.p-detail__content h3 {
  font-size: 1.125em;
  padding-left: 1em;
  margin-bottom: 2em;
  font-weight: 700;
  position: relative;
}
.p-detail__content h3:before {
  position: absolute;
  left: 0;
  top: 0.35em;
  content: "";
  width: 4px;
  height: 1.11em;
  line-height: 1.5;
  background: #171717;
}
.p-detail__content h4 {
  font-size: 1.125em;
  padding: 0.2em 1em;
  margin-bottom: 2em;
  font-weight: 700;
  background: #eee;
}
.p-detail__content h5 {
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: 2em;
}
.p-detail__content a {
  color: #171717;
  text-decoration: underline;
  transition: opacity 0.3s;
}
.p-detail__content a:hover, .p-detail__content a:active {
  opacity: 0.7;
}
.p-detail__content ul li {
  position: relative;
  padding-left: 1.05em;
  line-height: 1.5;
}
.p-detail__content ul li:before {
  content: "・";
  position: absolute;
  left: 0;
  top: 0;
}
.p-detail__content ul li + li {
  margin-top: 0.5em;
}
.p-detail__content ol {
  counter-reset: number 0;
}
.p-detail__content ol li + li {
  margin-top: 0.5em;
}
.p-detail__content ol li {
  position: relative;
  padding-left: 1.5em;
  line-height: 1.5;
}
.p-detail__content ol li:before {
  counter-increment: number 1;
  content: counter(number) ".";
  font-size: 1.5em;
  font-family: "Baloo Bhaijaan 2", cursive;
  line-height: 1.2;
  font-weight: 700;
  position: absolute;
  left: 0;
  bottom: 0;
}
.p-detail__content ol .pp-addr {
  margin-top: 0.5em;
  padding-left: 1em;
  margin-bottom: 1.75em;
}
.p-detail__content ol .pp-addr span {
  display: inline-block;
}
.p-detail__content ol .pp-addr span:first-of-type {
  padding-right: 1em;
}
.p-detail__content h2 + h2,
.p-detail__content h2 + h3,
.p-detail__content h2 + h4,
.p-detail__content h2 + h5,
.p-detail__content h3 + h2,
.p-detail__content h3 + h3,
.p-detail__content h3 + h4,
.p-detail__content h3 + h5,
.p-detail__content h4 + h2,
.p-detail__content h4 + h3,
.p-detail__content h4 + h4,
.p-detail__content h4 + h5,
.p-detail__content h5 + h2,
.p-detail__content h5 + h3,
.p-detail__content h5 + h4,
.p-detail__content h5 + h5 {
  margin-top: -0.9em;
}
.p-detail .aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.p-detail .p-contact__left {
  padding-right: 0;
}
.p-detail .p-contact__right {
  width: 100%;
}

.p-news__content {
  margin-bottom: 4rem;
}

.p-privacypolicy .c-inner {
  width: 98rem;
}
@media (--mobile) {
  .p-privacypolicy .c-inner {
    width: auto;
    padding: 0 3rem;
  }
}
.p-privacypolicy .c-inner .c-leftTtl {
  margin-bottom: 8rem;
}
.p-privacypolicy__content {
  margin: 0 auto 5rem auto;
}
@media (--mobile) {
  .p-privacypolicy__content {
    width: 100%;
  }
}
.p-privacypolicy__content h3 {
  font-size: 2rem;
  margin-bottom: 3rem;
}
@media (--mobile) {
  .p-privacypolicy__content h3 {
    margin-bottom: 2rem;
  }
}
.p-privacypolicy__content p {
  font-size: 1.5rem;
  line-height: 3.2rem;
}
.p-privacypolicy__content p:nth-child(2n+1) {
  margin-top: 3rem;
}
.p-privacypolicy__content p span {
  display: block;
}

.p-quality-about {
  border-bottom: 1px solid #D0D0D0;
  margin-bottom: 10rem;
  padding-bottom: 12rem;
}
@media (--mobile) {
  .p-quality-about {
    margin-bottom: 5rem;
    padding-bottom: 0;
  }
}
.p-quality-about__title {
  font-size: 3rem;
  line-height: 4.7rem;
  text-align: center;
  margin-bottom: 12rem;
}
@media (--mobile) {
  .p-quality-about__title {
    font-size: 2.2rem;
    margin-bottom: 4rem;
    text-align: left;
    line-height: 2;
  }
}
.p-quality-about__content {
  margin-bottom: 12rem;
}
@media (--mobile) {
  .p-quality-about__content {
    margin-bottom: 7rem;
  }
}
.p-quality-about__content .c-sideImg div {
  margin: 0 auto;
}
.p-quality-about__content .c-rightTtl strong,
.p-quality-about__content .c-leftTtl strong {
  font-size: 2.4rem;
}
.p-quality-about__content p {
  width: 40rem;
}
@media (--mobile) {
  .p-quality-about__content p {
    width: 100%;
  }
}
.p-quality-about__txt {
  width: 44rem;
}
@media (--mobile) {
  .p-quality-about__txt {
    width: 100%;
  }
}
.p-quality-about__txt p {
  font-size: 1.5rem;
  line-height: 3.2rem;
  margin-bottom: 3.2rem;
}
@media (--mobile) {
  .p-quality-about__txt p {
    margin-bottom: 1.5rem;
  }
}
.p-quality-mind {
  border-bottom: 1px solid #D0D0D0;
  margin-bottom: 10rem;
  padding-bottom: 16rem;
}
@media (--large) {
  .p-quality-mind {
    padding-left: 5rem;
    padding-right: 5rem;
  }
}
@media (--mobile) {
  .p-quality-mind {
    margin-bottom: 5rem;
    padding-bottom: 7rem;
  }
}
.p-quality-list {
  margin-top: 8rem;
}
@media (--mobile) {
  .p-quality-list {
    margin-top: 4rem;
  }
}
.p-quality-list__content {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12rem;
}
@media (--mobile) {
  .p-quality-list__content {
    width: 100%;
    display: block;
    margin-bottom: 10rem;
  }
}
.p-quality-list__content:nth-child(even) {
  flex-direction: row-reverse;
}
.p-quality-list__content:nth-child(even) .p-quality-list__img {
  margin-right: 0;
  margin-left: 14rem;
}
@media (--mobile) {
  .p-quality-list__content:nth-child(even) .p-quality-list__img {
    margin-right: 0;
    margin-left: 0;
  }
}
.p-quality-list__img {
  width: calc(100% - 58rem - 10rem - 14rem);
  margin-right: 14rem;
}
@media (--mobile) {
  .p-quality-list__img {
    width: 100%;
    margin-right: 0;
    margin-bottom: 8rem;
  }
}
.p-quality-list__txt {
  width: 58rem;
}
@media (--mobile) {
  .p-quality-list__txt {
    width: 100%;
  }
}
.p-quality-list__title {
  font-size: 2rem;
  border-bottom: 1px solid #D0D0D0;
  padding-bottom: 2rem;
  margin-bottom: 3rem;
}
@media (--mobile) {
  .p-quality-list__title {
    font-size: 2rem;
    margin-bottom: 4rem;
    padding-left: 1em;
    text-indent: -1em;
  }
}
.p-quality-list__title .num {
  font-size: 1.4rem;
  font-family: "EB Garamond", serif;
  margin-right: 1rem;
}
.p-quality-list__text {
  font-size: 1.5rem;
  line-height: 3.2rem;
  margin-bottom: 8rem;
}
@media (--mobile) {
  .p-quality-list__text {
    margin-bottom: 6rem;
  }
}
.p-quality-list-reviews {
  width: 48rem;
  margin-left: auto;
}
@media (--mobile) {
  .p-quality-list-reviews {
    width: 100%;
  }
}
.p-quality-list-reviews__title {
  display: flex;
  align-items: center;
  font-size: 2rem;
  font-family: "EB Garamond", serif;
  margin-bottom: 3rem;
}
@media (--mobile) {
  .p-quality-list-reviews__title {
    display: block;
  }
}
.p-quality-list-reviews__title:after {
  content: "";
  height: 1px;
  flex-grow: 1;
  background-color: #D0D0D0;
  margin-left: 1rem;
}
@media (--mobile) {
  .p-quality-list-reviews__title:after {
    content: none;
  }
}
.p-quality-list-reviews__title span {
  font-size: 1.5rem;
  margin-left: 1rem;
}
@media (--mobile) {
  .p-quality-list-reviews__title span {
    display: block;
    margin-left: 0;
  }
}
.p-quality-list-reviews__text {
  font-size: 1.5rem;
  line-height: 3.5rem;
}
.p-quality-list-reviews__text .link {
  font-size: 1.5rem;
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
}
.p-quality-list-reviews__text .link a {
  display: flex;
  align-items: center;
}
.p-quality-list-reviews__text .link a:hover svg circle:first-of-type {
  fill: rgb(23, 23, 23);
}
.p-quality-list-reviews__text .link a:hover svg path {
  stroke: #FFF;
}
.p-quality-list-reviews__text .link svg {
  width: 3rem;
  height: 3rem;
  margin-left: 1rem;
}
.p-quality-list-reviews__text .link svg circle:first-of-type {
  fill: rgba(255, 255, 255, 0);
  transition: 0.5s fill;
}
.p-quality-list-reviews__text .link svg path {
  stroke: #171717;
}
.p-quality-list-reviews__text .link svg, .p-quality-list-reviews__text .link svg * {
  transition: 0.5s;
}
.p-quality-left {
  display: flex;
  margin-top: 12rem;
  margin-bottom: 12rem;
  align-items: center;
  justify-content: space-between;
}
.p-quality-left.last {
  margin-bottom: 6rem;
}
@media (--mobile) {
  .p-quality-left.last {
    margin-bottom: 3rem;
  }
}
@media (--mobile) {
  .p-quality-left {
    display: block;
    margin-top: 4rem;
    margin-bottom: 6rem;
  }
}
.p-quality-left__img {
  width: 56rem;
}
@media (--mobile) {
  .p-quality-left__img {
    width: 100%;
    margin-bottom: 2rem;
  }
}
.p-quality-left__content {
  width: 48rem;
}
@media (--mobile) {
  .p-quality-left__content {
    width: 100%;
  }
}
.p-quality-left__title {
  font-size: 2.4rem;
  margin-bottom: 5rem;
}
@media (--mobile) {
  .p-quality-left__title {
    font-size: 2rem;
    margin-bottom: 2rem;
  }
}
.p-quality-left__text p {
  font-size: 1.5rem;
  line-height: 3.2rem;
  margin-bottom: 4rem;
}
@media (--mobile) {
  .p-quality-left__text p {
    margin-bottom: 2rem;
  }
}
.p-quality-left__text p:last-of-type {
  margin-bottom: 0;
}
.p-quality .p-quality-left:nth-child(even) {
  flex-direction: row-reverse;
}
.p-quality-ttl {
  font-size: 1.3rem;
  text-align: center;
  margin-bottom: 1.5rem;
}
.p-quality-btn {
  width: 40%;
  height: 10rem;
  margin: 0 auto;
}
@media (--mobile) {
  .p-quality-btn {
    width: 100%;
    height: 8rem;
  }
}
.p-quality-btn a {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FFF;
  border: 0.2rem solid #171717;
  color: #171717;
  transition: 0.5s background, 0.5s color;
  font-size: 2rem;
}
@media (--mobile) {
  .p-quality-btn a {
    font-size: 1.5rem;
  }
}
.p-quality-btn a:hover {
  background: #171717;
  color: #fff;
}
.p-quality-btn a:hover svg circle:first-of-type {
  fill: rgb(23, 23, 23);
}
.p-quality-btn a:hover svg path {
  stroke: #FFF;
}
.p-quality-btn svg {
  width: 3rem;
  height: 3rem;
  margin-left: 1rem;
}
.p-quality-btn svg circle:first-of-type {
  fill: rgba(255, 255, 255, 0);
  transition: 0.5s fill;
}
.p-quality-btn svg path {
  stroke: #171717;
}
.p-quality-btn svg, .p-quality-btn svg * {
  transition: 0.5s;
}

.p-restaurant-detail-content {
  width: 98rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
@media (--mobile) {
  .p-restaurant-detail-content {
    width: 100%;
    display: block;
  }
}
.p-restaurant-detail-content__text {
  width: 68rem;
}
@media (--mobile) {
  .p-restaurant-detail-content__text {
    width: 100%;
    margin-top: 2rem;
  }
}
.p-restaurant-detail-content__text p {
  font-size: 1.6rem;
  line-height: 3.2rem;
  margin-bottom: 4rem;
}
@media (--mobile) {
  .p-restaurant-detail-content__text p {
    margin-bottom: 2rem;
  }
}
.p-restaurant-detail-content__text p:last-child {
  margin-bottom: 0;
}
.p-restaurant-detail__img {
  margin-top: 12rem;
  margin-bottom: 12rem;
}
@media (--mobile) {
  .p-restaurant-detail__img {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
}
.p-restaurant-detail-num {
  width: 98rem;
  margin: 0 auto;
}
@media (--mobile) {
  .p-restaurant-detail-num {
    width: 100%;
  }
}
.p-restaurant-detail-num .c-leftTtl {
  margin-bottom: 4rem;
}
@media (--mobile) {
  .p-restaurant-detail-num .c-leftTtl {
    margin-bottom: 2rem;
  }
}
.p-restaurant-detail-num__title {
  font-size: 2rem;
  margin-bottom: 3rem;
}
@media (--mobile) {
  .p-restaurant-detail-num__title {
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
  }
}
.p-restaurant-detail-num__title span {
  font-size: 1.8rem;
  font-family: "EB Garamond", serif;
  margin-right: 0.7rem;
}
.p-restaurant-detail-num__text {
  margin-bottom: 7rem;
  font-size: 1.6rem;
  line-height: 3.2rem;
}
.p-restaurant-detail-num__text:last-of-type {
  margin-bottom: 0;
}
@media (--mobile) {
  .p-restaurant-detail-num__text {
    margin-bottom: 3rem;
  }
}
.p-restaurant-detail-cell {
  width: 98rem;
  margin: 0 auto;
}
@media (--mobile) {
  .p-restaurant-detail-cell {
    width: 100%;
  }
}
.p-restaurant-detail-cell__title {
  font-size: 2rem;
  margin-bottom: 3rem;
}
@media (--mobile) {
  .p-restaurant-detail-cell__title {
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
  }
}
.p-restaurant-detail-cell__text {
  margin-bottom: 7rem;
  font-size: 1.6rem;
  line-height: 3.2rem;
}
.p-restaurant-detail-cell__text:last-of-type {
  margin-bottom: 0;
}
@media (--mobile) {
  .p-restaurant-detail-cell__text {
    margin-bottom: 3rem;
  }
}
.p-restaurant-detail-left {
  display: flex;
  margin-top: 12rem;
  margin-bottom: 12rem;
  align-items: center;
  justify-content: space-between;
}
@media (--mobile) {
  .p-restaurant-detail-left {
    display: block;
    margin-top: 6rem;
    margin-bottom: 6rem;
  }
}
.p-restaurant-detail-left__img {
  width: 56rem;
}
@media (--mobile) {
  .p-restaurant-detail-left__img {
    width: 100%;
    margin-bottom: 2rem;
  }
}
.p-restaurant-detail-left__content {
  width: 48rem;
}
@media (--mobile) {
  .p-restaurant-detail-left__content {
    width: 100%;
  }
}
.p-restaurant-detail-left__title {
  font-size: 2.6rem;
  margin-bottom: 5rem;
}
@media (--mobile) {
  .p-restaurant-detail-left__title {
    font-size: 2rem;
    margin-bottom: 2rem;
  }
}
.p-restaurant-detail-left__text p {
  font-size: 1.6rem;
  line-height: 3.2rem;
  margin-bottom: 4rem;
}
@media (--mobile) {
  .p-restaurant-detail-left__text p {
    margin-bottom: 2rem;
  }
}
.p-restaurant-detail-left__text p:last-of-type {
  margin-bottom: 0;
}
.p-restaurant-detail-gallery {
  margin-top: 8rem;
  margin-bottom: 8rem;
}
@media (--mobile) {
  .p-restaurant-detail-gallery {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
}
.p-restaurant-detail-gallery ul {
  width: 118rem;
  display: flex;
  flex-wrap: wrap;
}
@media (--mobile) {
  .p-restaurant-detail-gallery ul {
    width: 100%;
  }
}
.p-restaurant-detail-gallery ul li {
  width: 18rem;
  margin-right: 2rem;
  margin-bottom: 2rem;
}
@media (--mobile) {
  .p-restaurant-detail-gallery ul li {
    width: 47%;
  }
}
.p-restaurant-detail-gallery ul li:nth-child(6n) {
  margin-right: 0;
}
@media (--mobile) {
  .p-restaurant-detail-gallery ul li:nth-child(2n) {
    margin-right: 0;
  }
}
.p-restaurant-detail-layout__img {
  width: 82rem;
  margin: 0 auto 9rem auto;
}
@media (--mobile) {
  .p-restaurant-detail-layout__img {
    width: 100%;
    margin-bottom: 4rem;
  }
}
.p-restaurant-detail-layout dl {
  display: flex;
  flex-wrap: wrap;
  margin-top: 8rem;
}
@media (--mobile) {
  .p-restaurant-detail-layout dl {
    display: block;
    margin-top: 4rem;
  }
}
.p-restaurant-detail-layout dt {
  width: 20%;
  font-size: 1.6rem;
  font-weight: bold;
  padding: 4rem 0;
  border-bottom: 1px solid #D0D0D0;
  display: flex;
  align-items: flex-start;
  padding-left: 3rem;
}
.p-restaurant-detail-layout dt:first-of-type {
  border-top: 1px solid #D0D0D0;
}
@media (--mobile) {
  .p-restaurant-detail-layout dt:first-of-type {
    border: none;
  }
}
@media (--mobile) {
  .p-restaurant-detail-layout dt {
    width: 100%;
    display: block;
    padding: 0;
    border: none;
  }
}
.p-restaurant-detail-layout dd {
  width: 80%;
  font-size: 1.6rem;
  padding: 4rem 0;
  border-bottom: 1px solid #D0D0D0;
  line-height: 3.2rem;
}
.p-restaurant-detail-layout dd:first-of-type {
  border-top: 1px solid #D0D0D0;
}
@media (--mobile) {
  .p-restaurant-detail-layout dd:first-of-type {
    border: none;
  }
}
@media (--mobile) {
  .p-restaurant-detail-layout dd {
    width: 100%;
    border: none;
    padding: 2rem 0 4rem 0;
  }
}
.p-restaurant-detail-interview {
  width: 118rem;
  margin: 12rem auto 0 auto;
  display: flex;
  justify-content: space-between;
}
@media (--mobile) {
  .p-restaurant-detail-interview {
    width: 100%;
    margin-top: 6rem;
    display: block;
  }
}
.p-restaurant-detail-interview__title {
  font-size: 3.2rem;
  font-family: "EB Garamond", serif;
  font-weight: Regular;
}
@media (--mobile) {
  .p-restaurant-detail-interview__title {
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 2rem;
  }
}
.p-restaurant-detail-interview__title .jp {
  font-size: 1.5rem;
  margin-bottom: 1.4rem;
  display: block;
}
@media (--mobile) {
  .p-restaurant-detail-interview__title .jp {
    margin-bottom: 1rem;
    font-size: 1.2rem;
  }
}
.p-restaurant-detail-interview__content {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.p-restaurant-detail-interview__cell {
  width: 78rem;
  display: flex;
  border-bottom: 1px solid #D0D0D0;
  padding-bottom: 4rem;
  margin-bottom: 4rem;
  padding: 0 2rem 4rem 2rem;
}
@media (--mobile) {
  .p-restaurant-detail-interview__cell {
    width: 100%;
    display: block;
  }
}
.p-restaurant-detail-interview__cell:nth-child(even) {
  flex-direction: row-reverse;
}
.p-restaurant-detail-interview__cell:nth-child(even) .p-restaurant-detail-interview__text {
  margin-right: 0rem;
  margin-left: 3rem;
}
@media (--mobile) {
  .p-restaurant-detail-interview__cell:nth-child(even) .p-restaurant-detail-interview__text {
    margin-left: 0;
  }
}
.p-restaurant-detail-interview__text {
  width: 55rem;
  margin-right: 3rem;
}
@media (--mobile) {
  .p-restaurant-detail-interview__text {
    width: 100%;
    margin-right: 0;
    margin-bottom: 3rem;
  }
}
.p-restaurant-detail-interview__heading {
  font-size: 2rem;
  margin-bottom: 1.4rem;
}
.p-restaurant-detail-interview__txt {
  font-size: 1.6rem;
  line-height: 3.2rem;
  margin-bottom: 1.3rem;
}
.p-restaurant-detail-interview__name {
  font-size: 2rem;
  margin-bottom: 2.5rem;
}
.p-restaurant-detail-interview__name span {
  font-size: 1.3rem;
  margin-right: 1.6rem;
}
.p-restaurant-detail-interview__name span.city {
  font-size: 1.3rem;
  color: #7B7B7B;
  position: relative;
  margin-left: 2rem;
}
.p-restaurant-detail-interview__name span.city::before {
  position: absolute;
  display: block;
  content: "";
  width: 1px;
  height: 1.3rem;
  background-color: #7B7B7B;
  left: -1rem;
  top: 0;
}
.p-restaurant-detail-interview__more {
  display: flex;
  justify-content: flex-end;
  font-size: 1.6rem;
  margin-top: -2.2rem;
}
@media (--mobile) {
  .p-restaurant-detail-interview__more {
    margin-top: 1rem;
  }
}
.p-restaurant-detail-interview__more img {
  margin-left: 1.5rem;
}
.p-restaurant-detail-interview__img {
  width: calc(100% - 55rem - 3rem);
}
@media (--mobile) {
  .p-restaurant-detail-interview__img {
    width: 100%;
    text-align: center;
  }
}
.p-restaurant-detail .c-backBtn {
  width: 1080px;
  margin: 6rem auto 0 auto;
}

.p-restaurant.keyword .c-title {
  border-bottom: 1px solid #D0D0D0;
  padding-bottom: 7rem;
}
@media (--mobile) {
  .p-restaurant.keyword .c-title {
    padding-bottom: 4rem;
  }
}

.p-restaurant .keyword {
  width: 118rem;
  margin: 10rem auto 0 auto;
}
@media (--mobile) {
  .p-restaurant .keyword {
    width: auto;
    padding: 0 2rem;
    margin-top: 4rem;
  }
}
.p-restaurant-content {
  width: 48rem;
  margin-left: 10rem;
}
@media (--mobile) {
  .p-restaurant-content {
    width: 100%;
    margin-left: 0;
  }
}
.p-restaurant-content__text {
  margin-top: 4rem;
  margin-bottom: 8rem;
  font-size: 1.6rem;
  line-height: 3.2rem;
}
@media (--mobile) {
  .p-restaurant-content__text {
    margin-bottom: 4rem;
  }
}
.p-restaurant-content__keyword {
  font-size: 1.6rem;
  margin-bottom: 12rem;
}
@media (--mobile) {
  .p-restaurant-content__keyword {
    margin-bottom: 8rem;
  }
}
.p-restaurant-content__keyword h3 {
  margin-bottom: 3rem;
}
@media (--mobile) {
  .p-restaurant-content__keyword h3 {
    margin-bottom: 1.5rem;
  }
}
.p-restaurant-content__keyword ul {
  display: flex;
  flex-wrap: wrap;
}
@media (--mobile) {
  .p-restaurant-content__keyword ul {
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 0 -2rem;
    margin-top: -1rem;
    padding: 0 2rem;
  }
}
.p-restaurant-content__keyword ul li {
  font-size: 1.4rem;
  color: #7B7B7B;
  margin-right: 1.4rem;
  white-space: nowrap;
}
@media (--mobile) {
  .p-restaurant-content__keyword ul li {
    margin-top: 1rem;
  }
}
.p-restaurant-content__keyword ul li:last-child {
  margin-right: 0;
}
.p-restaurant-content__keyword ul li a {
  display: block;
  position: relative;
}
.p-restaurant-content__keyword ul li a::before {
  content: "";
  margin: auto;
  position: absolute;
  left: 0;
  bottom: -0.2rem;
  transition: transform 0.3s, -webkit-transform 0.3s;
  -webkit-transform: scale(0, 1);
  transform: scale(0, 1);
  -webkit-transform-origin: right center;
  transform-origin: right center;
  width: 100%;
  height: 0.1rem;
  background: #171717;
}
.p-restaurant-content__keyword ul li a:hover::before {
  transform: scale(1, 1);
  transform-origin: left center;
}
.p-restaurant ul.p-restaurant-cell {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.p-restaurant ul.p-restaurant-cell li.p-restaurant-list {
  width: 53.6rem;
}
@media (--mobile) {
  .p-restaurant ul.p-restaurant-cell li.p-restaurant-list {
    width: 100%;
    margin-bottom: 4rem;
  }
}
.p-restaurant-list {
  margin-bottom: 8rem;
}
.p-restaurant-list__img {
  position: relative;
  margin-bottom: 2.4rem;
}
.p-restaurant-list__txt {
  position: absolute;
  right: 0;
  top: 0;
  margin-right: -20px;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  color: #898989;
  font-family: "EB Garamond", serif;
  font-weight: Regular;
  font-size: 1rem;
}
.p-restaurant-list__title {
  font-size: 1.8rem;
  margin-bottom: 1.7rem;
}
.p-restaurant-list ul {
  display: flex;
}
.p-restaurant-list ul li {
  margin-right: 0.8rem;
  font-size: 1.2rem;
  color: #707070;
  border: 1px solid #CECECE;
}
.p-restaurant-list ul li a {
  display: block;
  padding: 6px 8px;
}
.p-restaurant-voice {
  width: 118rem;
  margin: 12rem auto 0 auto;
  border-top: 1px solid #D0D0D0;
  padding-top: 12rem;
  display: flex;
  justify-content: space-between;
}
@media (--mobile) {
  .p-restaurant-voice {
    width: 100%;
    margin-top: 6rem;
    padding-top: 6rem;
    display: block;
  }
}
.p-restaurant-voice__title {
  font-size: 3.2rem;
  font-family: "EB Garamond", serif;
  font-weight: Regular;
}
@media (--mobile) {
  .p-restaurant-voice__title {
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 2rem;
  }
}
.p-restaurant-voice__title .jp {
  font-size: 1.5rem;
  margin-bottom: 1.4rem;
  display: block;
}
@media (--mobile) {
  .p-restaurant-voice__title .jp {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
}
.p-restaurant-voice__content {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.p-restaurant-voice__cell {
  width: 78rem;
  display: flex;
  border-bottom: 1px solid #D0D0D0;
  padding-bottom: 4rem;
  margin-bottom: 4rem;
  padding: 0 2rem 4rem 2rem;
}
@media (--mobile) {
  .p-restaurant-voice__cell {
    width: 100%;
    display: block;
  }
}
.p-restaurant-voice__cell:nth-child(even) {
  flex-direction: row-reverse;
}
.p-restaurant-voice__cell:nth-child(even) .p-restaurant-voice__text {
  margin-right: 0rem;
  margin-left: 3rem;
}
@media (--mobile) {
  .p-restaurant-voice__cell:nth-child(even) .p-restaurant-voice__text {
    margin-left: 0;
  }
}
.p-restaurant-voice__text {
  width: 55rem;
  margin-right: 3rem;
}
@media (--mobile) {
  .p-restaurant-voice__text {
    width: 100%;
    margin-right: 0;
    margin-bottom: 3rem;
  }
}
.p-restaurant-voice__heading {
  font-size: 2rem;
  margin-bottom: 1.4rem;
}
.p-restaurant-voice__txt {
  font-size: 1.6rem;
  line-height: 3.2rem;
  margin-bottom: 1.3rem;
}
.p-restaurant-voice__name {
  font-size: 1.6rem;
}
.p-restaurant-voice__name span.city {
  font-size: 1.3rem;
  color: #7B7B7B;
  position: relative;
  margin-left: 2rem;
}
.p-restaurant-voice__name span.city::before {
  position: absolute;
  display: block;
  content: "";
  width: 1px;
  height: 1.3rem;
  background-color: #7B7B7B;
  left: -1rem;
  top: 0;
}
.p-restaurant-voice__more {
  display: flex;
  justify-content: flex-end;
  font-size: 1.6rem;
  margin-top: -2.2rem;
}
@media (--mobile) {
  .p-restaurant-voice__more {
    margin-top: 1rem;
  }
}
.p-restaurant-voice__more img {
  margin-left: 1.5rem;
}
.p-restaurant-voice__img {
  width: calc(100% - 55rem - 3rem);
}
@media (--mobile) {
  .p-restaurant-voice__img {
    width: 100%;
    text-align: center;
  }
}
.p-restaurant-keyword {
  width: 118rem;
  margin: 12rem auto 0 auto;
  padding-top: 8rem;
  border-top: 1px solid #D0D0D0;
}
@media (--mobile) {
  .p-restaurant-keyword {
    width: 100%;
    margin-top: 6rem;
    padding-top: 4rem;
  }
}
.p-restaurant-keyword__title {
  text-align: center;
  font-size: 1.6rem;
  margin-bottom: 3rem;
}
.p-restaurant-keyword__list ul {
  display: flex;
  justify-content: center;
}
.p-restaurant-keyword__list ul li {
  font-size: 1.4rem;
  color: #7B7B7B;
  margin-right: 2.4rem;
}
.p-restaurant-keyword__list ul li:last-child {
  margin-right: 0;
}

.p-restaurant.keyword .p-restaurant-voice {
  margin-top: 8rem;
}
@media (--mobile) {
  .p-restaurant.keyword .p-restaurant-voice {
    margin-top: 4rem;
  }
}

.p-staff {
  width: 118rem;
  margin: 0 auto;
}
@media (--mobile) {
  .p-staff {
    width: calc(100vw - 4rem);
  }
}
.p-staff .staff-list {
  display: grid;
  grid-template-columns: repeat(3, 37rem);
  row-gap: 7rem;
  column-gap: 3.5rem;
}
@media (--mobile) {
  .p-staff .staff-list {
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    row-gap: 4rem;
  }
}
.p-staff .staff-list__item figure {
  width: 100%;
  height: 23rem;
}
@media (--mobile) {
  .p-staff .staff-list__item figure {
    height: 10rem;
  }
}
.p-staff .staff-list__item figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.p-staff .staff-list__item .staff-post {
  font-family: "Shippori Mincho B1", serif;
  font-weight: 400;
  font-size: 1.2rem;
  letter-spacing: 0.1em;
  margin-top: 1rem;
}
.p-staff .staff-list__item .staff-name {
  display: flex;
  align-items: end;
  gap: 1.6rem;
  margin-top: 0.8rem;
}
@media (--mobile) {
  .p-staff .staff-list__item .staff-name {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.4rem;
  }
}
.p-staff .staff-list__item .staff-name__jp {
  font-family: "Shippori Mincho B1", serif;
  font-weight: 400;
  font-size: 1.6rem;
  letter-spacing: 0.1em;
}
.p-staff .staff-list__item .staff-name__en {
  font-family: "adobe-garamond-pro", serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.4rem;
  letter-spacing: 0.1em;
}
@media (--mobile) {
  .p-staff .staff-list__item .staff-name__en {
    font-size: 1rem;
  }
}
.p-staff .c-breadcrumb {
  width: 100%;
}

.p-support-inner {
  margin-top: 11rem;
  display: flex;
}
@media (--mobile) {
  .p-support-inner {
    width: 100%;
    display: block;
    margin-top: 3rem;
  }
}
@media (--large) {
  .p-support-inner__wrap {
    height: 100%;
  }
}
@media (--mobile) {
  .p-support-inner__wrap {
    position: relative;
    transition: 0.2s opacity;
  }
  .p-support-inner__wrap.is-active {
    position: fixed;
    top: 6rem;
    z-index: 99;
  }
  .p-support-inner__wrap.is-remove {
    pointer-events: none;
    opacity: 0;
  }
}
.p-support-inner__left {
  width: 24rem;
  margin-right: 11rem;
}
@media (--mobile) {
  .p-support-inner__left {
    width: 100%;
    margin-right: 0;
    margin-bottom: 5rem;
    background: #fff;
    height: 5.2rem;
  }
}
.p-support-inner__left ul {
  position: sticky;
  top: 50%;
  padding: 4rem 0;
  border-top: 0.1rem solid #171717;
  border-bottom: 0.1rem solid #171717;
  margin-top: 33.2rem;
  transform: translateY(-50%);
}
@media (--mobile) {
  .p-support-inner__left ul {
    position: absolute;
    margin: 0 -3rem;
    padding: 2rem 3rem;
    transform: inherit;
    top: 5rem;
    display: none;
    border-top: none;
    width: calc(100% + 6rem);
    background: #fff;
  }
}
.p-support-inner__left ul li {
  font-size: 1.8rem;
  margin-bottom: 2.5rem;
}
@media (--mobile) {
  .p-support-inner__left ul li {
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
  }
}
.p-support-inner__left ul li:last-child {
  margin-bottom: 0;
}
.p-support-inner__left ul li a {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  opacity: 0.2;
  transition: 0.3s opacity;
}
.p-support-inner__left ul li a:hover, .p-support-inner__left ul li a.is-active {
  opacity: 1;
}
.p-support-inner__left ul li a:hover svg circle:first-of-type, .p-support-inner__left ul li a.is-active svg circle:first-of-type {
  fill: #171717;
  transition: 0.5s fill;
}
.p-support-inner__left ul li a:hover svg path, .p-support-inner__left ul li a.is-active svg path {
  stroke: #fff;
}
.p-support-inner__left ul li svg {
  width: 3rem;
  height: 3rem;
  margin-left: 1rem;
}
@media (--mobile) {
  .p-support-inner__left ul li svg {
    width: 2.5rem;
    height: 2.5rem;
  }
}
.p-support-inner__left ul li svg circle:first-of-type {
  fill: rgba(255, 255, 255, 0);
  transition: 0.5s fill;
}
.p-support-inner__left ul li svg path {
  stroke: #171717;
}
.p-support-inner__left ul li svg, .p-support-inner__left ul li svg * {
  transition: 0.5s;
}
.p-support-inner__menu {
  position: relative;
  font-size: 2rem;
  padding: 1rem 0;
  border: 1px solid #000;
  border-right: none;
  border-left: none;
  margin: 0 -3rem;
  padding: 1rem 3rem;
  background: #fff;
  width: 100vw;
}
.p-support-inner__menu span {
  position: absolute;
  right: 3rem;
  top: 50%;
  transform: translateY(-50%);
  height: 2rem;
  width: 2rem;
}
.p-support-inner__menu span::after, .p-support-inner__menu span::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 0.1rem;
  background: #000;
  transition: 0.3s transform;
}
.p-support-inner__menu span::before {
  transform: translate(-50%, -50%) rotate(90deg);
}
.p-support-inner__menu.is-active span::after {
  transform: translate(-50%, -50%) rotate(90deg);
}
.p-support-inner__right {
  width: calc(100% - 24rem - 11rem);
}
@media (--mobile) {
  .p-support-inner__right {
    padding-bottom: 5rem;
    width: 100%;
  }
}
.p-support-inner__right .c-centerTtl {
  margin-bottom: 3.6rem;
}
.p-support-inner__right .c-centerTtl strong {
  font-size: 2.4rem;
}
.p-support-inner__cell {
  border-bottom: 0.1rem solid #D0D0D0;
  padding-bottom: 11rem;
  padding-top: 12rem;
}
@media (--mobile) {
  .p-support-inner__cell {
    padding-bottom: 5rem;
    margin-bottom: 5rem;
    padding-top: 7rem !important;
    margin-top: -7rem;
  }
}
.p-support-inner__cell:last-of-type {
  border: none;
  padding-bottom: 0;
  margin-bottom: 0;
}
.p-support-inner__title {
  font-size: 2.4rem;
  line-height: 3.9rem;
  text-align: center;
  margin-bottom: 5rem;
}
@media (--mobile) {
  .p-support-inner__title {
    font-size: 2rem;
    line-height: 2;
  }
}
.p-support-inner__title.left {
  text-align: left;
}
.p-support-inner__text p {
  font-size: 1.5rem;
  line-height: 3.2rem;
  margin-bottom: 3.2rem;
}
.p-support-inner__text p.center {
  text-align: center;
}
.p-support-inner__img {
  margin-top: 7rem;
  margin-bottom: 5rem;
}
@media (--mobile) {
  .p-support-inner__img {
    margin-top: 3rem;
    margin-bottom: 3rem;
    width: 65%;
    margin-left: auto;
    margin-right: auto;
  }
}
.p-support-inner__ttl {
  font-size: 1.8rem;
  font-weight: bold;
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  border-bottom: 0.1rem solid #262727;
}
.p-support-inner__txt {
  font-size: 1.5rem;
  line-height: 3.2rem;
  margin-bottom: 12rem;
}
.p-support-inner__block {
  display: flex;
  align-items: center;
  margin-bottom: 10rem;
}
@media (--mobile) {
  .p-support-inner__block {
    display: block;
    margin-bottom: 5rem;
  }
}
.p-support-inner__block .img {
  width: 27.4rem;
  margin-right: 5.8rem;
}
@media (--mobile) {
  .p-support-inner__block .img {
    width: 100%;
    margin-right: 0;
    margin-bottom: 1rem;
  }
}
.p-support-inner__block .text {
  width: calc(100% - 27.4rem - 5.8rem);
}
@media (--mobile) {
  .p-support-inner__block .text {
    width: 100%;
  }
}
.p-support-inner__block .text .title {
  font-size: 2.4rem;
  line-height: 4.7rem;
  margin-bottom: 4.6rem;
}
@media (--mobile) {
  .p-support-inner__block .text .title {
    font-size: 2rem;
    line-height: 2;
    margin-bottom: 2rem;
  }
}
.p-support-inner__block .text p {
  font-size: 1.5rem;
  line-height: 3.2rem;
}
.p-support-inner .p-support-inner__block:nth-child(even) {
  margin-top: 9rem;
  margin-bottom: 10rem;
}
@media (--mobile) {
  .p-support-inner .p-support-inner__block:nth-child(even) {
    margin-top: 4rem;
    margin-bottom: 5rem;
  }
}
.p-support-inner .p-support-inner__block:nth-child(odd) {
  flex-direction: row-reverse;
  margin-bottom: 12rem;
}
@media (--mobile) {
  .p-support-inner .p-support-inner__block:nth-child(odd) {
    margin-bottom: 6rem;
  }
}
.p-support-inner .p-support-inner__block:nth-child(odd) .img {
  margin-right: 0;
  margin-left: 5.8rem;
}
@media (--mobile) {
  .p-support-inner .p-support-inner__block:nth-child(odd) .img {
    margin-left: 0;
  }
}
.p-support-flow__contents {
  display: flex;
  justify-content: space-between;
}
@media (--mobile) {
  .p-support-flow__contents {
    display: block;
    width: 100%;
  }
}
.p-support-flow__contents ul {
  position: relative;
}
@media (--mobile) {
  .p-support-flow__contents ul {
    width: 100%;
  }
}
.p-support-flow__contents ul::after {
  content: "";
  display: inline-block;
  width: 1px;
  height: 100%;
  background-color: #D0D0D0;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, -50%) translateX(-38rem);
}
@media (--mobile) {
  .p-support-flow__contents ul::after {
    transform: translate(-50%, -50%) translateX(-14rem);
  }
}
.p-support-flow__contents ul li {
  display: flex;
  margin-bottom: 5rem;
}
@media (--mobile) {
  .p-support-flow__contents ul li {
    margin-bottom: 10rem;
  }
}
.p-support-flow__contents ul li:last-child {
  margin-bottom: 0;
}
.p-support-flow__contents ul li.turn {
  padding-bottom: 10rem;
}
@media (--mobile) {
  .p-support-flow__contents ul li.turn {
    padding-bottom: 0;
    margin-bottom: 8rem;
  }
}
.p-support-flow__step {
  margin-right: 3rem;
  font-size: 1.8rem;
  font-family: "EB Garamond", serif;
  font-weight: Regular;
  white-space: nowrap;
}
@media (--mobile) {
  .p-support-flow__step {
    margin-right: 1rem;
    font-size: 1.4rem;
  }
}
.p-support-flow__step p {
  background-color: #FFFFFF;
  z-index: 2;
  position: relative;
  padding: 1.7rem 0;
}
@media (--mobile) {
  .p-support-flow__step p {
    padding: 0;
    margin-bottom: 0.5rem;
    text-align: center;
  }
}
.p-support-flow__content {
  padding-top: 1.7rem;
  display: flex;
}
@media (--mobile) {
  .p-support-flow__content {
    width: 100%;
    display: block;
    padding-top: 0;
  }
}
.p-support-flow__text {
  width: calc(100% - 25.8rem - 4rem);
  margin-right: 4rem;
}
@media (--mobile) {
  .p-support-flow__text {
    width: 100%;
    margin-right: 0;
  }
}
.p-support-flow__img {
  width: 25.8rem;
}
@media (--mobile) {
  .p-support-flow__img {
    width: 100%;
  }
}
.p-support-flow__ttl {
  font-size: 2rem;
  color: #171717;
  font-weight: bold;
  margin-bottom: 2.7rem;
}
@media (--mobile) {
  .p-support-flow__ttl {
    margin-bottom: 2rem;
  }
}
.p-support-flow__ttl.last {
  padding-top: 1.5rem;
}
.p-support-flow__ttl span {
  display: inline-block;
  font-size: 1.2rem;
  font-weight: normal;
  background-color: #EAEAEA;
  padding: 0.5rem;
  margin-top: 1rem;
}
@media (--mobile) {
  .p-support-flow__ttl span {
    font-size: 1.5rem;
  }
}
.p-support-flow__txt {
  font-size: 1.5rem;
  line-height: 3.2rem;
}
@media (--mobile) {
  .p-support-flow__txt {
    margin-bottom: 2rem;
  }
}
.p-support .p-support-flow__step.last {
  background: #FFFFFF;
  z-index: 10;
  position: relative;
}
.p-support-assurance__contents {
  margin-bottom: 7rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
@media (--mobile) {
  .p-support-assurance__contents {
    display: block;
  }
}
.p-support-assurance__title {
  font-size: 2rem;
  display: flex;
  margin-bottom: 3rem;
}
.p-support-assurance__title span {
  font-size: 1.8rem;
  margin-right: 0.6rem;
}
.p-support-assurance__text {
  font-size: 1.5rem;
  line-height: 3.2rem;
  margin-bottom: 3rem;
}
.p-support-assurance__btn {
  font-size: 1.5rem;
  border-bottom: 0.1rem solid #707070;
  padding-bottom: 1rem;
  display: inline-block;
}
.p-support-assurance__btn a {
  display: flex;
  align-items: center;
}
.p-support-assurance__btn a:hover svg circle:first-of-type {
  fill: rgb(23, 23, 23);
}
.p-support-assurance__btn a:hover svg path {
  stroke: #FFF;
}
.p-support-assurance__btn svg {
  width: 3rem;
  height: 3rem;
  margin-left: 1rem;
}
.p-support-assurance__btn svg circle:first-of-type {
  fill: rgba(255, 255, 255, 0);
  transition: 0.5s fill;
}
.p-support-assurance__btn svg path {
  stroke: #171717;
}
.p-support-assurance__btn svg, .p-support-assurance__btn svg * {
  transition: 0.5s;
}
.p-support-assurance__content {
  width: calc(100% - 21.9rem - 5rem);
  margin-right: 5rem;
}
@media (--mobile) {
  .p-support-assurance__content {
    margin-right: 2.5rem;
    width: 100%;
  }
}
.p-support-assurance__img {
  width: 21.9rem;
}
@media (--mobile) {
  .p-support-assurance__img {
    width: 20rem;
    margin: 2rem auto 0 auto;
  }
}

/*モーダル本体の指定 + モーダル外側の背景の指定*/
.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  background: rgba(177, 177, 177, 0.8);
  padding: 8rem 3rem;
  overflow: auto;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  box-sizing: border-box;
}
.modal-container .title {
  font-size: 2.6rem;
  margin-bottom: 6rem;
  text-align: center;
}
@media (--mobile) {
  .modal-container .title {
    font-size: 2rem;
    margin-bottom: 2rem;
  }
}
.modal-container .img {
  margin-bottom: 6rem;
  text-align: center;
}
.modal-container .link ul {
  display: flex;
  justify-content: space-between;
}
@media (--mobile) {
  .modal-container .link ul {
    display: block;
  }
}
.modal-container .link ul li {
  width: 27rem;
  border-top: 1px solid #D0D0D0;
  border-bottom: 1px solid #D0D0D0;
  margin-right: 5rem;
}
@media (--mobile) {
  .modal-container .link ul li {
    margin-right: 0;
    margin-bottom: 1.5rem;
    width: 100%;
  }
}
.modal-container .link ul li:last-child {
  margin-right: 0;
}
.modal-container .link ul li a {
  width: 100%;
  height: 100%;
  display: block;
  padding: 2rem;
  font-size: 2rem;
  letter-spacing: 0.3rem;
  color: #333333;
  position: relative;
}
.modal-container .link ul li a::after {
  position: absolute;
  content: "";
  right: 2rem;
  top: 50%;
  width: 1.5rem;
  height: 1.5rem;
  border-top: 1px solid #707070;
  border-right: 1px solid #707070;
  transform: translate(0, -50%) rotate(45deg);
}
.modal-container .link ul li a span {
  font-size: 2.2rem;
  font-weight: bold;
}

/*モーダル本体の擬似要素の指定*/
.modal-container:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}

/*モーダル本体に「active」クラス付与した時のスタイル*/
.modal-container.active {
  opacity: 1;
  visibility: visible;
  z-index: 1100;
}

/*モーダル枠の指定*/
.modal-body {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  max-width: 100rem;
  width: 90%;
}

/*モーダルを閉じるボタンの指定*/
.modal-close {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -4rem;
  right: -4rem;
  width: 4rem;
  height: 4rem;
  font-size: 4rem;
  color: #fff;
  cursor: pointer;
}

/*モーダル内のコンテンツの指定*/
.modal-content {
  background: #fff;
  text-align: left;
  padding: 8rem;
}
@media (--mobile) {
  .modal-content {
    padding: 2rem;
  }
}

.p-thanks .s-text {
  display: flex;
  justify-content: center;
  margin-top: 16rem;
  font-size: 1.8rem;
  line-height: 2;
}
@media (--mobile) {
  .p-thanks .s-text {
    margin-top: 5rem;
    padding: 0 2rem;
  }
}
.p-thanks .s-btn {
  display: flex;
  justify-content: center;
  margin-top: 6rem;
}
.p-thanks .s-btn a {
  font-size: 1.6rem;
  border: 1px solid #171717;
  padding: 0.9rem 3rem;
  transition: 0.3s;
}
.p-thanks .s-btn a:hover {
  background: #171717;
  color: #fff;
}

.loading {
  position: absolute;
  top: 0;
  left: 0;
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  z-index: 9999;
  overflow: hidden;
}
.loading.no-loading {
  position: absolute;
  pointer-events: none;
}
.loading__txt01 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.5) translateY(-1.7vw);
  width: 60vw;
  z-index: 1;
}
.no-loading .loading__txt01 {
  transform: translate(-50%, -50%) translateY(-0.9vw);
}
@media (--mobile) {
  .no-loading .loading__txt01 {
    transform: translate(-50%, -50%) translateY(-0.9vw) scale(1.5, 1.5);
  }
}

@media (--mobile) {
  .loading__txt01 {
    width: 100vw;
    transform: translate(-50%, -50%) scale(0.8) translateY(-1.7vw);
  }
}
.loading__txt01 path {
  opacity: 0;
  transform: translateY(4rem);
}
.loading__txt01 path.is-active {
  fill: #fff;
}
.on-loading .loading__txt01 path {
  transition: 1s fill;
}

.no-loading .loading__txt01 path {
  opacity: 1;
  fill: #fff;
  transform: inherit;
}

.no-loading .loading__txt01 path.delete {
  opacity: 0;
}

.no-loading .loading__txt01 path.initial_d {
  transform: translateX(24.5rem);
}
@media (--mobile) {
  .no-loading .loading__txt01 path.initial_d {
    transform: matrix(1, 0, 0, 1, 222, 0);
  }
}

.no-loading .loading__txt01 path.initial_i {
  transform: translateX(7.5rem);
}
@media (--mobile) {
  .no-loading .loading__txt01 path.initial_i {
    transform: matrix(1, 0, 0, 1, 49, 0);
  }
}

.no-loading .loading__txt01 path.initial_p {
  transform: translateX(-19rem);
}
@media (--mobile) {
  .no-loading .loading__txt01 path.initial_p {
    transform: matrix(1, 0, 0, 1, -217, 0);
  }
}

.loading__txt02 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) translateY(-0.3vw) translateX(3.7vw);
  width: 12vw;
  opacity: 0;
  z-index: 1;
}
.no-loading .loading__txt02 {
  opacity: 1;
}

@media (--mobile) {
  .loading__txt02 {
    width: 40vw;
    transform: translate(-50%, -50%) translateY(-0.3vw) translateX(9.7vw);
  }
}
.loading__txt02 path {
  fill: #000;
}
.loading__txt02 path.is-active {
  fill: #fff;
}
.on-loading .loading__txt02 path {
  transition: 1s fill;
}

.no-loading .loading__txt02 path {
  fill: #fff;
}

.loading__txt03 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) translateY(3.8vw) translateX(0);
  width: 14vw;
  z-index: 1;
}
@media (--mobile) {
  .loading__txt03 {
    transform: translate(-50%, -50%) translateY(10.8vw) translateX(0);
    width: 50vw;
  }
}
.on-loading .loading__txt03 g path {
  transition: 1s fill, 1s stroke;
}

.loading__txt03 g path:nth-of-type(odd) {
  fill: #000;
}
.loading__txt03 g path:nth-of-type(odd).is-active {
  fill: #fff;
}
.no-loading .loading__txt03 g path:nth-of-type(odd) {
  fill: #fff;
}

.loading__txt03 g path:nth-of-type(even) {
  stroke: #000;
}
.loading__txt03 g path:nth-of-type(even).is-active {
  stroke: #fff;
}
.no-loading .loading__txt03 g path:nth-of-type(even) {
  stroke: #fff;
}

.loading_bg_white {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  height: calc(var(--vh, 1vh) * 100 + 0.1rem);
  width: 100%;
  background: #fff;
}
.no-loading .loading_bg_white {
  display: none;
}

.loading_bg_black {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  background: #000;
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  transform: translateY(-100%);
}

.mouse_stalker {
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  z-index: 99;
  transform: translate(-50%, -50%) translate(-0.3rem, -1.5rem);
  border-radius: 999rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 15px;
  pointer-events: none;
  opacity: 0;
  width: 0;
  height: 0;
  transition: 0.3s width, 0.3s height, 0.3s opacity;
}
.mouse_stalker p {
  overflow: hidden;
  line-height: 1;
  white-space: nowrap;
  opacity: 0.8;
}
.mouse_stalker p span {
  display: inline-block;
  transform: translateY(1.2em);
  transition: 0.3s;
}
.mouse_stalker p span:nth-of-type(1) {
  transition-delay: 0.03s !important;
}
.mouse_stalker p span:nth-of-type(2) {
  transition-delay: 0.06s !important;
}
.mouse_stalker p span:nth-of-type(3) {
  transition-delay: 0.09s !important;
}
.mouse_stalker p span:nth-of-type(4) {
  transition-delay: 0.12s !important;
}
.mouse_stalker.is-active {
  opacity: 1;
  width: 7rem;
  height: 7rem;
}
.mouse_stalker.is-active p span {
  transform: translateY(0);
}

.p-top .p-mv {
  position: relative;
  /* キャンペーン用 */
}
.p-top .p-mv__tegaki {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 35vw;
  z-index: 2;
}
@media (--mobile) {
  .p-top .p-mv__tegaki {
    width: 80vw;
  }
}
.p-top .p-mv .mvSwiper {
  margin: 0 0 0 auto;
}
@media (--mobile) {
  .p-top .p-mv .mvSwiper {
    width: 100%;
  }
}
.p-top .p-mv .mvSwiper .swiper-slide picture {
  display: block;
  position: relative;
  height: calc(var(--vh, 1vh) * 100);
  transform: scale(1.05);
  overflow: hidden;
}
.p-top .p-mv .mvSwiper .swiper-slide picture img {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1);
  height: 100%;
  width: 100%;
  object-fit: cover;
  transition: 10s linear;
}
.p-top .p-mv .mvSwiper .swiper-slide.swiper-slide-active picture img {
  transform: translate(-50%, -50%) scale(1.05);
}
.p-top .p-mv .swiper-pagination {
  width: auto;
  position: absolute;
  left: 6.5rem;
  transform: translateX(-50%);
  bottom: 4rem;
  width: auto;
}
@media (--mobile) {
  .p-top .p-mv .swiper-pagination {
    display: none;
  }
}
.p-top .p-mv .swiper-pagination-bullet {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "EB Garamond", serif;
  background: inherit;
  font-size: 1.4rem;
  width: 4rem;
  height: 4rem;
  outline: none;
  color: #fff;
  opacity: 1 !important;
}
.p-top .p-mv .swiper-pagination-bullet:nth-of-type(n + 2) {
  margin-top: 2rem;
}
.p-top .p-mv .swiper-pagination-bullet svg {
  transition: 1s;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%) rotate(-90deg);
  opacity: 0;
}
.p-top .p-mv .swiper-pagination-bullet.swiper-pagination-bullet-active .bg {
  opacity: 1;
}
.p-top .p-mv .swiper-pagination-bullet.swiper-pagination-bullet-active .anime {
  opacity: 1;
}
.p-top .p-mv .swiper-pagination-bullet.swiper-pagination-bullet-active .anime .svg-elem-1 {
  animation: animate-svg-stroke-1 3s linear 0s both;
}
@keyframes animate-svg-stroke-1 {
  0% {
    stroke-dashoffset: 137.0884841044px;
    stroke-dasharray: 137.0884841044px;
  }
  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 137.0884841044px;
  }
}
.p-top .p-mv__event {
  position: absolute;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  border-radius: 9999px;
  bottom: 8rem;
  right: 2rem;
  z-index: 2;
  height: 15rem;
  width: 15rem;
  background: #000;
}
@media (--mobile) {
  .p-top .p-mv__event {
    display: none;
  }
}
.p-top .p-mv__event a {
  font-size: 1.6rem;
  color: #fff;
}
.p-top .p-mv__news {
  position: absolute;
  display: flex;
  align-items: center;
  bottom: 0;
  right: 0;
  z-index: 2;
  height: 7rem;
}
@media (--mobile) {
  .p-top .p-mv__news {
    width: 100%;
    height: 8rem;
  }
}
.p-top .p-mv__newsTtl {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  width: 7rem;
  height: 100%;
  background: #171717;
  font-size: 1.6rem;
}
@media (--mobile) {
  .p-top .p-mv__newsTtl {
    font-size: 1.3rem;
  }
}
.p-top .p-mv__newsWrap {
  display: flex;
  align-items: center;
}
@media (--mobile) {
  .p-top .p-mv__newsWrap {
    margin-bottom: 0.5rem;
  }
}
.p-top .p-mv__newsLink {
  display: flex;
  align-items: center;
  padding: 0 2.7rem 0 3rem;
  background: #fff;
  height: 100%;
}
@media (--mobile) {
  .p-top .p-mv__newsLink {
    position: relative;
    width: calc(100% - 7rem);
    display: flex;
    align-items: center;
    padding: 0 1rem;
  }
  .p-top .p-mv__newsLink::after {
    content: "";
    position: absolute;
    right: 1.5rem;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
    height: 1.5rem;
    width: 1.5rem;
    border-top: 1px solid #707070;
    border-right: 1px solid #707070;
  }
}
@media (--large) {
  .p-top .p-mv__newsLink div {
    display: flex;
    align-items: center;
  }
}
.p-top .p-mv__newsLink:hover .p-mv__newsTxt::after {
  right: -1rem;
}
.p-top .p-mv__newsYear {
  writing-mode: tb;
  font-size: 1rem;
  margin-right: 0.5rem;
}
.p-top .p-mv__newsDate {
  font-size: 2.4rem;
  margin-right: 1rem;
}
@media (--mobile) {
  .p-top .p-mv__newsDate {
    line-height: 1;
  }
}
.p-top .p-mv__newsCat {
  border-bottom: 1px solid #C9C9C9;
}
.p-top .p-mv__newsTxt {
  position: relative;
  font-size: 1.4rem;
  padding-right: 3rem;
  letter-spacing: 0.1em;
}
@media (--mobile) {
  .p-top .p-mv__newsTxt {
    position: relative;
    left: 0.5rem;
    width: 26rem;
    padding-right: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@media (--large) {
  .p-top .p-mv__newsTxt::after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
    right: 0;
    height: 1.5rem;
    width: 1.5rem;
    border-top: 0.1rem solid #707070;
    border-right: 0.1rem solid #707070;
    transition: 0.5s;
  }
}
.p-top .p-mv__txt {
  position: absolute;
  z-index: 3;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%) translateY(-10vw);
  letter-spacing: 0.2em;
  font-size: 1.5vw;
  color: #fff;
  line-height: 2;
}
@media (--mobile) {
  .p-top .p-mv__txt {
    transform: translate(-50%, -50%) translateY(-10rem);
    font-size: 1.7rem;
    white-space: nowrap;
  }
}
.p-top .p-mv__campaign {
  position: relative;
  display: block;
  height: auto;
  width: auto;
}
.p-top .p-mv__campaign img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.p-top .p-mv__campaign-link {
  position: absolute;
  display: block;
  bottom: 5.3%;
  right: 6.7%;
  width: 21vw;
  height: 6vw;
}
@media (--mobile) {
  .p-top .p-mv__campaign-link {
    bottom: 4.1%;
    right: 9.7%;
    width: 18.2vw;
    height: 5.2vw;
  }
}
.p-top .p-concept, .p-top .p-quality {
  position: relative;
  margin-top: 10.2rem;
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;
}
@media (--mobile) {
  .p-top .p-concept, .p-top .p-quality {
    margin-top: 6rem;
    align-items: center;
    flex-direction: column;
  }
}
.p-top .p-concept__tegaki, .p-top .p-quality__tegaki {
  position: absolute;
  left: 50%;
  transform: translateX(-50%) translateX(-22rem);
  bottom: 0;
}
@media (--mobile) {
  .p-top .p-concept__tegaki, .p-top .p-quality__tegaki {
    transform: translateX(-50%) translateX(-6rem) translateY(4rem);
  }
  .p-top .p-concept__tegaki svg, .p-top .p-quality__tegaki svg {
    width: 18rem;
  }
}
.p-top .p-concept__ttl, .p-top .p-quality__ttl {
  display: flex;
  align-items: flex-start;
  flex-direction: row-reverse;
  margin-left: 11.7rem;
}
@media (--mobile) {
  .p-top .p-concept__ttl, .p-top .p-quality__ttl {
    margin-left: 0;
  }
}
.p-top .p-concept__ttl small, .p-top .p-quality__ttl small {
  position: relative;
  display: block;
  writing-mode: tb;
  margin-left: 1.5rem;
  font-size: 1.5rem;
  padding-top: 6.5rem;
}
.p-top .p-concept__ttl small::after, .p-top .p-quality__ttl small::after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%) translateX(0.2rem);
  height: 5rem;
  width: 0.1rem;
  background: #000;
  transition: 0.3s;
  opacity: 0;
}
@media (--mobile) {
  .p-top .p-concept__ttl small, .p-top .p-quality__ttl small {
    display: block;
    margin-left: 1.6rem;
  }
}
.p-top .p-concept__ttl small.is-inview::after, .p-top .p-quality__ttl small.is-inview::after {
  opacity: 1;
  animation: pathmove 2s cubic-bezier(0.645, 0.045, 0.355, 1) 0s infinite;
}
@keyframes pathmove {
  0% {
    transform: scaleY(0);
    transform-origin: center top;
  }
  50% {
    transform: scaleY(1);
    transform-origin: center top;
  }
  51% {
    transform: scaleY(1);
    transform-origin: center bottom;
  }
  100% {
    transform: scaleY(0);
    transform-origin: center bottom;
  }
}
.p-top .p-concept__ttl strong, .p-top .p-quality__ttl strong {
  display: flex;
  align-items: flex-start;
  font-size: 2.6rem;
  font-weight: lighter;
  width: 7rem;
}
.p-top .p-concept__ttl strong svg, .p-top .p-quality__ttl strong svg {
  transform: translateY(-1.5rem);
  opacity: 0;
  transition: 1s;
  width: 2.2rem;
}
.p-top .p-concept__ttl strong svg:first-of-type, .p-top .p-quality__ttl strong svg:first-of-type {
  margin-right: 2rem;
  transition-delay: 0.3s;
}
.p-top .p-concept__ttl strong.is-inview svg, .p-top .p-quality__ttl strong.is-inview svg {
  opacity: 1;
  transform: translateY(0);
}
.p-top .p-concept__content, .p-top .p-quality__content {
  position: relative;
  font-size: 1.4rem;
  margin-top: 3rem;
  padding-bottom: 6rem;
}
@media (--mobile) {
  .p-top .p-concept__content, .p-top .p-quality__content {
    padding-bottom: 0;
    width: 100%;
    margin-top: 4rem;
  }
}
.p-top .p-concept__content p, .p-top .p-quality__content p {
  line-height: 2.2;
}
@media (--mobile) {
  .p-top .p-concept__content p, .p-top .p-quality__content p {
    display: flex;
    justify-content: center;
  }
}
@media (--large) {
  .p-top .p-quality {
    flex-direction: inherit;
  }
  .p-top .p-quality__ttl {
    margin-right: 11.7rem;
    margin-left: 0;
  }
}
.p-top .p-quality__content::after {
  background-image: url(../images/top/handwritten_ttl02.svg);
}
@media (--large) {
  .p-top .p-quality__content::after {
    width: 20.2rem;
    height: 13.6rem;
  }
}
.p-top .p-quality__tegaki {
  position: absolute;
  left: 50%;
  transform: translateX(-50%) translateX(0rem);
  bottom: 3rem;
}
@media (--mobile) {
  .p-top .p-quality__tegaki {
    transform: translateX(-50%) translateX(-6rem) translateY(8rem);
  }
}
.p-top .p-concept-img {
  display: flex;
  margin-top: 14rem;
}
@media (--mobile) {
  .p-top .p-concept-img {
    display: block;
    margin-top: 7rem;
  }
}
.p-top .p-concept-img__left, .p-top .p-concept-img__center {
  width: 37%;
}
.p-top .p-concept-img__left figure, .p-top .p-concept-img__center figure {
  height: 30rem;
  overflow: hidden;
  margin: 0 !important;
}
.p-top .p-concept-img__left figure img, .p-top .p-concept-img__center figure img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  transform: scale(1.1) translateY(-1rem);
}
.p-top .p-concept-img__center figure img {
  transform: scale(1.1) translateY(1rem);
}
.p-top .p-concept-img__right {
  width: 26%;
}
.p-top .p-concept-img__right figure {
  height: 60rem;
  overflow: hidden;
}
.p-top .p-concept-img__right figure img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  transform: scale(1.1) translateY(-1rem);
}
@media (--mobile) {
  .p-top .p-concept-img {
    display: flex;
    flex-wrap: wrap;
  }
  .p-top .p-concept-img__left {
    display: flex;
    width: 100%;
  }
  .p-top .p-concept-img__left figure {
    height: 16rem;
  }
  .p-top .p-concept-img__center, .p-top .p-concept-img__right {
    width: 50%;
  }
  .p-top .p-concept-img__center figure {
    height: 16rem;
  }
  .p-top .p-concept-img__right figure {
    height: 32rem;
  }
}
.p-top .p-quality-img {
  width: 100%;
  position: relative;
  margin-top: 8rem;
}
@media (--mobile) {
  .p-top .p-quality-img {
    margin-top: 8rem;
  }
}
.p-top .p-quality-img picture {
  display: block;
  overflow: hidden;
  height: 32vw;
}
@media (--mobile) {
  .p-top .p-quality-img picture {
    height: 60vw;
  }
}
.p-top .p-quality-img picture img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  transform: translateY(3rem) scale(1.1);
}
.p-top .p-design {
  margin-top: 15rem;
}
@media (--mobile) {
  .p-top .p-design {
    margin-top: 8rem;
  }
}
.p-top .p-design__ttl {
  text-align: center;
}
.p-top .p-design__ttl > * {
  display: block;
  font-size: 2rem;
  font-weight: lighter;
}
.p-top .p-design__ttl strong {
  display: inline-block;
}
@media (--mobile) {
  .p-top .p-design__ttl strong {
    width: 21rem;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}
.p-top .p-design__trigger {
  display: flex;
  justify-content: center;
  font-size: 1.4rem;
  margin-top: 5rem;
  width: 58rem;
  margin-left: auto;
  margin-right: auto;
}
@media (--mobile) {
  .p-top .p-design__trigger {
    margin-top: 2.5rem;
    padding: 0 2rem;
    width: 100%;
  }
}
.p-top .p-design__trigger li {
  width: 12.7rem;
  border-bottom: 1px solid #D0D0D0;
  text-align: center;
  padding-bottom: 1rem;
  cursor: pointer;
  transition: 0.3s border-color;
}
@media (--mobile) {
  .p-top .p-design__trigger li {
    font-size: 1.2rem;
  }
}
.p-top .p-design__trigger li:nth-of-type(n + 2) {
  margin-left: 2.6rem;
}
@media (--mobile) {
  .p-top .p-design__trigger li:nth-of-type(n + 2) {
    margin-left: 1rem;
  }
}
.p-top .p-design__trigger li.is-active {
  border-color: #171717;
  pointer-events: none;
}
.p-top .p-design__tag {
  width: 118rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5rem;
  display: flex;
  flex-wrap: wrap;
}
@media (--mobile) {
  .p-top .p-design__tag {
    padding: 0 3rem;
    width: auto;
    margin-top: 3rem;
  }
}
.p-top .p-design__tag a {
  display: block;
  padding: 6px 8px;
  color: #7B7B7B;
  transition: 0.3s all;
  margin-right: 0.8rem;
  font-size: 1.2rem;
  color: #707070;
  border: 1px solid #CECECE;
  margin-top: 1rem;
}
.p-top .p-design__tag a:hover {
  background: #7B7B7B;
  color: #FFFFFF;
}
.p-top .p-design__listWrap {
  margin-top: 6rem;
}
@media (--mobile) {
  .p-top .p-design__listWrap {
    margin-top: 4rem;
  }
}
.p-top .p-design__listWrap.is-active {
  display: block !important;
}
.p-top .p-design__list {
  width: 118rem;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 6rem 4rem;
}
@media (--mobile) {
  .p-top .p-design__list {
    grid-template-columns: repeat(1, 1fr);
    gap: 4rem 0;
    width: 100%;
  }
}
@media (--large) {
  .p-top .p-design__list .swiper-wrapper {
    justify-content: space-between;
    flex-wrap: wrap;
  }
}
.p-top .p-design__list a {
  width: 57rem;
  display: block;
  position: relative;
}
@media (--mobile) {
  .p-top .p-design__list a {
    width: 100%;
  }
}
.p-top .p-design__list a figure {
  position: relative;
  height: 35rem;
  overflow: hidden;
}
@media (--mobile) {
  .p-top .p-design__list a figure {
    height: 22rem;
    width: 100%;
  }
}
.p-top .p-design__list a figure img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  transition: 1s;
}
.p-top .p-design__list a .cat {
  position: absolute;
  right: 3rem;
  top: 31rem;
  z-index: 9;
  font-size: 1.3rem;
  color: #171717;
  color: #FFF;
  text-shadow: 0 0 2rem #000, 0 0 2rem #000;
}
@media (--mobile) {
  .p-top .p-design__list a .cat {
    right: 2rem;
    top: 18.5rem;
  }
}
.p-top .p-design__list a .txtWrap {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 2;
  background: linear-gradient(to bottom, rgba(31, 31, 31, 0), rgba(54, 54, 54, 0.72));
  padding: 8.4rem 3rem 2.4rem;
  width: 100%;
}
@media (--mobile) {
  .p-top .p-design__list a .txtWrap {
    position: relative;
    right: inherit;
    padding: 1.8rem 0 0 0;
    width: 100%;
    background: none;
  }
}
.p-top .p-design__list a .txtWrap p {
  font-size: 1.2rem;
  color: #fff;
}
@media (--mobile) {
  .p-top .p-design__list a .txtWrap p {
    font-size: 1.2rem;
    color: #171717;
  }
}
.p-top .p-design__list a .txtWrap h4 {
  font-size: 1.8rem;
  color: #fff;
  margin-top: 0.5rem;
  font-weight: lighter;
  padding-left: 3rem;
}
@media (--mobile) {
  .p-top .p-design__list a .txtWrap h4 {
    font-size: 1.6rem;
    color: #171717;
  }
}
.p-top .p-design__list a:hover figure img {
  transform: scale(1.05);
}
.p-top .p-design__more {
  width: 118rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3rem;
}
@media (--mobile) {
  .p-top .p-design__more {
    padding: 0 4rem;
    width: 100%;
  }
}
.p-top .p-design__more .c-moreBtn {
  margin-top: 0;
}
@media (--mobile) {
  .p-top .p-design__more .c-moreBtn {
    justify-content: flex-end;
  }
}
.p-top .p-design__btn {
  margin-top: 6rem;
}
.p-top .p-design__btn a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38rem;
  border: 2px solid #333333;
  margin-inline: auto;
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: 0.05em;
  line-height: 1.2;
  color: #333333;
  text-align: center;
  padding: 2.5rem 0;
  transition: 0.3s;
}
@media (--mobile) {
  .p-top .p-design__btn a {
    width: 100%;
    font-size: 1.6rem;
    padding: 2rem 0;
  }
}
.p-top .p-design__btn a svg {
  margin-left: 3rem;
  width: 3rem;
  height: 3rem;
}
@media (--mobile) {
  .p-top .p-design__btn a svg {
    margin-left: 1.5rem;
    width: 2.9rem;
    height: 2.9rem;
  }
}
@media (--large) {
  .p-top .p-design__btn a:hover {
    background-color: #333333;
    color: #fff;
  }
  .p-top .p-design__btn a:hover svg circle:first-of-type {
    fill: #fff;
  }
  .p-top .p-design__btn a:hover svg path {
    stroke: #171717;
  }
  .p-top .p-design__btn a:hover svg g {
    stroke: #fff;
  }
}
.p-top .p-list {
  margin-top: 14rem;
}
@media (--mobile) {
  .p-top .p-list {
    margin-top: 8rem;
  }
}
.p-top .p-list__container {
  width: 118rem;
  margin-inline: auto;
}
@media (--mobile) {
  .p-top .p-list__container {
    width: 100%;
  }
}
.p-top .p-list__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
@media (--mobile) {
  .p-top .p-list__item {
    flex-direction: column;
  }
}
.p-top .p-list__item:nth-of-type(2) {
  flex-direction: row-reverse;
  margin-top: 8rem;
}
@media (--mobile) {
  .p-top .p-list__item:nth-of-type(2) {
    flex-direction: column;
    margin-top: 6rem;
  }
}
.p-top .p-list__item figure {
  width: 56rem;
  height: auto;
}
@media (--mobile) {
  .p-top .p-list__item figure {
    width: 100%;
  }
}
.p-top .p-list__item figure img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.p-top .p-list__item > div {
  width: 48rem;
}
@media (--mobile) {
  .p-top .p-list__item > div {
    width: 100%;
    margin-top: 3rem;
  }
}
.p-top .p-list__item > div h3 {
  font-size: 2.6rem;
  font-weight: 400;
  letter-spacing: 0.1em;
  line-height: 1.4;
  color: #171717;
}
@media (--mobile) {
  .p-top .p-list__item > div h3 {
    font-size: 2rem;
  }
}
.p-top .p-list__item > div p {
  display: block;
  margin-top: 4rem;
  font-size: 1.6rem;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 1.8;
  color: #333333;
}
@media (--mobile) {
  .p-top .p-list__item > div p {
    margin-top: 2.4rem;
  }
}
.p-top .p-list__feature {
  width: 118rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 8rem;
}
@media (--mobile) {
  .p-top .p-list__feature {
    width: 100%;
  }
}
.p-top .p-list__feature ul {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3.5rem;
}
@media (--mobile) {
  .p-top .p-list__feature ul {
    grid-template-columns: repeat(1, 1fr);
    gap: 2rem 0;
  }
}
.p-top .p-list__feature ul li a:hover .img img {
  transform: scale(1.1);
}
.p-top .p-list__feature ul li a .img {
  position: relative;
  width: 37rem;
  height: 20rem;
  overflow: hidden;
}
@media (--mobile) {
  .p-top .p-list__feature ul li a .img {
    width: 100%;
    height: 14rem;
  }
}
.p-top .p-list__feature ul li a .img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.4s;
}
.p-top .p-list__feature ul li a .img p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #FFF;
  font-size: 2.4rem;
  text-align: center;
}
.p-top .p-list__feature ul li a .img p small {
  font-size: 1.4rem;
  display: block;
}
@media (--mobile) {
  .p-top .p-list__feature ul li a .img p {
    font-size: 2.2rem;
    white-space: nowrap;
  }
}
.p-top .p-movie {
  position: relative;
  margin-top: 15rem;
  overflow: hidden;
}
@media (--mobile) {
  .p-top .p-movie {
    margin-top: 8rem;
    padding-bottom: 8rem;
  }
}
.p-top .p-movie::after, .p-top .p-movie::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #333333;
  z-index: 2;
}
.p-top .p-movie::after {
  background: rgba(51, 51, 51, 0);
}
.p-top .p-movie::before {
  z-index: -1;
}
.p-top .p-movie .c-inner {
  padding-top: 14rem;
  padding-bottom: 14rem;
}
@media (--mobile) {
  .p-top .p-movie .c-inner {
    padding-top: 8rem;
    padding-bottom: 0rem;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
.p-top .p-movie > * {
  position: relative;
  z-index: 3;
}
.p-top .p-movie > * strong {
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
}
@media (--mobile) {
  .p-top .p-movie > * strong {
    width: 20rem;
  }
}
.p-top .p-movie__bgImg {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  opacity: 0;
  z-index: 1;
  transition: 0.5s opacity;
}
@media (--mobile) {
  .p-top .p-movie__bgImg {
    display: none;
  }
}
.p-top .p-movie__bgImg.is-active {
  opacity: 1;
}
.p-top .p-movie__ttl {
  text-align: center;
  color: #fff;
}
.p-top .p-movie__ttl > * {
  display: block;
  font-size: 2.6rem;
  font-weight: lighter;
}
@media (--mobile) {
  .p-top .p-movie__ttl > * {
    font-size: 2rem;
  }
}
.p-top .p-movie .c-leftTtl {
  width: 118rem;
  margin-left: auto;
  margin-right: auto;
}
@media (--mobile) {
  .p-top .p-movie .c-leftTtl {
    width: 100%;
  }
}
.p-top .p-movie__list {
  display: flex;
  justify-content: space-between;
  margin-top: 6.5rem;
}
@media (--mobile) {
  .p-top .p-movie__list {
    border: none;
    display: block;
    margin-top: 5rem;
  }
}
.p-top .p-movie__list li {
  width: 37.4rem;
}
@media (--mobile) {
  .p-top .p-movie__list li {
    width: 100%;
  }
  .p-top .p-movie__list li:nth-of-type(n + 2) {
    margin-top: 5rem;
  }
}
.p-top .p-movie__ele {
  cursor: pointer;
}
@media (--large) {
  .p-top .p-movie__ele {
    transition: 0.5s opacity;
  }
}
@media (--mobile) {
  .p-top .p-movie__ele {
    opacity: 1 !important;
  }
}
@media (--large) {
  .p-top .p-movie__ele a:hover .p-movie__img img {
    transform: scale(1.1);
  }
}
.p-top .p-movie__img {
  position: relative;
  overflow: hidden;
  border: 0.1rem solid rgba(255, 255, 255, 0);
  transition: 0.5s;
  z-index: 1;
}
.p-top .p-movie__img img {
  transition: 0.5s;
}
.p-top .p-movie__ttlArea {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2.4rem;
}
@media (--mobile) {
  .p-top .p-movie__ttlArea {
    margin-left: 3rem;
  }
}
.p-top .p-movie__ttlArea, .p-top .p-movie__ttlArea * {
  transition: 0.5s;
}
.p-top .p-movie__txt {
  text-align: left;
  font-weight: lighter;
  color: #fff;
}
.p-top .p-movie__txt small {
  font-size: 1.3rem;
}
.p-top .p-movie__txt p {
  font-size: 1.8rem;
  letter-spacing: 0.2em;
}
@media (--mobile) {
  .p-top .p-movie__txt p {
    margin-top: 0.4rem;
  }
}
.p-top .p-movie__link {
  position: absolute;
  top: -16rem;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 0;
  z-index: 3;
}
@media (--mobile) {
  .p-top .p-movie__link {
    transform: translateX(-50%) translateX(-2rem);
  }
}
.p-top .p-movie__link span {
  font-size: 1.3rem;
  color: #fff;
}
.p-top .p-movie__link svg {
  margin-left: 1.2rem;
}
.p-top .p-movie__link svg circle:first-of-type {
  fill: rgba(255, 255, 255, 0);
  transition: 0.5s fill;
}
.p-top .p-movie__link svg path {
  stroke: #fff;
}
.p-top .p-movie__ele:hover .p-movie__link circle:first-of-type {
  fill: #fff;
}
.p-top .p-movie__ele:hover .p-movie__link path {
  stroke: #333333;
}
@media (--mobile) {
  .p-top .p-movie .c-moreBtn {
    margin-right: 3rem;
  }
}
.p-top .p-movie .c-moreBtn a {
  color: rgba(255, 255, 255, 0.8);
}
.p-top .p-movie .c-moreBtn a svg {
  margin-left: 1.5rem;
}
.p-top .p-movie .c-moreBtn a svg circle:first-of-type {
  fill: #333;
}
.p-top .p-movie .c-moreBtn a svg circle:last-of-type {
  stroke: #fff;
}
.p-top .p-movie .c-moreBtn a svg path {
  stroke: #fff !important;
}
.p-top .p-movie .c-moreBtn a svg g {
  fill: #fff;
  stroke: #333333 !important;
}
@media (--large) {
  .p-top .p-movie .c-moreBtn a:hover svg path {
    stroke: #000 !important;
  }
  .p-top .p-movie .c-moreBtn a:hover circle:first-of-type {
    fill: #fff !important;
  }
  .p-top .p-movie .c-moreBtn a:hover, .p-top .p-movie .c-moreBtn a:hover * {
    transition: 0.5s;
  }
}
.p-top .p-blog {
  margin-top: 14.2rem;
}
@media (--mobile) {
  .p-top .p-blog {
    margin-top: 6rem;
  }
}
@media (--mobile) {
  .p-top .p-blog__inner {
    padding: 0 !important;
  }
}
.p-top .p-blog .c-leftTtl {
  width: 128rem;
  margin: 0 auto;
}
.p-top .p-blog .c-leftTtl strong {
  font-size: 3.2rem;
}
@media (--mobile) {
  .p-top .p-blog .c-leftTtl {
    width: auto;
    padding: 0 3rem;
  }
}
.p-top .p-blog ul {
  margin-top: 3.5rem;
  display: flex;
  justify-content: space-between;
  gap: 0 2rem;
}
@media (--mobile) {
  .p-top .p-blog ul {
    margin-top: 2rem;
    flex-direction: column;
  }
}
.p-top .p-blog ul li {
  width: 50%;
}
@media (--mobile) {
  .p-top .p-blog ul li {
    width: 100%;
  }
}
.p-top .p-blog ul li:last-child h4 p {
  margin-bottom: 2rem;
}
@media (--mobile) {
  .p-top .p-blog ul li:last-child h4 p {
    margin-bottom: 1rem;
  }
}
@media (--mobile) {
  .p-top .p-blog ul li:last-child p svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%) translateY(-1.5rem);
    right: -5rem;
    margin-top: 0;
  }
}
.p-top .p-blog ul li a {
  position: relative;
  display: block;
}
.p-top .p-blog ul li a:hover svg circle:first-of-type {
  fill: rgb(255, 255, 255);
}
.p-top .p-blog ul li a:hover svg path {
  stroke: #171717;
}
.p-top .p-blog ul li a div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  font-weight: lighter;
  text-align: center;
}
.p-top .p-blog ul li a div p {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  letter-spacing: 0.2em;
  color: #FFF;
}
@media (--mobile) {
  .p-top .p-blog ul li a div p {
    font-size: 1.8rem;
  }
}
.p-top .p-blog ul li a div p svg {
  width: 4rem;
  height: 4rem;
  margin-top: 4rem;
}
.p-top .p-blog ul li a div p svg circle:first-of-type {
  fill: rgba(255, 255, 255, 0);
  transition: 0.5s fill;
}
.p-top .p-blog ul li a div p svg path {
  stroke: #fff;
}
.p-top .p-blog ul li a div p svg, .p-top .p-blog ul li a div p svg * {
  transition: 0.5s;
}
.p-top .p-blog ul li a h4 {
  display: flex;
  align-items: center;
  white-space: nowrap;
  justify-content: center;
  flex-direction: column;
}
@media (--mobile) {
  .p-top .p-blog ul li a h4 {
    flex-direction: row;
  }
}
@media (--mobile) {
  .p-top .p-blog ul li a h4 > div {
    position: static;
    top: initial;
    left: initial;
    transform: translate(0, 0);
    text-align: initial;
  }
}
.p-top .p-blog ul li a h4 svg {
  width: 4rem;
  height: 4rem;
  margin-top: 4rem;
}
@media (--mobile) {
  .p-top .p-blog ul li a h4 svg {
    margin-top: 0;
    margin-left: 1rem;
  }
}
.p-top .p-blog ul li a h4 svg circle:first-of-type {
  fill: rgba(255, 255, 255, 0);
  transition: 0.5s fill;
}
.p-top .p-blog ul li a h4 svg path {
  stroke: #fff;
}
.p-top .p-blog ul li a h4 svg, .p-top .p-blog ul li a h4 svg * {
  transition: 0.5s;
}
.p-top .p-blog ul li a h4 span {
  display: inline-block;
  width: 16rem;
}
.p-top .p-blog ul li a h4 p {
  font-size: 3rem;
  color: #fff;
  letter-spacing: 0.2em;
  text-align: right;
  line-height: 3.2rem;
  margin-right: 2rem;
  font-weight: normal;
}
@media (--mobile) {
  .p-top .p-blog ul li a h4 p {
    display: flex;
    white-space: nowrap;
    font-size: 2.4rem;
    margin-right: 0;
  }
}
.p-top .p-blog ul li a figure {
  overflow: hidden;
  position: relative;
  transition: 1s transform;
  height: 55rem;
}
@media (--mobile) {
  .p-top .p-blog ul li a figure {
    height: 22rem;
  }
}
.p-top .p-blog ul li a figure img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  transition: transform 1s;
}
.p-top .p-blog ul li a:hover figure img {
  transform: scale(1.05);
}
.p-top .p-blog ul li a:hover::after {
  right: 3rem;
}
.p-top .p-news {
  margin-top: 14rem;
  display: flex;
  justify-content: space-between;
}
@media (--mobile) {
  .p-top .p-news {
    margin-top: 6rem;
    display: block;
  }
}
.p-top .p-news__right {
  width: 92rem;
}
@media (--mobile) {
  .p-top .p-news__right {
    width: 100%;
  }
}
.p-top .p-news__right.is-inview ul li::after {
  width: 100%;
}
.p-top .p-news__right.is-inview ul li a {
  opacity: 1;
  transform: scale(1);
}
.p-top .p-link {
  margin: 14rem 0;
}
@media (--mobile) {
  .p-top .p-link {
    margin: 8rem 0 0;
  }
}
.p-top .p-link ul {
  display: flex;
  justify-content: space-between;
}
@media (--mobile) {
  .p-top .p-link ul {
    display: block;
  }
}
.p-top .p-link ul li {
  width: 37.3rem;
  height: 37.3rem;
}
@media (--mobile) {
  .p-top .p-link ul li {
    width: 100vw;
    height: 22rem;
    box-sizing: border-box;
    margin-left: calc(-50vw + 50%);
  }
}
.p-top .p-link ul li a {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: block;
}
.p-top .p-link ul li a::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
  transition: 1s transform;
}
.p-top .p-link ul li a:hover svg circle:first-of-type {
  fill: rgb(255, 255, 255);
}
.p-top .p-link ul li a:hover svg path {
  stroke: #171717;
}
.p-top .p-link ul li a > * {
  position: relative;
  z-index: 2;
}
.p-top .p-link ul li a h3 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  text-align: center;
  font-weight: lighter;
  letter-spacing: 0.2em;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.p-top .p-link ul li a h3 svg {
  width: 4rem;
  height: 4rem;
}
.p-top .p-link ul li a h3 svg circle:first-of-type {
  fill: rgba(255, 255, 255, 0);
  transition: 0.5s fill;
}
.p-top .p-link ul li a h3 svg path {
  stroke: #fff;
}
.p-top .p-link ul li a h3 svg, .p-top .p-link ul li a h3 svg * {
  transition: 0.5s;
}
.p-top .p-link ul li a h3 * {
  color: #fff;
  transition: 0.3s color;
}
.p-top .p-link ul li a h3 strong {
  font-size: 3rem;
}
@media (--mobile) {
  .p-top .p-link ul li a h3 strong {
    letter-spacing: 0.2em;
    font-size: 2rem;
  }
}
.p-top .p-link ul li a h3 small {
  font-size: 1.4rem;
  margin-bottom: 3rem;
}
@media (--mobile) {
  .p-top .p-link ul li a h3 small {
    margin-bottom: 2rem;
    font-size: 1.2rem;
  }
}
.p-top .p-link ul li a p {
  position: absolute;
  bottom: 3.3rem;
  right: 3rem;
  z-index: 4;
  color: #fff;
  font-size: 1.2rem;
}
.p-top .p-link ul li a p::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: -0.3rem;
  width: 100%;
  height: 0.1rem;
  background: #fff;
  transition: 0.5s width;
}
.p-top .p-link ul li a figure {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 50rem;
}
@media (--mobile) {
  .p-top .p-link ul li a figure {
    height: 100%;
  }
}
.p-top .p-link ul li a figure::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  transition: 0.5s background;
}
@media (--mobile) {
  .p-top .p-link ul li a figure::after {
    background: none;
  }
}
.p-top .p-link ul li a figure img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  filter: grayscale(100%);
  transform: scale(1.05);
  transition: 0.5s transform;
}
@media (--mobile) {
  .p-top .p-link ul li a figure img {
    filter: grayscale(0%);
  }
}
.p-top .p-link ul li a img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (--large) {
  .p-top .p-link ul li a:hover figure::after {
    background: rgba(0, 0, 0, 0);
  }
  .p-top .p-link ul li a:hover figure img {
    filter: grayscale(0%) blur(0);
  }
  .p-top .p-link ul li a:hover p::after {
    width: 20%;
  }
}

.p-movie_modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  z-index: 9999;
  opacity: 0;
  pointer-events: none;
  transition: 1s opacity;
}
.p-movie_modal.is-active {
  opacity: 1;
  pointer-events: inherit;
}
.p-movie_modal__inner {
  position: relative;
  padding: 2.5rem;
  background: #171717;
}
@media (--mobile) {
  .p-movie_modal__inner {
    padding: 1.5rem;
  }
}
.p-movie_modal__inner iframe {
  height: 80vh;
  width: 80vw;
  max-width: 1000px;
  transition: 1s opacity;
  display: none;
}
@media (--mobile) {
  .p-movie_modal__inner iframe {
    height: 60vw;
  }
}
.p-movie_modal__inner iframe.is-active {
  display: block;
  width: 80vw;
}
.p-movie_modal__close {
  display: inline-block;
  position: absolute;
  right: 0;
  top: 0;
  height: 5rem;
  width: 5rem;
  background: #171717;
  transition: 0.3s opacity;
  cursor: pointer;
}
@media (--mobile) {
  .p-movie_modal__close {
    width: 4rem;
    height: 4rem;
  }
}
.p-movie_modal__close:hover {
  opacity: 0.7;
}
.p-movie_modal__close::before, .p-movie_modal__close::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  height: 50%;
  width: 0.1rem;
  background: #fff;
}
.p-movie_modal__close::after {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.scroll-hint-icon-wrap {
  display: none !important;
}

.p-traveling * {
  font-weight: 400;
}
.p-traveling__mv {
  height: 46vw !important;
}
.p-traveling__mv img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.p-traveling__heading {
  width: 98rem;
  margin: 0 auto;
}
@media (--mobile) {
  .p-traveling__heading {
    width: 100%;
    padding: 0 3rem;
  }
}
.p-traveling__heading .heading-ttl {
  font-size: 2.6rem;
  font-weight: 400;
}
.p-traveling__heading .heading-ttl small {
  font-size: 1.4rem;
  display: block;
  margin-bottom: 2rem;
}
@media (--mobile) {
  .p-traveling__heading .heading-ttl small {
    margin-bottom: 0.6rem;
  }
}
.p-traveling__heading .heading-txt {
  margin-top: 5rem;
  font-size: 1.6rem;
  letter-spacing: 0;
  line-height: 2;
}
.p-traveling__heading .heading-txt.movies {
  margin-top: 8rem;
}
@media (--mobile) {
  .p-traveling__heading .heading-txt {
    font-size: 1.4rem;
    margin-top: 2.5rem;
  }
}
.p-traveling__catList {
  width: 98rem;
  margin: 11rem auto 0;
}
@media (--mobile) {
  .p-traveling__catList {
    width: 100%;
    margin-top: 6rem;
  }
}
.p-traveling__catList ul {
  width: 60rem;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  gap: 3rem 2.6rem;
}
@media (--mobile) {
  .p-traveling__catList ul {
    width: 30rem;
  }
}
.p-traveling__catList ul li {
  text-align: center;
}
.p-traveling__catList ul li a {
  width: 12.7rem;
  display: block;
  padding-bottom: 0.8rem;
  font-size: 1.4rem;
  transition: 0.3s;
  position: relative;
}
.p-traveling__catList ul li a::before, .p-traveling__catList ul li a::after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
}
.p-traveling__catList ul li a::before {
  height: 1px;
  width: 100%;
  background: #D0D0D0;
}
.p-traveling__catList ul li a::after {
  content: "";
  display: block;
  height: 1px;
  background-color: #171717;
  transition: width 300ms;
  width: 0;
}
@media (--large) {
  .p-traveling__catList ul li a:hover::after {
    width: 100%;
  }
}
.p-traveling__list {
  width: 118rem;
  margin: 7rem auto 0rem;
}
@media (--mobile) {
  .p-traveling__list {
    width: 100%;
  }
}
.p-traveling__list > ul {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 7rem;
  margin-bottom: 8rem;
}
.p-traveling__list > ul > li {
  width: 55rem;
  position: relative;
}
.p-traveling__list > ul > li figure {
  overflow: hidden;
  width: 100%;
  height: 36rem;
}
.p-traveling__list > ul > li figure.movies {
  position: relative;
  cursor: pointer;
}
.p-traveling__list > ul > li figure.movies:hover img {
  transform: scale(1.08);
}
.p-traveling__list > ul > li figure.movies::before {
  position: absolute;
  content: "";
  background: #000;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0.3;
  z-index: 10;
}
.p-traveling__list > ul > li figure.movies .movies-img__ttl {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 20;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.p-traveling__list > ul > li figure.movies .movies-img__ttl h3 {
  font-size: 2.4rem;
  color: #fff;
}
.p-traveling__list > ul > li figure.movies .movies-img__ttl span {
  display: block;
  width: 4.4rem;
  height: 4.4rem;
  margin-top: 2.4rem;
}
@media (--mobile) {
  .p-traveling__list > ul > li figure {
    height: 20rem;
  }
}
.p-traveling__list > ul > li figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.3s;
}
.p-traveling__list > ul > li .list-content.movies {
  display: flex;
  flex-direction: row-reverse;
  column-gap: 2rem;
  position: relative;
  align-items: baseline;
}
.p-traveling__list > ul > li .list-content.movies .list-ttl {
  margin-top: 0;
}
@media (--mobile) {
  .p-traveling__list > ul > li .list-content.movies .list-ttl {
    display: flex;
    justify-content: flex-end;
  }
}
.p-traveling__list > ul > li .list-content.movies .list-cat {
  flex: 1;
}
@media (--mobile) {
  .p-traveling__list > ul > li .list-content.movies .list-cat {
    margin-top: 1rem;
  }
}
@media (--mobile) {
  .p-traveling__list > ul > li .list-content {
    padding: 0 3rem;
  }
}
.p-traveling__list > ul > li .list-ttl {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.6rem;
}
.p-traveling__list > ul > li .list-ttl h3 {
  font-size: 1.8rem;
}
.p-traveling__list > ul > li .list-ttl a {
  font-size: 1.4rem;
  position: relative;
  padding-bottom: 0.3rem;
  z-index: 20;
}
.p-traveling__list > ul > li .list-ttl a::before, .p-traveling__list > ul > li .list-ttl a::after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
}
.p-traveling__list > ul > li .list-ttl a::before {
  height: 1px;
  width: 100%;
  background: #D0D0D0;
}
.p-traveling__list > ul > li .list-ttl a::after {
  content: "";
  display: block;
  height: 1px;
  background-color: #171717;
  transition: width 300ms;
  width: 0;
}
@media (--large) {
  .p-traveling__list > ul > li .list-ttl a:hover::after {
    width: 100%;
  }
}
.p-traveling__list > ul > li .list-cat {
  margin-top: 2rem;
  display: flex;
  column-gap: 0.8rem;
  row-gap: 2rem;
  flex-wrap: wrap;
}
.p-traveling__list > ul > li .list-cat li a {
  font-size: 1.2rem;
  padding: 0.6rem 0.8rem;
  border: 1px solid #CECECE;
  color: #707070;
  transition: 0.3s;
}
.p-traveling__list > ul > li .list-cat li a:hover {
  opacity: 0.7;
}
.p-traveling__list > ul > li .list-addr {
  font-size: 1.6rem;
  margin-top: 2.8rem;
}
.p-traveling__list > ul > li .list-txt {
  font-size: 1.6rem;
  margin-top: 1rem;
}
@media (--mobile) {
  .p-traveling__list > ul > li .list-txt {
    font-size: 1.5rem;
  }
}

.barba-leave-active,
.barba-enter-active {
  transition: opacity 450ms ease;
}

/* initial state */
.barba-leave {
  opacity: 1;
}

.barba-enter {
  opacity: 0;
}

/* ending state */
.barba-leave-to {
  opacity: 0;
}

.barba-enter-to {
  opacity: 1;
}

.c-breadcrumb {
  width: 118rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 4rem;
  margin-top: 15rem;
}
@media (--mobile) {
  .c-breadcrumb {
    width: auto;
    padding: 0 2rem;
    margin-bottom: 2rem;
    margin-top: 8rem;
  }
}
.c-breadcrumb ul {
  display: flex;
  justify-content: flex-end;
}
.c-breadcrumb ul li, .c-breadcrumb ul li a {
  font-size: 1.2rem;
  color: #898989;
}
.c-breadcrumb ul li:nth-of-type(n + 2) {
  position: relative;
  margin-left: 1rem;
  padding-left: 1rem;
}
.c-breadcrumb ul li:nth-of-type(n + 2)::after {
  position: absolute;
  content: "/";
  left: 0;
  top: 50%;
  transform: translate(-50%, -50%);
}
.c-breadcrumb ul li a {
  transition: 0.3s;
  display: inline-block;
  padding: 0;
  margin-top: -3px;
  vertical-align: middle;
  max-width: 13em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.c-breadcrumb ul li a:hover {
  color: #000;
}
.c-breadcrumb ul li span {
  display: inline-block;
  padding: 0;
  margin-top: -3px;
  vertical-align: middle;
  max-width: 8em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.c-backBtn {
  display: flex;
  justify-content: flex-end;
  flex-direction: row-reverse;
  margin-top: 6rem;
}
.c-backBtn a, .c-backBtn span {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 1.6rem;
}
.c-backBtn a svg, .c-backBtn span svg {
  width: 4.4rem;
  margin-right: 2.6rem;
  transform: rotate(180deg);
}
.c-backBtn a svg circle:first-of-type, .c-backBtn span svg circle:first-of-type {
  fill: #fff;
}
.c-backBtn a svg, .c-backBtn a svg *, .c-backBtn span svg, .c-backBtn span svg * {
  transition: 0.3s;
}
.c-backBtn a:hover svg, .c-backBtn span:hover svg {
  margin-right: 1.5rem;
}
.c-backBtn a:hover svg circle:first-of-type, .c-backBtn span:hover svg circle:first-of-type {
  fill: #171717;
}
.c-backBtn a:hover svg path, .c-backBtn span:hover svg path {
  stroke: #fff;
}

.c-moreBtn {
  display: flex;
  justify-content: flex-end;
  margin-top: 6rem;
}
@media (--mobile) {
  .c-moreBtn {
    margin-top: 4rem;
  }
}
.c-moreBtn a, .c-moreBtn span {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 1.6rem;
}
.c-moreBtn a svg, .c-moreBtn span svg {
  width: 4.4rem;
  margin-left: 2.6rem;
}
@media (--mobile) {
  .c-moreBtn a svg, .c-moreBtn span svg {
    margin-left: 1.6rem;
  }
}
.c-moreBtn a svg circle:first-of-type, .c-moreBtn span svg circle:first-of-type {
  fill: #fff;
}
.c-moreBtn a svg, .c-moreBtn a svg *, .c-moreBtn span svg, .c-moreBtn span svg * {
  transition: 0.5s;
}
.c-moreBtn a:hover svg, .c-moreBtn span:hover svg {
  margin-left: 1.5rem;
}
.c-moreBtn a:hover svg circle:first-of-type, .c-moreBtn span:hover svg circle:first-of-type {
  fill: #171717;
}
.c-moreBtn a:hover svg path, .c-moreBtn span:hover svg path {
  stroke: #fff;
}
.c-moreBtn a:hover svg g, .c-moreBtn span:hover svg g {
  stroke: #171717;
}

.c-inner {
  width: 128rem;
  margin-left: auto;
  margin-right: auto;
}
@media (--mobile) {
  .c-inner {
    width: auto;
    padding: 0 3rem;
  }
}

.c-i-inner {
  width: 118rem;
  margin-left: auto;
  margin-right: auto;
}
@media (--mobile) {
  .c-i-inner {
    width: auto;
    padding: 0 3rem;
  }
}

.land-inner {
  padding-left: 10rem;
}
@media (--mobile) {
  .land-inner {
    padding-left: 0;
  }
}

@media (--mobile) {
  .c-newsList {
    margin-top: 2rem;
  }
}
.c-newsList li {
  position: relative;
}
.c-newsList li::after, .c-newsList li::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 0;
  height: 100%;
  pointer-events: none;
  border-top: 1px solid #D0D0D0;
  border-bottom: 1px solid #D0D0D0;
  transition: 1s;
}
.c-newsList li::after {
  width: 100%;
}
.c-newsList li::before {
  border-color: #5b5b5b;
  z-index: 1;
}
.c-newsList li:hover::before {
  width: 100%;
}
.c-newsList li a {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 13.6rem;
  margin-top: -1px;
  padding: 2rem 0;
}
@media (--large) {
  .c-newsList li a div {
    display: flex;
    align-items: center;
    width: 100%;
  }
  .c-newsList li a div p {
    padding-right: 4rem;
    width: 24.6rem;
    display: flex;
    align-items: center;
  }
}
@media (--mobile) {
  .c-newsList li a {
    min-height: 11rem;
  }
  .c-newsList li a div {
    width: calc(100vw - 6rem);
  }
  .c-newsList li a p {
    position: relative;
    display: flex;
    align-items: center;
  }
  .c-newsList li a p::after {
    content: "";
    position: absolute;
    right: 2.5rem;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
    height: 1.5rem;
    width: 1.5rem;
    border-top: 1px solid #707070;
    border-right: 1px solid #707070;
    transition: 0.3s border, 0.3s right;
  }
}
@media (--large) {
  .c-newsList li a::after {
    content: "";
    position: absolute;
    right: 3rem;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
    height: 1.5rem;
    width: 1.5rem;
    border-top: 1px solid #707070;
    border-right: 1px solid #707070;
    transition: 0.5s border, 0.5s right;
  }
}
@media (--large) {
  .c-newsList li a:hover::after {
    content: "";
    border-top: 1px solid #171717;
    border-right: 1px solid #171717;
    right: 2rem;
  }
}
.c-newsList li a .year {
  writing-mode: tb;
  font-size: 1.4rem;
  margin-right: 1rem;
}
@media (--mobile) {
  .c-newsList li a .year {
    line-height: 1;
    font-size: 1.2rem;
  }
}
.c-newsList li a .date {
  font-size: 3.6rem;
  margin-right: 3rem;
}
@media (--mobile) {
  .c-newsList li a .date {
    margin-right: 2rem;
    font-size: 3rem;
  }
}
.c-newsList li a .cat {
  font-size: 1.3rem;
  width: 8rem;
  padding-right: 1rem;
}
.c-newsList li a .cat em {
  border-bottom: 0.1rem solid #C9C9C9;
}
.c-newsList li a .ttl {
  font-size: 1.5rem;
  width: calc(100% - 24.6rem);
  padding-right: 7rem;
  font-weight: lighter;
  letter-spacing: 0.2em;
}
@media (--mobile) {
  .c-newsList li a .ttl {
    width: auto;
    line-height: 1.7;
    margin-top: 1rem;
    padding-right: 0;
    font-size: 1.4rem;
  }
}

.c-pagenation {
  text-align: center;
}

.wp-pagenavi {
  display: flex;
  justify-content: center;
  font-size: 1.5rem;
  font-family: "EB Garamond", serif;
  font-weight: bold;
  margin-top: 4rem;
}
.wp-pagenavi span, .wp-pagenavi a {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.6rem;
  height: 3.6rem;
}
.wp-pagenavi span::after, .wp-pagenavi a::after {
  content: "";
  position: absolute;
  width: 2.1rem;
  height: 1px;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  background: #171717;
}
.wp-pagenavi a {
  color: #7B7B7B;
  transition: 0.3s;
}
.wp-pagenavi a::after {
  width: 0;
  transition: 0.3s;
}
.wp-pagenavi a:hover {
  color: #171717;
}
.wp-pagenavi a:hover::after {
  content: "";
  width: 2.1rem;
}
.wp-pagenavi span {
  color: #171717;
}
.wp-pagenavi span.pages {
  display: none;
}

@media (--large) {
  .c-sideImg {
    display: flex;
    align-items: center;
    width: 100vw;
    margin-left: calc(-50vw + 59rem);
  }
  .c-sideImg__reverse {
    flex-direction: row-reverse;
  }
}
@media (--large) {
  .c-sideImg div {
    width: 50%;
    height: 42.7rem;
  }
}
@media (--mobile) {
  .c-sideImg div {
    margin-left: -4rem;
  }
}
.c-sideImg div img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
@media (--large) {
  .c-sideImg figcaption {
    width: 50%;
    padding-right: calc(50vw - 59rem);
    padding-left: 12rem;
  }
}
@media (--mobile) {
  .c-sideImg figcaption {
    margin-top: 6rem;
  }
}
.c-sideImg figcaption p {
  margin-top: 3rem;
  font-size: 1.4rem;
  line-height: 2;
}
@media (--large) {
  .c-sideImg__reverse {
    flex-direction: row-reverse;
  }
  .c-sideImg__reverse figcaption {
    padding-right: 12rem;
    padding-left: calc(50vw - 49rem);
  }
}

.c-title {
  margin-top: 22rem;
  text-align: center;
  font-size: 1.5rem;
}
@media (--mobile) {
  .c-title {
    margin-top: 12rem;
    padding: 0 2rem;
  }
}
.c-title strong {
  display: block;
  font-size: 3.6rem;
  margin-top: 1.4rem;
  font-weight: normal;
  letter-spacing: 0.2em;
}
@media (--mobile) {
  .c-title strong {
    font-size: 2.7rem;
  }
}

.c-mv {
  position: relative;
  margin-top: 7rem;
  margin-bottom: 12rem;
  height: 38vw;
}
@media (--mobile) {
  .c-mv.staff {
    height: 33rem;
  }
}
@media (--mobile) {
  .c-mv {
    margin-top: 5rem;
    margin-bottom: 4rem;
    height: 20rem;
  }
}
.c-mv img {
  object-fit: cover;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.c-mv img.c-mv-feature {
  object-position: bottom;
}

.c-leftTtl small, .c-centerTtl small {
  font-size: 1.4rem;
}
@media (--mobile) {
  .c-leftTtl small, .c-centerTtl small {
    font-size: 1.5rem;
  }
}
.c-leftTtl strong, .c-centerTtl strong {
  display: block;
  margin-top: 1rem;
  font-size: 3rem;
  font-weight: normal;
  letter-spacing: 0.2em;
  line-height: 1.8;
}
@media (--mobile) {
  .c-leftTtl strong, .c-centerTtl strong {
    letter-spacing: 0.1em;
    font-size: 2rem;
  }
}
.c-leftTtl strong.ja, .c-centerTtl strong.ja {
  font-size: 2.6rem;
}
@media (--mobile) {
  .c-leftTtl strong.ja, .c-centerTtl strong.ja {
    font-size: 2.4rem;
  }
}

.c-centerTtl {
  text-align: center;
}

.c-row {
  display: flex;
}

.c-google-maps {
  position: relative;
  padding-bottom: 75%;
  height: 0;
  overflow: hidden;
}

.c-google-maps iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

.split_txt em {
  display: inline-block;
  transform: translateX(20px);
  opacity: 0;
  transition: 0.8s opacity, 0.8s transform;
}
.split_txt.is-inview em, .split_txt.is-mv em {
  opacity: 1;
  transform: translateX(0);
}
.split_txt.is-mv .p-mv__catch--top em:nth-of-type(1) {
  transition-delay: 0.1s !important;
}
.split_txt.is-mv .p-mv__catch--top em:nth-of-type(2) {
  transition-delay: 0.2s !important;
}
.split_txt.is-mv .p-mv__catch--top em:nth-of-type(3) {
  transition-delay: 0.3s !important;
}
.split_txt.is-mv .p-mv__catch--top em:nth-of-type(4) {
  transition-delay: 0.4s !important;
}
.split_txt.is-mv .p-mv__catch--top em:nth-of-type(5) {
  transition-delay: 0.5s !important;
}
.split_txt.is-mv .p-mv__catch--top em:nth-of-type(6) {
  transition-delay: 0.6s !important;
}
.split_txt.is-mv .p-mv__catch--top em:nth-of-type(7) {
  transition-delay: 0.7s !important;
}
.split_txt.is-mv .p-mv__catch--top em:nth-of-type(8) {
  transition-delay: 0.8s !important;
}
.split_txt.is-mv .p-mv__catch--top em:nth-of-type(9) {
  transition-delay: 0.9s !important;
}
.split_txt.is-mv .p-mv__catch--top em:nth-of-type(10) {
  transition-delay: 1s !important;
}
.split_txt.is-mv .p-mv__catch--top em:nth-of-type(11) {
  transition-delay: 1.1s !important;
}
.split_txt.is-mv .p-mv__catch--top em:nth-of-type(12) {
  transition-delay: 1.2s !important;
}
.split_txt.is-mv .p-mv__catch--top em:nth-of-type(13) {
  transition-delay: 1.3s !important;
}
.split_txt.is-mv .p-mv__catch--top em:nth-of-type(14) {
  transition-delay: 1.4s !important;
}
.split_txt.is-mv .p-mv__catch--top em:nth-of-type(15) {
  transition-delay: 1.5s !important;
}
.split_txt.is-mv .p-mv__catch--middle em:nth-of-type(1) {
  transition-delay: 0.8s !important;
}
.split_txt.is-mv .p-mv__catch--middle em:nth-of-type(2) {
  transition-delay: 1s !important;
}
.split_txt.is-mv .p-mv__catch--middle em:nth-of-type(3) {
  transition-delay: 1.2s !important;
}
.split_txt.is-mv .p-mv__catch--middle em:nth-of-type(4) {
  transition-delay: 1.4s !important;
}
.split_txt.is-mv .p-mv__catch--middle em:nth-of-type(5) {
  transition-delay: 1.6s !important;
}
.split_txt.is-mv .p-mv__catch--middle em:nth-of-type(6) {
  transition-delay: 1.8s !important;
}
.split_txt.is-mv .p-mv__catch--middle em:nth-of-type(7) {
  transition-delay: 2s !important;
}
.split_txt.is-mv .p-mv__catch--middle em:nth-of-type(8) {
  transition-delay: 2.2s !important;
}
.split_txt.is-mv .p-mv__catch--middle em:nth-of-type(9) {
  transition-delay: 2.4s !important;
}
.split_txt.is-mv .p-mv__catch--middle em:nth-of-type(10) {
  transition-delay: 2.6s !important;
}
.split_txt.is-mv .p-mv__catch--middle em:nth-of-type(11) {
  transition-delay: 2.8s !important;
}
.split_txt.is-mv .p-mv__catch--middle em:nth-of-type(12) {
  transition-delay: 3s !important;
}
.split_txt.is-mv .p-mv__catch--middle em:nth-of-type(13) {
  transition-delay: 3.2s !important;
}
.split_txt.is-mv .p-mv__catch--middle em:nth-of-type(14) {
  transition-delay: 3.4s !important;
}
.split_txt.is-mv .p-mv__catch--middle em:nth-of-type(15) {
  transition-delay: 3.6s !important;
}
.split_txt.is-mv .p-mv__catch--middle small em:first-of-type {
  transition-delay: 1.3s !important;
}
.split_txt.is-mv .p-mv__catch--bottom em:nth-of-type(1) {
  transition-delay: 1.63s !important;
}
.split_txt.is-mv .p-mv__catch--bottom em:nth-of-type(2) {
  transition-delay: 1.66s !important;
}
.split_txt.is-mv .p-mv__catch--bottom em:nth-of-type(3) {
  transition-delay: 1.69s !important;
}
.split_txt.is-mv .p-mv__catch--bottom em:nth-of-type(4) {
  transition-delay: 1.72s !important;
}
.split_txt.is-mv .p-mv__catch--bottom em:nth-of-type(5) {
  transition-delay: 1.75s !important;
}
.split_txt.is-mv .p-mv__catch--bottom em:nth-of-type(6) {
  transition-delay: 1.78s !important;
}
.split_txt.is-mv .p-mv__catch--bottom em:nth-of-type(7) {
  transition-delay: 1.81s !important;
}
.split_txt.is-mv .p-mv__catch--bottom em:nth-of-type(8) {
  transition-delay: 1.84s !important;
}
.split_txt.is-mv .p-mv__catch--bottom em:nth-of-type(9) {
  transition-delay: 1.87s !important;
}
.split_txt.is-mv .p-mv__catch--bottom em:nth-of-type(10) {
  transition-delay: 1.9s !important;
}
.split_txt.is-mv .p-mv__catch--bottom em:nth-of-type(11) {
  transition-delay: 1.93s !important;
}
.split_txt.is-mv .p-mv__catch--bottom em:nth-of-type(12) {
  transition-delay: 1.96s !important;
}
.split_txt.is-mv .p-mv__catch--bottom em:nth-of-type(13) {
  transition-delay: 1.99s !important;
}
.split_txt.is-mv .p-mv__catch--bottom em:nth-of-type(14) {
  transition-delay: 2.02s !important;
}
.split_txt.is-mv .p-mv__catch--bottom em:nth-of-type(15) {
  transition-delay: 2.05s !important;
}

.c-marquee {
  animation-name: animationMarquee;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-duration: 90s;
  align-items: center;
  display: flex;
}
@media (--mobile) {
  .c-marquee {
    animation-duration: 60s;
  }
}
.c-marquee li {
  white-space: nowrap;
}

.specialFeature {
  font-family: "Shippori Mincho B1", serif;
  font-size: 1.6rem;
  /* ==========================================================================
    ボタンパーツ
  ========================================================================== */
  /* ==========================================================================
    画像スタイル調整
  ========================================================================== */
  /* ==========================================================================
    インナーパーツ
  ========================================================================== */
  /* ==========================================================================
    テキストエア
  ========================================================================== */
  /* ==========================================================================
    リストパーツ
  ========================================================================== */
  /* ==========================================================================
    ボーダー
  ========================================================================== */
  /* ==========================================================================
    幅
  ========================================================================== */
  /* ==========================================================================
    余白パーツ
  ========================================================================== */
  /* Top margin */
  /* Right margin */
  /* Bottom margin */
  /* Left margin */
  /* ==========================================================================
    padding
  ========================================================================== */
  /* Top padding */
  /* Right padding */
  /* Bottom padding */
  /* Left padding */
}
@media (--mobile) {
  .specialFeature {
    font-size: 1.4rem;
  }
}
.specialFeature p {
  font-weight: 400;
  letter-spacing: 0.1em;
}
.specialFeature .parts-ttl {
  font-size: 2rem;
  padding: 1rem 2rem;
  border: 1px solid #000;
  margin-bottom: 5rem;
  display: block;
  text-align: center;
}
.specialFeature .ttl01 {
  text-align: center;
  font-size: 3.6rem;
  font-weight: 400;
}
@media (--mobile) {
  .specialFeature .ttl01 {
    font-size: 2.4rem;
  }
}
.specialFeature .ttl01 small {
  font-size: 1.4rem;
  display: block;
  font-family: "EB Garamond", serif;
  font-weight: 400;
}
@media (--mobile) {
  .specialFeature .ttl01 small {
    font-size: 1.2rem;
  }
}
.specialFeature .ttl02 {
  font-size: 3rem;
  font-weight: 400;
}
@media (--mobile) {
  .specialFeature .ttl02 {
    font-size: 2rem;
  }
}
.specialFeature .ttl02 small {
  margin-bottom: 2rem;
  font-size: 1.4rem;
  display: block;
  font-family: "EB Garamond", serif;
  font-weight: 400;
}
@media (--mobile) {
  .specialFeature .ttl02 small {
    font-size: 1.2rem;
  }
}
.specialFeature .ttl03 {
  font-size: 2.6rem;
  font-weight: 400;
}
@media (--mobile) {
  .specialFeature .ttl03 {
    font-size: 2rem;
  }
}
.specialFeature .ttl04 {
  font-size: 2.6rem;
  font-weight: 400;
  text-align: center;
}
@media (--mobile) {
  .specialFeature .ttl04 {
    font-size: 2rem;
  }
}
.specialFeature .ttl04 small {
  margin-bottom: 1.2rem;
  font-size: 1.4rem;
  display: block;
  font-family: "EB Garamond", serif;
  font-weight: 400;
}
.specialFeature .ttl05 {
  font-size: 2.6rem;
  font-weight: 400;
}
@media (--mobile) {
  .specialFeature .ttl05 {
    font-size: 2rem;
  }
}
.specialFeature .ttl05 small {
  margin-bottom: 1.2rem;
  font-size: 1.2rem;
  display: block;
  font-family: "EB Garamond", serif;
  font-weight: 400;
}
.specialFeature .ttl06 {
  text-align: center;
  font-size: 3.8rem;
  font-weight: 400;
}
@media (--mobile) {
  .specialFeature .ttl06 {
    font-size: 2.5rem;
  }
}
.specialFeature .ttl06 small {
  margin-bottom: 1.2rem;
  font-size: 2rem;
  display: block;
  font-weight: 400;
}
@media (--mobile) {
  .specialFeature .ttl06 small {
    font-size: 1.4rem;
    margin-bottom: 0;
  }
}
.specialFeature .ttl07 {
  font-size: 2rem;
}
.specialFeature .ttl08 {
  text-align: center;
  font-size: 2.6rem;
  font-weight: 400;
  margin-bottom: 9rem;
}
@media (--mobile) {
  .specialFeature .ttl08 {
    margin-bottom: 5rem;
  }
}
.specialFeature .ttl08 span {
  font-size: 1.4rem;
  font-family: "EB Garamond", serif;
  display: block;
}
.specialFeature .ttl08 small {
  font-size: 1.8rem;
  display: block;
  margin-top: 2rem;
  margin-bottom: 1rem;
}
@media (--mobile) {
  .specialFeature .ttl08 small {
    margin-bottom: 0;
    margin-top: 1rem;
  }
}
.specialFeature .btn {
  font-size: 1.8rem;
  font-weight: 400;
  border: 2px solid #000 !important;
  padding: 1.3rem 0;
  text-align: center;
  display: block;
  color: #000 !important;
  transition: 0.3s;
}
@media (--large) {
  .specialFeature .btn:hover {
    background-color: #000;
    color: #fff !important;
  }
}
@media (--mobile) {
  .specialFeature .btn {
    width: 100% !important;
  }
}
.specialFeature .btn.center {
  margin-left: auto;
  margin-right: auto;
}
.specialFeature .img {
  width: 100%;
  position: relative;
}
.specialFeature .img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.specialFeature .img-side {
  position: absolute;
  top: 0;
  right: -3rem;
  writing-mode: vertical-rl;
  color: #898989;
  font-family: "EB Garamond", serif;
}
@media (--mobile) {
  .specialFeature .img-side {
    right: -2rem;
  }
}
.specialFeature .inner01 {
  width: 98rem;
  margin: 0 auto;
}
@media (--mobile) {
  .specialFeature .inner01 {
    width: auto;
    padding: 0 3rem;
  }
}
.specialFeature .inner02 {
  width: 118rem;
  margin: 0 auto;
}
@media (--mobile) {
  .specialFeature .inner02 {
    width: auto;
    padding: 0 3rem;
  }
}
.specialFeature .inner02.border {
  position: relative;
}
.specialFeature .inner02.border::before {
  position: absolute;
  content: "";
  width: 118rem;
  height: 0.1rem;
  background-color: #D0D0D0;
  transform: translateX(-10rem);
  left: 0;
  top: 0;
}
@media (--mobile) {
  .specialFeature .inner02.border::before {
    width: 100%;
    transform: translateX(0);
  }
}
@media (--mobile) {
  .specialFeature .inner-flex__inner:nth-child(n+2) {
    margin-top: 6rem;
  }
}
.specialFeature .inner-flex {
  display: flex;
  justify-content: space-between;
}
.specialFeature .inner-flex.wrap {
  flex-wrap: wrap;
}
@media (--mobile) {
  .specialFeature .inner-flex {
    flex-direction: column;
    justify-content: center;
  }
}
.specialFeature .inner-flex.border {
  position: relative;
}
.specialFeature .inner-flex.border::before, .specialFeature .inner-flex.border::after {
  position: absolute;
  content: "";
  width: 118rem;
  height: 0.1rem;
  background-color: #D0D0D0;
  transform: translateX(-10rem);
  left: 0;
}
@media (--mobile) {
  .specialFeature .inner-flex.border::before, .specialFeature .inner-flex.border::after {
    width: 120%;
    transform: translateX(-3.1rem);
  }
}
.specialFeature .inner-flex.border::before {
  top: 0;
}
.specialFeature .inner-flex.border:after {
  bottom: 0;
}
.specialFeature .inner-flex__item {
  width: 54rem;
  position: relative;
}
@media (--large) {
  .specialFeature .inner-flex__item:hover img {
    transform: scale(1.1);
  }
}
@media (--mobile) {
  .specialFeature .inner-flex__item {
    width: 100%;
  }
  .specialFeature .inner-flex__item:nth-child(n+2) {
    margin-top: 4rem !important;
  }
}
.specialFeature .inner-flex__item:nth-child(n+3) {
  margin-top: 6rem;
}
@media (--mobile) {
  .specialFeature .inner-flex__item:nth-child(n+3) {
    margin-top: 0;
  }
}
.specialFeature .inner-flex__item .img {
  height: 36rem;
  overflow: hidden;
}
@media (--mobile) {
  .specialFeature .inner-flex__item .img {
    height: auto;
  }
}
.specialFeature .inner-flex__item .img img {
  transition: 0.3s;
}
.specialFeature .inner-flex__item .name {
  font-size: 1.8rem;
  margin-top: 2.6rem;
  margin-bottom: 1.5rem;
}
.specialFeature .inner-flex__item .tag {
  display: flex;
  flex-wrap: wrap;
}
.specialFeature .inner-flex__item .tag a {
  font-size: 1.2rem;
  color: #707070;
  padding: 0.4rem 0.8rem;
  border: 1px solid #707070;
  display: block;
  margin-right: 0.8rem;
  margin-bottom: 1rem;
  position: relative;
  z-index: 10;
  transition: 0.3s;
}
.specialFeature .inner-flex__item .tag a:hover {
  color: #fff;
  background-color: #7B7B7B;
}
.specialFeature .inner-flex__item .link {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.specialFeature .inner-flex.mt {
  margin-top: 8rem;
}
@media (--mobile) {
  .specialFeature .inner-flex.mt {
    margin-top: 4rem !important;
  }
}
.specialFeature .textArea01 {
  margin-top: 5rem;
  margin-bottom: 5rem;
}
@media (--mobile) {
  .specialFeature .textArea01 {
    margin-top: 3rem;
    margin-bottom: 6rem;
  }
}
.specialFeature .list01 li {
  font-size: 1.6rem;
  padding-bottom: 1.3rem;
  border-bottom: 1px solid #D0D0D0;
}
.specialFeature .list01 li:nth-child(n+2) {
  margin-top: 2.7rem;
  font-size: 1.4rem;
}
.specialFeature .list01 li span {
  margin-right: 2rem;
  font-family: "EB Garamond", serif;
}
@media (--mobile) {
  .specialFeature .list01 li span {
    margin-right: 1rem;
  }
}
.specialFeature .list02 {
  position: relative;
}
.specialFeature .list02 li {
  display: flex;
  position: relative;
}
.specialFeature .list02 li.free::before {
  letter-spacing: 0;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  position: absolute;
  content: "無料";
  background-color: #171717;
  color: #FFF;
  font-size: 1.2rem;
  left: -50px;
  top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (--mobile) {
  .specialFeature .list02 li.free::before {
    top: 6.6rem;
    z-index: 4;
    left: -0.1rem;
    z-index: 4;
  }
}
.specialFeature .list02-step {
  margin-right: 4rem;
  font-size: 1.8rem;
  font-family: "EB Garamond", serif;
  font-weight: Regular;
  position: relative;
}
.specialFeature .list02-step div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) translateY(3rem);
  white-space: nowrap;
  font-size: 1.4rem;
  text-align: center;
  border: 1px solid #CECECE;
  padding: 0.8rem 1.5rem;
  background-color: #FFF;
  z-index: 2;
  color: #707070;
}
.specialFeature .list02-step div.first {
  transform: translate(-50%, -50%) translateY(-9rem);
}
@media (--mobile) {
  .specialFeature .list02-step div.first {
    transform: translate(-50%, -50%) translateY(-6rem);
  }
}
.specialFeature .list02-step div.second {
  transform: translate(-50%, -50%) translateY(9rem);
}
@media (--mobile) {
  .specialFeature .list02-step div {
    writing-mode: vertical-rl;
    transform: translate(-50%, -50%) translateY(3rem);
    padding: 0.8rem 0.5rem;
    font-size: 1.2rem;
  }
}
.specialFeature .list02-step div span {
  font-weight: bold;
}
.specialFeature .list02-step div span.num {
  font-weight: normal;
}
@media (--mobile) {
  .specialFeature .list02-step div span.num {
    text-combine-upright: all;
  }
}
@media (--mobile) {
  .specialFeature .list02-step {
    margin-right: 2rem;
  }
}
.specialFeature .list02-step p {
  background-color: #FFFFFF;
  z-index: 2;
  position: relative;
  padding: 1.7rem 0;
}
@media (--mobile) {
  .specialFeature .list02-step p {
    padding: 0;
    padding-bottom: 0.5rem;
    font-size: 1.2rem;
    text-align: center;
    margin-left: 0.3rem;
  }
}
.specialFeature .list02-content {
  width: 70rem;
  padding-top: 1.7rem;
  padding-bottom: 5rem;
}
.specialFeature .list02-content.flex {
  display: flex;
}
@media (--mobile) {
  .specialFeature .list02-content.flex {
    display: block;
  }
}
@media (--mobile) {
  .specialFeature .list02-content {
    width: 100%;
    padding-top: 0;
    padding-bottom: 5rem;
  }
  .specialFeature .list02-content.pb-0 {
    padding-bottom: 0;
  }
}
.specialFeature .list02-ttl {
  font-size: 2rem;
  color: #171717;
  font-weight: bold;
  margin-bottom: 1.5rem;
}
@media (--mobile) {
  .specialFeature .list02-ttl {
    margin-bottom: 2rem;
  }
}
.specialFeature .list02-ttl.last {
  padding-top: 1.5rem;
}
.specialFeature .list02-ttl span {
  display: inline-block;
  font-size: 1.2rem;
  font-weight: normal;
  background-color: #EAEAEA;
  padding: 0.5rem;
  margin-top: 1rem;
}
@media (--mobile) {
  .specialFeature .list02-ttl span {
    font-size: 1.3rem;
  }
}
.specialFeature .list02-more {
  font-size: 1.6rem;
  padding: 1rem;
  border: 0.1rem solid #D0D0D0;
  position: relative;
}
.specialFeature .list02-txt {
  font-size: 1.5rem;
  line-height: 3.2rem;
}
@media (--mobile) {
  .specialFeature .list02-txt {
    margin-top: 1rem;
    display: none;
  }
}
@media (--large) {
  .specialFeature .list02-txt img {
    display: none;
  }
}
.specialFeature .list02-txt p a {
  border-bottom: 1px solid #000;
}
.specialFeature .list02::after {
  content: "";
  display: inline-block;
  width: 1px;
  height: 100%;
  background-color: #D0D0D0;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, -50%) translateX(-46rem);
}
@media (--mobile) {
  .specialFeature .list02::after {
    transform: translate(-50%, -50%) translateX(-14rem);
  }
}
.specialFeature .list02.w-85::after {
  height: 85%;
}
.specialFeature .list03 {
  margin-top: 11rem;
}
@media (--mobile) {
  .specialFeature .list03 {
    margin-top: 5rem;
  }
}
.specialFeature .list03 li:nth-child(n+2) {
  margin-top: 8.3rem;
}
@media (--mobile) {
  .specialFeature .list03 li:nth-child(n+2) {
    margin-top: 4rem;
  }
}
.specialFeature .list03 li .list03-ttl {
  font-size: 2rem;
  display: flex;
  align-items: center;
  margin-bottom: 2.8rem;
}
.specialFeature .list03 li .list03-ttl span {
  margin-right: 1rem;
  font-size: 1.8rem;
  font-family: "EB Garamond", serif;
}
@media (--mobile) {
  .specialFeature .list03 li .list03-ttl.column_sp {
    text-align: center;
    flex-direction: column;
  }
}
.specialFeature .border {
  position: relative;
}
.specialFeature .border::before {
  position: absolute;
  content: "";
  width: 118rem;
  height: 0.1rem;
  background-color: #D0D0D0;
  transform: translateX(-10rem);
  left: 0;
  top: 0;
}
@media (--mobile) {
  .specialFeature .border::before {
    width: 100%;
    transform: translateX(0);
  }
}
.specialFeature .border::after {
  position: absolute;
  content: "";
  width: 118rem;
  height: 0.1rem;
  background-color: #D0D0D0;
  transform: translateX(-10rem);
  left: 0;
  bottom: 0;
}
@media (--mobile) {
  .specialFeature .border::after {
    width: 100%;
    transform: translateX(0);
  }
}
.specialFeature .border-top {
  position: relative;
}
.specialFeature .border-top::before {
  position: absolute;
  content: "";
  width: 118rem;
  height: 0.1rem;
  background-color: #D0D0D0;
  transform: translateX(-10rem);
  left: 0;
  top: 0;
}
@media (--mobile) {
  .specialFeature .border-top::before {
    width: 100%;
    transform: translateX(0);
  }
}
.specialFeature .u-w-all {
  width: 100% !important;
}
.specialFeature .u-w-0 {
  width: 0rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-0 {
    width: 100% !important;
  }
}
.specialFeature .u-w-1 {
  width: 1rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-1 {
    width: 100% !important;
  }
}
.specialFeature .u-w-2 {
  width: 2rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-2 {
    width: 100% !important;
  }
}
.specialFeature .u-w-3 {
  width: 3rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-3 {
    width: 100% !important;
  }
}
.specialFeature .u-w-4 {
  width: 4rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-4 {
    width: 100% !important;
  }
}
.specialFeature .u-w-5 {
  width: 5rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-5 {
    width: 100% !important;
  }
}
.specialFeature .u-w-6 {
  width: 6rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-6 {
    width: 100% !important;
  }
}
.specialFeature .u-w-7 {
  width: 7rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-7 {
    width: 100% !important;
  }
}
.specialFeature .u-w-8 {
  width: 8rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-8 {
    width: 100% !important;
  }
}
.specialFeature .u-w-9 {
  width: 9rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-9 {
    width: 100% !important;
  }
}
.specialFeature .u-w-10 {
  width: 10rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-10 {
    width: 100% !important;
  }
}
.specialFeature .u-w-11 {
  width: 11rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-11 {
    width: 100% !important;
  }
}
.specialFeature .u-w-12 {
  width: 12rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-12 {
    width: 100% !important;
  }
}
.specialFeature .u-w-13 {
  width: 13rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-13 {
    width: 100% !important;
  }
}
.specialFeature .u-w-14 {
  width: 14rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-14 {
    width: 100% !important;
  }
}
.specialFeature .u-w-15 {
  width: 15rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-15 {
    width: 100% !important;
  }
}
.specialFeature .u-w-16 {
  width: 16rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-16 {
    width: 100% !important;
  }
}
.specialFeature .u-w-17 {
  width: 17rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-17 {
    width: 100% !important;
  }
}
.specialFeature .u-w-18 {
  width: 18rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-18 {
    width: 100% !important;
  }
}
.specialFeature .u-w-19 {
  width: 19rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-19 {
    width: 100% !important;
  }
}
.specialFeature .u-w-20 {
  width: 20rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-20 {
    width: 100% !important;
  }
}
.specialFeature .u-w-21 {
  width: 21rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-21 {
    width: 100% !important;
  }
}
.specialFeature .u-w-22 {
  width: 22rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-22 {
    width: 100% !important;
  }
}
.specialFeature .u-w-23 {
  width: 23rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-23 {
    width: 100% !important;
  }
}
.specialFeature .u-w-24 {
  width: 24rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-24 {
    width: 100% !important;
  }
}
.specialFeature .u-w-25 {
  width: 25rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-25 {
    width: 100% !important;
  }
}
.specialFeature .u-w-26 {
  width: 26rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-26 {
    width: 100% !important;
  }
}
.specialFeature .u-w-27 {
  width: 27rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-27 {
    width: 100% !important;
  }
}
.specialFeature .u-w-28 {
  width: 28rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-28 {
    width: 100% !important;
  }
}
.specialFeature .u-w-29 {
  width: 29rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-29 {
    width: 100% !important;
  }
}
.specialFeature .u-w-30 {
  width: 30rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-30 {
    width: 100% !important;
  }
}
.specialFeature .u-w-31 {
  width: 31rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-31 {
    width: 100% !important;
  }
}
.specialFeature .u-w-32 {
  width: 32rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-32 {
    width: 100% !important;
  }
}
.specialFeature .u-w-33 {
  width: 33rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-33 {
    width: 100% !important;
  }
}
.specialFeature .u-w-34 {
  width: 34rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-34 {
    width: 100% !important;
  }
}
.specialFeature .u-w-35 {
  width: 35rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-35 {
    width: 100% !important;
  }
}
.specialFeature .u-w-36 {
  width: 36rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-36 {
    width: 100% !important;
  }
}
.specialFeature .u-w-37 {
  width: 37rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-37 {
    width: 100% !important;
  }
}
.specialFeature .u-w-38 {
  width: 38rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-38 {
    width: 100% !important;
  }
}
.specialFeature .u-w-39 {
  width: 39rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-39 {
    width: 100% !important;
  }
}
.specialFeature .u-w-40 {
  width: 40rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-40 {
    width: 100% !important;
  }
}
.specialFeature .u-w-41 {
  width: 41rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-41 {
    width: 100% !important;
  }
}
.specialFeature .u-w-42 {
  width: 42rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-42 {
    width: 100% !important;
  }
}
.specialFeature .u-w-43 {
  width: 43rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-43 {
    width: 100% !important;
  }
}
.specialFeature .u-w-44 {
  width: 44rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-44 {
    width: 100% !important;
  }
}
.specialFeature .u-w-45 {
  width: 45rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-45 {
    width: 100% !important;
  }
}
.specialFeature .u-w-46 {
  width: 46rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-46 {
    width: 100% !important;
  }
}
.specialFeature .u-w-47 {
  width: 47rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-47 {
    width: 100% !important;
  }
}
.specialFeature .u-w-48 {
  width: 48rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-48 {
    width: 100% !important;
  }
}
.specialFeature .u-w-49 {
  width: 49rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-49 {
    width: 100% !important;
  }
}
.specialFeature .u-w-50 {
  width: 50rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-50 {
    width: 100% !important;
  }
}
.specialFeature .u-w-51 {
  width: 51rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-51 {
    width: 100% !important;
  }
}
.specialFeature .u-w-52 {
  width: 52rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-52 {
    width: 100% !important;
  }
}
.specialFeature .u-w-53 {
  width: 53rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-53 {
    width: 100% !important;
  }
}
.specialFeature .u-w-54 {
  width: 54rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-54 {
    width: 100% !important;
  }
}
.specialFeature .u-w-55 {
  width: 55rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-55 {
    width: 100% !important;
  }
}
.specialFeature .u-w-56 {
  width: 56rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-56 {
    width: 100% !important;
  }
}
.specialFeature .u-w-57 {
  width: 57rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-57 {
    width: 100% !important;
  }
}
.specialFeature .u-w-58 {
  width: 58rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-58 {
    width: 100% !important;
  }
}
.specialFeature .u-w-59 {
  width: 59rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-59 {
    width: 100% !important;
  }
}
.specialFeature .u-w-60 {
  width: 60rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-60 {
    width: 100% !important;
  }
}
.specialFeature .u-w-61 {
  width: 61rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-61 {
    width: 100% !important;
  }
}
.specialFeature .u-w-62 {
  width: 62rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-62 {
    width: 100% !important;
  }
}
.specialFeature .u-w-63 {
  width: 63rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-63 {
    width: 100% !important;
  }
}
.specialFeature .u-w-64 {
  width: 64rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-64 {
    width: 100% !important;
  }
}
.specialFeature .u-w-65 {
  width: 65rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-65 {
    width: 100% !important;
  }
}
.specialFeature .u-w-66 {
  width: 66rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-66 {
    width: 100% !important;
  }
}
.specialFeature .u-w-67 {
  width: 67rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-67 {
    width: 100% !important;
  }
}
.specialFeature .u-w-68 {
  width: 68rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-68 {
    width: 100% !important;
  }
}
.specialFeature .u-w-69 {
  width: 69rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-69 {
    width: 100% !important;
  }
}
.specialFeature .u-w-70 {
  width: 70rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-70 {
    width: 100% !important;
  }
}
.specialFeature .u-w-71 {
  width: 71rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-71 {
    width: 100% !important;
  }
}
.specialFeature .u-w-72 {
  width: 72rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-72 {
    width: 100% !important;
  }
}
.specialFeature .u-w-73 {
  width: 73rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-73 {
    width: 100% !important;
  }
}
.specialFeature .u-w-74 {
  width: 74rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-74 {
    width: 100% !important;
  }
}
.specialFeature .u-w-75 {
  width: 75rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-75 {
    width: 100% !important;
  }
}
.specialFeature .u-w-76 {
  width: 76rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-76 {
    width: 100% !important;
  }
}
.specialFeature .u-w-77 {
  width: 77rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-77 {
    width: 100% !important;
  }
}
.specialFeature .u-w-78 {
  width: 78rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-78 {
    width: 100% !important;
  }
}
.specialFeature .u-w-79 {
  width: 79rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-79 {
    width: 100% !important;
  }
}
.specialFeature .u-w-80 {
  width: 80rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-80 {
    width: 100% !important;
  }
}
.specialFeature .u-w-81 {
  width: 81rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-81 {
    width: 100% !important;
  }
}
.specialFeature .u-w-82 {
  width: 82rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-82 {
    width: 100% !important;
  }
}
.specialFeature .u-w-83 {
  width: 83rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-83 {
    width: 100% !important;
  }
}
.specialFeature .u-w-84 {
  width: 84rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-84 {
    width: 100% !important;
  }
}
.specialFeature .u-w-85 {
  width: 85rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-85 {
    width: 100% !important;
  }
}
.specialFeature .u-w-86 {
  width: 86rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-86 {
    width: 100% !important;
  }
}
.specialFeature .u-w-87 {
  width: 87rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-87 {
    width: 100% !important;
  }
}
.specialFeature .u-w-88 {
  width: 88rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-88 {
    width: 100% !important;
  }
}
.specialFeature .u-w-89 {
  width: 89rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-89 {
    width: 100% !important;
  }
}
.specialFeature .u-w-90 {
  width: 90rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-90 {
    width: 100% !important;
  }
}
.specialFeature .u-w-91 {
  width: 91rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-91 {
    width: 100% !important;
  }
}
.specialFeature .u-w-92 {
  width: 92rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-92 {
    width: 100% !important;
  }
}
.specialFeature .u-w-93 {
  width: 93rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-93 {
    width: 100% !important;
  }
}
.specialFeature .u-w-94 {
  width: 94rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-94 {
    width: 100% !important;
  }
}
.specialFeature .u-w-95 {
  width: 95rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-95 {
    width: 100% !important;
  }
}
.specialFeature .u-w-96 {
  width: 96rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-96 {
    width: 100% !important;
  }
}
.specialFeature .u-w-97 {
  width: 97rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-97 {
    width: 100% !important;
  }
}
.specialFeature .u-w-98 {
  width: 98rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-98 {
    width: 100% !important;
  }
}
.specialFeature .u-w-99 {
  width: 99rem !important;
}
@media (--mobile) {
  .specialFeature .u-w-99 {
    width: 100% !important;
  }
}
@media (--mobile) {
  .specialFeature .u-w-sp-all {
    width: 100% !important;
  }
  .specialFeature .u-w-sp-0 {
    width: 0rem !important;
  }
  .specialFeature .u-w-sp-1 {
    width: 1rem !important;
  }
  .specialFeature .u-w-sp-2 {
    width: 2rem !important;
  }
  .specialFeature .u-w-sp-3 {
    width: 3rem !important;
  }
  .specialFeature .u-w-sp-4 {
    width: 4rem !important;
  }
  .specialFeature .u-w-sp-5 {
    width: 5rem !important;
  }
  .specialFeature .u-w-sp-6 {
    width: 6rem !important;
  }
  .specialFeature .u-w-sp-7 {
    width: 7rem !important;
  }
  .specialFeature .u-w-sp-8 {
    width: 8rem !important;
  }
  .specialFeature .u-w-sp-9 {
    width: 9rem !important;
  }
  .specialFeature .u-w-sp-10 {
    width: 10rem !important;
  }
  .specialFeature .u-w-sp-11 {
    width: 11rem !important;
  }
  .specialFeature .u-w-sp-12 {
    width: 12rem !important;
  }
  .specialFeature .u-w-sp-13 {
    width: 13rem !important;
  }
  .specialFeature .u-w-sp-14 {
    width: 14rem !important;
  }
  .specialFeature .u-w-sp-15 {
    width: 15rem !important;
  }
  .specialFeature .u-w-sp-16 {
    width: 16rem !important;
  }
  .specialFeature .u-w-sp-17 {
    width: 17rem !important;
  }
  .specialFeature .u-w-sp-18 {
    width: 18rem !important;
  }
  .specialFeature .u-w-sp-19 {
    width: 19rem !important;
  }
  .specialFeature .u-w-sp-20 {
    width: 20rem !important;
  }
  .specialFeature .u-w-sp-21 {
    width: 21rem !important;
  }
  .specialFeature .u-w-sp-22 {
    width: 22rem !important;
  }
  .specialFeature .u-w-sp-23 {
    width: 23rem !important;
  }
  .specialFeature .u-w-sp-24 {
    width: 24rem !important;
  }
  .specialFeature .u-w-sp-25 {
    width: 25rem !important;
  }
  .specialFeature .u-w-sp-26 {
    width: 26rem !important;
  }
  .specialFeature .u-w-sp-27 {
    width: 27rem !important;
  }
  .specialFeature .u-w-sp-28 {
    width: 28rem !important;
  }
  .specialFeature .u-w-sp-29 {
    width: 29rem !important;
  }
  .specialFeature .u-w-sp-30 {
    width: 30rem !important;
  }
  .specialFeature .u-w-sp-31 {
    width: 31rem !important;
  }
  .specialFeature .u-w-sp-32 {
    width: 32rem !important;
  }
  .specialFeature .u-w-sp-33 {
    width: 33rem !important;
  }
  .specialFeature .u-w-sp-34 {
    width: 34rem !important;
  }
  .specialFeature .u-w-sp-35 {
    width: 35rem !important;
  }
  .specialFeature .u-w-sp-36 {
    width: 36rem !important;
  }
  .specialFeature .u-w-sp-37 {
    width: 37rem !important;
  }
  .specialFeature .u-w-sp-38 {
    width: 38rem !important;
  }
  .specialFeature .u-w-sp-39 {
    width: 39rem !important;
  }
  .specialFeature .u-w-sp-40 {
    width: 40rem !important;
  }
  .specialFeature .u-w-sp-41 {
    width: 41rem !important;
  }
  .specialFeature .u-w-sp-42 {
    width: 42rem !important;
  }
  .specialFeature .u-w-sp-43 {
    width: 43rem !important;
  }
  .specialFeature .u-w-sp-44 {
    width: 44rem !important;
  }
  .specialFeature .u-w-sp-45 {
    width: 45rem !important;
  }
  .specialFeature .u-w-sp-46 {
    width: 46rem !important;
  }
  .specialFeature .u-w-sp-47 {
    width: 47rem !important;
  }
  .specialFeature .u-w-sp-48 {
    width: 48rem !important;
  }
  .specialFeature .u-w-sp-49 {
    width: 49rem !important;
  }
  .specialFeature .u-w-sp-50 {
    width: 50rem !important;
  }
  .specialFeature .u-w-sp-51 {
    width: 51rem !important;
  }
  .specialFeature .u-w-sp-52 {
    width: 52rem !important;
  }
  .specialFeature .u-w-sp-53 {
    width: 53rem !important;
  }
  .specialFeature .u-w-sp-54 {
    width: 54rem !important;
  }
  .specialFeature .u-w-sp-55 {
    width: 55rem !important;
  }
  .specialFeature .u-w-sp-56 {
    width: 56rem !important;
  }
  .specialFeature .u-w-sp-57 {
    width: 57rem !important;
  }
  .specialFeature .u-w-sp-58 {
    width: 58rem !important;
  }
  .specialFeature .u-w-sp-59 {
    width: 59rem !important;
  }
  .specialFeature .u-w-sp-60 {
    width: 60rem !important;
  }
  .specialFeature .u-w-sp-61 {
    width: 61rem !important;
  }
  .specialFeature .u-w-sp-62 {
    width: 62rem !important;
  }
  .specialFeature .u-w-sp-63 {
    width: 63rem !important;
  }
  .specialFeature .u-w-sp-64 {
    width: 64rem !important;
  }
  .specialFeature .u-w-sp-65 {
    width: 65rem !important;
  }
  .specialFeature .u-w-sp-66 {
    width: 66rem !important;
  }
  .specialFeature .u-w-sp-67 {
    width: 67rem !important;
  }
  .specialFeature .u-w-sp-68 {
    width: 68rem !important;
  }
  .specialFeature .u-w-sp-69 {
    width: 69rem !important;
  }
  .specialFeature .u-w-sp-70 {
    width: 70rem !important;
  }
  .specialFeature .u-w-sp-71 {
    width: 71rem !important;
  }
  .specialFeature .u-w-sp-72 {
    width: 72rem !important;
  }
  .specialFeature .u-w-sp-73 {
    width: 73rem !important;
  }
  .specialFeature .u-w-sp-74 {
    width: 74rem !important;
  }
  .specialFeature .u-w-sp-75 {
    width: 75rem !important;
  }
  .specialFeature .u-w-sp-76 {
    width: 76rem !important;
  }
  .specialFeature .u-w-sp-77 {
    width: 77rem !important;
  }
  .specialFeature .u-w-sp-78 {
    width: 78rem !important;
  }
  .specialFeature .u-w-sp-79 {
    width: 79rem !important;
  }
  .specialFeature .u-w-sp-80 {
    width: 80rem !important;
  }
  .specialFeature .u-w-sp-81 {
    width: 81rem !important;
  }
  .specialFeature .u-w-sp-82 {
    width: 82rem !important;
  }
  .specialFeature .u-w-sp-83 {
    width: 83rem !important;
  }
  .specialFeature .u-w-sp-84 {
    width: 84rem !important;
  }
  .specialFeature .u-w-sp-85 {
    width: 85rem !important;
  }
  .specialFeature .u-w-sp-86 {
    width: 86rem !important;
  }
  .specialFeature .u-w-sp-87 {
    width: 87rem !important;
  }
  .specialFeature .u-w-sp-88 {
    width: 88rem !important;
  }
  .specialFeature .u-w-sp-89 {
    width: 89rem !important;
  }
  .specialFeature .u-w-sp-90 {
    width: 90rem !important;
  }
  .specialFeature .u-w-sp-91 {
    width: 91rem !important;
  }
  .specialFeature .u-w-sp-92 {
    width: 92rem !important;
  }
  .specialFeature .u-w-sp-93 {
    width: 93rem !important;
  }
  .specialFeature .u-w-sp-94 {
    width: 94rem !important;
  }
  .specialFeature .u-w-sp-95 {
    width: 95rem !important;
  }
  .specialFeature .u-w-sp-96 {
    width: 96rem !important;
  }
  .specialFeature .u-w-sp-97 {
    width: 97rem !important;
  }
  .specialFeature .u-w-sp-98 {
    width: 98rem !important;
  }
  .specialFeature .u-w-sp-99 {
    width: 99rem !important;
  }
}
.specialFeature .u-m-c {
  margin-right: auto !important;
  margin-left: auto !important;
}
.specialFeature .u-m-r {
  margin-right: auto !important;
  margin-left: 0 !important;
}
.specialFeature .u-mt-0 {
  margin-top: 0rem !important;
}
.specialFeature .u-mt-1 {
  margin-top: 1rem !important;
}
.specialFeature .u-mt-2 {
  margin-top: 2rem !important;
}
.specialFeature .u-mt-3 {
  margin-top: 3rem !important;
}
.specialFeature .u-mt-4 {
  margin-top: 4rem !important;
}
.specialFeature .u-mt-5 {
  margin-top: 5rem !important;
}
.specialFeature .u-mt-6 {
  margin-top: 6rem !important;
}
.specialFeature .u-mt-7 {
  margin-top: 7rem !important;
}
.specialFeature .u-mt-8 {
  margin-top: 8rem !important;
}
.specialFeature .u-mt-9 {
  margin-top: 9rem !important;
}
.specialFeature .u-mt-10 {
  margin-top: 10rem !important;
}
.specialFeature .u-mt-11 {
  margin-top: 11rem !important;
}
.specialFeature .u-mt-12 {
  margin-top: 12rem !important;
}
.specialFeature .u-mt-13 {
  margin-top: 13rem !important;
}
.specialFeature .u-mt-14 {
  margin-top: 14rem !important;
}
.specialFeature .u-mt-15 {
  margin-top: 15rem !important;
}
.specialFeature .u-mt-16 {
  margin-top: 16rem !important;
}
.specialFeature .u-mt-17 {
  margin-top: 17rem !important;
}
.specialFeature .u-mt-18 {
  margin-top: 18rem !important;
}
.specialFeature .u-mt-19 {
  margin-top: 19rem !important;
}
.specialFeature .u-mt-20 {
  margin-top: 20rem !important;
}
.specialFeature .u-mt-21 {
  margin-top: 21rem !important;
}
.specialFeature .u-mt-22 {
  margin-top: 22rem !important;
}
.specialFeature .u-mt-23 {
  margin-top: 23rem !important;
}
.specialFeature .u-mt-24 {
  margin-top: 24rem !important;
}
.specialFeature .u-mt-25 {
  margin-top: 25rem !important;
}
.specialFeature .u-mr-0 {
  margin-right: 0rem !important;
}
.specialFeature .u-mr-1 {
  margin-right: 1rem !important;
}
.specialFeature .u-mr-2 {
  margin-right: 2rem !important;
}
.specialFeature .u-mr-3 {
  margin-right: 3rem !important;
}
.specialFeature .u-mr-4 {
  margin-right: 4rem !important;
}
.specialFeature .u-mr-5 {
  margin-right: 5rem !important;
}
.specialFeature .u-mr-6 {
  margin-right: 6rem !important;
}
.specialFeature .u-mr-7 {
  margin-right: 7rem !important;
}
.specialFeature .u-mr-8 {
  margin-right: 8rem !important;
}
.specialFeature .u-mr-9 {
  margin-right: 9rem !important;
}
.specialFeature .u-mr-10 {
  margin-right: 10rem !important;
}
.specialFeature .u-mr-11 {
  margin-right: 11rem !important;
}
.specialFeature .u-mr-12 {
  margin-right: 12rem !important;
}
.specialFeature .u-mr-13 {
  margin-right: 13rem !important;
}
.specialFeature .u-mr-14 {
  margin-right: 14rem !important;
}
.specialFeature .u-mr-15 {
  margin-right: 15rem !important;
}
.specialFeature .u-mr-16 {
  margin-right: 16rem !important;
}
.specialFeature .u-mr-17 {
  margin-right: 17rem !important;
}
.specialFeature .u-mr-18 {
  margin-right: 18rem !important;
}
.specialFeature .u-mr-19 {
  margin-right: 19rem !important;
}
.specialFeature .u-mr-20 {
  margin-right: 20rem !important;
}
.specialFeature .u-mr-21 {
  margin-right: 21rem !important;
}
.specialFeature .u-mr-22 {
  margin-right: 22rem !important;
}
.specialFeature .u-mr-23 {
  margin-right: 23rem !important;
}
.specialFeature .u-mr-24 {
  margin-right: 24rem !important;
}
.specialFeature .u-mr-25 {
  margin-right: 25rem !important;
}
.specialFeature .u-mb-0 {
  margin-bottom: 0rem !important;
}
.specialFeature .u-mb-1 {
  margin-bottom: 1rem !important;
}
.specialFeature .u-mb-2 {
  margin-bottom: 2rem !important;
}
.specialFeature .u-mb-3 {
  margin-bottom: 3rem !important;
}
.specialFeature .u-mb-4 {
  margin-bottom: 4rem !important;
}
.specialFeature .u-mb-5 {
  margin-bottom: 5rem !important;
}
.specialFeature .u-mb-6 {
  margin-bottom: 6rem !important;
}
.specialFeature .u-mb-7 {
  margin-bottom: 7rem !important;
}
.specialFeature .u-mb-8 {
  margin-bottom: 8rem !important;
}
.specialFeature .u-mb-9 {
  margin-bottom: 9rem !important;
}
.specialFeature .u-mb-10 {
  margin-bottom: 10rem !important;
}
.specialFeature .u-mb-11 {
  margin-bottom: 11rem !important;
}
.specialFeature .u-mb-12 {
  margin-bottom: 12rem !important;
}
.specialFeature .u-mb-13 {
  margin-bottom: 13rem !important;
}
.specialFeature .u-mb-14 {
  margin-bottom: 14rem !important;
}
.specialFeature .u-mb-15 {
  margin-bottom: 15rem !important;
}
.specialFeature .u-mb-16 {
  margin-bottom: 16rem !important;
}
.specialFeature .u-mb-17 {
  margin-bottom: 17rem !important;
}
.specialFeature .u-mb-18 {
  margin-bottom: 18rem !important;
}
.specialFeature .u-mb-19 {
  margin-bottom: 19rem !important;
}
.specialFeature .u-mb-20 {
  margin-bottom: 20rem !important;
}
.specialFeature .u-mb-21 {
  margin-bottom: 21rem !important;
}
.specialFeature .u-mb-22 {
  margin-bottom: 22rem !important;
}
.specialFeature .u-mb-23 {
  margin-bottom: 23rem !important;
}
.specialFeature .u-mb-24 {
  margin-bottom: 24rem !important;
}
.specialFeature .u-mb-25 {
  margin-bottom: 25rem !important;
}
.specialFeature .u-ml-0 {
  margin-left: 0rem !important;
}
.specialFeature .u-ml-1 {
  margin-left: 1rem !important;
}
.specialFeature .u-ml-2 {
  margin-left: 2rem !important;
}
.specialFeature .u-ml-3 {
  margin-left: 3rem !important;
}
.specialFeature .u-ml-4 {
  margin-left: 4rem !important;
}
.specialFeature .u-ml-5 {
  margin-left: 5rem !important;
}
.specialFeature .u-ml-6 {
  margin-left: 6rem !important;
}
.specialFeature .u-ml-7 {
  margin-left: 7rem !important;
}
.specialFeature .u-ml-8 {
  margin-left: 8rem !important;
}
.specialFeature .u-ml-9 {
  margin-left: 9rem !important;
}
.specialFeature .u-ml-10 {
  margin-left: 10rem !important;
}
.specialFeature .u-ml-11 {
  margin-left: 11rem !important;
}
.specialFeature .u-ml-12 {
  margin-left: 12rem !important;
}
.specialFeature .u-ml-13 {
  margin-left: 13rem !important;
}
.specialFeature .u-ml-14 {
  margin-left: 14rem !important;
}
.specialFeature .u-ml-15 {
  margin-left: 15rem !important;
}
.specialFeature .u-ml-16 {
  margin-left: 16rem !important;
}
.specialFeature .u-ml-17 {
  margin-left: 17rem !important;
}
.specialFeature .u-ml-18 {
  margin-left: 18rem !important;
}
.specialFeature .u-ml-19 {
  margin-left: 19rem !important;
}
.specialFeature .u-ml-20 {
  margin-left: 20rem !important;
}
.specialFeature .u-ml-21 {
  margin-left: 21rem !important;
}
.specialFeature .u-ml-22 {
  margin-left: 22rem !important;
}
.specialFeature .u-ml-23 {
  margin-left: 23rem !important;
}
.specialFeature .u-ml-24 {
  margin-left: 24rem !important;
}
.specialFeature .u-ml-25 {
  margin-left: 25rem !important;
}
@media (--mobile) {
  .specialFeature {
    /* Top margin */
    /* Right margin */
    /* Bottom margin */
    /* Left margin */
  }
  .specialFeature .u-m-sp-c {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .specialFeature .u-m-sp-r {
    margin-right: auto !important;
    margin-left: 0 !important;
  }
  .specialFeature .u-mt-sp-0 {
    margin-top: 0rem !important;
  }
  .specialFeature .u-mt-sp-1 {
    margin-top: 1rem !important;
  }
  .specialFeature .u-mt-sp-2 {
    margin-top: 2rem !important;
  }
  .specialFeature .u-mt-sp-3 {
    margin-top: 3rem !important;
  }
  .specialFeature .u-mt-sp-4 {
    margin-top: 4rem !important;
  }
  .specialFeature .u-mt-sp-5 {
    margin-top: 5rem !important;
  }
  .specialFeature .u-mt-sp-6 {
    margin-top: 6rem !important;
  }
  .specialFeature .u-mt-sp-7 {
    margin-top: 7rem !important;
  }
  .specialFeature .u-mt-sp-8 {
    margin-top: 8rem !important;
  }
  .specialFeature .u-mt-sp-9 {
    margin-top: 9rem !important;
  }
  .specialFeature .u-mt-sp-10 {
    margin-top: 10rem !important;
  }
  .specialFeature .u-mt-sp-11 {
    margin-top: 11rem !important;
  }
  .specialFeature .u-mt-sp-12 {
    margin-top: 12rem !important;
  }
  .specialFeature .u-mt-sp-13 {
    margin-top: 13rem !important;
  }
  .specialFeature .u-mt-sp-14 {
    margin-top: 14rem !important;
  }
  .specialFeature .u-mt-sp-15 {
    margin-top: 15rem !important;
  }
  .specialFeature .u-mt-sp-16 {
    margin-top: 16rem !important;
  }
  .specialFeature .u-mt-sp-17 {
    margin-top: 17rem !important;
  }
  .specialFeature .u-mt-sp-18 {
    margin-top: 18rem !important;
  }
  .specialFeature .u-mt-sp-19 {
    margin-top: 19rem !important;
  }
  .specialFeature .u-mt-sp-20 {
    margin-top: 20rem !important;
  }
  .specialFeature .u-mt-sp-21 {
    margin-top: 21rem !important;
  }
  .specialFeature .u-mt-sp-22 {
    margin-top: 22rem !important;
  }
  .specialFeature .u-mt-sp-23 {
    margin-top: 23rem !important;
  }
  .specialFeature .u-mt-sp-24 {
    margin-top: 24rem !important;
  }
  .specialFeature .u-mt-sp-25 {
    margin-top: 25rem !important;
  }
  .specialFeature .u-mr-sp-0 {
    margin-right: 0rem !important;
  }
  .specialFeature .u-mr-sp-1 {
    margin-right: 1rem !important;
  }
  .specialFeature .u-mr-sp-2 {
    margin-right: 2rem !important;
  }
  .specialFeature .u-mr-sp-3 {
    margin-right: 3rem !important;
  }
  .specialFeature .u-mr-sp-4 {
    margin-right: 4rem !important;
  }
  .specialFeature .u-mr-sp-5 {
    margin-right: 5rem !important;
  }
  .specialFeature .u-mr-sp-6 {
    margin-right: 6rem !important;
  }
  .specialFeature .u-mr-sp-7 {
    margin-right: 7rem !important;
  }
  .specialFeature .u-mr-sp-8 {
    margin-right: 8rem !important;
  }
  .specialFeature .u-mr-sp-9 {
    margin-right: 9rem !important;
  }
  .specialFeature .u-mr-sp-10 {
    margin-right: 10rem !important;
  }
  .specialFeature .u-mr-sp-11 {
    margin-right: 11rem !important;
  }
  .specialFeature .u-mr-sp-12 {
    margin-right: 12rem !important;
  }
  .specialFeature .u-mr-sp-13 {
    margin-right: 13rem !important;
  }
  .specialFeature .u-mr-sp-14 {
    margin-right: 14rem !important;
  }
  .specialFeature .u-mr-sp-15 {
    margin-right: 15rem !important;
  }
  .specialFeature .u-mr-sp-16 {
    margin-right: 16rem !important;
  }
  .specialFeature .u-mr-sp-17 {
    margin-right: 17rem !important;
  }
  .specialFeature .u-mr-sp-18 {
    margin-right: 18rem !important;
  }
  .specialFeature .u-mr-sp-19 {
    margin-right: 19rem !important;
  }
  .specialFeature .u-mr-sp-20 {
    margin-right: 20rem !important;
  }
  .specialFeature .u-mr-sp-21 {
    margin-right: 21rem !important;
  }
  .specialFeature .u-mr-sp-22 {
    margin-right: 22rem !important;
  }
  .specialFeature .u-mr-sp-23 {
    margin-right: 23rem !important;
  }
  .specialFeature .u-mr-sp-24 {
    margin-right: 24rem !important;
  }
  .specialFeature .u-mr-sp-25 {
    margin-right: 25rem !important;
  }
  .specialFeature .u-mb-sp-0 {
    margin-bottom: 0rem !important;
  }
  .specialFeature .u-mb-sp-1 {
    margin-bottom: 1rem !important;
  }
  .specialFeature .u-mb-sp-2 {
    margin-bottom: 2rem !important;
  }
  .specialFeature .u-mb-sp-3 {
    margin-bottom: 3rem !important;
  }
  .specialFeature .u-mb-sp-4 {
    margin-bottom: 4rem !important;
  }
  .specialFeature .u-mb-sp-5 {
    margin-bottom: 5rem !important;
  }
  .specialFeature .u-mb-sp-6 {
    margin-bottom: 6rem !important;
  }
  .specialFeature .u-mb-sp-7 {
    margin-bottom: 7rem !important;
  }
  .specialFeature .u-mb-sp-8 {
    margin-bottom: 8rem !important;
  }
  .specialFeature .u-mb-sp-9 {
    margin-bottom: 9rem !important;
  }
  .specialFeature .u-mb-sp-10 {
    margin-bottom: 10rem !important;
  }
  .specialFeature .u-mb-sp-11 {
    margin-bottom: 11rem !important;
  }
  .specialFeature .u-mb-sp-12 {
    margin-bottom: 12rem !important;
  }
  .specialFeature .u-mb-sp-13 {
    margin-bottom: 13rem !important;
  }
  .specialFeature .u-mb-sp-14 {
    margin-bottom: 14rem !important;
  }
  .specialFeature .u-mb-sp-15 {
    margin-bottom: 15rem !important;
  }
  .specialFeature .u-mb-sp-16 {
    margin-bottom: 16rem !important;
  }
  .specialFeature .u-mb-sp-17 {
    margin-bottom: 17rem !important;
  }
  .specialFeature .u-mb-sp-18 {
    margin-bottom: 18rem !important;
  }
  .specialFeature .u-mb-sp-19 {
    margin-bottom: 19rem !important;
  }
  .specialFeature .u-mb-sp-20 {
    margin-bottom: 20rem !important;
  }
  .specialFeature .u-mb-sp-21 {
    margin-bottom: 21rem !important;
  }
  .specialFeature .u-mb-sp-22 {
    margin-bottom: 22rem !important;
  }
  .specialFeature .u-mb-sp-23 {
    margin-bottom: 23rem !important;
  }
  .specialFeature .u-mb-sp-24 {
    margin-bottom: 24rem !important;
  }
  .specialFeature .u-mb-sp-25 {
    margin-bottom: 25rem !important;
  }
  .specialFeature .u-ml-sp-0 {
    margin-left: 0rem !important;
  }
  .specialFeature .u-ml-sp-1 {
    margin-left: 1rem !important;
  }
  .specialFeature .u-ml-sp-2 {
    margin-left: 2rem !important;
  }
  .specialFeature .u-ml-sp-3 {
    margin-left: 3rem !important;
  }
  .specialFeature .u-ml-sp-4 {
    margin-left: 4rem !important;
  }
  .specialFeature .u-ml-sp-5 {
    margin-left: 5rem !important;
  }
  .specialFeature .u-ml-sp-6 {
    margin-left: 6rem !important;
  }
  .specialFeature .u-ml-sp-7 {
    margin-left: 7rem !important;
  }
  .specialFeature .u-ml-sp-8 {
    margin-left: 8rem !important;
  }
  .specialFeature .u-ml-sp-9 {
    margin-left: 9rem !important;
  }
  .specialFeature .u-ml-sp-10 {
    margin-left: 10rem !important;
  }
  .specialFeature .u-ml-sp-11 {
    margin-left: 11rem !important;
  }
  .specialFeature .u-ml-sp-12 {
    margin-left: 12rem !important;
  }
  .specialFeature .u-ml-sp-13 {
    margin-left: 13rem !important;
  }
  .specialFeature .u-ml-sp-14 {
    margin-left: 14rem !important;
  }
  .specialFeature .u-ml-sp-15 {
    margin-left: 15rem !important;
  }
  .specialFeature .u-ml-sp-16 {
    margin-left: 16rem !important;
  }
  .specialFeature .u-ml-sp-17 {
    margin-left: 17rem !important;
  }
  .specialFeature .u-ml-sp-18 {
    margin-left: 18rem !important;
  }
  .specialFeature .u-ml-sp-19 {
    margin-left: 19rem !important;
  }
  .specialFeature .u-ml-sp-20 {
    margin-left: 20rem !important;
  }
  .specialFeature .u-ml-sp-21 {
    margin-left: 21rem !important;
  }
  .specialFeature .u-ml-sp-22 {
    margin-left: 22rem !important;
  }
  .specialFeature .u-ml-sp-23 {
    margin-left: 23rem !important;
  }
  .specialFeature .u-ml-sp-24 {
    margin-left: 24rem !important;
  }
  .specialFeature .u-ml-sp-25 {
    margin-left: 25rem !important;
  }
}
.specialFeature .u-pt-0 {
  padding-top: 0rem !important;
}
.specialFeature .u-pt-1 {
  padding-top: 1rem !important;
}
.specialFeature .u-pt-2 {
  padding-top: 2rem !important;
}
.specialFeature .u-pt-3 {
  padding-top: 3rem !important;
}
.specialFeature .u-pt-4 {
  padding-top: 4rem !important;
}
.specialFeature .u-pt-5 {
  padding-top: 5rem !important;
}
.specialFeature .u-pt-6 {
  padding-top: 6rem !important;
}
.specialFeature .u-pt-7 {
  padding-top: 7rem !important;
}
.specialFeature .u-pt-8 {
  padding-top: 8rem !important;
}
.specialFeature .u-pt-9 {
  padding-top: 9rem !important;
}
.specialFeature .u-pt-10 {
  padding-top: 10rem !important;
}
.specialFeature .u-pt-11 {
  padding-top: 11rem !important;
}
.specialFeature .u-pt-12 {
  padding-top: 12rem !important;
}
.specialFeature .u-pt-13 {
  padding-top: 13rem !important;
}
.specialFeature .u-pt-14 {
  padding-top: 14rem !important;
}
.specialFeature .u-pt-15 {
  padding-top: 15rem !important;
}
.specialFeature .u-pt-16 {
  padding-top: 16rem !important;
}
.specialFeature .u-pt-17 {
  padding-top: 17rem !important;
}
.specialFeature .u-pt-18 {
  padding-top: 18rem !important;
}
.specialFeature .u-pt-19 {
  padding-top: 19rem !important;
}
.specialFeature .u-pt-20 {
  padding-top: 20rem !important;
}
.specialFeature .u-pt-21 {
  padding-top: 21rem !important;
}
.specialFeature .u-pt-22 {
  padding-top: 22rem !important;
}
.specialFeature .u-pt-23 {
  padding-top: 23rem !important;
}
.specialFeature .u-pt-24 {
  padding-top: 24rem !important;
}
.specialFeature .u-pt-25 {
  padding-top: 25rem !important;
}
.specialFeature .u-pr-0 {
  padding-right: 0rem !important;
}
.specialFeature .u-pr-1 {
  padding-right: 1rem !important;
}
.specialFeature .u-pr-2 {
  padding-right: 2rem !important;
}
.specialFeature .u-pr-3 {
  padding-right: 3rem !important;
}
.specialFeature .u-pr-4 {
  padding-right: 4rem !important;
}
.specialFeature .u-pr-5 {
  padding-right: 5rem !important;
}
.specialFeature .u-pr-6 {
  padding-right: 6rem !important;
}
.specialFeature .u-pr-7 {
  padding-right: 7rem !important;
}
.specialFeature .u-pr-8 {
  padding-right: 8rem !important;
}
.specialFeature .u-pr-9 {
  padding-right: 9rem !important;
}
.specialFeature .u-pr-10 {
  padding-right: 10rem !important;
}
.specialFeature .u-pr-11 {
  padding-right: 11rem !important;
}
.specialFeature .u-pr-12 {
  padding-right: 12rem !important;
}
.specialFeature .u-pr-13 {
  padding-right: 13rem !important;
}
.specialFeature .u-pr-14 {
  padding-right: 14rem !important;
}
.specialFeature .u-pr-15 {
  padding-right: 15rem !important;
}
.specialFeature .u-pr-16 {
  padding-right: 16rem !important;
}
.specialFeature .u-pr-17 {
  padding-right: 17rem !important;
}
.specialFeature .u-pr-18 {
  padding-right: 18rem !important;
}
.specialFeature .u-pr-19 {
  padding-right: 19rem !important;
}
.specialFeature .u-pr-20 {
  padding-right: 20rem !important;
}
.specialFeature .u-pr-21 {
  padding-right: 21rem !important;
}
.specialFeature .u-pr-22 {
  padding-right: 22rem !important;
}
.specialFeature .u-pr-23 {
  padding-right: 23rem !important;
}
.specialFeature .u-pr-24 {
  padding-right: 24rem !important;
}
.specialFeature .u-pr-25 {
  padding-right: 25rem !important;
}
.specialFeature .u-pb-0 {
  padding-bottom: 0rem !important;
}
.specialFeature .u-pb-1 {
  padding-bottom: 1rem !important;
}
.specialFeature .u-pb-2 {
  padding-bottom: 2rem !important;
}
.specialFeature .u-pb-3 {
  padding-bottom: 3rem !important;
}
.specialFeature .u-pb-4 {
  padding-bottom: 4rem !important;
}
.specialFeature .u-pb-5 {
  padding-bottom: 5rem !important;
}
.specialFeature .u-pb-6 {
  padding-bottom: 6rem !important;
}
.specialFeature .u-pb-7 {
  padding-bottom: 7rem !important;
}
.specialFeature .u-pb-8 {
  padding-bottom: 8rem !important;
}
.specialFeature .u-pb-9 {
  padding-bottom: 9rem !important;
}
.specialFeature .u-pb-10 {
  padding-bottom: 10rem !important;
}
.specialFeature .u-pb-11 {
  padding-bottom: 11rem !important;
}
.specialFeature .u-pb-12 {
  padding-bottom: 12rem !important;
}
.specialFeature .u-pb-13 {
  padding-bottom: 13rem !important;
}
.specialFeature .u-pb-14 {
  padding-bottom: 14rem !important;
}
.specialFeature .u-pb-15 {
  padding-bottom: 15rem !important;
}
.specialFeature .u-pb-16 {
  padding-bottom: 16rem !important;
}
.specialFeature .u-pb-17 {
  padding-bottom: 17rem !important;
}
.specialFeature .u-pb-18 {
  padding-bottom: 18rem !important;
}
.specialFeature .u-pb-19 {
  padding-bottom: 19rem !important;
}
.specialFeature .u-pb-20 {
  padding-bottom: 20rem !important;
}
.specialFeature .u-pb-21 {
  padding-bottom: 21rem !important;
}
.specialFeature .u-pb-22 {
  padding-bottom: 22rem !important;
}
.specialFeature .u-pb-23 {
  padding-bottom: 23rem !important;
}
.specialFeature .u-pb-24 {
  padding-bottom: 24rem !important;
}
.specialFeature .u-pb-25 {
  padding-bottom: 25rem !important;
}
.specialFeature .u-pl-0 {
  padding-left: 0rem !important;
}
.specialFeature .u-pl-1 {
  padding-left: 1rem !important;
}
.specialFeature .u-pl-2 {
  padding-left: 2rem !important;
}
.specialFeature .u-pl-3 {
  padding-left: 3rem !important;
}
.specialFeature .u-pl-4 {
  padding-left: 4rem !important;
}
.specialFeature .u-pl-5 {
  padding-left: 5rem !important;
}
.specialFeature .u-pl-6 {
  padding-left: 6rem !important;
}
.specialFeature .u-pl-7 {
  padding-left: 7rem !important;
}
.specialFeature .u-pl-8 {
  padding-left: 8rem !important;
}
.specialFeature .u-pl-9 {
  padding-left: 9rem !important;
}
.specialFeature .u-pl-10 {
  padding-left: 10rem !important;
}
.specialFeature .u-pl-11 {
  padding-left: 11rem !important;
}
.specialFeature .u-pl-12 {
  padding-left: 12rem !important;
}
.specialFeature .u-pl-13 {
  padding-left: 13rem !important;
}
.specialFeature .u-pl-14 {
  padding-left: 14rem !important;
}
.specialFeature .u-pl-15 {
  padding-left: 15rem !important;
}
.specialFeature .u-pl-16 {
  padding-left: 16rem !important;
}
.specialFeature .u-pl-17 {
  padding-left: 17rem !important;
}
.specialFeature .u-pl-18 {
  padding-left: 18rem !important;
}
.specialFeature .u-pl-19 {
  padding-left: 19rem !important;
}
.specialFeature .u-pl-20 {
  padding-left: 20rem !important;
}
.specialFeature .u-pl-21 {
  padding-left: 21rem !important;
}
.specialFeature .u-pl-22 {
  padding-left: 22rem !important;
}
.specialFeature .u-pl-23 {
  padding-left: 23rem !important;
}
.specialFeature .u-pl-24 {
  padding-left: 24rem !important;
}
.specialFeature .u-pl-25 {
  padding-left: 25rem !important;
}
@media (--mobile) {
  .specialFeature {
    /* Top padding */
    /* Right padding */
    /* Bottom padding */
    /* Left padding */
  }
  .specialFeature .u-pt-sp-0 {
    padding-top: 0rem !important;
  }
  .specialFeature .u-pt-sp-1 {
    padding-top: 1rem !important;
  }
  .specialFeature .u-pt-sp-2 {
    padding-top: 2rem !important;
  }
  .specialFeature .u-pt-sp-3 {
    padding-top: 3rem !important;
  }
  .specialFeature .u-pt-sp-4 {
    padding-top: 4rem !important;
  }
  .specialFeature .u-pt-sp-5 {
    padding-top: 5rem !important;
  }
  .specialFeature .u-pt-sp-6 {
    padding-top: 6rem !important;
  }
  .specialFeature .u-pt-sp-7 {
    padding-top: 7rem !important;
  }
  .specialFeature .u-pt-sp-8 {
    padding-top: 8rem !important;
  }
  .specialFeature .u-pt-sp-9 {
    padding-top: 9rem !important;
  }
  .specialFeature .u-pt-sp-10 {
    padding-top: 10rem !important;
  }
  .specialFeature .u-pt-sp-11 {
    padding-top: 11rem !important;
  }
  .specialFeature .u-pt-sp-12 {
    padding-top: 12rem !important;
  }
  .specialFeature .u-pt-sp-13 {
    padding-top: 13rem !important;
  }
  .specialFeature .u-pt-sp-14 {
    padding-top: 14rem !important;
  }
  .specialFeature .u-pt-sp-15 {
    padding-top: 15rem !important;
  }
  .specialFeature .u-pt-sp-16 {
    padding-top: 16rem !important;
  }
  .specialFeature .u-pt-sp-17 {
    padding-top: 17rem !important;
  }
  .specialFeature .u-pt-sp-18 {
    padding-top: 18rem !important;
  }
  .specialFeature .u-pt-sp-19 {
    padding-top: 19rem !important;
  }
  .specialFeature .u-pt-sp-20 {
    padding-top: 20rem !important;
  }
  .specialFeature .u-pt-sp-21 {
    padding-top: 21rem !important;
  }
  .specialFeature .u-pt-sp-22 {
    padding-top: 22rem !important;
  }
  .specialFeature .u-pt-sp-23 {
    padding-top: 23rem !important;
  }
  .specialFeature .u-pt-sp-24 {
    padding-top: 24rem !important;
  }
  .specialFeature .u-pt-sp-25 {
    padding-top: 25rem !important;
  }
  .specialFeature .u-pr-sp-0 {
    padding-right: 0rem !important;
  }
  .specialFeature .u-pr-sp-1 {
    padding-right: 1rem !important;
  }
  .specialFeature .u-pr-sp-2 {
    padding-right: 2rem !important;
  }
  .specialFeature .u-pr-sp-3 {
    padding-right: 3rem !important;
  }
  .specialFeature .u-pr-sp-4 {
    padding-right: 4rem !important;
  }
  .specialFeature .u-pr-sp-5 {
    padding-right: 5rem !important;
  }
  .specialFeature .u-pr-sp-6 {
    padding-right: 6rem !important;
  }
  .specialFeature .u-pr-sp-7 {
    padding-right: 7rem !important;
  }
  .specialFeature .u-pr-sp-8 {
    padding-right: 8rem !important;
  }
  .specialFeature .u-pr-sp-9 {
    padding-right: 9rem !important;
  }
  .specialFeature .u-pr-sp-10 {
    padding-right: 10rem !important;
  }
  .specialFeature .u-pr-sp-11 {
    padding-right: 11rem !important;
  }
  .specialFeature .u-pr-sp-12 {
    padding-right: 12rem !important;
  }
  .specialFeature .u-pr-sp-13 {
    padding-right: 13rem !important;
  }
  .specialFeature .u-pr-sp-14 {
    padding-right: 14rem !important;
  }
  .specialFeature .u-pr-sp-15 {
    padding-right: 15rem !important;
  }
  .specialFeature .u-pr-sp-16 {
    padding-right: 16rem !important;
  }
  .specialFeature .u-pr-sp-17 {
    padding-right: 17rem !important;
  }
  .specialFeature .u-pr-sp-18 {
    padding-right: 18rem !important;
  }
  .specialFeature .u-pr-sp-19 {
    padding-right: 19rem !important;
  }
  .specialFeature .u-pr-sp-20 {
    padding-right: 20rem !important;
  }
  .specialFeature .u-pr-sp-21 {
    padding-right: 21rem !important;
  }
  .specialFeature .u-pr-sp-22 {
    padding-right: 22rem !important;
  }
  .specialFeature .u-pr-sp-23 {
    padding-right: 23rem !important;
  }
  .specialFeature .u-pr-sp-24 {
    padding-right: 24rem !important;
  }
  .specialFeature .u-pr-sp-25 {
    padding-right: 25rem !important;
  }
  .specialFeature .u-pb-sp-0 {
    padding-bottom: 0rem !important;
  }
  .specialFeature .u-pb-sp-1 {
    padding-bottom: 1rem !important;
  }
  .specialFeature .u-pb-sp-2 {
    padding-bottom: 2rem !important;
  }
  .specialFeature .u-pb-sp-3 {
    padding-bottom: 3rem !important;
  }
  .specialFeature .u-pb-sp-4 {
    padding-bottom: 4rem !important;
  }
  .specialFeature .u-pb-sp-5 {
    padding-bottom: 5rem !important;
  }
  .specialFeature .u-pb-sp-6 {
    padding-bottom: 6rem !important;
  }
  .specialFeature .u-pb-sp-7 {
    padding-bottom: 7rem !important;
  }
  .specialFeature .u-pb-sp-8 {
    padding-bottom: 8rem !important;
  }
  .specialFeature .u-pb-sp-9 {
    padding-bottom: 9rem !important;
  }
  .specialFeature .u-pb-sp-10 {
    padding-bottom: 10rem !important;
  }
  .specialFeature .u-pb-sp-11 {
    padding-bottom: 11rem !important;
  }
  .specialFeature .u-pb-sp-12 {
    padding-bottom: 12rem !important;
  }
  .specialFeature .u-pb-sp-13 {
    padding-bottom: 13rem !important;
  }
  .specialFeature .u-pb-sp-14 {
    padding-bottom: 14rem !important;
  }
  .specialFeature .u-pb-sp-15 {
    padding-bottom: 15rem !important;
  }
  .specialFeature .u-pb-sp-16 {
    padding-bottom: 16rem !important;
  }
  .specialFeature .u-pb-sp-17 {
    padding-bottom: 17rem !important;
  }
  .specialFeature .u-pb-sp-18 {
    padding-bottom: 18rem !important;
  }
  .specialFeature .u-pb-sp-19 {
    padding-bottom: 19rem !important;
  }
  .specialFeature .u-pb-sp-20 {
    padding-bottom: 20rem !important;
  }
  .specialFeature .u-pb-sp-21 {
    padding-bottom: 21rem !important;
  }
  .specialFeature .u-pb-sp-22 {
    padding-bottom: 22rem !important;
  }
  .specialFeature .u-pb-sp-23 {
    padding-bottom: 23rem !important;
  }
  .specialFeature .u-pb-sp-24 {
    padding-bottom: 24rem !important;
  }
  .specialFeature .u-pb-sp-25 {
    padding-bottom: 25rem !important;
  }
  .specialFeature .u-pl-sp-0 {
    padding-left: 0rem !important;
  }
  .specialFeature .u-pl-sp-1 {
    padding-left: 1rem !important;
  }
  .specialFeature .u-pl-sp-2 {
    padding-left: 2rem !important;
  }
  .specialFeature .u-pl-sp-3 {
    padding-left: 3rem !important;
  }
  .specialFeature .u-pl-sp-4 {
    padding-left: 4rem !important;
  }
  .specialFeature .u-pl-sp-5 {
    padding-left: 5rem !important;
  }
  .specialFeature .u-pl-sp-6 {
    padding-left: 6rem !important;
  }
  .specialFeature .u-pl-sp-7 {
    padding-left: 7rem !important;
  }
  .specialFeature .u-pl-sp-8 {
    padding-left: 8rem !important;
  }
  .specialFeature .u-pl-sp-9 {
    padding-left: 9rem !important;
  }
  .specialFeature .u-pl-sp-10 {
    padding-left: 10rem !important;
  }
  .specialFeature .u-pl-sp-11 {
    padding-left: 11rem !important;
  }
  .specialFeature .u-pl-sp-12 {
    padding-left: 12rem !important;
  }
  .specialFeature .u-pl-sp-13 {
    padding-left: 13rem !important;
  }
  .specialFeature .u-pl-sp-14 {
    padding-left: 14rem !important;
  }
  .specialFeature .u-pl-sp-15 {
    padding-left: 15rem !important;
  }
  .specialFeature .u-pl-sp-16 {
    padding-left: 16rem !important;
  }
  .specialFeature .u-pl-sp-17 {
    padding-left: 17rem !important;
  }
  .specialFeature .u-pl-sp-18 {
    padding-left: 18rem !important;
  }
  .specialFeature .u-pl-sp-19 {
    padding-left: 19rem !important;
  }
  .specialFeature .u-pl-sp-20 {
    padding-left: 20rem !important;
  }
  .specialFeature .u-pl-sp-21 {
    padding-left: 21rem !important;
  }
  .specialFeature .u-pl-sp-22 {
    padding-left: 22rem !important;
  }
  .specialFeature .u-pl-sp-23 {
    padding-left: 23rem !important;
  }
  .specialFeature .u-pl-sp-24 {
    padding-left: 24rem !important;
  }
  .specialFeature .u-pl-sp-25 {
    padding-left: 25rem !important;
  }
}
.specialFeature .h_after-85::after {
  height: 85%;
}

.border-b {
  border-bottom: 1px solid #000;
}

.f-noto {
  font-family: "Noto Sans JP", sans-serif;
}

.f-oswald {
  font-family: "Oswald", sans-serif;
}

/**
* wordpress
* WYSIWYG compatchble style
*/
.c-wp-post_article strong {
  font-weight: bold;
}
.c-wp-post_article em {
  font-style: italic;
}
.c-wp-post_article ul {
  margin-left: 1em;
  display: block;
  list-style-type: disc !important;
  margin-before: 1em;
  margin-after: 1em;
  margin-start: 0px;
  margin-end: 0px;
  padding-start: 40px;
}
.c-wp-post_article ol {
  display: block;
  list-style-type: decimal !important;
  margin-before: 1em;
  margin-after: 1em;
  margin-start: 0px;
  margin-end: 0px;
  padding-start: 40px;
}
.c-wp-post_article li {
  display: list-item;
  text-align: match-parent;
}
.c-wp-post_article img {
  width: auto;
}
.c-wp-post_article .alignleft {
  display: inline;
  float: left;
}
.c-wp-post_article .alignright {
  display: inline;
  float: right;
}
.c-wp-post_article .aligncenter {
  display: block;
  margin-right: auto;
  margin-left: auto;
}
.c-wp-post_article blockquote.alignleft,
.c-wp-post_article .wp-caption.alignleft,
.c-wp-post_article img.alignleft {
  margin: 0.4em 1.6em 1.6em 0;
}
.c-wp-post_article blockquote.alignright,
.c-wp-post_article .wp-caption.alignright,
.c-wp-post_article img.alignright {
  margin: 0.4em 0 1.6em 1.6em;
}
.c-wp-post_article blockquote.aligncenter,
.c-wp-post_article .wp-caption.aligncenter,
.c-wp-post_article img.aligncenter {
  clear: both;
  margin-top: 0.4em;
  margin-bottom: 1.6em;
}
.c-wp-post_article .wp-caption.alignleft,
.c-wp-post_article .wp-caption.alignright,
.c-wp-post_article .wp-caption.aligncenter {
  margin-bottom: 1.2em;
}

.garamond {
  font-family: "EB Garamond", serif;
  font-weight: lighter;
}

.tsukushi {
  font-family: TsukuGoPr5-R-Between2-Light;
  font-weight: lighter;
}

.notoSans {
  font-family: "Noto Sans JP", sans-serif;
}

.tsukushiB {
  font-family: TsukuGoPro-B;
}

.tsukushiM {
  font-family: TsukuGoPr5-R;
}

.shippori {
  font-family: "Shippori Mincho B1", serif;
}

.roboto {
  font-family: "Roboto", serif;
}

.garamond {
  font-family: "adobe-garamond-pro", serif;
  font-weight: 400;
  font-style: normal;
}

/**
 * clearfix micro
 */
.clearfix:after {
  content: "";
  clear: both;
  display: block;
}

/**
 * text ellipsis., text...
 */
.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.u-hide {
  display: none;
}

.u-click {
  cursor: pointer;
}

@media (--large) {
  .for-mobile {
    display: none;
  }
}
@media (--mobile) {
  .for-large {
    display: none;
  }
}
/**
 * BrowserSync
 */
#__bs_notify__ {
  display: none !important;
  font-size: 8px !important;
  opacity: 0.25;
  max-height: 50px;
}

.__debug_mihon__ {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  margin: 0 auto 0 auto;
  z-index: 100000;
  pointer-events: none;
  cursor: none;
  width: 100%;
  overflow: hidden;
  text-align: center;
  animation: __debug_mihon__ 1s infinite;
}

.__debug_mihon__ {
  padding-top: 0px;
  margin-top: 0px;
}

@-webkit-keyframes __debug_mihon__ {
  0% {
    opacity: 0;
  }
  60% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.5;
  }
}
/*
@example
 <div class="mihon">
  <img src="./images/sample1.png" alt="" class="type_pc">
  <img src="./images/sample2.png" alt="" class="type_sp">
</div>
*/