.c-pagenation {
  text-align: center;
}

.wp-pagenavi {
  display: flex;
  justify-content: center;
  font-size: 1.5rem;
  font-family: 'EB Garamond', serif;
  font-weight: bold;
  margin-top: 4rem;
  span, a {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3.6rem;
    height: 3.6rem;
    &::after {
      content: "";
      position: absolute;
      width: 2.1rem;
      height: 1px;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
      background: #171717;
    }
  }
  a {
    color: #7B7B7B;
    transition: .3s;
    &::after {
      width: 0;
      transition: .3s;
    }
    &:hover {
      color: #171717;
      &::after {
        content: "";
        width: 2.1rem;
      }
    }
  }
  span {
    color: #171717;
  }
  span.pages{
    display: none;
  }
}