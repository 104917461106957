.c-title {
  margin-top: 22rem;
  text-align: center;
  font-size: 1.5rem;
  @media (--mobile) {
    margin-top: 12rem;
    padding: 0 2rem;
  }
  strong {
    display: block;
    font-size: 3.6rem;
    margin-top: 1.4rem;
    font-weight: normal;
    letter-spacing: .2em;
    @media (--mobile) {
      font-size: 2.7rem;
    }
  }
}

.c-mv {
  position: relative;
  margin-top: 7rem;
  margin-bottom: 12rem;
  height: 38vw;

  &.staff{
    @media (--mobile) {
      height: 33rem;
    }
  }

  @media (--mobile) {
    margin-top: 5rem;
    margin-bottom: 4rem;
    height: 20rem;
  }

  img {
    object-fit: cover;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &.c-mv-feature{
      object-position: bottom;
    }
  }
}