.p-staff{
	width: 118rem;
	margin: 0 auto;
	@media (--mobile) {
		width: calc(100vw - 4rem);
  }

	.staff-list{
		display: grid;
		grid-template-columns: repeat(3,37rem);
		row-gap: 7rem;
		column-gap: 3.5rem;
		@media (--mobile) {
			grid-template-columns: repeat(2,1fr);
			gap: 2rem;
			row-gap: 4rem;
		}

		&__item{
			figure{
				width: 100%;
				height: 23rem;
				
				@media (--mobile) {
					height: 10rem;
				}

				img{
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}

			.staff-post{
				font-family: 'Shippori Mincho B1', serif;
				font-weight: 400;
				font-size: 1.2rem;
				letter-spacing: .1em;
				margin-top: 1rem;
			}

			.staff-name{
				display: flex;
				align-items: end;
				gap: 1.6rem;
				margin-top: .8rem;
				@media (--mobile) {
					flex-direction: column;
					align-items: flex-start;
					gap: .4rem;
				}

				&__jp{
					font-family: 'Shippori Mincho B1', serif;
					font-weight: 400;
					font-size: 1.6rem;
					letter-spacing: .1em;
				}

				&__en{
					font-family: "adobe-garamond-pro", serif;
					font-weight: 400;
					font-style: normal;
					font-size: 1.4rem;
					letter-spacing: .1em;
					@media (--mobile) {
						font-size: 1rem;
					}
				}
			}
		}
	}
	.c-breadcrumb{
		width: 100%;
	}
}