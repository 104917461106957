.p-contact-list{
	&__content{
		width: 98rem;
		margin: 0 auto;
		display: flex;
    justify-content: space-between;
		@media (--mobile) {
			width: 100%;
			display: block;
		}
	}
	&__title{
		width: 25rem;
		font-size: 2.6rem;
		@media (--mobile) {
			width: 100%;
			font-size: 2rem;
			margin-bottom: 2rem;
		}
		.en{
			display: block;
			font-family: 'EB Garamond', serif;
			font-size: 1.4rem;
			margin-bottom: 1.7rem;
		}
	}
	&__faq{
		width: 58rem;
		border-top: 1px solid #D0D0D0;
		border-bottom: 1px solid #D0D0D0;
		font-size: 2rem;
		position: relative;
		@media (--mobile) {
			width: 100%;
		}
		a{
			display: block;
			padding: 4rem 0;
		}
		&::after {
			border-right: solid 1px #707070;
			border-top: solid 1px #707070;
			content: "";
			display: block;
			width: 1.8rem;
			height: 1.8rem;
			position: absolute;
			right: 3rem;
			top: 40%;
			transform: rotate(45deg);
			pointer-events: none;
			@media (--mobile) {
				width: 1.2rem;
				height: 1.2rem;
				top: 45%;
				right: 2rem;
			}
		}
	}
}