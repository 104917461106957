.c-leftTtl, .c-centerTtl {
  small {
    font-size: 1.4rem;
    @media (--mobile) {
      font-size: 1.5rem;
    }
  }
  strong {
    display: block;
    margin-top: 1rem;
    font-size: 3rem;
    font-weight: normal;
    letter-spacing: .2em;
    line-height: 1.8;
    @media (--mobile) {
      letter-spacing: .1em;
      font-size: 2rem;
    }
    &.ja {
      font-size: 2.6rem;
      @media (--mobile) {
        font-size: 2.4rem;
      }
    }
  }
}

.c-centerTtl {
  text-align: center;
}