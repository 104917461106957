.p-movies{

	.list-container{
		flex: 1;
		.movies-img__ttl{
			font-size: 1.8rem;
		}
	}
	.list-cat{
		@media (--mobile) {
			padding: 0 3rem;
		}
	}


  .movies-modal{
    opacity: 0;
    pointer-events: none;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    transition: 0.3s;

    &.is-active{
      opacity: 1;
      pointer-events: auto;
    }

    &__overlay{
      position: absolute;
      width: 100%;
      height: 100vh;
      background: unquote("rgba(0, 0, 0, 0.8)");
      z-index: -1;
    }
    &__content{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: 70%;
      aspect-ratio: 5 / 3;

      @media (--mobile) {
        width: 90%;
      }

      iframe{
        width: 100%;
        height: 100%;
        display: inline-block;
      }
    }
    &__bg{
			width: 75%;
			aspect-ratio: 5 / 3.1;
			position: absolute;
			background: #111;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			z-index: -1;
			@media screen and (max-width: 767px) {
				display: none;
			}
		}

		&__close{
			cursor: pointer;
			width: 6rem;
			height: 6rem;
			background: #111;
			border-radius: 50%;
			position: absolute;
			top: -6rem;
			right: -6rem;

			@media (--mobile) {
				width: 4rem;
				height: 4rem;
				top: -5rem;
				right: 0rem;

				span{
					font-size: 2.3rem;
				}
			}

			span{
				color: #fff;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);
				position: absolute;
				font-size: 3rem;
				z-index: 30;
			}
		}
  }

}