@charset "utf-8";

// Foundation
@import "foundation/setting/_font",
"foundation/setting/_mediaquery",
"foundation/setting/_timing",
"foundation/setting/_function",
"foundation/setting/_mixin",
"foundation/setting/_regulation",
"foundation/setting/_animation",
"foundation/setting/_inview";

// vendor
@import "normalize.css"; /* == @import "../node_modules/normalize.css/normalize.css"; */
@import "swiper/swiper-bundle.min.css";
@import "modaal/dist/css/modaal.min.css";



@import "foundation/base/_vue";
@import "foundation/base/_structure";

// Layout
@import "layout/_footer.scss";
@import "layout/_header.scss";
@import "layout/_main.scss";
@import "layout/_sidebar.scss";

//pages
@import "object/project/_404.scss";
@import "object/project/_beauty.scss";
@import "object/project/_blog.scss";
@import "object/project/_common.scss";
@import "object/project/_company.scss";
@import "object/project/_concept.scss";
@import "object/project/_contact_list.scss";
@import "object/project/_contact.scss";
@import "object/project/_cost.scss";
@import "object/project/_counseling_detail.scss";
@import "object/project/_counseling.scss";
@import "object/project/_design_detail.scss";
@import "object/project/_design.scss";
@import "object/project/_faq.scss";
@import "object/project/_hairsalon_detail.scss";
@import "object/project/_land-search.scss";
@import "object/project/_line.scss";
@import "object/project/_link.scss";
@import "object/project/_movies.scss";
@import "object/project/_news_detail.scss";
@import "object/project/_news.scss";
@import "object/project/_privacypolicy.scss";
@import "object/project/_quality.scss";
@import "object/project/_restaurant_detail.scss";
@import "object/project/_restaurant.scss";
@import "object/project/_staff.scss";
@import "object/project/_support.scss";
@import "object/project/_thanks.scss";
@import "object/project/_top.scss";
@import "object/project/_traveling.scss";

// Object/Component
 
@import "object/component/_barba.scss";
@import "object/component/_bread.scss";
@import "object/component/_btn.scss";
@import "object/component/_c-breadcrumb.scss";
@import "object/component/_c-btn.scss";
@import "object/component/_c-inner.scss";
@import "object/component/_c-newsList.scss";
@import "object/component/_c-pagenation.scss";
@import "object/component/_c-sideImg.scss";
@import "object/component/_c-topParts.scss";
@import "object/component/_c-variousTtl.scss";
@import "object/component/_flex.scss";
@import "object/component/_form.scss";
@import "object/component/_iframe.scss";
@import "object/component/_inviewTxt.scss";
@import "object/component/_marquee.scss";
@import "object/component/_pagination.scss";
@import "object/component/_sns.scss";
@import "object/component/_specialFeature.scss";
@import "object/component/_webfonts.scss";
@import "object/component/_wordpress.scss";

// Object/Component
@import "object/utility/_font.scss";
@import "object/utility/_helper.scss";

// debug
@import "foundation/base/_debug";
