.p-detail {
  .c-title {
    margin-bottom: 6rem;
    padding-bottom: 7rem;
    border-bottom: 1px solid #D0D0D0;
    @media (--mobile) {
      margin-bottom: 3rem;
      padding-bottom: 3rem;
    }
  }
  &__inner {
    width: 78rem;
    margin-left: auto;
    margin-right: auto;
    @media (--mobile) {
      width: 100%;
    }
  }
  &__ttl {
    font-size: 2.4rem;
    letter-spacing: .2em;
    font-weight: lighter;
    @media (--mobile) {
      font-size: 2.2rem;
    }
  }
  &__catWrap {
    margin-top: 4.3rem;
    display: flex;
    justify-content: space-between;
    @media (--mobile) {
      margin-top: 2.3rem;
    }
  }
  &__cat {
    font-size: 1.3rem;
    border-bottom: 1px solid #C9C9C9;
  }
  &__date {
    font-size: 1.4rem;
    color: #7B7B7B;
  }
  &__mv {
    margin-top: 4rem;
    @media (--mobile) {
      margin-top: 2rem;
    }
    
     &.event{
      height: 50rem;
      @media (--mobile) {
        height: 21rem;
      }
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  &__btnWrap {
    display: flex;
    justify-content: space-between;
    margin-top: 5rem;
    & > * {
      margin-top: 0 !important;
    }
  }
  &__content {
    margin-top: 6rem;
    font-size: 1.5rem;
    line-height: 2;
    @media (--mobile) {
      margin-top: 4rem;
    }
    * {
      max-width: 100%;
    }
    img {
      margin-bottom: .5em;
      margin-top: 1.6em;
      display: block;
    }
    .wp-caption-text {
      font-size: 1.2rem;
    }
    h2 {
      font-size: 1.25em;
      font-weight: 700;
      color: #171717;
      line-height: 1.5;
      padding-bottom: .2em;
      border-bottom: 2px solid;
      margin-bottom: 2em;
    }
    h3 {
      font-size: 1.125em;
      padding-left: 1em;
      margin-bottom: 2em;
      font-weight: 700;
      position: relative;
    }
    h3:before {
      position: absolute;
      left: 0;
      top: .35em;
      content: '';
      width: 4px;
      height: 1.11em;
      line-height: 1.5;
      background: #171717;
    }
    h4 {
      font-size: 1.125em;
      padding: .2em 1em;
      margin-bottom: 2em;
      font-weight: 700;
      background: #eee;
    }
    h5 {
      font-weight: 700;
      line-height: 1.5;
      margin-bottom: 2em;
    }
    // p + p {
    //   margin-top: 1.75em;
    // }
    a {
      color: #171717;
      text-decoration: underline;
      transition: opacity .3s;
    }
    a:hover, a:active {
      opacity: .7;
    }
    ul li {
      position: relative;
      padding-left: 1.05em;
      line-height: 1.5;
    }
    ul li:before {
      content: '・';
      position: absolute;
      left: 0;
      top: 0;
    }
    ul li + li {
      margin-top: .5em;
    }
    ol {
      counter-reset: number 0;
    }
    ol li + li {
      margin-top: .5em;
    }
    ol li {
      position: relative;
      padding-left: 1.5em;
      line-height: 1.5;
    }
    ol li:before {
      counter-increment: number 1;
      content: counter(number) ".";
      font-size: 1.5em;
      font-family: 'Baloo Bhaijaan 2', cursive;
      line-height: 1.2;
      font-weight: 700;
      position: absolute;
      left: 0;
      bottom: 0;
    }
    ol .pp-addr {
      margin-top: .5em;
      padding-left: 1em;
      margin-bottom: 1.75em;
    }
    ol .pp-addr span {
      display: inline-block;
    }
    ol .pp-addr span:first-of-type {
      padding-right: 1em;
    }
    h2 + h2,
    h2 + h3,
    h2 + h4,
    h2 + h5,
    h3 + h2,
    h3 + h3,
    h3 + h4,
    h3 + h5,
    h4 + h2,
    h4 + h3,
    h4 + h4,
    h4 + h5,
    h5 + h2,
    h5 + h3,
    h5 + h4,
    h5 + h5 {
      margin-top: -.9em;
    }
    // p + p,
    // p + img,
    // p + ul,
    // p + ol,
    // img + p,
    // img + ul,
    // img + ol,
    // ul + ol,
    // ul + p,
    // ul + img,
    // ol + p,
    // ol + img,
    // ol + ul {
    //   margin-top: 1.75em;
    // }
  }
  .aligncenter {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .p-contact__left {
    padding-right: 0;
  }
  .p-contact__right {
    width: 100%;
  }
}