.p-error{
	&__txt{
		font-size: 1.6rem;
		line-height: 3.2rem;
		margin-top: 5rem;
		text-align: center;
		margin-bottom: 5rem;
	}
	&__btn{
		display: flex;
		align-items: center;
    justify-content: center;
		font-size: 1.5rem;
		margin: 0 auto;
		a{
			width: 30rem;
			padding: 2rem 2rem;
			border: 1px solid #D0D0D0;
			text-align: center;
			transition: all .3s;
			&:hover{
				border: 1px solid #FFF;
				background-color: #D0D0D0;
			}
		}
	}
}