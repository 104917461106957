.js-inview {
  &[data-inview="fade"]{
    opacity: 0;
    transition: opacity 1s;
    &.is-inview {
      opacity: 1;
    }
  }
  &[data-inview="fade-top"]{
    opacity: 0;
    transform: translateY(30px);
    transition: opacity 1s, transform 1s;
    &.is-inview {
      opacity: 1;
      transform: translateY(0);
    }
  }
  &[data-inview="fade-left"]{
    opacity: 0;
    transform: translateX(-30px);
    transition: opacity 1.5s, transform 1.5s;
    &.is-inview {
      opacity: 1;
      transform: translateX(0);
    }
  }
  &[data-inview="fade-right"]{
    opacity: 0;
    transform: translateX(30px);
    transition: opacity 1.5s, transform 1.5s;
    &.is-inview {
      opacity: 1;
      transform: translateX(0);
    }
  }
  &[data-inview="fade-bottom"] {
    opacity: 0;
    transform: translateY(-30px);
    transition: opacity 1.5s, transform 1.5s;
    &.is-inview {
      opacity: 1;
      transform: translateY(0);
    }
  }
  &[data-inview="scale"] {
    transform: scale(1.2);
    transition: transform 2s cubic-bezier(.3,.1,.1,1);
    &.is-inview {
      transform: scale(1);
    }
  }
  &[data-inview="btn"] {
    a,
    button {
      &::before {
        transform: scale(0, 1);
        transform-origin: left top;
        transition: transform .4s;
      }
    }
    span {
      opacity: 0;
      transform: translateY(10px);
      transition: opacity 1.5s .3s, transform 1.5s .3s;
    }
    i {
      opacity: 0;
      transform: translateY(10px);
      transition: opacity 1.5s .3s, transform 1.5s .3s;
    }
    &.is-inview {
      a,
      button {
        &::before {
          transform: scale(1, 1);
        }
      }
      span {
        opacity: 1;
        transform: translateY(0);
      }
      i {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
  &[data-inview="btn-border"] {
    a,
    button {
      &::after {
        transform: scale(0, 1);
        transform-origin: left top;
        transition: transform .4s;
      }
    }
    span {
      opacity: 0;
      transform: translateY(10px);
      transition: opacity 1.5s .3s, transform 1.5s .3s;
    }
    &.is-inview {
      a,
      button {
        &::after {
          transform: scale(1, 1);
        }
      }
      span {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
  &[data-inview="bg"]{
    &::after {
      content: "";
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      transform: scale(1, 1);
      transform-origin: right top;
      transition: transform .4s;
      width: 100%;
    }
    &.is-inview {
      &::after {
        transform: scale(0, 1);
      }
    }
  }
  &[data-inview="bg-white"]{
    &::after {
      background-color: #fff;
      content: "";
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      transform: scale(1, 1);
      transform-origin: right top;
      transition: transform .4s;
      width: 100%;
    }
    &.is-inview {
      &::after {
        transform: scale(0, 1);
      }
    }
  }
  &[data-inview="bg-scale"]{
    &::before {
      transform: translate(-50%, -50%) scale(0.5);
      transform-origin: center center;
      transition: transform 1.5s;
    }
    &.is-inview {
      &::before {
        transform: translate(-50%, -50%) scale(1);
      }
    }
  }
  &[data-inview="clip"] {
    clip-path: inset(0 0 0 100%);
    transition: clip-path 1.5s .3s;
    &.is-inview {
      clip-path: inset(0 0 0 0);
    }
  }
  &-photo[data-inview="scale-small"]{
    transform: scale(1.5);
    transition: transform 1s;
    @at-root .is-inview & {
      transform: scale(1);
    }
  }
  &-photo-img[data-inview="scale-small"]{
    transform: translateX(-50%) scale(1.5);
    transition: transform 1s;
    @at-root .is-inview & {
      transform: translateX(-50%) scale(1);
    }
  }
  &-eachItem {
    &[data-inview="fade"] {
      opacity: 0;
      transition: opacity 1.5s;
      &.is-inview {
        opacity: 1;
      }
    }
    &[data-inview="fade-top"] {
      opacity: 0;
      transform: translateY(30px);
      transition: opacity 1.5s, transform 1.5s;
      &.is-inview {
        opacity: 1;
        transform: translateY(0);
      }
    }
    &[data-inview="fade-bottom"] {
      opacity: 0;
      transform: translateY(-30px);
      transition: opacity 1.5s, transform 1.5s;
      &.is-inview {
        opacity: 1;
        transform: translateY(0);
      }
    }
    &[data-inview="fade-right"] {
      opacity: 0;
      transform: translateX(30px);
      transition: opacity 1.5s, transform 1.5s;
      &.is-inview {
        opacity: 1;
        transform: translateX(0);
      }
    }
    &[data-inview="btn"] {
      a,
      button {
        &::before {
          transform: scale(0, 1);
          transform-origin: left top;
          transition: transform .4s;
        }
      }
      span {
        opacity: 0;
        transform: translateY(10px);
        transition: opacity 1.5s .3s, transform 1.5s .3s;
      }
      &.is-inview {
        a,
        button {
          &::before {
            transform: scale(1, 1);
          }
        }
        span {
          opacity: 1;
          transform: translateY(0);
        }
      }
    }
    &[data-inview="btn-border"] {
      a,
      button {
        &::after {
          transform: scale(0, 1);
          transform-origin: left top;
          transition: transform .4s;
        }
      }
      span {
        opacity: 0;
        transform: translateY(10px);
        transition: opacity 1.5s .3s, transform 1.5s .3s;
      }
      &.is-inview {
        a,
        button {
          &::after {
            transform: scale(1, 1);
          }
        }
        span {
          opacity: 1;
          transform: translateY(0);
        }
      }
    }
    &[data-inview="list-border"] {
      &::before {
        transform: scale(0, 1);
        transform-origin: left top;
        transition: transform 1.5s;
      }
      span,
      figure,
      a {
        opacity: 0;
        transform: translateY(30px);
        transition: opacity 1.5s, transform 1.5s;
      }
      i {
        opacity: 0;
        transform: translateX(-20px) rotate(45deg);
        transition: opacity 1.5s, transform 1.5s;
      }
      &.is-inview {
        &::before {
          transform: scale(1);
        }
        span,
        figure,
        a {
          opacity: 1;
          transform: translateY(0);
        }
        i {
          opacity: 1;
          transform: translateX(-10px) rotate(45deg);
        }
      }
    }
    &[data-inview="clip"] {
      clip-path: inset(0 100% 0 0);
      transition: clip-path 1.5s .3s;
      &.is-inview {
        clip-path: inset(0 0 0 0);
      }
    }
  }
  &-eachItem-fast {
    &[data-inview="fade-top"] {
      opacity: 0;
      transform: translateY(10px);
      transition: opacity 1.5s, transform 1.5s;
      &.is-inview {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
  &-eachItem-delay {
    &[data-inview="fade"] {
      opacity: 0;
      transition: transform 1.5s;
      &.is-inview {
        opacity: 1;
        transform: translateY(0);
      }
    }
    &[data-inview="fade-top"] {
      opacity: 0;
      transform: translateY(30px);
      transition: opacity 1.5s, transform 1.5s;
      &.is-inview {
        opacity: 1;
        transform: translateY(0);
      }
    }
    &[data-inview="fade-right"] {
      opacity: 0;
      transform: translateX(30px);
      transition: opacity 1.5s, transform 1.5s;
      &.is-inview {
        opacity: 1;
        transform: translateY(0);
      }
    }
    &[data-inview="fade-bottom"] {
      opacity: 0;
      transform: translateY(-30px);
      transition: opacity 1.5s, transform 1.5s;
      &.is-inview {
        opacity: 1;
        transform: translateY(0);
      }
    }
    &[data-inview="clip"] {
      clip-path: inset(0 0 0 100%);
      transition: clip-path 1.5s .3s;
      &.is-inview {
        clip-path: inset(0 0 0 0);
      }
    }
  }
}
.js-inview-all {
  &[data-inview="btn"] {
    a,
    button {
      &::before {
        transform: scale(0, 1);
        transform-origin: left top;
        transition: transform .4s;
      }
    }
    span {
      opacity: 0;
      transform: translateY(10px);
      transition: opacity 1.5s .3s, transform 1.5s .3s;
    }
    &.is-inview {
      a,
      button {
        &::before {
          transform: scale(1, 1);
        }
      }
      span {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
  &[data-inview="btn-border"] {
    a,
    button {
      &::after {
        transform: scale(0, 1);
        transform-origin: left top;
        transition: transform .4s;
      }
    }
    span {
      opacity: 0;
      transform: translateY(10px);
      transition: opacity 1.5s .3s, transform 1.5s .3s;
    }
    &.is-inview {
      a,
      button {
        &::after {
          transform: scale(1, 1);
        }
      }
      span {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
  &[data-inview="clip"] {
    clip-path: inset(0 0 0 100%);
    transition: clip-path 1.5s .3s;
    &.is-inview {
      clip-path: inset(0 0 0 0);
    }
  }
}

_:-ms-lang(x)::-ms-backdrop, .js-inview-photo[data-inview="scale-small"] {
  transform: scale(1);
}

_:-ms-lang(x)::-ms-backdrop, .js-inview-photo-img[data-inview="scale-small"] {
  transform: translateX(-50%) scale(1);
}
