@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@500&Shippori+Mincho+B1&display=swap');
.garamond {
  font-family: 'EB Garamond', serif;
  font-weight: lighter;
}
.tsukushi {
  font-family: TsukuGoPr5-R-Between2-Light;
  font-weight: lighter;
}
.notoSans {
  font-family: 'Noto Sans JP', sans-serif;
}
.tsukushiB {
  font-family: TsukuGoPro-B;
}
.tsukushiM {
  font-family: TsukuGoPr5-R;
}
.shippori {
  font-family: 'Shippori Mincho B1', serif;
}
.roboto{
  font-family: "Roboto", serif;
}
.garamond{
  font-family: "adobe-garamond-pro", serif;
  font-weight: 400;
  font-style: normal;
}