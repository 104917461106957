.p-blog{
	&__content {
		ul.c-newsList{
			li{
				@media (--large) {
				a{
					div{
						p{
							width: 18.5rem;
						}
					}
					.ttl{
						width: calc(100% - 18.5rem);
					}
				}
				}
			}
		}
	}
}