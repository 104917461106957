.loading {
  position: absolute;
  top: 0;
  left: 0;
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  z-index: 9999;
  overflow: hidden;
  &.no-loading {
    position: absolute;
    pointer-events: none;
  }
  &__txt01 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(.5) translateY(-1.7vw);
    width: 60vw;
    z-index: 1;
    @at-root .no-loading & {
      transform: translate(-50%, -50%) translateY(-.9vw);
      @media (--mobile) {
        transform: translate(-50%, -50%) translateY(-.9vw) scale(1.5, 1.5);
      }
    }
    @media (--mobile) {
      width: 100vw;
      transform: translate(-50%, -50%) scale(.8) translateY(-1.7vw);
    }
    path {
      opacity: 0;
      transform: translateY(4rem);
      &.is-active {
        fill: #fff;
      }
      @at-root .on-loading & {
        transition: 1s fill;
      }
      @at-root .no-loading & {
        opacity: 1;
        fill: #fff;
        transform: inherit;
      }
      &.delete {
        @at-root .no-loading & {
          opacity: 0;
        }
      }
      &.initial_d {
        @at-root .no-loading & {
          transform: translateX(24.5rem);
          @media (--mobile) {
            transform: matrix(1, 0, 0, 1, 222, 0);
          }
        }
      }
      &.initial_i {
        @at-root .no-loading & {
          transform: translateX(7.5rem);
          @media (--mobile) {
            transform: matrix(1, 0, 0, 1, 49, 0);
          }
        }
      }
      &.initial_p {
        @at-root .no-loading & {
          transform: translateX(-19rem);
          @media (--mobile) {
            transform: matrix(1, 0, 0, 1, -217, 0);
          }
        }
      }
    }
  }
  &__txt02 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) translateY(-.3vw) translateX(3.7vw);
    width: 12vw;
    opacity: 0;
    z-index: 1;
    @at-root .no-loading & {
      opacity: 1;
    }
    @media (--mobile) {
      width: 40vw;
      transform: translate(-50%, -50%) translateY(-.3vw) translateX(9.7vw);
    }
    path {
      fill: #000;
      &.is-active {
        fill: #fff;
      }
      @at-root .on-loading & {
        transition: 1s fill;
      }
      @at-root .no-loading & {
        fill: #fff;
      }
    }
  }
  &__txt03 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) translateY(3.8vw) translateX(0);
    width: 14vw;
    z-index: 1;
    @media (--mobile) {
      transform: translate(-50%, -50%) translateY(10.8vw) translateX(0);
      width: 50vw;
    }
    g {
      path {
        @at-root .on-loading & {
          transition: 1s fill, 1s stroke;
        }
        &:nth-of-type(odd) {
          fill: #000;
          &.is-active {
            fill: #fff;
          }
          @at-root .no-loading & {
            fill: #fff;
          }
        }
        &:nth-of-type(even) {
          stroke: #000;
          &.is-active {
            stroke: #fff;
          }

          @at-root .no-loading & {
            stroke: #fff;
          }
        }
      }
    }
  }
  &_bg_white {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    height: calc(var(--vh, 1vh) * 100 + .1rem);
    width: 100%;
    background: #fff;
    @at-root .no-loading & {
      display: none;
    }
  }
  &_bg_black {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    background: #000;
    height: calc(var(--vh, 1vh) * 100);
    width: 100vw;
    transform: translateY(-100%);
  }
}

.mouse_stalker {
  background: rgba(0, 0, 0, .6);
  position: fixed;
  z-index: 99;
  transform: translate(-50%, -50%) translate(-0.3rem, -1.5rem);
  border-radius: 999rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 15px;
  pointer-events: none;
  opacity: 0;
  width: 0;
  height: 0;
  transition: .3s width, .3s height, .3s opacity;
  p {
    overflow: hidden;
    line-height: 1;
    white-space: nowrap;
    opacity: 0.8;
    span {
      display: inline-block;
      transform: translateY(1.2em);
      transition: .3s;
      @for $i from 1 through 4 {
        &:nth-of-type(#{$i}) {
          transition-delay: #{0.03 * $i}s !important;
        }
      }
    }
  }
  &.is-active {
    opacity: 1;
    width: 7rem;
    height: 7rem;
    p {
      span {
        transform: translateY(0);
      }
    }
  }
}

.p-top {
  .p-mv {
    position: relative;
    @media (--mobile) {
      // height: calc(var(--vh, 1vh) * 100);
    }
    &__tegaki {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 35vw;
      z-index: 2;
      @media (--mobile) {
        width: 80vw;
      }
    }
    .mvSwiper {
      margin: 0 0 0 auto;
      @media (--mobile) {
        width: 100%;
      }
      .swiper-slide {
        picture {
          display: block;
          position: relative;
          height: calc(var(--vh, 1vh) * 100);
          transform: scale(1.05);
          overflow: hidden;
          // transition: 1s;
          img {
            position: absolute;
            display: block;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) scale(1);
            height: 100%;
            width: 100%;
            object-fit: cover;
            transition: 10s linear;
          }
        }
        &.swiper-slide-active {
          picture {
            img {
              transform: translate(-50%, -50%) scale(1.05);
            }
          }
        }
      }
    }
    .swiper-pagination {
      width: auto;
      position: absolute;
      left: calc(13rem / 2);
      transform: translateX(-50%);
      bottom: 4rem;
      width: auto;
      @media (--mobile) {
        display: none;
      }
    }
    .swiper-pagination-bullet {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'EB Garamond', serif;
      background: inherit;
      font-size: 1.4rem;
      width: 4rem;
      height: 4rem;
      outline: none;
      color: #fff;
      opacity: 1 !important;
      &:nth-of-type(n + 2) {
        margin-top: 2rem;
      }
      svg {
        transition: 1s;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        transform: translate(-50%, -50%) rotate(-90deg);
        opacity: 0;
      }
      &.swiper-pagination-bullet-active {
        .bg {
          opacity: 1;
        }
        .anime {
          opacity: 1;
          .svg-elem-1 {
            animation: animate-svg-stroke-1 3s linear 0s both;
            @keyframes animate-svg-stroke-1 {
              0% {
                stroke-dashoffset: 137.0884841043611px;
                stroke-dasharray: 137.0884841043611px;
              }

              100% {
                stroke-dashoffset: 0;
                stroke-dasharray: 137.0884841043611px;
              }
            }
          }
        }
      }
    }

    &__event {
      position: absolute;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      border-radius: 9999px;
      bottom: 8rem;
      right: 2rem;
      z-index: 2;
      height: 15rem;
      width: 15rem;
      background: #000;
      @media (--mobile) {
        display: none;
      }
      a{
        font-size: 1.6rem;
        color: #fff;
      }
    }

    &__news {
      position: absolute;
      display: flex;
      align-items: center;
      bottom: 0;
      right: 0;
      z-index: 2;
      height: 7rem;
      @media (--mobile) {
        width: 100%;
        height: 8rem;
      }
    }
    &__newsTtl {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      width: 7rem;
      height: 100%;
      background: #171717;
      font-size: 1.6rem;
      @media (--mobile) {
        font-size: 1.3rem;
      }
    }
    &__newsWrap {
      display: flex;
      align-items: center;
      @media (--mobile) {
        margin-bottom: .5rem;
      }
    }
    &__newsLink {
      display: flex;
      align-items: center;
      padding: 0 2.7rem 0 3rem;
      background: #fff;
      height: 100%;
      @media (--mobile) {
        position: relative;
        width: calc(100% - 7rem);
        display: flex;
        align-items: center;
        padding: 0 1rem;
        &::after {
          content: "";
          position: absolute;
          right: 1.5rem;
          top: 50%;
          transform: translateY(-50%) rotate(45deg);
          height: 1.5rem;
          width: 1.5rem;
          border-top: 1px solid #707070;
          border-right: 1px solid #707070;
        }
      }
      @media (--large) {
        div {
          display: flex;
          align-items: center;
        }
      }
      &:hover {
        .p-mv__newsTxt {
          &::after {
            right: -1rem;
          }
        }
      }
    }
    &__newsYear {
      writing-mode: tb;
      font-size: 1rem;
      margin-right: .5rem;
    }
    &__newsDate {
      font-size: 2.4rem;
      margin-right: 1rem;
      @media (--mobile) {
        line-height: 1;
      }
    }
    &__newsCat {
      border-bottom: 1px solid #C9C9C9;
    }
    &__newsTxt {
      position: relative;
      font-size: 1.4rem;
      padding-right: 3rem;
      letter-spacing: .1em;
      @media (--mobile) {
        position: relative;
        left: .5rem;
        width: 26rem;
        padding-right: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      @media (--large) {
        &::after {
          content: "";
          position: absolute;
          top: 50%;
          transform: translateY(-50%) rotate(45deg);
          right: 0;
          height: 1.5rem;
          width: 1.5rem;
          border-top: .1rem solid #707070;
          border-right: .1rem solid #707070;
          transition: .5s;
        }
      }
    }
    &__txt {
      position: absolute;
      z-index: 3;
      top: 50%;
      left: 50%;
      text-align: center;
      transform: translate(-50%, -50%) translateY(-10vw);
      letter-spacing: .2em;
      font-size: 1.5vw;
      color: #fff;
      line-height: 2;
      @media (--mobile) {
        transform: translate(-50%, -50%) translateY(-10rem);
        font-size: 1.7rem;
        white-space: nowrap;
      }
    }

    /* キャンペーン用 */
    &__campaign{
      position: relative;
      display: block;
      height: auto;
      width: auto;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      &-link{
        position: absolute;
        display: block;
        bottom: 5.3%;
        right: 6.7%;
        width: 21vw;
        height: 6vw;
        // background-color: rgba(0, 0, 0, 0.5);

        @media (--mobile) {
          bottom: 4.1%;
          right: 9.7%;
          width: 18.2vw;
          height: 5.2vw;
          // background-color: rgba(0, 0, 0, 0.5);
        }

      }
    }
  }
  .p-concept, .p-quality {
    position: relative;
    margin-top: 10.2rem;
    display: flex;
    justify-content: center;
    flex-direction: row-reverse;
    @media (--mobile) {
      margin-top: 6rem;
      align-items: center;
      flex-direction: column;
    }
    &__tegaki {
      position: absolute;
      left: 50%;
      transform: translateX(-50%) translateX(-22rem);
      bottom: 0;
      @media (--mobile) {
        transform: translateX(-50%) translateX(-6rem) translateY(4rem);
        svg {
          width: 18rem;
        }
      }
    }
    &__ttl {
      display: flex;
      align-items: flex-start;
      flex-direction: row-reverse;
      margin-left: 11.7rem;
      @media (--mobile) {
        margin-left: 0;
      }
      small {
        position: relative;
        display: block;
        writing-mode: tb;
        margin-left: 1.5rem;
        font-size: 1.5rem;
        padding-top: 6.5rem;
        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%) translateX(.2rem);
          height: 5rem;
          width: .1rem;
          background: #000;
          transition: .3s;
          opacity: 0;
        }
        @media (--mobile) {
          display: block;
          margin-left: 1.6rem;
        }
        &.is-inview {
          &::after {
            opacity: 1;
            animation: pathmove 2s cubic-bezier(0.645, 0.045, 0.355, 1) 0s infinite;
          }
        }
      }
      @keyframes pathmove {
        0% {
          transform: scaleY(0);
          transform-origin: center top;
        }
        50% {
          transform: scaleY(1);
          transform-origin: center top;
        }
        51% {
          transform: scaleY(1);
          transform-origin: center bottom;
        }
        100% {
          transform: scaleY(0);
          transform-origin: center bottom;
        }
      }
      strong {
        display: flex;
        align-items: flex-start;
        font-size: 2.6rem;
        font-weight: lighter;
        width: 7rem;
        svg {
          transform: translateY(-1.5rem);
          opacity: 0;
          transition: 1s;
          width: 2.2rem;
          &:first-of-type {
            margin-right: 2rem;
            transition-delay: .3s;
          }
        }
        &.is-inview {
          svg {
            opacity: 1;
            transform: translateY(0);
          }
        }
      }
    }
    &__content {
      position: relative;
      font-size: 1.4rem;
      margin-top: 3rem;
      padding-bottom: 6rem;
      @media (--mobile) {
        padding-bottom: 0;
        width: 100%;
        margin-top: 4rem;
      }
      p {
        line-height: 2.2;
        @media (--mobile) {
          display: flex;
          justify-content: center;
        }
      }
    }
  }
  .p-quality {
    @media (--large) {
      flex-direction: inherit;
      &__ttl {
        margin-right: 11.7rem;
        margin-left: 0;
      }
    }
    &__content {
      &::after {
        @media (--large) {
          width: 20.2rem;
          height: 13.6rem;
        }
        background-image: url(../images/top/handwritten_ttl02.svg);
      }
    }
    &__tegaki {
      position: absolute;
      left: 50%;
      transform: translateX(-50%) translateX(0rem);
      bottom: 3rem;
      @media (--mobile) {
        transform: translateX(-50%) translateX(-6rem) translateY(8rem);
      }
    }
  }
  .p-concept-img {
    display: flex;
    margin-top: 14rem;
    @media (--mobile) {
      display: block;
      margin-top: 7rem;
    }
    &__left, &__center {
      width: 37%;
      figure {
        height: 30rem;
        overflow: hidden;
        margin: 0 !important;
        img {
          object-fit: cover;
          height: 100%;
          width: 100%;
          transform: scale(1.1) translateY(-1rem);
        }
      }
    }
    &__center {
      figure {
        img {
          transform: scale(1.1) translateY(1rem);
        }
      }
    }
    &__right {
      width: calc(100% - (37% * 2));
      figure {
        height: 60rem;
        overflow: hidden;
        img {
          object-fit: cover;
          height: 100%;
          width: 100%;
          transform: scale(1.1) translateY(-1rem);
        }
      }
    }
    @media (--mobile) {
      display: flex;
      flex-wrap: wrap;
      &__left {
        display: flex;
        width: 100%;
        figure {
          height: 16rem;
        }
      }
      &__center,&__right {
        width: 50%;
      }
      &__center {
        figure {
          height: 16rem;
        }
      }
      &__right {
        figure {
          height: calc(16rem * 2);
        }
      }
    }
  }
  .p-quality-img {
    // height: 70rem;
    width: 100%;
    position: relative;
    margin-top: 8rem;
    @media (--mobile) {
      // height: 25rem;
      margin-top: 8rem;
    }
    picture {
      display: block;
      overflow: hidden;
      height: 32vw;
      @media (--mobile) {
        height: 60vw;
      }
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        transform: translateY(3rem) scale(1.1);
      }
    }
  }
  .p-design {
    margin-top: 15rem;
    @media (--mobile) {
      margin-top: 8rem;
    }
    &__ttl {
      text-align: center;
      & > * {
        display: block;
        font-size: 2rem;
        font-weight: lighter;
      }
      strong {
        display: inline-block;
      }
      @media (--mobile) {
        strong {
          width: 21rem;
          display: block;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
    &__trigger {
      display: flex;
      justify-content: center;
      font-size: 1.4rem;
      margin-top: 5rem;
      width: 58rem;
      margin-left: auto;
      margin-right: auto;
      @media (--mobile) {
        margin-top: 2.5rem;
        padding: 0 2rem;
        width: 100%;
      }
      li {
        width: 12.7rem;
        border-bottom: 1px solid #D0D0D0;
        text-align: center;
        padding-bottom: 1rem;
        cursor: pointer;
        transition: .3s border-color;
        @media (--mobile) {
          font-size: 1.2rem;
        }
        &:nth-of-type(n + 2) {
          margin-left: 2.6rem;
          @media (--mobile) {
            margin-left: 1rem;
          }
        }
        &.is-active {
          border-color: #171717;
          pointer-events: none;
        }
      }
    }
    &__tag {
      width: 118rem;
      margin-left: auto;
      margin-right: auto;
      margin-top: 5rem;
      display: flex;
      flex-wrap: wrap;
      @media (--mobile) {
        padding: 0 3rem;
        width: auto;
        margin-top: 3rem;
      }
      a {
        display: block;
        padding: 6px 8px;
        color: #7B7B7B;
        transition: .3s all;
        margin-right: 0.8rem;
        font-size: 1.2rem;
        color: #707070;
        border: 1px solid #CECECE;
        margin-top: 1rem;
        &:hover {
          background: #7B7B7B;
          color: #FFFFFF;
        }
      }
    }
    &__listWrap {
      margin-top: 6rem;
      @media (--mobile) {
        margin-top: 4rem;
      }
      &.is-active {
        display: block !important;
      }
    }
    &__list {
      width: 118rem;
      margin-left: auto;
      margin-right: auto;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 6rem 4rem;
      @media (--mobile) {
        grid-template-columns: repeat(1, 1fr);
        gap: 4rem 0;
        width: 100%;
      }
      .swiper-wrapper {
        @media (--large) {
          justify-content: space-between;
          flex-wrap: wrap;
        }
      }
      a {
        width: 57rem;
        display: block;
        position: relative;
        @media (--mobile) {
          width: 100%;
        }

        figure {
          position: relative;
          height: 35rem;
          overflow: hidden;
          @media (--mobile) {
            height: 22rem;
            width: calc(100%);
          }
          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            transition: 1s;
          }
        }
        .cat {
          position: absolute;
          right: 3rem;
          top: 31rem;
          z-index: 9;
          font-size: 1.3rem;
          color: #171717;
          color: #FFF;
          text-shadow: 0 0 2rem #000, 0 0 2rem #000;
          @media (--mobile) {
            right: 2rem;
            top: 18.5rem;
          }
        }
        .txtWrap {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          z-index: 2;
          background: linear-gradient(to bottom, rgba(31, 31, 31, 0), rgba(54, 54, 54, .72));
          padding: 8.4rem 3rem 2.4rem;
          width: 100%;
          @media (--mobile) {
            position: relative;
            right: inherit;
            padding: 1.8rem 0 0 0;
            width: 100%;
            background: none;
          }
          p {
            font-size: 1.2rem;
            color: #fff;
            @media (--mobile) {
              font-size: 1.2rem;
              color: #171717;
            }
          }
          h4 {
            font-size: 1.8rem;
            color: #fff;
            margin-top: .5rem;
            font-weight: lighter;
            padding-left: 3rem;
            @media (--mobile) {
              font-size: 1.6rem;
              color: #171717;
            }
          }
        }
        &:hover {
          figure {
            img {
              transform: scale(1.05);
            }
          }
        }
      }
      .swiper-slide {

      }
    }
    &__more {
      width: 118rem;
      margin-left: auto;
      margin-right: auto;
      margin-top: 3rem;
      @media (--mobile) {
        padding: 0 4rem;
        width: 100%;
      }
      .c-moreBtn {
        margin-top: 0;
        @media (--mobile) {
          justify-content: flex-end;
        }
      }
    }

    &__btn {
      margin-top: 6rem;
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 38rem;
        border: 2px solid #333333;
        margin-inline: auto;
        font-size: 2rem;
        font-weight: 500;
        letter-spacing: 0.05em;
        line-height: 1.2;
        color: #333333;
        text-align: center;
        padding: 2.5rem 0;
        transition: .3s;
        @media (--mobile){
          width: 100%;
          font-size: 1.6rem;
          padding: 2rem 0;
        }
        svg {
          margin-left: 3rem;
          width: 3rem;
          height: 3rem;
          @media (--mobile){
            margin-left: 1.5rem;
            width: 2.9rem;
            height: 2.9rem;
          }
        }

        @media (--large){
          &:hover {
            background-color: #333333;
            color: #fff;

            svg {
              circle:first-of-type {
                fill: #fff;
              }
              path {
                stroke: #171717;
              }
              g{
                stroke: #fff;
              }
            }
          }
        }
      }
    }
  }
  .p-list {
    margin-top: 14rem;
    @media (--mobile){
      margin-top: 8rem;
    }
    &__container {
      width: 118rem;
      margin-inline: auto;
      @media (--mobile){
        width: 100%;
      }
    }

    &__item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      @media (--mobile){
        flex-direction: column;
      }

      &:nth-of-type( 2 ){
        flex-direction: row-reverse;
        margin-top: 8rem;
        @media (--mobile){
          flex-direction: column;
          margin-top: 6rem;
        }
      }

      figure {
        width: 56rem;
        height: auto;
        @media (--mobile){
          width: 100%;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      & > div {
        width: 48rem;
        @media (--mobile){
          width: 100%;
          margin-top: 3rem;
        }
        h3 {
          font-size: 2.6rem;
          font-weight: 400;
          letter-spacing: 0.1em;
          line-height: 1.4;
          color: #171717;
          @media (--mobile){
            font-size: 2rem;
          }
        }

        p {
          display: block;
          margin-top: 4rem;
          font-size: 1.6rem;
          font-weight: 400;
          letter-spacing: 0em;
          line-height: 1.8;
          color: #333333;
          @media (--mobile){
            margin-top: 2.4rem;

          }
        }
      }
    }

    &__feature{
      width: 118rem;
      margin-left: auto;
      margin-right: auto;
      margin-top: 8rem;
      @media (--mobile) {
        width: 100%;
      }
      ul{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 3.5rem;
        @media (--mobile) {
          grid-template-columns: repeat(1, 1fr);
          gap: 2rem 0;
        }
        li{
          a{
            &:hover{
              .img{
                img{
                  transform: scale(1.1);
                }
              }
            }
            .img{
              position: relative;
              width: 37rem;
              height: 20rem;
              overflow: hidden;
              @media (--mobile) {
                width: 100%;
                height: 14rem;
              }
              img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: 0.4s;

              }
              p{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                color: #FFF;
                font-size: 2.4rem;
                text-align: center;
                small{
                  font-size: 1.4rem;
                  display: block;
                }
                @media (--mobile) {
                  font-size: 2.2rem;
                  white-space: nowrap;
                }
              }
            }
          }
        }
      }
    }
  }
  .p-movie {
    position: relative;
    margin-top: 15rem;
    overflow: hidden;
    @media (--mobile) {
      margin-top: 8rem;
      padding-bottom: 8rem;
    }
    &::after, &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: #333333;
      z-index: 2;
    }
    &::after {
      background: rgba(51, 51, 51, 0);
    }
    &::before {
      z-index: -1;
    }
    .c-inner {
      padding-top: 14rem;
      padding-bottom: 14rem;
      @media (--mobile) {
        padding-top: 8rem;
        padding-bottom: 0rem;
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }
    & > * {
      position: relative;
      z-index: 3;
      strong {
        display: inline-block;
        margin-left: auto;
        margin-right: auto;
        @media (--mobile) {
          width: 20rem;
        }
      }
    }
    &__bgImg {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
      opacity: 0;
      z-index: 1;
      transition: .5s opacity;
      @media (--mobile) {
        display: none;
      }
      &.is-active {
        opacity: 1;
      }
    }
    &__ttl {
      text-align: center;
      color: #fff;
      & > * {
        display: block;
        font-size: 2.6rem;
        font-weight: lighter;
        @media (--mobile) {
          font-size: 2rem;
        }
      }
    }
    .c-leftTtl {
      width: 118rem;
      margin-left: auto;
      margin-right: auto;
      @media (--mobile) {
        width: 100%;
      }
    }
    &__list {
      display: flex;
      justify-content: space-between;
      margin-top: 6.5rem;
      @media (--mobile) {
        border: none;
        display: block;
        margin-top: 5rem;
      }
      li {
        width: 37.4rem;
        @media (--mobile) {
          width: 100%;

          &:nth-of-type(n + 2) {
            margin-top: 5rem;
          }
        }
      }
    }
    &__ele {
      cursor: pointer;
      @media (--large) {
        transition: .5s opacity;
        // &.is-active, &.is-siblings {
        //   .p-movie__img {
        //     border: .1rem solid rgba(255, 255, 255, 1);
        //     img {
        //       opacity: 0;
        //     }
        //   }
        // }
        // &.is-active {
        //   .p-movie__img {
        //     img {
        //       transform: scale(1.05);
        //     }
        //   }
        // }
        // &.is-siblings {
        //   .p-movie__img {
        //     border: .1rem solid rgba(255, 255, 255, .3);
        //   }
        //   .p-movie__ttlArea {
        //     opacity: .5;
        //   }
        // }
      }
      @media (--mobile) {
        opacity: 1 !important;
      }
      a {
        @media (--large){
          &:hover {
            .p-movie__img {
              img {
                transform: scale(1.1);
              }
            }
          }
        }
      }
    }
    &__img {
      position: relative;
      overflow: hidden;
      border: .1rem solid rgba(255, 255, 255, 0);
      transition: .5s;
      z-index: 1;
      // &::before {
      //   position: absolute;
      //   content: "";
      //   top: 0;
      //   left: 0;
      //   width: 100%;
      //   height: 100%;
      //   background-color: rgba(38, 38, 38, 0.5);
      //   z-index: 2;
      // }
      img {
        transition: .5s;
      }
    }
    &__ttlArea {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 2.4rem;
      @media (--mobile){
        margin-left: 3rem;
      }
      &, * {
        transition: .5s;
      }
    }
    &__txt {
      text-align: left;
      font-weight: lighter;
      color: #fff;
      small {
        font-size: 1.3rem;
      }
      p {
        font-size: 1.8rem;
        letter-spacing: 0.2em;
        @media (--mobile) {
          margin-top: .4rem;
        }
      }
    }
    &__link {
      position: absolute;
      top: -16rem;
      left: 50%;
      transform: translateX(-50%);
      margin-top: 0;
      z-index: 3;
      @media (--mobile){
        transform: translateX(-50%) translateX(-2rem);
      }
      span {
        font-size: 1.3rem;
        color: #fff;
      }
      svg {
        margin-left: 1.2rem;
        circle {
          &:first-of-type {
            fill: rgba(255, 255, 255, 0);
            transition: .5s fill;
          }
        }
        path {
          stroke: #fff;
        }
      }
    }
    &__ele {
      &:hover {
        .p-movie__link {
          circle {
            &:first-of-type {
              fill: #fff;
            }
          }
          path {
            stroke: #333333;
          }
        }
      }
    }

    .c-moreBtn {
      @media (--mobile){
        margin-right: 3rem;
      }
      a {
        color: rgba(255, 255, 255, 0.8);
        svg {
          margin-left: 1.5rem;
          circle:first-of-type {
            fill: #333;            
          }
          circle:last-of-type {
            stroke: #fff;
          }
          path {
            stroke: #fff !important;
          }
          g{
            fill: #fff;
            stroke: #333333 !important;
          }
        }
        @media (--large){
          &:hover {
            svg {
              path {
                stroke: #000 !important;
              }
            }
            circle:first-of-type {
              fill: #fff !important;
            }
            &, * {
              transition: .5s;
            }
          }
        }
      }
    }
  }
  .p-blog {
    margin-top: 14.2rem;
    @media (--mobile) {
      margin-top: 6rem;
    }
    &__inner {
      @media (--mobile){
        padding: 0 !important;
      }
    }
    .c-leftTtl {
      width: 128rem;
      margin: 0 auto;
      strong {
        font-size: 3.2rem;
      }
      @media (--mobile) {
        width: auto;
        padding: 0 3rem;
      }
    }
    ul {
      margin-top: 3.5rem;
      display: flex;
      justify-content: space-between;
      gap: 0 2rem;
      @media (--mobile) {
        margin-top: 2rem;
        flex-direction: column;
      }
      li {
        width: calc(100% / 2);
        @media (--mobile) {
          width: 100%;
        }
        &:last-child{
          h4{
            p{
              margin-bottom: 2rem;
              @media (--mobile) {
                margin-bottom: 1rem;
              }
            }
          }
          p {
            svg {
              @media (--mobile){
                position: absolute;
                top: 50%;
                transform: translateY(-50%) translateY(-1.5rem);
                right: -5rem;
                margin-top: 0;
              }
            }
          }
        }
        a {
          position: relative;
          display: block;
          // &::after {
          //   content: "";
          //   position: absolute;
          //   top: 50%;
          //   transform: translateY(-50%) rotate(45deg);
          //   right: 4rem;
          //   height: 1.5rem;
          //   width: 1.5rem;
          //   border-top: 1px solid #fff;
          //   border-right: 1px solid #fff;
          //   transition: 1s right;
          //   @media (--mobile) {
          //     right: 2.5rem;
          //   }
          // }
          &:hover {
            svg {
              circle:first-of-type {
                fill: rgba(255,255,255,1);
              }
              path {
                stroke: #171717;
              }
            }
          }
          div{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 2;
            font-weight: lighter;
            text-align: center;
            p{
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              font-size: 2rem;
              letter-spacing: .2em;
              color: #FFF;
              @media (--mobile) {
                font-size: 1.8rem;
              }
              svg{
                width: 4rem;
                height: 4rem;
                margin-top: 4rem;
                circle:first-of-type {
                  fill: rgba(255,255,255,0);
                  transition: .5s fill;
                }
                path{
                  stroke: #fff;
                }
                &, * {
                  transition: .5s;
                }
              }
            }
          }
          h4 {
            display: flex;
            align-items: center;
            white-space: nowrap;
            justify-content: center;
            flex-direction: column;
            @media (--mobile) {
              flex-direction: row;
            }
            & > div {
              @media (--mobile){
                position: static;
                top: initial;
                left: initial;
                transform: translate(0, 0);
                text-align: initial;
              }
            }
            svg{
              width: 4rem;
              height: 4rem;
              margin-top: 4rem;
              @media (--mobile){
                margin-top: 0;
                margin-left: 1rem;
              }
              circle:first-of-type {
                fill: rgba(255,255,255,0);
                transition: .5s fill;
              }
              path{
                stroke: #fff;
              }
              &, * {
                transition: .5s;
              }
            }
            span {
              display: inline-block;
              width: 16rem;
            }

            p {
              font-size: 3rem;
              color: #fff;
              letter-spacing: .2em;
              text-align: right;
              line-height: 3.2rem;
              margin-right: 2rem;
              font-weight: normal;
              @media (--mobile) {
                display: flex;
                white-space: nowrap;
                font-size: 2.4rem;
                margin-right: 0;
              }
            }
          }
          figure {
            overflow: hidden;
            position: relative;
            transition: 1s transform;
            height: 55rem;
            @media (--mobile) {
              height: 22rem;
            }
            img {
              height: 100%;
              width: 100%;
              object-fit: cover;
              transition: transform 1s;
            }
          }
          &:hover {
            figure {
              img {
                transform: scale(1.05);
              }
            }
            &::after {
              right: 3rem;
            }
          }
        }
      }
    }
  }
  .p-news {
    margin-top: 14rem;
    display: flex;
    justify-content: space-between;
    @media (--mobile) {
      margin-top: 6rem;
      display: block;
    }
    &__right {
      width: 92rem;
      @media (--mobile) {
        width: 100%;
      }
      // .c-newsList {
      //   li {
      //     &::after, &::before {
      //       width: 0;
      //     }
      //     a {
      //       opacity: 0;
      //       transform: scale(.95);
      //       transition-duration: 1s;
      //       transition-delay: .3s;
      //     }
      //   }
      // }
      &.is-inview {
        ul {
          li {
            &::after {
              width: 100%;
            }
            a {
              opacity: 1;
              transform: scale(1);
            }
          }
        }
      }
    }
  }
  .p-link {
    margin: 14rem 0;
    @media (--mobile){
      margin: 8rem 0 0;
    }
    ul {
      display: flex;
      justify-content: space-between;
      @media (--mobile) {
        display: block;
      }
      li {
        width: 37.3rem;
        height: 37.3rem;
        @media (--mobile) {
          width: 100vw;
          height: 22rem;
          box-sizing: border-box;
          margin-left: calc(-50vw + 50%);
        }
        a {
          position: relative;
          height: 100%;
          width: 100%;
          overflow: hidden;
          display: block;
          &::after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 100%;
            width: 100%;
            transition: 1s transform;
          }
          &:hover {
            svg {
              circle:first-of-type {
                fill: rgba(255,255,255,1);
              }
              path {
                stroke: #171717;
              }
            }
          }
          & > * {
            position: relative;
            z-index: 2;
          }
          h3 {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 3;
            text-align: center;
            font-weight: lighter;
            letter-spacing: .2em;
            white-space: nowrap;
            display: flex;
            flex-direction: column;
            align-items: center;
            svg{
              width: 4rem;
              height: 4rem;
              circle:first-of-type {
                fill: rgba(255,255,255,0);
                transition: .5s fill;
              }
              path{
                stroke: #fff;
              }
              &, * {
                transition: .5s;
              }
            }
            * {
              color: #fff;
              transition: .3s color;
            }
            strong {
              font-size: 3rem;
              @media (--mobile) {
                letter-spacing: .2em;
                font-size: 2rem;
              }
            }
            small {
              font-size: 1.4rem;
              margin-bottom: 3rem;
              @media (--mobile) {
                margin-bottom: 2rem;
                font-size: 1.2rem;
              }
            }
          }
          p {
            position: absolute;
            bottom: 3.3rem;
            right: 3rem;
            z-index: 4;
            color: #fff;
            font-size: 1.2rem;
            &::after {
              content: "";
              position: absolute;
              right: 0;
              bottom: -.3rem;
              width: 100%;
              height: .1rem;
              background: #fff;
              transition: .5s width;
            }
          }
          figure {
            position: relative;
            width: 100%;
            overflow: hidden;
            height: 50rem;
            @media (--mobile) {
              height: 100%;
            }
            &::after {
              content: "";
              position: absolute;
              height: 100%;
              width: 100%;
              // background: rgba(0,0,0, .1);
              background: rgba(0, 0, 0, .3);
              transition: .5s background;
              @media (--mobile) {
                background: none;
              }
            }
            img {
              height: 100%;
              width: 100%;
              object-fit: cover;
              filter: grayscale(100%);
              transform: scale(1.05);
              transition: .5s transform;
              @media (--mobile) {
                filter: grayscale(0%);
              }
            }
          }
          img {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          @media (--large) {
            &:hover {
              figure {
                &::after {
                  background: rgba(0, 0, 0, .0);
                }
                img {
                  filter: grayscale(0%) blur(0);
                }
              }
              p {
                &::after {
                  width: 20%;
                }
              }
            }
          }
        }
      }
    }
  }
}
.p-movie_modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, .4);
  z-index: 9999;
  opacity: 0;
  pointer-events: none;
  transition: 1s opacity;
  &.is-active {
    opacity: 1;
    pointer-events: inherit;
  }
  &__inner {
    position: relative;
    padding: 2.5rem;
    background: #171717;
    @media (--mobile) {
      padding: 1.5rem;
    }
    iframe {
      height: 80vh;
      width: 80vw;
      max-width: 1000px;
      transition: 1s opacity;
      display: none;
      @media (--mobile) {
        height: 60vw;
      }
      &.is-active {
        display: block;
        width: 80vw;
      }
    }
  }
  &__close {
    display: inline-block;
    position: absolute;
    right: 0;
    top: 0;
    height: 5rem;
    width: 5rem;
    background: #171717;
    transition: .3s opacity;
    @media (--mobile) {
      width: 4rem;
      height: 4rem;
    }
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
    &::before, &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      height: 50%;
      width: .1rem;
      background: #fff;
    }
    &::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}
.scroll-hint-icon-wrap {
  display: none !important;
}