.c-sideImg {
  @media (--large) {
    display: flex;
    align-items: center;
    width: 100vw;
    margin-left: calc(-50vw + (118rem / 2));
    &__reverse {
      flex-direction: row-reverse;
    }
  }
  div {
    @media (--large) {
      width: 50%;
      height: 42.7rem;
    }
    @media (--mobile) {
      margin-left: -4rem;
    }
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  figcaption {
    @media (--large) {
      width: 50%;
      padding-right: calc(50vw - (118rem / 2));
      padding-left: 12rem;
    }
    @media (--mobile) {
      margin-top: 6rem;
    }
    p {
      margin-top: 3rem;
      font-size: 1.4rem;
      line-height: 2;
    }
  }
  @media (--large) {
    &__reverse {
      flex-direction: row-reverse;
      figcaption {
        padding-right: 12rem;
        padding-left: calc(50vw - (98rem / 2));
      }
    }
  }
}