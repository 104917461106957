.l-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 997;
  background-color: transparent;
  height: 8rem;
  padding: 0 2rem;
  display: flex;
  transition: 0.3s;
  width: 100%;
  a{
    display: inline-block;
  }
  &.top-active{
    path{
      transition: 0.3s;
      fill: #fff;
    }
  }
  &.is-active {
    background-color: #fff;
    // mix-blend-mode: difference;
    &.top-active{
      background-color: #fff;
      path{
        fill: #171717;
      }
    }
  }
  @media (--mobile) {
    height: 6rem;
  }
  &__logo {
    width: 7rem;
    display: flex;
    align-items: flex-start;
    transform: none;
    @media (--mobile) {
      justify-content: center;
    }
    a {
      display: flex;
      align-items: flex-start;
    }
    @media (--mobile) {
      // width: 6rem;
    }
    svg{
      // width: auto;
    }
    path {
      fill: #171717;
    }
  }
  &__drawer {
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    font-size: 1.6rem;
    font-family: 'EB Garamond', serif;
    font-weight: lighter;
    cursor: pointer;
    color: #fff;
    width: 18rem;
    height: 8rem;
    background: #171717;
    z-index: 999;
    @media (--mobile) {
      width: 15.3rem;
      height: 6rem;
    }
    strong {
      text-align: center;
      width: 10rem;
      display: inline-block;
      @media (--mobile) {
        width: 9rem;
      }
    }
    div {
      position: relative;
      top: -.2rem;
      height: 1.2rem;
      width: 2rem;
      width: calc(100% - 10rem);
      @media (--mobile) {
        width: calc(100% - 9rem);
      }
      &::before {
        content: "";
        position: absolute;
        height: 2.8rem;
        width: 0.1rem;
        background: #727272;
        top: 50%;
        transform: translateY(-50%);
      }
      span {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        display: block;
        width: 2rem;
        height: .2rem;
        background: #fff;
        border-radius: 9999px;
        transition: .5s;
        &:first-of-type {
          top: 0;
          @at-root .is-active & {
            transform: translateX(-50%) translateY(-50%) rotate(30deg);
            top: 50%;
          }
        }
        &:last-of-type {
          bottom: 0;
          @at-root .is-active & {
            transform: translateX(-50%) translateY(50%) rotate(-30deg);
            bottom: 50%;
          }
        }
      }
    }
  }

  &__reserve {
    transition: .3s;
    position: fixed;
    top: 0;
    right: 18rem;
    padding: 0 3rem;
    border-right: 1px solid #727272;
    height: 8rem;
    background: #171717;
    z-index: 999;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.6rem;
    &:hover {
      opacity: .7;
    }
  }

  &__reserve2{
    transition: .3s;
    position: fixed;
    top: 0;
    right: 39.8rem;
    padding: 0 3rem;
    border-right: 1px solid #727272;
    height: 8rem;
    background: #171717;
    z-index: 999;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.6rem;
    &:hover {
      opacity: .7;
    }
  }


  &__fixedBanner {
    position: fixed;
    z-index: 99;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    opacity: 0;
    transition: .5s;
    pointer-events: none;
    &.is-active {
      opacity: 1;
      pointer-events: inherit;
    }

    // hr {
    //   width: 1px;
    //   background: #727272;
    //   border: none;
    //   height: 5rem;
    //   margin: 0;
    // }

    & > div {
      height: 5rem;
      position: relative;
      & > a {
        width: 100%;
        height: 100%;
        display: flex;
        background: #171717;
        justify-content: center;
        align-items: center;
        color: #fff;
        text-align: center;
        font-size: 1.4rem;
        line-height: 1.3;
      }
      &::before {
        position: absolute;
        content: "";
        width: 100%;
        height: 1px;
        background: #727272;
        top: 99%;
        left: 0;
      }
    }

    & > ul {
      display: grid;
      grid-template-columns: 1fr 1fr;
      
      & > li {
        position: relative;

        a {
          width: 100%;
          background: #171717;
          height: 5rem;
          justify-content: center;
          display: flex;
          align-items: center;
          color: #fff;
          text-align: center;
          font-size: 1.4rem;
          line-height: 1.3;
        }

        &:last-child {
          &::before {
            position: absolute;
            content: "";
            width: 1px;
            height: 100%;
            background: #727272;
            top: 0;
            left: 0;
          }
        }
      }
    }
  }
}

.drawer {
  overflow-y: scroll;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 998;
  background: #fff;
  opacity: 0;
  transition: 1s;
  pointer-events: none;
  min-height: 100vh;
  @media (--mobile) {
    min-height: 100vh;
    padding-bottom: 12rem;
    top: 0;
    left: 0;
    overflow-y: scroll;
    height: 100vh;
    align-items: flex-start;
  }
  &.is-active {
    opacity: 1;
    pointer-events: inherit;
  }

  &-works-wrap{
    position: relative;
  }

  &-works-icon{
    position: absolute;
    left: 10rem;
    top: 0px;
    transform: scale(0.6);
    @media (--mobile) {
      left: 10rem;
      transform: scale(0.7);

    }
  }

  &-works-box{
    position: relative;
    display: inline-block;
    vertical-align: middle;
    color: #333;
    line-height: 1;
    width: 1em;
    height: 0.08em;
    background: currentColor;
    position: relative;
    margin-bottom: 0.6rem;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: inherit;
    border-radius: inherit;
    transform: rotate(90deg);
    transition: 0.3s;
  }
  }

  &-works-acco{
    font-size: 2.2rem;
    padding: 0;
    position: relative;
    color: #333;
    // padding-bottom: 0.3rem;
    cursor: pointer;

    &::before {
      content: "";
      margin: auto;
      position: absolute;
      left: 0;
      bottom: -.2rem;
      transition: transform .3s, -webkit-transform .3s;
      transform: scale(0, 1);
      transform-origin: right center;
      width: 100%;
      height: .1rem;
      background: #171717;
    }
    @media (--large) {
      &:hover {
        &::before {
          transform: scale(1, 1);
          transform-origin: left center;
        }
      }
    }

    @media (--mobile) {
    font-size:2.2rem;
    padding-bottom: 0;
    }

    &.open{
      .drawer-works-box{
        &::before{
          transform: rotate(0);
        }
      }
    }
  }

  &-works{
    display: flex;
    margin-top: 1.2rem;
    margin-left: 1rem;
    display: none;
    @media (--mobile) {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
    ul{
      display: flex;
      @media (--mobile) {
        flex-direction: column;
      }
    }
    li{
      margin-top: 0 !important;
      font-size: 1.4rem;
      &:nth-child(n + 2){
        margin-left: 3rem;
        @media (--mobile) {
          margin-left: 0;
          margin-top: 1rem !important;
        }
      }
    }

  }
  &__logo{
    // position: absolute;
    // left: 10rem;
    // top: 3rem;
    display: inline-block;
    margin-bottom: 3.3rem;
    @media (--mobile) {
      display: flex;
      justify-content: left;
      margin-bottom: 2rem;
      a{
        display: block;
        svg{
          width: auto;
        }
      }
    }
  }
  &__inner {
    width: 112.5rem;
    padding-left: 10rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    transition: 1s;
    transform: translateX(-10rem);
    position: relative;
    padding-bottom: 11rem;
    margin-top: 4rem;
    @media (--mobile) {
      width: 100%;
      align-items: flex-start;
      padding-left: 0;
      padding-bottom: 0;
      margin-top: 2.8rem;
    }
    // @media (--menu) {
    //   padding-top: 16rem;
    // }
    @at-root .is-active & {
      transform: translateX(0);
    }
  }
  &__left {
    position: relative;
    width: 38rem;
    height: 50rem;
    overflow: hidden;
    @media (--mobile) {
      display: none;
    }
    @media (--large) {
      overflow-y: hidden;
      img {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
        transform: scale(1.5);
        transition: 2s transform;
        z-index: 1;
        @at-root .is-active & {
          transform: scale(1);
        }
        &:nth-of-type(n + 2) {
          z-index: 2;
          opacity: 0;
          transition: .5s;
          &.is-active {
            opacity: 1;
          }
        }
      }
    }
  }
  &__right {
    @media (--large) {
      width: 69rem;
    }
    @media (--mobile) {
      padding: 0rem 4rem 1.8rem;
      width: 100%;
    }
  }
  &__top {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    &-item{
      line-height: 1.2;
      &:nth-child(n + 2){
        margin-top: 1.4rem;
        @media (--mobile) {
          margin-top: 1.3rem;
        }
      }
    }
    ul{
      margin-right: 8.5rem;

      &:last-child{
        margin-right: 0;
      }

      li{
        &:nth-of-type(n + 2) {
          margin-top: 2rem;

        }
      }
    }
  }
  &__topList {
    display: flex;
    flex-wrap: wrap;
    @media (--mobile) {
      justify-content: space-between;
    }
    li {
      margin-right: 8.5rem;

      @media (--mobile) {
        margin-right: 0;
      }
      &:nth-of-type(3n) {
        margin-right: 0;
      }
      &:nth-of-type(n + 4) {
        margin-top: 2rem;
        @media (--mobile) {
          margin-top: inherit;
        }
      }
      @media (--mobile) {
        &:nth-of-type(n + 3) {
          margin-top: 2rem;
        }
      }
    }
  }
  &__top, &__topList, &__middleWrap ul {
    li {
      // width: 15.8rem;
      @media (--mobile) {
        width: 100%;
      }
      &, a {
        font-size: 1.3rem;
        @media (--mobile) {
          font-size: 1.2rem;
        }
      }
      a {
        display: inline-block;
        position: relative;
        &.drawer__top-link{
          font-size: 2.2rem;
          display: inline-block;
          line-height: 1;
          @media (--mobile) {
            font-size:2.2rem;
          }
        }

        // &::after {
        //   content: "";
        //   position: absolute;
        //   right: 0;
        //   top: 50%;
        //   transform: translateY(-50%) translateY(-.1rem) rotate(45deg);
        //   height: 0.7rem;
        //   width: 0.7rem;
        //   border-top: 1px solid #171717;
        //   border-right: 1px solid #171717;
        //   transition: .3s right;
        // }
        &::before {
          content: "";
          margin: auto;
          position: absolute;
          left: 0;
          bottom: -.2rem;
          transition: transform .3s, -webkit-transform .3s;
          transform: scale(0, 1);
          transform-origin: right center;
          width: 100%;
          height: .1rem;
          background: #171717;
        }
        @media (--large) {
          &:hover {
            &::before {
              transform: scale(1, 1);
              transform-origin: left center;
            }
          }
        }
      }
    }
  }
  &__middleWrap {
    margin-top: 4.5rem;
    display: flex;
    @media (--mobile) {
      margin-top: 3rem !important;
    }

    &-item{
      a{
        font-size: 1.2rem !important;
      }
    }

    @media (--mobile) {
      margin-top: 3rem;
      display: flex;
      justify-content: space-between;
    }
    ul {
      @media (--mobile) {
        width: 100%;
      }
      &:nth-of-type(n + 2) {
        @media (--large) {
          margin-left: 8.5rem;
        }
      }
      li {
        &:nth-of-type(n + 2) {
          margin-top: 0.6rem;
        }
        a {
          &::after {
            transform: translateY(-50%) rotate(45deg);
          }
        }
        @media (--mobile) {
          width: 100%;
        }
      }
    }
  }
  &__snsWrap{
    margin-top: 3.7rem;
    @media (--mobile) {
      margin-top: 2.2rem;
    }
    ul{
      display: flex;
      align-items: center;
      li{
        a{
          &.h-ig{
            svg{
              width: 2rem;
            }
          }
          svg{
            width: auto;
            path{
              fill: #333;

            }
          }
        }
        &:nth-child(n + 2){
          margin-left: 1.7rem;
        }
      }
    }
  }
  &__bottomWrap {
    display: flex;
    align-items: center;
    margin-top: 2rem;
    flex-wrap: wrap;
    .drawer__box{
      width: 100%;
    }
    @media (--mobile) {
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 1.5rem;
    }
  }
  &__mail, &__line {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15rem;
    height: 8rem;
    border: 1px solid #707070;
    font-size: 1.4rem;
    padding-top: 0.3rem;
    transition: .3s background, .3s color;
    @media (--mobile) {
      text-align: center;
      width: 46%;
      margin: 0;
      padding-top: .5rem;
      height: 8rem;
    }
    figure {
      height: 1.6rem;
      @media (--mobile) {
        height: 1.3rem;
      }
      svg {
        height: 100%;
      }
      path {
        transition: .3s fill;
        fill: #171717;
      }
    }
    @media (--mobile) {
      p {
        line-height: 1.4;
      }
    }
    &:hover {
      background: rgba(0, 0, 0, .7);
      color: #fff;
      path {
        fill: #fff;
      }
    }
  }
  &__mail {
    figure {
      svg {
        height: 1.6rem;
        @media (--mobile) {
          height: 1.6rem;
        }
      }
    }
  }
  &__line {
    margin-left: 1.9rem;
    margin-right: 2.5rem;
    figure {
      svg {
        height: 2.3rem;
        @media (--mobile) {
          position: relative;
          top: -0.2rem;
          height: 2.3rem;
        }
      }
    }
  }
  &__tel {
    font-size: 1.4rem;
    margin-left: 7rem;
    margin-top: 3rem;
    @media (--large) {
      pointer-events: none;
    }
    @media (--mobile) {
      width: 100%;
      text-align: center;
      margin-top: 2rem;
      margin-left: 0;
    }
    small {
      font-size: 1.2rem;
      @media (--mobile) {
        display: inline-block;
        margin-top: 1rem;
      }
    }
    svg{
      height: 2.4rem;
    }
  }
  &__contact-text{
    font-size: 1.2rem;
    @media (--mobile) {
      font-size: 1.1rem;
        }
  }
}

html.is-active {
  overflow: hidden;
}

