.p-counseling-detail{
	position: relative;

	.white-out{
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background-color: #FFF;
		animation-name: fadeout;
		animation-duration: 1s;
		z-index: 0;
		opacity: 0;

		@keyframes fadeout {
			0% {
				opacity: 1;
				z-index: 10;
			}
			100% {
				z-index: 0;
				opacity: 0;
			}
		}
	}

	margin-top: 22rem;
	&__ttl{
		text-align: center;
    font-size: 1.5rem;
		strong{
			display: block;
			font-size: 3.6rem;
			margin-top: 1.4rem;
			font-weight: normal;
			letter-spacing: .2em;
		}
	}
	&__inner{
		display: flex;
		justify-content: space-between;
	}
	&__cell{
		width: 78rem;
		margin: 7rem auto 12rem auto;
		@media (--mobile) {
			width: 100%;
		}
	}
	&__side{
		width: 30rem;
		& > div {
			position: sticky;
			top: 0;
			padding-top: 10rem;
			padding-right: 1rem;
			padding-bottom: 3rem;
			margin-top: -10rem;
			overflow-y: auto;
			height: 100vh;
			& > ul{
				margin-bottom: 3rem;
				& > li{
					position: relative;
					font-size: 1.5rem;
					margin-bottom: 2rem;
					&:last-child{
						margin-bottom: 0;
					}
					span{
						font-size: 1.4rem;
						font-family: 'EB Garamond', serif;
						margin-right: 2rem;
						// padding-top: 0.3rem;
					}
					a{
						display: flex;
						font-size: 1.2rem;
						align-items: flex-start;
						padding-top: 2rem;
						@media (--mobile) {
							padding: 2rem 4rem 2rem 2rem;
						}
					}
					// &::before {
					// 	border-right: solid 1px #707070;
					// 	border-top: solid 1px #707070;
					// 	content: "";
					// 	display: block;
					// 	width: 0.8rem;
					// 	height: 0.8rem;
					// 	position: absolute;
					// 	left: 0rem;
					// 	top: 11px;
					// 	transform: rotate(45deg);
					// 	@media (--mobile) {
					// 		width: 1.2rem;
					// 		height: 1.2rem;
					// 		top: 40%;
					// 		right: 2rem;
					// 	}
					// }
				}
			}
		}
		.paging{
			display: flex;
			justify-content: space-between;

			.c-backBtn{
				margin-top: 0;
			}
			.c-moreBtn{
				margin-top: 0;
			}
		}
	}

	&__question{
		font-family: 'EB Garamond', serif;
		font-weight: Regular;
		font-size: 2rem;
	}
	.en{
		position: relative;
		display: block;
		text-align: center;
		margin-bottom: 4rem;
		color: #333333;
	}
	.en::after{
		content: '';
		width: 78rem;
		height: 0.1rem;
		display: inline-block;
		background-color: #D0D0D0;
		position: absolute;
		bottom: -1rem;
		left: 0;
		@media (--mobile) {
			width: 100%;
		}
	}
	.jp{
		display: block;
		text-align: center;
		font-size: 2.6rem;
		margin-bottom: 1rem;
		@media (--mobile) {
			text-align: left;
			display: flex;
			justify-content: center;
			font-size: 2.2rem;
		}
	}
	.user{
		display: block;
		font-size: 1.4rem;
		text-align: center;
		margin-bottom: 5rem;
		@media (--mobile) {
			margin-bottom: 2rem;
		}
	}

	&__img{
		margin-bottom: 3rem;
		@media (--mobile) {
			margin-bottom: 3rem;
		}
		figcaption {
			margin-top: 1.5rem;
			font-size: 1.2rem;
		}
	}

	&__text{
		font-size: 1.5rem;
		line-height: 3.6rem;
		margin-bottom: 6rem;
		@media (--mobile) {
			margin-bottom: 3rem;
		}
	}
	&__answer{
		font-family: 'EB Garamond', serif;
		font-weight: Regular;
		font-size: 2rem;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		margin-bottom: 3rem;
		position: relative;
		@media (--mobile) {
			margin-bottom: 1rem;
		}
		span{
			background-color: #FFF;
			position: relative;
			z-index: 2;
			padding-right: 1rem;
		}
		&::after{
			position: absolute;
			border-top: 1px solid #D0D0D0;
			content: "";
			width: 100%;
			right: 0;
			@media (--mobile) {
				width: 100%;
			}
		}
	}
	&__comment{
		font-family: 'EB Garamond', serif;
		font-weight: Regular;
		font-size: 2rem;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		margin-bottom: 3rem;
		position: relative;
		@media (--mobile) {
			margin-bottom: 1rem;
		}
		span{
			background-color: #FFF;
			position: relative;
			z-index: 2;
			padding-right: 1rem;
		}
		&::after{
			position: absolute;
			border-top: 1px solid #D0D0D0;
			content: "";
			width: 100%;
			right: 0;
			@media (--mobile) {
				width: 100%;
			}
		}
	}
	&__acco{
		border-bottom: 1px solid rgba($color: #333, $alpha: 0.1);
		padding-bottom: 1.5rem;
		position: relative;
		cursor: pointer;
		&.open{
			&::before{
				transform: rotate(-45deg);
			}
		}
		&::before{
			transition: 0.3s;
			width: 1.2rem;
			height: 1.2rem;
			content: "";
			position: absolute;
			border-right: solid 1px #707070;
      border-top: solid 1px #707070;
			top: 32%;
			right: 1rem;
			transform: translateY(-50%) rotate(135deg);

			}
	}
	&__acco-list{
		display: none;
		padding-top: 1rem;
	}
}