.p-support{
	&-inner{
		margin-top: 11rem;
		display: flex;
		@media (--mobile) {
			width: 100%;
			display: block;
			margin-top: 3rem;
		}
		&__wrap {
			@media (--large) {
				height: 100%;
			}
			@media (--mobile) {
				position: relative;
				transition: .2s opacity;
				&.is-active {
					position: fixed;
					top: 6rem;
					z-index: 99;
				}
				&.is-remove {
					pointer-events: none;
					opacity: 0;
				}
			}
		}
		&__left{
			width: 24rem;
			margin-right: 11rem;
			// margin-top: 50vh;
			@media (--mobile) {
				width: 100%;
				margin-right: 0;
				margin-bottom: 5rem;
				background: #fff;
				height: 5.2rem;
			}

			ul{
				position: sticky;
				top: 50%;
				padding: 4rem 0;
				border-top: 0.1rem solid #171717;
				border-bottom: 0.1rem solid #171717;
				margin-top: 33.2rem;
				transform: translateY(-50%);
				@media (--mobile) {
					position: absolute;
					margin: 0 -3rem;
					padding: 2rem 3rem;
					transform: inherit;
					top: 5rem;
					display: none;
					border-top: none;
					width: calc(100% + 6rem);
					background: #fff;
				}
				li{
					font-size: 1.8rem;
					margin-bottom: 2.5rem;
					@media (--mobile) {
						margin-bottom: 1.5rem;
						font-size: 1.5rem;
					}
					&:last-child{
						margin-bottom: 0;
					}
					a{
						display: flex;
						flex-wrap: wrap;
						justify-content: space-between;
						opacity: .2;
						transition: .3s opacity;
						&:hover,&.is-active{
							opacity: 1;
							svg {
								circle:first-of-type {
									fill: #171717;
									transition: .5s fill;
								}
								path {
									stroke: #fff;
								}
							}
						}
					}
					svg{
						width: 3rem;
						height: 3rem;
						margin-left: 1rem;
						@media (--mobile) {
							width: 2.5rem;
    					height: 2.5rem;
						}
						circle:first-of-type {
							fill: rgba(255,255,255,0);
							transition: .5s fill;
						}
						path{
							stroke: #171717;
						}
						&, * {
							transition: .5s;
						}
					}
				}
			}
		}

		&__menu {
			position: relative;
			font-size: 2rem;
			padding: 1rem 0;
			border: 1px solid #000;
			border-right: none;
			border-left: none;
			margin: 0 -3rem;
			padding: 1rem 3rem;
			background: #fff;
			width: 100vw;
			span {
				position: absolute;
				right: 3rem;
				top: 50%;
				transform: translateY(-50%);
				height: 2rem;
				width: 2rem;
				&::after, &::before {
					content: "";
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
					height: 100%;
					width: 0.1rem;
					background: #000;
					transition: .3s transform;
				}
				&::before {
					transform: translate(-50%, -50%) rotate(90deg);
				}
			}
			&.is-active {
				span {
					&::after {
						transform: translate(-50%, -50%) rotate(90deg);
					}
				}
			}
		}

		&__right{
			width: calc(100% - 24rem - 11rem);
			@media (--mobile) {
				padding-bottom: 5rem;
				width: 100%;
			}

			.c-centerTtl{
				margin-bottom: 3.6rem;
				strong{
					font-size: 2.4rem;
				}
			}
		}

		&__cell{
			border-bottom: 0.1rem solid #D0D0D0;
			padding-bottom: 11rem;
			padding-top: 12rem;
			@media (--mobile) {
				padding-bottom: 5rem;
				margin-bottom: 5rem;
				padding-top: 7rem !important;
				margin-top: -7rem;
			}

			&:last-of-type{
				border: none;
				padding-bottom: 0;
				margin-bottom: 0;
			}
		}
		&__title{
			font-size: 2.4rem;
			line-height: 3.9rem;
			text-align: center;
			margin-bottom: 5rem;
			@media (--mobile) {
				font-size: 2rem;
				line-height: 2;
			}
			&.left{
				text-align: left;
			}
		}
		&__text{
			p{
				font-size: 1.5rem;
				line-height: 3.2rem;
				margin-bottom: 3.2rem;

				&.center{
					text-align: center;
				}

			}
		}
		&__img{
			margin-top: 7rem;
			margin-bottom: 5rem;
			@media (--mobile) {
				margin-top: 3rem;
				margin-bottom: 3rem;
				width: 65%;
				margin-left: auto;
				margin-right: auto;
			}

		}
		&__ttl{
			font-size: 1.8rem;
			font-weight: bold;
			padding-bottom: 2rem;
			margin-bottom: 2rem;
			border-bottom: 0.1rem solid #262727;
		}
		&__txt{
			font-size: 1.5rem;
			line-height: 3.2rem;
			margin-bottom: 12rem;
		}
		&__block{
			display: flex;
			align-items: center;
			margin-bottom: 10rem;
			@media (--mobile) {
				display: block;
				margin-bottom: 5rem;
			}

			.img{
				width: 27.4rem;
				margin-right: 5.8rem;
				@media (--mobile) {
					width: 100%;
					margin-right: 0;
					margin-bottom: 1rem;
				}

			}
			.text {
				width: calc(100% - 27.4rem - 5.8rem);
				@media (--mobile) {
					width: 100%;
				}

				.title{
					font-size: 2.4rem;
					line-height: 4.7rem;
					margin-bottom: 4.6rem;
					@media (--mobile) {
						font-size: 2rem;
						line-height: 2;
						margin-bottom: 2rem;
					}

				}
				p{
					font-size: 1.5rem;
					line-height: 3.2rem;
				}
			}
		}

		.p-support-inner__block:nth-child(even){
			margin-top: 9rem;
			margin-bottom: 10rem;
			@media (--mobile) {
				margin-top: 4rem;
				margin-bottom: 5rem;
			}

		}
		.p-support-inner__block:nth-child(odd){
			flex-direction: row-reverse;
			margin-bottom: 12rem;
			@media (--mobile) {
				margin-bottom: 6rem;
			}

			.img{
				margin-right: 0;
				margin-left: 5.8rem;
				@media (--mobile) {
					margin-left: 0;
				}

			}
		}
	}
	&-flow {
		&__contents{
			display: flex;
			justify-content: space-between;

			@media (--mobile) {
				display: block;
				width: 100%;
			}
			ul{
				position: relative;
				@media (--mobile) {
					width: 100%;
				}
				&::after{
					content:"";
					display: inline-block;
					width: 1px;
					height: 100%;
					background-color: #D0D0D0;
					position: absolute;
					top: 50%;
					left: 50%;
					z-index: 1;
					transform: translate(-50%, -50%) translateX(-38rem);
					@media (--mobile) {
						transform: translate(-50%, -50%) translateX(-14rem);
					}
				}
				li{
					display: flex;
					margin-bottom: 5rem;
					@media (--mobile) {
						margin-bottom: 10rem;
					}
					&:last-child{
						margin-bottom: 0;
					}
					&.turn{
						padding-bottom: 10rem;
						@media (--mobile) {
							padding-bottom: 0;
							margin-bottom: 8rem;
						}
					}
				}
			}
		}

		&__step{
			margin-right: 3rem;
			font-size: 1.8rem;
			font-family: 'EB Garamond', serif;
			font-weight: Regular;
			white-space: nowrap;

			@media (--mobile) {
				margin-right: 1rem;
				font-size: 1.4rem;
			}
			p{
				background-color: #FFFFFF;
				z-index: 2;
				position: relative;
				padding: 1.7rem 0;
				@media (--mobile) {
					padding: 0;
					margin-bottom: 0.5rem;
					text-align: center;
				}
			}
		}
		&__content{
			// width: 42rem;
			padding-top: 1.7rem;
			display: flex;
			@media (--mobile) {
				width: 100%;
				display: block;
				padding-top: 0;
			}
		}
		&__text{
			width: calc(100% - 25.8rem - 4rem);
			margin-right: 4rem;
			@media (--mobile) {
				width: 100%;
				margin-right: 0;
			}

		}
		&__img{
			width: 25.8rem;
			@media (--mobile) {
				width: 100%;
			}

		}
		&__ttl{
			font-size: 2rem;
			color: #171717;
			font-weight: bold;
			margin-bottom: 2.7rem;
			@media (--mobile) {
				margin-bottom: 2rem;
			}
			&.last{
				padding-top: 1.5rem;
			}
			span{
				display: inline-block;
				font-size: 1.2rem;
				font-weight: normal;
				background-color: #EAEAEA;
				// border: 0.1rem solid #171717;
				padding: 0.5rem;
				margin-top: 1rem;
				@media (--mobile) {
					font-size: 1.5rem;
				}
			}
		}
		&__txt{
			font-size: 1.5rem;
			line-height: 3.2rem;
			@media (--mobile) {
				margin-bottom: 2rem;
			}

		}
	}
	.p-support-flow__step.last{
		background: #FFFFFF;
		z-index: 10;
		position: relative;
	}

	&-assurance{
		&__contents{
			margin-bottom: 7rem;
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
			@media (--mobile) {
				display: block;
			}
		}
		&__title{
			font-size: 2rem;
			display: flex;
			margin-bottom: 3rem;
			span{
				font-size: 1.8rem;
				margin-right: 0.6rem;
			}
		}
		&__text{
			font-size: 1.5rem;
			line-height: 3.2rem;
			margin-bottom: 3rem;
		}
		&__btn{
			font-size: 1.5rem;
			border-bottom: 0.1rem solid #707070;
			padding-bottom: 1rem;
			display: inline-block;
			a{
				display: flex;
				align-items: center;

				&:hover {
					svg {
						circle:first-of-type {
							fill: rgba(23,23,23,1);
						}
						path {
							stroke: #FFF;
						}
					}
				}
			}
			svg{
				width: 3rem;
				height: 3rem;
				margin-left: 1rem;
				circle:first-of-type {
					fill: rgba(255,255,255,0);
					transition: .5s fill;
				}
				path{
					stroke: #171717;
				}
				&, * {
					transition: .5s;
				}
			}
		}
		&__content{
			width: calc(100% - 21.9rem - 5rem);
			margin-right: 5rem;
			@media (--mobile) {
				margin-right: 2.5rem;
				width: 100%;
			}

		}
		&__img{
			width: 21.9rem;
			@media (--mobile) {
				width: 20rem;
				margin: 2rem auto 0 auto;
			}
		}
	}
}

/*モーダル本体の指定 + モーダル外側の背景の指定*/
.modal-container{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  background: rgba(177,177,177,0.8);
  padding: 8rem 3rem;
  overflow: auto;
  opacity: 0;
  visibility: hidden;
  transition: .3s;
  box-sizing: border-box;

	.title{
		font-size: 2.6rem;
		margin-bottom: 6rem;
		text-align: center;
		@media (--mobile) {
			font-size: 2rem;
			margin-bottom: 2rem;
		}
	}
	.img{
		margin-bottom: 6rem;
		text-align: center;
	}
	.link{
		ul{
			display: flex;
			justify-content: space-between;
			@media (--mobile) {
				display: block;
			}
			li{
				width: 27rem;
				border-top: 1px solid #D0D0D0;
				border-bottom: 1px solid #D0D0D0;
				margin-right: 5rem;
				@media (--mobile) {
					margin-right: 0;
					margin-bottom: 1.5rem;
					width: 100%;
				}
				&:last-child{
					margin-right: 0;
				}
				a{
					width: 100%;
					height: 100%;
					display: block;
					padding: 2rem;
					font-size: 2rem;
					letter-spacing: 0.3rem;
					color: #333333;
					position: relative;
					&::after{
						position: absolute;
						content: "";
						right: 2rem;
						top: 50%;
						width: 1.5rem;
						height: 1.5rem;
						border-top: 1px solid #707070;
						border-right: 1px solid #707070;
						transform: translate(0,-50%) rotate(45deg);
					}
					span{
						font-size: 2.2rem;
						font-weight: bold;
					}
				}
			}
		}
	}
}
/*モーダル本体の擬似要素の指定*/
.modal-container:before{
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}
/*モーダル本体に「active」クラス付与した時のスタイル*/
.modal-container.active{
  opacity: 1;
  visibility: visible;
	z-index: 1100;
}
/*モーダル枠の指定*/
.modal-body{
  position: relative;
  display: inline-block;
  vertical-align: middle;
	max-width: 100rem;
	width: 90%;
}
/*モーダルを閉じるボタンの指定*/
.modal-close{
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -4rem;
  right: -4rem;
  width: 4rem;
  height: 4rem;
  font-size: 4rem;
  color: #fff;
  cursor: pointer;
}
/*モーダル内のコンテンツの指定*/
.modal-content{
  background: #fff;
  text-align: left;
  padding: 8rem;
	@media (--mobile) {
		padding: 2rem;
	}
}