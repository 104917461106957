.c-backBtn {
  display: flex;
  justify-content: flex-end;
  flex-direction: row-reverse;
  margin-top: 6rem;
  a, span {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 1.6rem;
    svg {
      width: 4.4rem;
      margin-right: 2.6rem;
      transform: rotate(180deg);
      circle:first-of-type {
        fill: #fff;
      }
      &, * {
        transition: .3s;
      }
    }
    &:hover {
      svg {
        margin-right: 1.5rem;
        circle:first-of-type {
          fill: #171717;
        }
        path {
          stroke: #fff;
        }
      }
    }
  }
}

.c-moreBtn {
  display: flex;
  justify-content: flex-end;
  margin-top: 6rem;
  @media (--mobile) {
    margin-top: 4rem;
  }
  a, span {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 1.6rem;
    svg {
      width: 4.4rem;
      margin-left: 2.6rem;
      @media (--mobile) {
        margin-left: 1.6rem;
      }
      circle:first-of-type {
        fill: #fff;
      }
      &, * {
        transition: .5s;
      }
    }
    &:hover {
      svg {
        margin-left: 1.5rem;
        circle:first-of-type {
          fill: #171717;
        }
        path {
          stroke: #fff;
        }
        g{
          stroke: #171717;
        }
      }
    }
  }
}