.p-design-detail{
	&-content{
		width: 98rem;
		margin: 0 auto 12rem auto;
		display: flex;
		justify-content: space-between;
		@media (--mobile) {
			width: 100%;
			display: block;
		}
		&__text{
			width: 68rem;
			@media (--mobile) {
				width: 100%;
				margin-top: 2rem;
			}
			p{
				font-size: 1.5rem;
				line-height: 3.2rem;
				margin-bottom: 4rem;
				@media (--mobile) {
					margin-bottom: 2rem;
				}

				&:last-child{
					margin-bottom: 0;
				}
			}
		}
	}
	.c-mv {
		height: 63vw;
		overflow: hidden;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	&__img{
		margin-bottom: 12rem;
		text-align: center;
		@media (--mobile) {
			margin-top: 5rem;
			margin-bottom: 5rem;
			margin-left: -3rem;
			margin-right: -3rem;
		}
	}

	&-num{
		width: 98rem;
		margin: 0 auto 12rem auto;
		@media (--mobile) {
			width: 100%;
		}

		.c-leftTtl{
			margin-bottom: 4rem;
			@media (--mobile) {
				margin-bottom: 2rem;
			}
		}
		&__title{
			font-size: 2rem;
			margin-bottom: 3rem;
			@media (--mobile) {
				font-size: 1.8rem;
				margin-bottom: 1.5rem;
			}
			span{
				font-size: 1.8rem;
				font-family: 'EB Garamond', serif;
				margin-right: 0.7rem;
			}
		}
		&__text{
			margin-bottom: 7rem;
			font-size: 1.5rem;
			line-height: 3.2rem;
			&:last-of-type{
				margin-bottom: 0;
			}
			@media (--mobile) {
				margin-bottom: 3rem;
			}
		}
	}
	&-cell{
		width: 98rem;
		margin: 0 auto 12rem auto;
		@media (--mobile) {
			width: 100%;
		}

		.c-leftTtl {
			margin-bottom: 4rem;
			@media (--mobile) {
				margin-bottom: 2rem;
			}
		}

		&__title{
			font-size: 2rem;
			margin-bottom: 3rem;
			@media (--mobile) {
				font-size: 1.8rem;
				margin-bottom: 1.5rem;
			}
		}
		&__text{
			margin-bottom: 7rem;
			font-size: 1.5rem;
			line-height: 3.2rem;
			&:last-of-type{
				margin-bottom: 0;
			}
			@media (--mobile) {
				margin-bottom: 3rem;
			}
		}
	}
	&-left{
		display: flex;
		margin-top: 12rem;
		margin-bottom: 12rem;
		align-items: center;
    justify-content: space-between;
		@media (--mobile) {
			display: block;
			margin-top: 6rem;
			margin-bottom: 6rem;
		}
		&__img{
			width: 56rem;
			@media (--mobile) {
				width: 100%;
				margin-bottom: 8rem;
			}
		}
		&__content{
			width: 48rem;
			@media (--mobile) {
				width: 100%;
				margin-bottom: 12rem;
			}
		}
		&__title{
			font-size: 2.6rem;
			margin-bottom: 5rem;
			@media (--mobile) {
				font-size: 2rem;
				margin-bottom: 4rem;
			}
		}
		&__text{
			p{
				font-size: 1.5rem;
				line-height: 3.2rem;
				margin-bottom: 4rem;
				@media (--mobile) {
					margin-bottom: 2rem;
				}
				&:last-of-type{
					margin-bottom: 0;
				}
			}
		}
	}
	&-sketch{
		margin-bottom: 12rem;
		ul{
			margin-top: 7rem;
			width: 100%;
			margin-left: auto;
			margin-right: auto;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;

			@media (--mobile) {
				width: 100%;
				margin-top: 3rem;
			}
			li{
				width: 48%;
				@media (--large) {
					&:nth-child(n + 3){
						margin-top: 6.6rem;
					}
				}

				@media (--mobile) {
					width: 100%;
					&:nth-child(n + 2){
						margin-top: 6rem;
					}
				}

				.img{
					width: 100%;
					height: auto;
					img{
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}
				p{
					margin-top: 3rem;
					font-size: 1.4rem;
				}
			}
		}
	}
	&-movie{
		margin-top: 4.5rem;
		margin-left: auto;
		margin-right: auto;
		width: 66%;
		height: 56rem;

		// @media (--mobile) {
		// 	height: 18rem;
		// 	width: 100%;
		// }

		iframe{
			width: 100%;
			height: 100%;
			display: block;
		}
	}
	.movie-link{
		margin: 4rem auto 8rem;
		padding-left: 10rem;
		padding-right: 12rem;
		border: 1px solid #171717;
		height: 8rem;
		width: 38rem;
		display: flex;
		font-size: 2rem;
		align-items: center;
		position: relative;

		@media (--mobile) {
			margin: 2.4rem auto 6rem;
			height: 5rem;
			font-size: 1.6rem;
			width: 70%;
			padding-left: 4rem;
			padding-right: 3rem;
		}

		@media (--large) {
			&:hover{
				svg{
					path{
						stroke: #fff;
					}
					circle{
						fill: #111;
					}
				}
			}

		}

		span{
			position: absolute;
			top: 50%;
			right: 8rem;
			transform: translateY(-50%) translateY(0.5rem);
			width: 3rem;
			height: auto;
			display: block;
			@media (--mobile) {
				right: 2rem;
				transform: translateY(-50%) translateY(0.2rem);
			}
			svg{
				path,circle{
					transition: 0.3s;
				}
			}
		}

	}
	&-gallery{
		margin-top: 8rem;
		margin-bottom: 8rem;
		@media (--mobile) {
			margin-top: 3rem;
			margin-bottom: 6rem;
			margin-right: -1rem;
			margin-left: -1rem;
		}
		ul{
			width: 118rem;
			display: flex;
			flex-wrap: wrap;
			margin: 0 auto;
			@media (--mobile) {
				width: 100%;
			}
			li{
				width: 18rem;
				height: 18rem;
				margin-right: 2rem;
				margin-bottom: 2rem;
				overflow: hidden;
				@media (--mobile) {
					width: calc(100% / 3 - 1rem);
					height: 10.4rem;
					margin: 0 .5rem;
					&:nth-of-type(n + 4) {
						margin-top: 1rem;
					}
				}
				&:nth-child(6n){
					margin-right: 0;
				}
				a {
					height: 100%;
					width: 100%;
					display: inline-block;
					img {
						width: 18rem;
						height: 18rem;
						object-fit: cover;
						transition:1s all;
						@media (--mobile) {
							width: 10.4rem;
							height: 10.4rem;
						}
						&:hover{
							transform: scale(1.05);
							transition: 1s all;
						}
					}
				}
			}
		}
		&-modal {
			overflow: hidden;
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 9999;
			opacity: 0;
			transition: .3s opacity;
			&, * {
				pointer-events: none !important;
			}
			&.is-active {
				opacity: 1;
				&, * {
					pointer-events: inherit !important;
				}
			}
			.swiper-modal {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 60%;
				height: inherit;
				overflow: hidden;
				@media (--mobile) {
					width: 100%;
				}
			}
			.swiper-slide, .swiper-wrapper {
				height: inherit;
			}
			img {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				&.w {
					max-width: inherit;
					width: 100%;
				}
				&.h {
					max-height: inherit;
					height: 100%;
					@media (--mobile) {
						width: 100%;
						height: auto;
						max-height: inherit;
					}
				}
			}
			.swiper-button-prev, .swiper-button-next {
				width: 4rem;
				height: 4rem;
				background-color: rgba(30,30,30,0.8);
				border: .1rem solid #333333;
				outline: none;
				@media (--mobile) {
					width: 3rem;
					height: 3rem;
				}
				&::after {
					content: "";
					position: absolute;
					height: 1px;
					width: 2rem;
					background-color: #fff;
				}
				&::before {
					content: "";
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%) translateX(15%) rotate(-135deg);
					height: 1.2rem;
					width: 1.2rem;
					// border-left: .1rem solid #fff ;
					border-right: 0.1rem solid #fff !important;
					border-top: .1rem solid #fff;
				}
			}
			.swiper-button-prev {
				@media (--large) {
					left: 30%;
					transform: translateX(-28vw);
					&::after {
						left: 1.1rem;
						top: 50%;
						transform: translateY(-50%);
					}
				}
				&::before {
					content: "";
					height: 1.2rem;
					width: 1.2rem;
					border-left: none;
					border-right: .1rem solid #333;
					left: 39%;
				}
			}
			.swiper-button-next {
				@media (--large) {
					right: 30%;
					transform: translateX(28vw);
					&::after {
						left: 0.9rem;
						top: 50%;
						transform: translateY(-50%);
					}
				}
				&::before {
					content: "";
					transform: translate(-50%, -50%) translateX(-15%) rotate(45deg);
					height: 1.2rem;
					width: 1.2rem;
					border-left: none;
					border-right: .1rem solid #333;
					left: 65%;
				}
			}
			&__overflow {
				width: 100%;
				height: 100%;
				background: rgba(255, 255, 255, 0.95);
			}
			&__close {
				position: absolute;
				top: 0rem;
				right: 0rem;
				width: 4rem;
				height: 4rem;
				background-color: rgba(30,30,30,0.6);
				// border: .1rem solid #333333;
				outline: none;
				cursor: pointer;
				z-index: 9999;
				transition: 0.3s;

				@media (--mobile) {
					width: 3rem;
					height: 3rem;
				}
				&:hover{
					background-color: rgba(30,30,30,0.8);
				}
				&::after, &::before {
					content: "";
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%) rotate(45deg);
					height: 2rem;
					width: .1rem;
					background: #fff;
				}
				&::before {
					transform: translate(-50%, -50%) rotate(-45deg);
				}
			}
		}
	}
	&-layout{
		&__img{
			text-align: center;
			margin: 5rem auto 9rem auto;
			@media (--mobile) {
				margin-top: 8rem;
				margin-bottom: 12rem;
			}
		}
	}
	&-summary{
		dl{
			display: flex;
			flex-wrap: wrap;
			margin-top: 8rem;
			@media (--mobile) {
				display: block;
				margin-top: 8rem;
				margin-bottom: 12rem;
			}
		}
		dt{
			width: 20%;
			font-size: 1.5rem;
			font-weight: bold;
			padding: 3rem 0;
			border-bottom: 1px solid #D0D0D0;
			display: flex;
			align-items: center;
			padding-left: 3rem;
			// margin-right: 10rem;
			&:first-of-type{
				border-top: 1px solid #D0D0D0;
				// @media (--mobile) {
				// 	border: none;
				// }
			}
			@media (--mobile) {
				width: 100%;
				display: block;
				padding: 1.5rem 0;
				border: none;
			}
		}
		dd{
			width: 80%;
			font-size: 1.5rem;
			padding: 3rem 0;
			border-bottom: 1px solid #D0D0D0;
			line-height: 3.2rem;
			&:first-of-type{
				border-top: 1px solid #D0D0D0;
				@media (--mobile) {
					border-bottom: 1px solid #D0D0D0;
					border-top: none;
				}
			}
			@media (--mobile) {
				width: 100%;
				// border: none;
				padding: 0rem 0 1.5rem 0;
			}
		}
	}
	&-interview{
		width: 118rem;
		margin: 12rem auto 0 auto;
		display: flex;
		justify-content: space-between;
		@media (--mobile) {
			width: 100%;
			margin-top: 6rem;
			display: block;
		}


		&__title{
			font-size: 3.2rem;
			font-family: 'EB Garamond', serif;
			font-weight: Regular;
			white-space: nowrap;
			@media (--mobile) {
				font-size: 2.5rem;
				text-align: left;
				margin-bottom: 6rem;
			}

			.jp{
				font-size: 1.5rem;
				margin-bottom: 1.4rem;
				display: block;
				@media (--mobile) {
					margin-bottom: 1rem;
					font-size: 1.2rem;
				}

			}
		}
		&__content{
			display: flex;
	    flex-wrap: wrap;
  	  justify-content: flex-end;
		}
		&__cell{
			width: 78rem;
			display: flex;
			border-bottom: 1px solid #D0D0D0;
			padding-bottom: 4rem;
			margin-bottom: 4rem;
			padding: 0 2rem 4rem 2rem;
			@media (--mobile) {
				width: 100%;
				display: flex;
				flex-direction: column-reverse;
				padding-bottom: 8rem;
				margin-bottom: 8rem;
				&:last-child{
					border-bottom: none;
					padding-bottom: 0;
					margin-bottom: 0;
				}
			}

		}
		&__cell:nth-child(even){
			flex-direction: row-reverse;
			@media (--mobile) {
				flex-direction: column-reverse;
			}
			.p-design-detail-interview__text{
				margin-right: 0rem;
				margin-left: 3rem;
				@media (--mobile) {
					margin-left: 0;
				}

			}
		}
		&__text{
			width: 55rem;
			margin-right: 3rem;
			@media (--mobile) {
				width: 100%;
				margin-right: 0;
				margin-bottom: 0rem;
			}

		}
		&__heading{
			font-size: 2rem;
			margin-bottom: 1.4rem;
		}
		&__ttl{
			font-size: 2rem;
			font-weight: bold;
			margin-bottom: 2.5rem;
		}
		&__txt{
			font-size: 1.5rem;
			line-height: 3.2rem;
			margin-bottom: 1.5rem;;
			@media (--mobile) {
				margin-top: 1rem;
			}
		}
		&__name{
			font-size: 1.5rem;
			margin-bottom: 1.5rem;
			@media (--mobile) {
				display: flex;
				align-items: center;
				margin-bottom: 0;
				white-space: nowrap;
			}
			span{
				font-size: 1.3rem;
				margin-right: 1.6rem;
			}
			span.position{
				position: relative;
				margin-right: 2rem;
				color: #7B7B7B;
				line-height: 1;
				&::after{
					position: absolute;
					display: block;
					content: "";
					width: 1px;
					height: 1.3rem;
					background-color: #7B7B7B;
					right: -1rem;
					top: 50%;
					transform: translateY(-50%);
					// @media (--mobile) {
					// 	top: 0.4rem;
					// }
				}
			}
			span.kana{
				margin-left: 1.2rem;
				@media (--mobile) {
					margin-right: 0;
					display: block;
				}
			}
			span.city{
				font-size: 1.3rem;
				color: #7B7B7B;
				position: relative;
				margin-left: 2rem;
				line-height: 1;
				&::before{
					position: absolute;
					display: block;
					content: "";
					width: 1px;
					height: 1.3rem;
					background-color: #7B7B7B;
					left: -1rem;
					top: 50%;
					transform: translateY(-50%);
				}
			}
		}
		&__more{
			display: flex;
			justify-content: flex-end;
			font-size: 1.5rem;
			margin-top: -2.2rem;
			@media (--mobile) {
				margin-top: 1rem;
			}

			img{
				margin-left: 1.5rem;
			}
		}
		&__img{
			width: calc(100% - 55rem - 3rem);
			// img{
			// 	width: 100%;
			// 	height: 100%;
			// 	object-fit: cover;
			// }
			@media (--mobile) {
				width: 100%;
				text-align: center;
				margin-bottom: 3rem;
			}

		}
	}
	&-traveling{
		padding: 6rem 15rem;
		border-top: 1px solid #D0D0D0;
		border-bottom: 1px solid #D0D0D0;

		@media (--mobile) {
			padding: 6rem 0rem;
		}

		h3{
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			font-weight: 400;
			small{
				font-size: 1.4rem;
			}
			span{
				font-size: 2.6rem;
				display: block;
				margin-top: 1rem;
				@media (--mobile) {
					font-size: 2rem;
				}
			}
		}
		.traveling{
			&-content{

				margin-top: 6rem;
				display: flex;
				align-items: center;
				column-gap: 4.8rem;

				@media (--mobile) {
					margin-top: 2rem;
					flex-direction: column;

				}

				p{
					font-size: 1.6rem;
					flex: 1;
				}

				&__btnWrap{
					@media (--mobile) {
						width: 100%;
						margin-top: 5rem;
					}
					a{
						display: flex;
						padding-left: 12rem;
						align-items: center;
						width: 47.3rem;
						position: relative;
						border: 1px solid #7E7E7E;
						font-size: 2rem;
						font-weight: 500;

						@media (--large) {

							svg{
								g{
									transition: 0.3s;
								}
								path{
									transition: 0.3s;
								}
							}

							&:hover{
								svg{
									g{
										fill: #111;
									}
									path{
										stroke: #fff;
									}
								}
							}
						}

						@media (--mobile) {
							width: 100%;
							padding-left: 7rem;
							height: 6rem !important;
							font-size: 1.6rem;
						}

						&.traveling{
							height: 7rem;
						}

						&.contact{
							height: 10rem;
							margin-top: 2rem;
						}

						svg{
							position: absolute;
							width: 3rem;
							height: auto;
							top: 50%;
							transform: translateY(-50%) translateY(0.2rem);
							right: 12rem;

							@media (--mobile) {
								right: 3rem;
							}

						}
					}
				}
			}
		}
	}

	&-youtube{
		&-img{
			width: 100%;
			height: 56rem;
			max-width: 93rem;
			margin: 5rem auto 0;
			position: relative;
			cursor: pointer;
			overflow: hidden;

			@media (--large) {
				&:hover{
					img{
						transform: scale(1.08);
					}
				}
			}

			img{
				width: 100%;
				height: 100%;
				object-fit: cover;
				transition: 0.3s;
			}

			@media screen and (max-width: 767px) {
				height: 20rem;
				margin: 2rem auto 0;
			}

			h4{
				color: #fff;
				font-size: 5.4rem;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%) translateY(-6rem);
				z-index: 20;
				font-weight: 300;

				@media screen and (max-width: 767px) {
					font-size: 3rem;
					transform: translate(-50%,-50%) translateY(-3rem);
				}
			}

			svg{
				display: block;
				width: 9rem;
				height: auto;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%) translateY(1rem);
				z-index: 20;

				@media (--mobile) {
					width: 4rem;
					transform: translate(-50%,-50%) translateY(1rem);
				}
			}

			&::before{
				content: "";
				z-index: 20;
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
				background: #000;
				opacity: 0.4;
			}

		}
		&-movie{
			width: 70%;
			aspect-ratio: 5 / 3;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);

			@media screen and (max-width: 767px) {
				width: 90%;
			}
		}
		&-bg{
			width: 75%;
			aspect-ratio: 5 / 3.1;
			position: absolute;
			background: #111;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			z-index: -1;
			@media screen and (max-width: 767px) {
				display: none;
			}
		}

		&-close{
			cursor: pointer;
			width: 6rem;
			height: 6rem;
			background: #111;
			border-radius: 50%;
			position: absolute;
			top: -6rem;
			right: -6rem;

			@media (--mobile) {
				width: 4rem;
				height: 4rem;
				top: -5rem;
				right: 0rem;

				span{
					font-size: 2.3rem;
				}
			}

			span{
				color: #fff;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);
				position: absolute;
				font-size: 3rem;
				z-index: 30;
			}
		}

	}

}

.js-gallery-movie{

	&-content{

		&.is-open{
			opacity: 1;
			pointer-events: auto;
		}

		opacity: 0;
		pointer-events: none;
		transition: 0.3s;

		position: fixed;
		z-index: 200;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%) translateY(-3rem);
		width: 100%;
		height: 100vh;
		z-index: 999;

	}
	&-overlay{
		position: fixed;
		width: 100%;
		height: 100vh;
		background: unquote("rgba(0, 0, 0, 0.8)");
		z-index: -1;
		cursor: pointer;
	}
}

.p-design-detail-picker{
	position: fixed;
	bottom: 0;
	right: 0;
	width: 40rem;
	height: 6rem;
	display: flex;
	z-index: 100;

	@media (--mobile) {
		width: 100%;
		height: 6rem;
	}

	.picker{
		&-ttl{
			background: #171717;
			width: 10.4rem;
			display: flex;
			justify-content: center;
			align-items: center;
			color: #fff;
			font-size: 1.6rem;

			@media (--mobile) {
				width: 8rem;
				font-size: 1.4rem;
			}

		}
		&-text{
			flex: 1;
			border: 1px solid #171717;
			border-right: 0px;
			border-bottom: 0px ;
			display: flex;
			padding-left: 1.6rem;
			align-items: center;
			font-size: 1.8rem;
			font-weight: bold;
			position: relative;
			letter-spacing: 0.05em;
			background: #fff;

			@media (--mobile) {
				font-size: 1.6rem;
				border-left: 0px;
			}

			&::after{
				content: "";
				position: absolute;
				top: 50%;
				transform: translateY(-50%) translateY(0.1rem) rotate(45deg);
				right: 2rem;
				width: 0.9rem;
				height: 0.9rem;
				border-top: 1px solid #707070;
				border-right: 1px solid #707070;
			}
		}
	}
}

.modaal-gallery-control{
	border: 1px solid #171717;
	background-color: rgba(255,255,255,0.3);
}

.modaal-gallery-control:after, .modaal-gallery-control:before{
	border-radius: 0;
	background: #171717;
	top: 1.5vw;
	left: 2.2vw;
	width: 1px;
	height: 9px;
}
.modaal-gallery-prev:after, .modaal-gallery-prev:before{
	left: 50%;
	top: 50%;
}
.modaal-gallery-prev:before{
	transform: translate(-50%, -50%) rotate(-45deg);
}
.modaal-gallery-prev:after{
	transform: translate(-50%, -50%) rotate(45deg);
}
.modaal-gallery-next:after, .modaal-gallery-next:before{
	left: 50%;
	top: 50%;
}
.modaal-gallery-next:before{
	transform: translate(-50%, -50%) rotate(-45deg);
}
.modaal-gallery-next:after{
	transform: translate(-50%, -50%) rotate(45deg);
}
.modaal-overlay{
	background: rgb(255, 255, 255)!important;
	opacity: 0.9!important;
}
.modaal-close:after, .modaal-close:before{
	background: #171717;
	width: 1px;
	top: 50%;
	left: 50%;
}
.modaal-close:before{
	transform: translate(-50%,-50%) rotate(-45deg);
}
.modaal-close:after{
	transform: translate(-50%,-50%) rotate(45deg);
}
.modaal-close:focus:after, .modaal-close:focus:before, .modaal-close:hover:after, .modaal-close:hover:before{
	background: #8B8B8B;
}
.modaal-gallery-control:focus, .modaal-gallery-control:hover{
	background: #C4C4C4;
	border: 1px solid #FFF;
}
.modaal-gallery-control:focus:after, .modaal-gallery-control:focus:before, .modaal-gallery-control:hover:after, .modaal-gallery-control:hover:before{
	background: #FFFFFF;
}
.modaal-wrapper .modaal-close{
	background-color: rgba(255,255,255,0.3);
	border: 1px solid #171717;
}
.modaal-wrapper .modaal-close:focus, .modaal-wrapper .modaal-close:hover{
	background: #C4C4C4;
	border: 1px solid #FFF;
}
.modaal-close:focus:after, .modaal-close:focus:before, .modaal-close:hover:after, .modaal-close:hover:before{
	background: #FFFFFF;
}
@media only screen and (max-width: 1140px){
	.modaal-gallery-control {
		background-color: rgba(255,255,255,0.7);
	}
	// .modaal-image .modaal-inner-wrapper{
	// 	padding-left: 0;
  //   padding-right: 0;
	// }
}
.modaal-gallery-control:before {
	display: none;
}
.modaal-gallery-control{
	width: 25px;
	height: 25px;
}
.modaal-close{
	width: 25px;
	height: 25px;
}
.modaal-close:after, .modaal-close:before{
	height: 13px;
}
.modaal-gallery-control:after {
	width: 0.5rem;
	height: 0.5rem;
	background: none !important;
	border-top: .1rem solid #171717;
	border-right: .1rem solid #171717;
	margin: 0;
	transform: translate(-75%, -50%) rotate(45deg);
}

.modaal-gallery-prev::after {
	transform: translate(-25%, -50%) rotate(-135deg);
}

.modaal-gallery-control:hover {
	&::after {
		border-color: #fff;
	}
}


.c-side-right__grid a{
	pointer-events: none;
}