.p-traveling{

  & * {
    font-weight: 400;
  }

  &__mv{
    height: 46vw !important;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;

    }
  }

  &__heading{

    width: 98rem;
    margin: 0 auto;

    @media (--mobile) {
      width: 100%;
      padding:0 3rem;
    }

    .heading{
      &-ttl{
        font-size: 2.6rem;
        font-weight: 400;
        small{
          font-size: 1.4rem;
          display: block;
          margin-bottom: 2rem;

          @media (--mobile) {
            margin-bottom: 0.6rem;
          }
        }
      }
      &-txt{
        margin-top: 5rem;
        font-size: 1.6rem;
        letter-spacing: 0;
        line-height: 2;

        &.movies{
          margin-top: 8rem;
        }

        @media (--mobile) {
          font-size: 1.4rem;
          margin-top: 2.5rem;
        }
      }
    }

  }

  &__catList{
    width: 98rem;
    margin: 11rem auto 0;

    @media (--mobile) {
      width: 100%;
      margin-top: 6rem;
    }

    ul{
      width: 60rem;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;

      gap: 3rem 2.6rem;

      @media (--mobile) {
        width: 30rem;
      }

      li{
        text-align: center;
        a{
          width: 12.7rem;
          display: block;
          padding-bottom: 0.8rem;
          font-size: 1.4rem;
          transition: 0.3s;
          position: relative;

          &::before,
          &::after{
            position: absolute;
            bottom: 0;
            left: 0;
            content: "";
          }
          &::before{
            height: 1px;
            width: 100%;
            background: #D0D0D0;
          }
          &::after{
            content: '';
            display: block;
            height: 1px;
            background-color: #171717;
            transition: width 300ms;
            width: 0;
          }
          @media (--large) {
            &:hover{
              &::after{
                width: 100%;
              }
            }
          }
        }
      }
    }
  }

  &__list{

    width: 118rem;
    margin: 7rem auto 0rem;

    @media (--mobile) {
      width: 100%;
    }

   & > ul{

      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      row-gap: 7rem;
      margin-bottom: 8rem;


      & > li{
        width: 55rem;
        position: relative;

        figure{
          overflow: hidden;
          width: 100%;
          height: 36rem;

          &.movies{
            position: relative;
            cursor: pointer;
            &:hover{
              img{
                transform: scale(1.08);
              }
            }

            &::before{
              position: absolute;
              content: "";
              background: #000;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              opacity: 0.3;
              z-index: 10;
            }

            .movies-img__ttl{
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              z-index: 20;
              display: flex;
              flex-direction: column;
              align-items: center;
              h3{
                font-size: 2.4rem;
                color: #fff;
              }
              span{
                display: block;
                width: 4.4rem;
                height: 4.4rem;
                margin-top: 2.4rem;
              }
            }
          }

          @media (--mobile) {
            height: 20rem;
          }
          img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: 0.3s;
          }
        }

        .list-content{

          &.movies{
            display: flex;
            flex-direction: row-reverse;
            column-gap: 2rem;
            position: relative;
            align-items: baseline;

            @media (--mobile) {

            }

            .list-ttl{
              margin-top: 0;
              @media (--mobile) {
                display: flex;
                justify-content: flex-end;
              }
            }
            .list-cat{
              flex: 1;
              @media (--mobile) {
                margin-top: 1rem;
              }
            }
          }

          @media (--mobile) {
            padding: 0 3rem;
          }
        }

        .list-ttl{
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 1.6rem;

          h3{
            font-size: 1.8rem;
          }
          a{
            font-size: 1.4rem;
            position: relative;
            padding-bottom: 0.3rem;
            z-index: 20;

            &::before,
            &::after{
              position: absolute;
              bottom: 0;
              left: 0;
              content: "";
            }
            &::before{
              height: 1px;
              width: 100%;
              background: #D0D0D0;
            }
            &::after{
              content: '';
              display: block;
              height: 1px;
              background-color: #171717;
              transition: width 300ms;
              width: 0;
            }
            @media (--large) {
              &:hover{
                &::after{
                  width: 100%;
                }
              }
            }
          }
        }

        .list-cat{
          margin-top: 2rem;
          display: flex;
          column-gap: 0.8rem;
          row-gap: 2rem;
          flex-wrap: wrap;


          li{
            a{
              font-size: 1.2rem;
              padding: 0.6rem 0.8rem;
              border: 1px solid #CECECE;
              color: #707070;
              transition: 0.3s;

              &:hover{
                opacity: 0.7;
              }
            }
          }
        }

        .list-addr{
          font-size: 1.6rem;
          margin-top: 2.8rem;
        }

        .list-txt{
          font-size: 1.6rem;
          margin-top: 1rem;

          @media (--mobile) {
            font-size: 1.5rem;
          }
        }

      }

    }
  }

}