@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:wght@400;500&display=swap');
html {
  font-size: 62.5%; //1.0rem = 10px
  background: #fff;
  @media (--mobile) {
    font-size: calc(10vw / 375 * 100);
  }
}
body {
  font-family: 'Shippori Mincho', serif;
  font-size: 1.0rem;
  letter-spacing: .15em;
  font-feature-settings: "palt";
  line-height: 1.5;
}
a {
  color: #000000;
  text-decoration: none;
}
img {
  max-width: 100%;
}

svg {
  width: 100%;
}

*, *:before, *:after {
  box-sizing: border-box;
}

//text
h1, h2, h3, h4, h5 ,h6, div, dl, dt, dd, p {
  margin:0;
  padding: 0;
}

ul,ol,li {
  padding: 0;
  margin: 0;
}
li {
  list-style: none;
}
em {
  font-style: initial;
}

img {
  vertical-align: bottom;
}
figure{
  margin: 0;
  padding: 0;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"]{
  -moz-appearance:textfield;
}

@media (--mobile) {
  html,body{
    width: 100%;
    height: 100%;
  }

  h1, h2, h3, h4, h5 ,h6, div, dl, dt,dd, p {
    margin:0;
    padding: 0;
  }

  img {
    height: auto;
    max-height: 100%;
    max-width: 100%;
    width: auto;
  }
}


@media (--large) {
  .sp {
    display: none !important;
  }
  [href*='tel:'] {
    pointer-events: none;
  }
}

@media (--mobile) {
  .pc {
    display: none !important;
  }
}

// フォーム リセット

input, textarea, button {
	appearance: none;
  border: none;
  background: none;
  outline: none;
}