.l-footer {
  position: relative;
  overflow: hidden;
  z-index: 2;
  &__bg {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
    transform: scale(1.3);
    img {
      height: 100%;
      width: 100%;
      transform: translateY(10rem);
      object-fit: cover;
    }
  }
  .f-contact {
    position: relative;
    z-index: 1;
    margin: 0 0 0 auto;
    padding: 8rem 0;
    @media (--mobile) {
      width: auto;
      padding: 5rem 2rem;
    }
    &__inner {
      width: 100rem;
      margin-left: auto;
      margin-right: auto;
      @media (--mobile) {
        width: auto;
      }
    }
    &__ttl {
      text-align: center;
      color: #fff;
      font-size: 1.6rem;
      font-weight: lighter;
      @media (--mobile) {
        line-height: 1.8;
      }
      strong {
        display: block;
        font-size: 1.8rem;
        letter-spacing: .15em;
      }
      span {
        display: inline-block;
      }
      @media (--mobile) {
        span {
          display: inline-block;
          width: 21rem;
          img {
            width: 100%;
          }
        }
      }
    }
    &__txt {
      text-align: center;
      color: #fff;
      letter-spacing: .2em;
      font-size: 1.8rem;
      margin-top: 3.2rem;
      @media (--mobile) {
        line-height: 2;
      }
    }
    &__flex-wrap {
      display: flex;
      justify-content: space-between;
      margin-top: 4rem;
      @media (--mobile) {
        flex-wrap: wrap;
        justify-content: space-between;
      }
      &--ele {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 14rem;
        text-align: center;
        font-size: 1.6rem;
        color: #fff;
        @media (--mobile) {
          height: 9rem;
          padding-top: 0;
          &:last-of-type {
            width: 100%;
          }
        }
        figure {
          width: 3.6rem;
          min-height: 4rem;
          margin-left: auto;
          margin-right: auto;
          @media (--mobile) {
            min-height: auto;
          }
          svg {
            path {
              transition: .3s fill;
              fill: #fff;
            }
          }
        }
      }
      a {
        background: rgba(0, 0, 0, .6);
      }
      &--mail, &--line {
        width: 32rem;
        transition: .3s background, .3s color;
        @media (--mobile) {
          display: flex;
          align-items: center;
          width: 15.7rem;
          height: 13rem;
          padding-top: 1rem;
          figure {
            margin-bottom: .5rem;
            height: 3rem;
            svg {
              height: 2rem;
            }
          }
        }
        &:hover {
          background: rgba(255, 255, 255, .7);
          color: #171717;
          svg {
            path {
              fill: #171717;
            }
          }
        }
      }
      &--line {
        svg {
          width: 4rem;
          position: relative;
          top: -.4rem;
        }
        @media (--mobile) {
          figure {
            position: relative;
            top: -.5rem;
            svg {
              height: 3rem;
              position: relative;
              top: -.2rem;
            }
          }
        }
      }
      &--tel {
        @media (--large) {
          width: 32rem;
        }
        @media (--mobile) {
          margin-top: 2rem !important;
          height: 11rem;
          padding-top: 1rem;
        }
        figure {
          width: 23.7rem;
          margin-bottom: .6rem;
        }
      }
    }
  }
  .f-menu {
    position: relative;
    background: rgba(23, 23, 23, .8);
    display: flex;
    justify-content: center;
    padding-top: 11.5rem;
    padding-bottom: 11.5rem;
    z-index: 1;
    @media (--mobile) {
      display: block;
      margin-top: 0;
      padding-top: 4rem;
      padding-bottom: 8rem;
      padding-left: 4rem;
      padding-right: 4rem;
    }
    &__box{
      min-width: 8rem;
    }
    &__logo {
      @media (--large) {
        margin-right: 9.8rem;
        width: 11rem;
      }
      @media (--mobile) {
        width: 7rem;
      }
      svg{
        width: 11rem;
        @media (--mobile) {
          width: 7rem;
        }
      }
      path {
        fill: #fff;
      }
      p{
        font-size: 1.2rem;
        color: #9B9B9B;
        margin-top: 2rem;
      }
    }
    &__sns{
      display: flex;
      @media (--mobile) {
        flex-direction: row !important;
        justify-content: left !important;
      }
      a{
        display: block;
        @media (--mobile) {
         display: flex;
        }
        &:nth-child(n + 2){
          margin-left: 1.4rem;
        }
        svg{
          width: 100%;
          @media (--mobile) {
            width: auto;
          }
        }
      }
    }
    &__right{
      width: 90rem;
      @media (--mobile) {
        width: 100%;
        margin-top: 3.3rem;
      }
    }
    &__listWrap {
      display: flex;
      @media (--mobile) {
        display: block;
      }
      ul {
        @media (--large) {
          width: 34rem;
          margin-right: 4.2rem;
        }
        &:last-of-type {
          margin-right: 0;
        }
        @media (--mobile) {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          flex-direction: column;
          &.sp-m-top {
            margin-top: 3rem;
          }
          &.sp-m-top-mini {
            margin-top: 3rem;
          }
        }
        li {
          font-weight: lighter;
          @media (--mobile) {
            width: 100%;
          }
          &, * {
            color: #fff;
            // font-size: 1.5rem;
          }
          @media (--large) {
            &:nth-of-type(n + 2) {
              margin-top: 2.2rem;
            }
          }
          @media (--mobile) {
            &:nth-of-type(n + 2) {
              margin-top: 1.5rem;
            }
          }
          @media (--mobile) {
            &.sp-w-all {
              width: 100%;
              margin-top: 5rem !important;
              & + li {
                margin-top: 2.5rem;
              }
            }
          }
          a {
            display: block;
            position: relative;
            min-height: 2.4rem;
            display: inline-block;
            @media (--mobile) {
              font-size: 1.3rem;
            }
            &::before {
              content: "";
              margin: auto;
              position: absolute;
              left: 0;
              bottom: -.2rem;
              transition: transform .3s, -webkit-transform .3s;
              transform: scale(0, 1);
              transform-origin: right center;
              width: 100%;
              height: .1rem;
              background: rgba(255, 255, 255, .7);
            }
            &:hover {
              &::before {
                transform: scale(1, 1);
                transform-origin: left center;
              }
            }
          }
          &.tsukushi {
            &, * {
              color: #BABABA;
              font-size: 1.4rem;
              font-family: inherit;
              @media (--mobile) {
                font-size: 1.3rem;
              }
              &::after {
                border-color: #BABABA;
              }
            }
          }
        }
      }
    }
    &__mainList{
      margin-right: 10rem !important;
      @media (--mobile) {
        margin-right: 0 !important;
      }
      button, a{
        font-size: 1.7rem;
        padding: 0;
        @media (--mobile) {
          font-size: 2.4rem !important;
        }
      }
    }
    &__subList{
      font-size: 1.3rem;

      @media (--mobile) {
        margin-top: 3rem;
        }

      &:nth-child(n + 2){
        li{
          margin-top: 1.6rem !important;
        }
      }
    }
    &__firstsub{
      margin-right: 10rem !important;
      @media (--mobile) {
        margin-right: 0 !important;
      }
    }
    &__acco-icon{

    }
    &__acco-event{
      pointer-events: inherit;
      position: relative;
      @media (--large) {
        pointer-events: none;
      }

      &.open{
        .drawer-works-box{
          &::before{
            transform: rotate(0deg);
          }
        }
      }
    }
    &__acco{
      display: flex;
      justify-content: space-between;
      margin-left: 1rem;

      li{
        margin-top: 1.5rem !important;
        a{
          font-size: 1.4rem !important;
        }
      }
    }
    &__acco-inner{
      @media (--large) {
        display: block;
      }
      @media (--mobile) {
        display: none;
      }
    }
    &__sns {
      display: flex;
      justify-content: flex-start;
      margin-top: 3.5rem;
      @media (--mobile) {
        margin-bottom: 1.5rem;
      }
      ul{
        display: flex;
        margin-bottom: 1.3rem;
        @media (--mobile) {
          margin-bottom: 0;
        }
        li{
          margin-right: 1.5rem;
          a{
            transition: .3s opacity;
            &:hover{
              opacity: 0.6;
            }
          }
        }
      }
    }
    &__other {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      // margin-top: 4rem;
      padding-top: 3rem;
      border-top: 1px solid #707070;
      ul {
        display: flex;
        align-items: center;
        @media (--mobile) {
          flex-wrap: wrap;
        }
        li {
          a {
            color: #707070;
            font-size: 1.2rem;
            border-right: 1px solid #707070;
            padding: .2rem 1.4rem;
            transition: .5s;
            &:hover {
              color: #fff;
            }
          }
          &:first-of-type {
            a {
              padding-left: 0;
            }
          }
          @media (--mobile) {
            &:nth-of-type(3) {
              a {
                padding-left: 0;
              }
            }
            &:nth-of-type(n + 3) {
              margin-top: 1rem;
            }
          }
        }
      }
      p {
        font-size: 1.2rem;
        color: #707070;
        @media (--mobile) {
          white-space: nowrap;
        }
      }
    }
    &__contact{
      color: #fff;
      .drawer__bottomWrap{
        path{
          fill: #fff;
        }
      }
      .drawer__contact-text{
        color: #fff;
      }
    }
    &__copy {
      margin-top: 2rem;
      color: #fff;
      font-size: 1.6rem;
    }
  }
}