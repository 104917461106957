.p-line{
	&-about{
		width: 98rem;
		border-bottom: 1px solid #D0D0D0;
		padding-bottom: 8rem;
		margin: 0 auto 12rem auto;
		@media (--mobile) {
			width: 100%;
		}
		&__title{
			font-size: 3.2rem;
			text-align: center;
			margin-bottom: 12rem;
			@media (--mobile) {
				font-size: 2.6rem;
				margin-bottom: 4rem;
				text-align: left;
			}
		}
		.c-leftTtl{
			margin-bottom: 9rem;
			@media (--mobile) {
				margin-bottom: 6rem;
			}
		}
		&__inner{
			@media (--mobile) {
				display: block;
			}
		}
		&__img{
			width: 59rem;
			@media (--mobile) {
				width: auto;
				margin-bottom: 2rem;
			}
		}
		&__txt{
			margin-right: 9rem;
			@media (--mobile) {
				margin-right: 0rem;
			}
		}
		&__subtitle{
			font-size: 2.6rem;
			color: #333333;
			margin-bottom: 4.8rem;
			span.en{
				font-size: 1.2rem;
				font-family: 'EB Garamond', serif;
				font-weight: Regular;
				display: block;
				margin-bottom: 1.4rem;
				@media (--mobile) {
					margin-bottom: 1.2rem;
				}
			}
			@media (--mobile) {
				text-align: center;
			}
		}
		&__list{
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			margin-top: 4rem;
			margin-bottom: 5rem;
			@media (--mobile) {
				margin-top: 2rem;
				margin-bottom: 8rem;
			}
			p{
				width: 46rem;
				margin-bottom: 3rem;
				font-size: 1.8rem;
				padding-bottom: 2rem;
				border-bottom: 1px solid #D0D0D0;
				letter-spacing: 0.05em;
				@media (--mobile) {
					width: 100%;
					display: flex;
					align-items: center;

					&:last-child{
						margin-bottom: 0;
					}
				}

				span{
					font-size: 1.4rem;
					font-family: 'EB Garamond', serif;
					font-weight: Regular;
					margin-right: 1rem;
				}
			}
		}
		&__text{
			width: 98rem;
			margin: 0 auto;
			font-size: 1.5rem;
			line-height: 3.2rem;

			p{
				margin-bottom: 4rem;
				@media (--mobile) {
					letter-spacing: 1.6px;
				}
				&:last-child{
					margin-bottom: 0;
				}
			}
			@media (--mobile) {
				width: 100%;
			}
		}
	}

	&-flow{
		margin-top: 8rem;
		@media (--mobile) {
			margin-top: 4rem;
		}
		p.mainTxt{
			font-size: 1.5rem;
			line-height: 3.2rem;
			text-align: center;
			margin-bottom: 8rem;
			@media (--mobile) {
				margin-bottom: 4rem;
			}
		}

		.mainTxt-detail{
			display: block;
			white-space: nowrap;
		}

		&__step{
			margin-right: 4rem;
			font-size: 1.8rem;
			font-family: 'EB Garamond', serif;
			font-weight: Regular;
			@media (--mobile) {
				width: 38%;
				margin-right: 1rem;
			}
			p{
				background-color: #FFFFFF;
				z-index: 99;
				position: relative;
				padding: 1.7rem 0;
				@media (--mobile) {
					padding: 0;
					margin-bottom: 0.5rem;
				}
			}
		}

		.p-line-flow__step.last{
			background: #FFFFFF;
			// padding-top: 1.7rem;
			z-index: 99;
			position: relative;
		}
		&__content{
			width: 50rem;
			padding-top: 1.7rem;
			@media (--mobile) {
				width: 100%;
				padding-top: 0;
			}
			p{
				font-size: 1.5rem;
				line-height: 3.2rem;
				@media (--mobile) {
				letter-spacing: 1.6px;
				font-size: 1.4rem;
				}
			}
			.title{
				font-size: 1.6rem;
				line-height: 2.9rem;
				margin-bottom: 1.5rem;
				letter-spacing: 0;
				@media (--mobile) {
					font-size: 1.4rem;
					}
				span{
					font-weight: bold;
				}
			}
			.qrcode{
				margin-top: -1rem;
				margin-left: -1rem;
			}
			.img_line{
				width: 27.8rem;
				margin-bottom: 3rem;
				@media (--mobile) {
					width: 100%;
				}
			}
			.line_id{
				font-size: 1.8rem;
				font-weight: bold;
				margin-bottom: 1.5rem;
				span{
					background-color: #F0F0F0;
					padding: 1rem 3rem;
					display: inline;
				}
			}

		}
		&__box{
			display: flex;
			justify-content: space-between;
			margin-top: 3.5rem;
			margin-bottom: 4rem;
			@media (--mobile) {
				display: block;
			}
		}
		&__left{
			width: 46%;
			@media (--mobile) {
				width: 100%;
				margin-bottom: 4rem;
			}
		}
		&__right{
			width: 46%;
			@media (--mobile) {
				width: 100%;
			}
		}
		&__ttl{
			font-size: 2rem;
			color: #171717;
			font-weight: bold;
			margin-bottom: 1.5rem;
			@media (--mobile) {
				margin-bottom: 4rem;
			}
			&.last{
				padding-top: 1.5rem;
			}
		}
		&__txt{
			font-size: 1.5rem;
			line-height: 3.2rem;
			margin-bottom: 2.5rem;
		}
		&__contents{
			display: flex;
			justify-content: center;
			&:last-child{
				margin-top: 12rem;
			}
			@media (--mobile) {
				display: block;
				width: 100%;
			}
		}
		ul{
			position: relative;
			@media (--mobile) {
				width: 100%;
			}
			&::after{
				content:"";
				display: inline-block;
				width: 1px;
				height: 100%;
				background-color: #D0D0D0;
				position: absolute;
				top: 0;
		    left: 35px;
				z-index: 1;
				@media (--mobile) {
					left: 30px;
				}
			}
			li{
				display: flex;
				margin-bottom: 5rem;
				@media (--mobile) {
					margin-bottom: 10rem;
				}
				&:last-child{
					margin-bottom: 0;
				}
				&.turn{
					padding-bottom: 10rem;
					@media (--mobile) {
						padding-bottom: 0;
						margin-bottom: 8rem;
					}
				}
			}
		}
	}
}