.p-privacypolicy{
	.c-inner{
		width: 98rem;
		@media (--mobile) {
			width: auto;
			padding: 0 3rem;
		}
		.c-leftTtl{
			margin-bottom: 8rem;
		}
	}
	&__content{
		margin: 0 auto 5rem auto;
		@media (--mobile) {
			width: 100%;
		}
		h3{
			font-size: 2rem;
			margin-bottom: 3rem;
			@media (--mobile) {
				margin-bottom: 2rem;
			}
		}
		p{
			font-size: 1.5rem;
			line-height: 3.2rem;
			&:nth-child(2n+1){
				margin-top: 3rem;
			}
			span{
				display: block;
			}
		}
	}
}