.c-inner {
  width: 128rem;
  margin-left: auto;
  margin-right: auto;
  @media (--mobile) {
    width: auto;
    padding: 0 3rem;
  }
}
.c-i-inner {
  width: 118rem;
  margin-left: auto;
  margin-right: auto;
  @media (--mobile) {
    width: auto;
    padding: 0 3rem;
  }
}

// 土地探しページで使用
.land-inner {
  padding-left: 10rem;
  @media (--mobile){
    padding-left: 0;
  }
}
