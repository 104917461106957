.p-concept{
	&-main{
		@media (--mobile) {
			overflow-x: hidden;
		}
	}
	&-about{
		width: 98rem;
		margin: 0 auto 12rem auto;
		@media (--mobile) {
			width: 100%;
			margin-bottom: 6rem;
		}
		&__title{
			font-size: 3.2rem;
			text-align: center;
			margin-bottom: 12rem;
			@media (--mobile) {
				font-size: 2.6rem;
				margin-bottom: 4rem;
				text-align: left;
			}
		}
		.c-leftTtl strong{
			@media (--mobile) {
				font-size: 1.8rem!important;
			}
		}
		&-ttl{
			margin-bottom: 8rem !important;
			@media (--mobile) {
				margin-bottom: 4rem!important;
			}
		}
		&__ttl-text{
			font-size: 1.5rem;
			@media (--mobile) {
				font-size: 1.3rem;
			}


			p{
				line-height: 2.2;
				&:nth-child(n + 2){
					margin-top: 4rem;
					@media (--mobile) {
						margin-top: 1rem;
					}
				}
			}
		}
		.c-leftTtl{
			margin-bottom: 9rem;
			@media (--mobile) {
				margin-bottom: 6rem;
			}
		}
		&__inner{
			@media (--mobile) {
				display: block;
			}
		}
		&__img{
			width: 59rem;
			@media (--mobile) {
				width: auto;
				margin-bottom: 2rem;
			}
		}
		&__txt{
			margin-right: 9rem;
			@media (--mobile) {
				margin-right: 0rem;
			}
		}
		&__subtitle{
			font-size: 2.6rem;
			color: #333333;
			margin-bottom: 4.8rem;
			span.en{
				font-size: 1.2rem;
				font-family: 'EB Garamond', serif;
				font-weight: Regular;
				display: block;
				margin-bottom: 1.4rem;
				@media (--mobile) {
					margin-bottom: 1.2rem;
				}
			}
			@media (--mobile) {
				text-align: center;
			}
		}

		&__list-ttl{
			margin-top: 15rem;
			@media (--mobile) {
				margin-top: 6rem;
			}
			p{
				font-size: 2rem;
				span{
					display: block;
					font-size: 1.5rem;
					margin-top: 3rem;
					@media (--mobile) {
						margin-top: 2rem;
						font-size: 1.3rem;
					}
				}
			}
		}
		&__list{
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			margin-top: 6rem;
			margin-bottom: 5rem;
			@media (--mobile) {
				margin-top: 4rem;
				margin-bottom: 0rem;
			}
			p{
				width: 46rem;
				margin-bottom: 3rem;
				font-size: 1.6rem;
				padding-bottom: 2rem;
				border-bottom: 1px solid #D0D0D0;

				@media (--mobile) {
					width: 100%;
					display: flex;
					align-items: flex-start;
					white-space: nowrap;
					font-size: 1.2rem;

					&:last-child{
						margin-bottom: 0;
					}
				}

				span{
					font-size: 1.4rem;
					font-family: 'EB Garamond', serif;
					font-weight: Regular;
					margin-right: 1rem;
				}
			}
		}
		&__text{
			width: 98rem;
			margin: 0 auto;
			font-size: 1.5rem;
			line-height: 3.2rem;

			p{
				margin-bottom: 4rem;
				&:last-child{
					margin-bottom: 0;
				}
			}
			@media (--mobile) {
				width: 100%;
			}
		}
	}
	&-thought{
		margin-bottom: 20rem;
		@media (--mobile) {
			margin-bottom: 6rem;
		}
		.c-sideImg{
			margin-right: calc(50% - 50vw);
			margin-left: calc(50% - 50vw);
		}
		.c-sideImg div{
			@media (--mobile) {
				margin-right: calc(50% - 50vw);
				margin-left: calc(50% - 50vw);
			}
		}
		.c-sideImg figcaption{
			position: relative;
			z-index: 20;
			color: #fff;
			@media (--mobile) {
				margin-top: 3rem;
			}
		}

		.p-concept-thought-bg{
			justify-content: end;
			background-repeat: no-repeat;
			background-size: cover;
			height: 40vw;
			max-height: 50rem;
			// margin-left: 0 !important;
			min-height: 62rem;
			position: relative;
			@media (--mobile) {
				margin-left: -8vw;
				margin-right: -8vw;
				min-width: initial;
				min-height: initial;
				height: auto;
			}
		}

		.p-concept-thought-ttl{
			strong{
				@media (--mobile) {
				font-size: 2.6rem !important;
				}
			}
		}

		.p-concept-thought-text{
			width: 45%;
			height: 100%;
			padding-left: 7rem !important;
			padding-top: 10rem;
			// padding-bottom: 4rem;
			@media (--mobile) {
				width: 100%;
				padding-left: 2rem !important;
				padding-right: 2rem !important;
				padding-top: 3rem;
				padding-bottom: 3rem;
			}
			p{
				font-size: 1.3rem !important;
				margin-top: 3rem !important;

				span{
					font-size: 1.6rem;
				}
			}
			.c-leftTtl strong{
				line-height: 1;
				margin-top: 0;
			}
		}

		.cover-grey{
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			width: 100%;
			height: 100%;
			background-color: rgba($color: #222, $alpha: 0.8);
			z-index: -1;
			@media (--mobile) {
			z-index: 0;
			}
		}
	}
	&-list{
		// background: url("../images/concept/bg-about.jpg");
		margin-bottom: 10rem;
		// margin-right: calc(50% - 50vw);
		// margin-left: calc(50% - 50vw);
		// padding-right: calc(50vw - 50%);
		// padding-left: calc(50vw - 50%);

		@media (--mobile) {
			padding-bottom: 0rem;
			margin-bottom: 6rem;
		}
		.img-wrap{
			height: 54rem;
			margin-right: calc(50% - 50vw);
			margin-left: calc(50% - 50vw);
			position: relative;
			background: url("../images/concept/bg-about.jpg");
			display: flex;
			align-items: center;
			&::before {
				content: "";
				position: absolute;
				height: 40rem;
				left: 0;
				width: 100%;
				backdrop-filter: grayscale(0.9) brightness(60%);
				@media (--mobile) {
					height: 95%;
				}
			}
			@media (--mobile) {
				padding: 5rem 0;
				height: 100%;
				background-size: cover;
			}
		}
		ul{
			display: flex;
			justify-content: space-between;
			color: #FFF;
			width: 118rem;
			margin-left: auto;
			margin-right: auto;
			// margin-right: calc(50% - 50vw);
			// margin-left: calc(50% - 50vw);
			// padding-right: calc(50vw - 50%);
			// padding-left: calc(50vw - 50%);
			position: relative;

			@media (--mobile) {
				width: auto;
				padding: 0 3rem;
				display: block;
			}

			li{
				width: 27%;
				@media (--mobile) {
					width: 100%;
					margin-right: 0;
					margin-bottom: 8rem;
				}

				&:last-child{
					margin-right: 0rem;
					@media (--mobile) {
						margin-bottom: 0rem;
					}
				}
			}
		}
		&__img{
			margin-bottom: 3rem;
			@media (--mobile) {
				margin-bottom: 6rem;
			}
		}
		&__title{
			font-size: 2rem;
			font-family: 'EB Garamond', serif;
			font-weight: Regular;
			text-align: center;
			margin-bottom: 2rem;
			@media (--mobile) {
				margin-bottom: 4rem;
			}
		}
		&__text{
			font-size: 1.5rem;
			line-height: 3.2rem;
		}
	}

	&-important{
		// width: 98rem;

		&-ttl{
			width: 107rem;
			margin: 0 auto;

			strong{
				font-size: 2rem !important;
				@media (--mobile) {

				}
			}
		}

		.c-centerTtl{
			@media (--mobile) {
				margin-bottom: 6rem;
			}
		}
		&__inner{
			margin-top: 6rem;
			@media (--mobile) {
				margin-top: 3rem;
			}
		}
		&__title{
			span{
				font-size: 1.4rem;
				font-family: 'EB Garamond', serif;
				font-weight: Regular;
				margin-bottom: 1.4rem;
				display: block;
				@media (--mobile) {
					margin-bottom: 1.2rem;
				}
			}
			font-size: 2.6rem;
			text-align: center;
			margin-bottom: 5rem;
		}

		&__inner{
			ul{
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				@media (--mobile) {
					display: block;
				}
				li{
					width: 48rem;
					margin-right: 10rem;
					margin-bottom: 7rem;
					@media (--mobile) {
						width: 100%;
						margin-bottom: 6rem;
					}
					&:nth-child(2n){
						margin-right: 0rem;
					}
					&:nth-child(5n){
						margin-bottom: 0rem;
						@media (--mobile) {
							margin-bottom: 6rem;
						}
					}
					&:nth-child(6n){
						margin-bottom: 0rem;
					}
				}
			}
		}
		&__subtitle{
			padding-bottom: 2rem;
			margin-bottom: 2rem;
			border-bottom: 1px solid #D0D0D0;
			font-size: 2rem;
			@media (--mobile) {
				padding-bottom: 2rem;
				margin-bottom: 2rem;
				letter-spacing: .05em;
				display: flex;
				align-content: stretch;
				align-items: flex-start;
			}
			span{
				font-size: 1.4rem;
				font-family: 'EB Garamond', serif;
				font-weight: Regular;
				margin-right: 1rem;
				@media (--mobile) {
					padding-top: 0.5rem;
				}
			}
		}
		&__text{
			font-size: 1.5rem;
			line-height: 3.2rem;
		}
	}
	.mt--9{
		@media (--mobile) {
			margin-top: -9rem !important;
		}
	}
	.h-500{
		height: 20rem !important;
	}
}