.c-newsList {
  @media (--mobile) {
    margin-top: 2rem;
  }
  li {
    position: relative;
    &::after, &::before {
      position: absolute;
      content: "";
      left: 0;
      top: 0;
      width: 0;
      height: 100%;
      pointer-events: none;
      border-top: 1px solid #D0D0D0;
      border-bottom: 1px solid #D0D0D0;
      transition: 1s;
    }
    &::after {
      width: 100%;
    }
    &::before {
      border-color: #5b5b5b;
      z-index: 1;
    }
    &:hover {
      &::before {
        width: 100%;
      }
    }
    a {
      position: relative;
      display: flex;
      align-items: center;
      min-height: 13.6rem;
      margin-top: -1px;
      padding: 2rem 0;
      @media (--large) {
        div {
          display: flex;
          align-items: center;
          width: 100%;
          p {
            padding-right: 4rem;
            width: 24.6rem;
            display: flex;
            align-items: center;
          }
        }
      }
      @media (--mobile) {
        min-height: 11rem;
        div {
          width: calc(100vw - 6rem);
        }
        p {
          position: relative;
          display: flex;
          align-items: center;
          &::after {
            content: "";
            position: absolute;
            right: 2.5rem;
            top: 50%;
            transform: translateY(-50%) rotate(45deg);
            height: 1.5rem;
            width: 1.5rem;
            border-top: 1px solid #707070;
            border-right: 1px solid #707070;
            transition: .3s border, .3s right;
          }
        }
      }
      @media (--large) {
        &::after {
          content: "";
          position: absolute;
          right: 3rem;
          top: 50%;
          transform: translateY(-50%) rotate(45deg);
          height: 1.5rem;
          width: 1.5rem;
          border-top: 1px solid #707070;
          border-right: 1px solid #707070;
          transition: .5s border, .5s right;
        }
      }
      @media (--large) {
        &:hover {
          &::after {
            content: "";
            border-top: 1px solid #171717;
            border-right: 1px solid #171717;
            right: 2rem;
          }
        }
      }
      .year {
        writing-mode: tb;
        font-size: 1.4rem;
        margin-right: 1rem;
        @media (--mobile) {
          line-height: 1;
          font-size: 1.2rem;
        }
      }
      .date {
        font-size: 3.6rem;
        margin-right: 3rem;
        @media (--mobile) {
          margin-right: 2rem;
          font-size: 3rem;
        }
      }
      .cat {
        font-size: 1.3rem; 
        width: 8rem;
        padding-right: 1rem;
        em {
          border-bottom: .1rem solid #C9C9C9;
        }
      }
      .ttl {
        font-size: 1.5rem;
        width: calc(100% - 24.6rem);
        padding-right: 7rem;
        font-weight: lighter;
        letter-spacing: .2em;
        @media (--mobile) {
          width: auto;
          line-height: 1.7;
          margin-top: 1rem;
          padding-right: 0;
          font-size: 1.4rem;
        }
      }
    }
  }
}