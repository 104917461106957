.p-quality{
	&-about{
		border-bottom: 1px solid #D0D0D0;
		margin-bottom: 10rem;
		padding-bottom: 12rem;
		@media (--mobile) {
			margin-bottom: 5rem;
			padding-bottom: 0;
		}

		&__title{
			font-size: 3rem;
			line-height: 4.7rem;
			text-align: center;
			margin-bottom: 12rem;
			@media (--mobile) {
				font-size: 2.2rem;
				margin-bottom: 4rem;
				text-align: left;
				line-height: 2;
			}
		}
		&__content{
			margin-bottom: 12rem;
			@media (--mobile) {
				margin-bottom: 7rem;
			}
			.c-sideImg div{
				margin: 0 auto;
			}
			.c-rightTtl strong,
			.c-leftTtl strong{
				font-size: 2.4rem;
			}
			p{
				width: 40rem;
				@media (--mobile) {
					width: 100%;
				}
			}
		}
		&__txt{
			width: 44rem;
			@media (--mobile) {
				width: 100%;
			}
			p{
				font-size: 1.5rem;
				line-height: 3.2rem;
				margin-bottom: 3.2rem;
				@media (--mobile) {
					margin-bottom: 1.5rem;
				}
			}
		}
	}
	&-mind{
		border-bottom: 1px solid #D0D0D0;
		margin-bottom: 10rem;
		padding-bottom: 16rem;
		@media (--large) {
			padding-left: 5rem;
			padding-right: 5rem;
		}
		@media (--mobile) {
			margin-bottom: 5rem;
			padding-bottom: 7rem;
			// padding-bottom: 0;
		}
	}
	// .p-quality-about__content:nth-child(even){
	// 	flex-direction: row-reverse;
	// }
	// .p-quality-about__content:last-of-type{
	// 	margin-bottom: 14rem;
	// 	@media (--mobile) {
	// 		margin-bottom: 6rem;
	// 	}
	// }
	&-list{
		margin-top: 8rem;
		@media (--mobile) {
			margin-top: 4rem;
			// padding-bottom: 0rem;
			// margin-bottom: 6rem;
		}
		&__content{
			display: flex;
			align-items: center;
			justify-content: center;
			margin-bottom: 12rem;
			@media (--mobile) {
				width: 100%;
				display: block;
				margin-bottom: 10rem;
			}
			&:nth-child(even){
				flex-direction: row-reverse;
				.p-quality-list__img{
					margin-right: 0;
					margin-left: 14rem;
					@media (--mobile) {
						margin-right: 0;
						margin-left: 0;
					}
				}
			}
		}
		&__img{
			width: calc(100% - 58rem - 10rem - 14rem);
			margin-right: 14rem;
			@media (--mobile) {
				width: 100%;
				margin-right: 0;
				margin-bottom: 8rem;
			}
		}
		&__txt{
			width: 58rem;
			@media (--mobile) {
				width: 100%;
			}
		}
		&__title{
			font-size: 2rem;
			border-bottom: 1px solid #D0D0D0;
			padding-bottom: 2rem;
			margin-bottom: 3rem;
			@media (--mobile) {
				font-size: 2rem;
				margin-bottom: 4rem;
				padding-left: 1em;
				text-indent: -1em;
			}
			.num{
				font-size: 1.4rem;
				font-family: 'EB Garamond', serif;
				margin-right: 1rem;
			}
		}
		&__text{
			font-size: 1.5rem;
			line-height: 3.2rem;
			margin-bottom: 8rem;
			@media (--mobile) {
				margin-bottom: 6rem;
			}
		}
		&-reviews{
			width: 48rem;
			margin-left: auto;
			@media (--mobile) {
				width: 100%;
			}
			&__title{
				display: flex;
				align-items: center;
				font-size: 2rem;
				font-family: 'EB Garamond', serif;
				margin-bottom: 3rem;
				@media (--mobile) {
					display: block;
				}
				&:after {
					content: "";
					height: 1px;
					flex-grow: 1;
					background-color: #D0D0D0;
					margin-left: 1rem;
					@media (--mobile) {
						content: none;
					}
				}
				span{
					font-size: 1.5rem;
					margin-left: 1rem;
					@media (--mobile) {
						display: block;
						margin-left: 0;
					}
				}
			}
			&__text{
				font-size: 1.5rem;
				line-height: 3.5rem;

				.link{
					font-size: 1.5rem;
					display: flex;
					justify-content: flex-end;
					margin-top: 2rem;
					a{
						display: flex;
						align-items: center;

						&:hover {
							svg {
								circle:first-of-type {
									fill: rgba(23,23,23,1);
								}
								path {
									stroke: #FFF;
								}
							}
						}
					}
					svg{
						width: 3rem;
						height: 3rem;
						margin-left: 1rem;
						circle:first-of-type {
							fill: rgba(255,255,255,0);
							transition: .5s fill;
						}
						path{
							stroke: #171717;
						}
						&, * {
							transition: .5s;
						}
					}
				}
			}
		}
}

		&-left{
			display: flex;
			margin-top: 12rem;
			margin-bottom: 12rem;
			align-items: center;
			justify-content: space-between;

			&.last{
				margin-bottom: 6rem;
				@media (--mobile) {
					margin-bottom: 3rem;
				}
			}
			@media (--mobile) {
				display: block;
				margin-top: 4rem;
				margin-bottom: 6rem;
			}
			&__img{
				width: 56rem;
				@media (--mobile) {
					width: 100%;
					margin-bottom: 2rem;
				}
			}
			&__content{
				width: 48rem;
				@media (--mobile) {
					width: 100%;
				}
			}
			&__title{
				font-size: 2.4rem;
				margin-bottom: 5rem;
				@media (--mobile) {
					font-size: 2rem;
					margin-bottom: 2rem;
				}
			}
			&__text{
				p{
					font-size: 1.5rem;
					line-height: 3.2rem;
					margin-bottom: 4rem;
					@media (--mobile) {
						margin-bottom: 2rem;
					}
					&:last-of-type{
						margin-bottom: 0;
					}
				}
			}
		}
		.p-quality-left:nth-child(even){
			flex-direction: row-reverse;
		}


	&-ttl{
		font-size: 1.3rem;
		text-align: center;
		margin-bottom: 1.5rem;
	}
	&-btn{
		width: 40%;
    height: 10rem;
		margin: 0 auto;
		@media (--mobile) {
			width: 100%;
			height: 8rem;
		}
		a{
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: #FFF;
			border: 0.2rem solid #171717;
			color: #171717;
			transition: .5s background, .5s color;
			font-size: 2rem;
			@media (--mobile) {
				font-size: 1.5rem;
			}

			&:hover{
				background: #171717;
				color: #fff;
				svg {
					circle:first-of-type {
						fill: rgba(23,23,23,1);
					}
					path {
						stroke: #FFF;
					}
				}
			}
		}
		svg{
			width: 3rem;
			height: 3rem;
			margin-left: 1rem;
			circle:first-of-type {
				fill: rgba(255,255,255,0);
				transition: .5s fill;
			}
			path{
				stroke: #171717;
			}
			&, * {
				transition: .5s;
			}
		}
	}
}