.p-thanks {
  .s-text {
    display: flex;
    justify-content: center;
    margin-top: 16rem;
    font-size: 1.8rem;
    line-height: 2;
    @media (--mobile) {
      margin-top: 5rem;
      padding: 0 2rem;
    }
  }

  .s-btn {
    display: flex;
    justify-content: center;
    margin-top: 6rem;
    a {
      font-size: 1.6rem;
      border: 1px solid #171717;
      padding: .9rem 3rem;
      transition: .3s;
      &:hover {
        background: #171717;
        color: #fff;
      }
    }
  }
}