.p-beauty.keyword .c-title{
	border-bottom: 1px solid #D0D0D0;
	padding-bottom: 7rem;
	@media (--mobile) {
		padding-bottom: 4rem;
	}
}
.p-beauty{
	overflow-x: hidden;
	.keyword{
		width: 118rem;
		margin: 10rem auto 0 auto;
		@media (--mobile) {
			width: auto;
	    padding: 0 2rem;
			margin-top: 4rem;
		}
	}
	&-content{
		width: 100rem;
		margin-left: 10rem;
		@media (--mobile) {
			width: 100%;
			margin-left: 0;
		}

		&__text{
			margin-top: 4rem;
			margin-bottom: 8rem;
			font-size: 1.5rem;
			line-height: 3.2rem;
			@media (--mobile) {
				margin-bottom: 4rem;
			}

		}
		&__keyword{
			font-size: 1.5rem;
			margin-bottom: 12rem;
			@media (--mobile) {
				margin-bottom: 8rem;
			}


			h3{
				margin-bottom: 3rem;
				@media (--mobile) {
					margin-bottom: 1.5rem;
				}

			}
			ul{
				display: flex;
				flex-wrap: wrap;
				margin-top: -1rem;
				@media (--mobile) {
					justify-content: flex-start;
					flex-wrap: wrap;
					margin-left: -2rem;
					margin-right: -2rem;
					padding: 0 2rem;
				}
				li{
					font-size: 1.4rem;
					color: #7B7B7B;
					margin-right: 1.4rem;
					white-space: nowrap;
					margin-top: 1rem;
					&:last-child{
						margin-right: 0;
					}
					a{
						display: block;
						position: relative;
						&::before{
							content: "";
							margin: auto;
							position: absolute;
							left: 0;
							bottom: -0.2rem;
							transition: transform .3s, -webkit-transform .3s;
							-webkit-transform: scale(0, 1);
							transform: scale(0, 1);
							-webkit-transform-origin: right center;
							transform-origin: right center;
							width: 100%;
							height: 0.1rem;
							background: #171717;
						}
					}
					a:hover{
						&::before{
							transform: scale(1, 1);
							transform-origin: left center;
						}
					}
				}
			}
		}
	}
	ul.p-beauty-cell{
		display: flex;
		justify-content: space-between;
    flex-wrap: wrap;
		margin: 0 auto;
		@media (--large) {
			width: 118rem;
			margin-top: 7rem;
		}
		@media (--mobile) {
			margin-right: calc(50% - 50vw);
			margin-left: calc(50% - 50vw);
			margin-top: 5rem;
		}
		li.p-beauty-list{
			width: 53.6rem;
			@media (--mobile) {
				width: 100%;
				margin-bottom: 4rem;
			}

		}
	}

	&-list{
		position: relative;
		margin-bottom: 8rem;
		&__img{
			display: block;
			position: relative;
			margin-bottom: 2.4rem;
			width: 53.6rem;
			height: 36rem;
			overflow:hidden;
			@media (--mobile) {
				width: 100%;
				// width: 30.5rem;
				height: 20rem;
			}
			img {
				height: 100%;
				width: 100%;
				object-fit: cover;
				transition:1s all;
				&:hover{
					transform:scale(1.05);
					transition:1s all;
				}
			}
		}
		&__txt {
			position: absolute;
			right: 3rem;
			top: 31rem;
			z-index: 9;
			font-size: 1.3rem;
			color: #171717;
			color: #FFF;
			@media (--mobile) {
				right: 2rem;
				top: 16rem;
			}
			// position: absolute;
			// right: 0;
			// top: 0;
			// margin-right: -20px;
			// -ms-writing-mode: tb-rl;
			// writing-mode: vertical-rl;
			// color: #898989;
			// font-family: 'EB Garamond', serif;
			// font-weight: Regular;
			// font-size: 1rem;
		}
		&__text{
			@media (--mobile) {
				padding: 0 3rem;
			}
		}
		&__title{
			display: block;
			font-size: 1.8rem;
			margin-bottom: 1.7rem;
			transition: .3s opacity;
			&:hover{
				opacity: 0.6;
			}
		}
		ul{
			display: flex;
			flex-wrap: wrap;
			margin-top: -1rem;
			li{
				margin-right: 0.8rem;
				font-size: 1.2rem;
				color: #707070;
				border: 1px solid #CECECE;
				margin-top: 1rem;
				a{
					display: block;
					padding: 6px 8px;
					color: #7B7B7B;
					transition: .3s all;
					@media (--mobile) {
						padding: 0.3rem 0.4rem;
					}
					&:hover{
						background: #7B7B7B;
						color: #FFFFFF;
					}
				}
			}
		}
	}

	&-voice{
		width: 118rem;
		margin: 12rem auto 0 auto;
		border-top: 1px solid #D0D0D0;
		padding-top: 12rem;
		display: flex;
		justify-content: space-between;
		@media (--mobile) {
			width: 100%;
			margin-top: 6rem;
			padding-top: 6rem;
			display: block;
		}


		&__title{
			font-size: 3.2rem;
			font-family: 'EB Garamond', serif;
			font-weight: Regular;
			@media (--mobile) {
				font-size: 2.5rem;
				text-align: center;
				margin-bottom: 2rem;
			}

			.jp{
				font-size: 1.5rem;
				margin-bottom: 1.4rem;
				display: block;
				@media (--mobile) {
					font-size: 1.2rem;
					margin-bottom: 1rem;
				}

			}
		}
		&__content{
			display: flex;
	    flex-wrap: wrap;
  	  justify-content: flex-end;
		}
		&__cell{
			width: 78rem;
			display: flex;
			border-bottom: 1px solid #D0D0D0;
			padding-bottom: 4rem;
			margin-bottom: 4rem;
			padding: 0 2rem 4rem 2rem;
			@media (--mobile) {
				width: 100%;
				padding: 0 1rem 4rem 1rem;
				display: block;
			}

		}
		&__cell:nth-child(even){
			flex-direction: row-reverse;
			.p-beauty-voice__text{
				margin-right: 0rem;
				margin-left: 3rem;
				@media (--mobile) {
					margin-left: 0;
				}

			}
		}
		&__text{
			width: 55rem;
			margin-right: 3rem;
			@media (--mobile) {
				width: 100%;
				margin-right: 0;
				margin-bottom: 3rem;
			}

		}
		&__heading{
			font-size: 2rem;
			margin-bottom: 1.4rem;
			font-weight: bold;
			@media (--mobile) {
				font-size: 1.8rem;
				white-space: nowrap;
			}
		}
		&__txt{
			font-size: 1.5rem;
			line-height: 3.2rem;
			margin-bottom: 1.3rem;
			@media (--mobile) {
				margin-bottom: 3rem;
				font-size: 1.3rem;
			}
		}
		&__name{
			font-size: 1.5rem;
			@media (--mobile) {
				display: flex;
				flex-direction: column;
			}
			span.city{
				font-size: 1.3rem;
				color: #7B7B7B;
				position: relative;
				margin-left: 2rem;
				@media (--mobile) {
					margin-left: 0;
					font-size: 1.1rem;
				}
				&::before{
					position: absolute;
					display: block;
					content: "";
					width: 1px;
					height: 1.3rem;
					background-color: #7B7B7B;
					left: -1rem;
					top: 50%;
					transform: translateY(-50%);
					@media (--mobile) {
						content: none;
					}
				}
			}
		}
		&__more{
			display: flex;
			justify-content: flex-end;
			font-size: 1.5rem;
			margin-top: -2.2rem;
			@media (--mobile) {
				margin-top: 4rem !important;
			}
			a, span {
				position: relative;
				display: flex;
				align-items: center;
				font-size: 1.5rem;

				svg{
					width: 2.4rem;
					margin-left: 1.5rem;
					transition: .3s all;

					@media (--mobile) {
						margin-left: 1.6rem;
					}
					circle:first-of-type {
						fill: #fff;
					}
					&, * {
						transition: .3s;
					}
				}
				&:hover {
					svg {
						circle:first-of-type {
							fill: #171717;
						}
						path {
							stroke: #fff;
						}
					}
				}
			}
			@media (--mobile) {
				margin-top: 1rem;
			}

			img{
				margin-left: 1.5rem;
			}
		}
		&__img{
			width: calc(100% - 55rem - 3rem);
			@media (--mobile) {
				width: 100%;
				text-align: center;
			}

		}
	}
	&-keyword{
		width: 118rem;
		margin: 12rem auto 0 auto;
		padding-top: 8rem;
		border-top: 1px solid #D0D0D0;
		@media (--mobile) {
			width: 100%;
			margin-top: 6rem;
			padding-top: 4rem;
		}
		&__title{
			text-align: center;
			font-size: 1.5rem;
			margin-bottom: 3rem;
		}

		&__list{
			ul{
				display: flex;
				justify-content: center;
				li{
					font-size: 1.4rem;
					color: #7B7B7B;
					margin-right: 2.4rem;

					&:last-child{
						margin-right: 0;
					}
				}
			}
		}
	}
}
.p-beauty.keyword .p-beauty-voice{
	margin-top: 8rem;
	@media (--mobile) {
		margin-top: 4rem;
	}
}