.c-breadcrumb {
  width: 118rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 4rem;
  margin-top: 15rem;
  @media (--mobile) {
    width: auto;
    padding: 0 2rem;
    margin-bottom: 2rem;
    margin-top: 8rem;
  }
  ul {
    display: flex;
    justify-content: flex-end;
    li {
      &, a {
        font-size: 1.2rem;
        color: #898989;
      }
      &:nth-of-type(n + 2) {
        position: relative;
        margin-left: 1rem;
        padding-left: 1rem;
        &::after {
          position: absolute;
          content: "/";
          left: 0;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
      a {
        transition: .3s;
        display: inline-block;
        padding: 0;
        margin-top: -3px;
        vertical-align: middle;
        max-width: 13em;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        &:hover {
          color: #000;
        }
      }
      span{
        display: inline-block;
        padding: 0;
        margin-top: -3px;
        vertical-align: middle;
        max-width: 8em;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}