.split_txt {
  em {
    display: inline-block;
    transform: translateX(20px);
    opacity: 0;
    transition: .8s opacity, .8s transform;
  }
  &.is-inview, &.is-mv {
    em {
      opacity: 1;
      transform: translateX(0);
    }
  }
  &.is-mv {
    .p-mv__catch--top {
      em {
        @for $i from 1 through 15 {
          &:nth-of-type(#{$i}) {
            transition-delay: #{0.1 * $i}s !important;
          }
        }
      }
    }
    .p-mv__catch--middle {
      em {
        @for $i from 1 through 15 {
          &:nth-of-type(#{$i}) {
            transition-delay: #{0.6 + (0.2 * $i)}s !important;
          }
        }
      }
      small {
        em {
          &:first-of-type {
            transition-delay: 1.3s !important;
          }
        }
      }
    }
    .p-mv__catch--bottom {
      em {
        @for $i from 1 through 15 {
          &:nth-of-type(#{$i}) {
            transition-delay: #{1.6 + (0.03 * $i)}s !important;
          }
        }
      }
    }
  }
}
