.f-noto {
  font-family: 'Noto Sans JP', sans-serif;
}

.f-oswald {
  font-family: 'Oswald', sans-serif;
}

// .f-tsukushi-d {
//   font-family: TsukuGoPr5-D;
// }

// .f-tsukushi-m {
//   font-family: TsukuGoPr5-M;
// }

// .f-tsukushi-r {
//   font-family: TsukuGoPr5-R;
// }

// .f-tsukushi-l {
//   font-family: TsukuGoPr5-L;
// }

// .f-tsukushi-b {
//   font-family: TsukuGoPro-B;
// }