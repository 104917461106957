@keyframes scroll {
  0% {
    bottom: -.5rem;
  }
  50% {
    bottom: .5rem;
  }
  100% {
    bottom: -.5rem;
  }
}
