.p-link{
	&__content{
		width: 98rem;
		margin: 0 auto 7rem auto;
		@media (--mobile) {
			width: 100%;
			margin-bottom: 5rem;
		}
		h3{
			font-size: 2rem;
			margin-bottom: 2.5rem;
			padding-bottom: 3rem;
			border-bottom: 1px solid #D0D0D0;
			@media (--mobile) {
				margin-bottom: 2rem;
				padding-bottom: 1.5rem;
			}
		}
		ul{
			li{
				font-size: 1.5rem;
				margin-bottom: 2rem;
				position: relative;
				padding-left: 1.5rem;

				&::after{
					content:'';
					display: inline-block;
					position: absolute;
					left: 0rem;
					top: 0.8rem;
					width: 0.7rem;
					height: 0.7rem;
					border-bottom: 1px solid #000000;
					border-right: 1px solid #000000;
					transform: rotateZ(-45deg);
				}
			}
		}
	}
}