.p-company{
	.c-mv {
		position: relative;
		overflow: hidden;
		height: 38vw;
		@media (--mobile) {
			height: 56vw;
		}
		video {
			width: 100%;
		}
		.js-sound {
			position: absolute;
			bottom: 3rem;
			right: 3rem;
			height: 7rem;
			width: 7rem;
			// background: #000;
			border-radius: 999rem;
			background: rgba(255, 255, 255, .4);
			@media (--mobile) {
				bottom: 2rem;
				right: 2rem;
				height: 5rem;
				width: 5rem;
			}
			svg {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%) translateX(-0.3rem);
				opacity: 0;
				width: 5rem;
				* {
					fill: #000;
				}
				&.sound_on {
					opacity: 1;
				}
				&.sound_off {
					opacity: 0;
				}
				@media (--mobile) {
					width: 3rem;
					transform: translate(-50%, -50%) translateX(0rem);
				}
			}
			&.is-active {
				.sound_on {
					opacity: 0;
				}
				.sound_off {
					opacity: 1;
				}
			}
		}
		&.company-mv{
			height: auto !important;
		}
	}

	&-message{
		display: flex;
		margin-left: 10rem;
		margin-bottom: 10rem;
		@media (--mobile) {
			display: block;
			margin-left: 0;
		}
		&__content{
			margin-right: 16rem;
			@media (--mobile) {
				margin-right: 0;
				margin-bottom: 2rem;
			}
		}
		&__title{
			span{
				font-size: 1.4rem;
				font-family: 'EB Garamond', serif;
				font-weight: Regular;
				display: block;
				margin-bottom: 1.4rem;
			}
			font-size: 2.6rem;
			margin-bottom: 2rem;
			@media (--mobile) {
				text-align: center;
			}
		}
		&__text{
			width: 48rem;
			margin-top: 4rem;
			@media (--mobile) {
				width: 100%;
				margin-top: 2rem;
			}
			p{
				font-size: 1.5rem;
				line-height: 3.2rem;
				margin-bottom: 3rem;
			}
		}
		&__name{
			font-size: 1.5rem;
		}
	}
	&-overview{
		margin-bottom: 12rem;
		@media (--mobile) {
			margin-bottom: 0rem;
		}
		&__title{
			span{
				font-size: 1.4rem;
				font-family: 'EB Garamond', serif;
				font-weight: Regular;
				display: block;
				margin-bottom: 1.4rem;
			}
			font-size: 2.6rem;
			text-align: center;
			margin-bottom: 7rem;
		}
		&__content{
			width: 98rem;
			margin: 7rem auto 0 auto;
			@media (--mobile) {
				width: 100%;
				margin-top: 3rem;
			}
			dl{
				display: flex;
				flex-wrap: wrap;
				@media (--mobile) {
					display: block;
				}
			}
			dt{
				width: 20%;
				font-size: 1.5rem;
				font-weight: bold;
				padding: 4rem 0;
				border-bottom: 1px solid #D0D0D0;
				display: flex;
				align-items: flex-start;
				padding-left: 3rem;
				padding-top: 4.6rem;
				// margin-right: 10rem;
				&:first-of-type{
					border-top: 1px solid #D0D0D0;
					@media (--mobile) {
						// border: none;
						padding-top: 2rem;
					}
				}
				@media (--mobile) {
					width: 100%;
					display: block;
					padding: 0;
					border: none;
					padding-top: 2rem;
				}
			}
			dd{
				width: 80%;
				font-size: 1.5rem;
				padding: 4rem 0;
				border-bottom: 1px solid #D0D0D0;
				line-height: 3.2rem;
				&:first-of-type{
					border-top: 1px solid #D0D0D0;
					@media (--mobile) {
						border: none;
					}
				}
				@media (--mobile) {
					border-bottom: 1px solid #D0D0D0 !important;
					width: 100%;
					border: none;
					padding: 0.5rem 0rem 2rem 0;
					line-height: 1.5;
				}

				.content-list{
					padding-left: 2rem;
					display: flex;
					flex-direction: column;
					gap: 0rem 0.5rem;
					@media (--mobile) {
						width: 100%;
						margin-top: 0.5rem;
					}
					span{
						display: block;
						font-size: 1.4rem;
						line-height: 1.8;
						@media (--mobile) {
							&:last-child{
								width: auto !important;
							}
						}
					}
				}
			}
		}
	}
	&-other{
		display: flex;
		@media (--mobile) {
			margin-bottom: 3rem;
		}
	}
	&-staff{
		width: 56rem;
		height: 31.5rem;
		img{
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
		@media (--mobile) {
			width: 100%;
			height: 21.5rem;
		}
	}
	&-youtube{
		width: 56rem;
		margin: 0 auto 12rem auto;
		@media (--mobile) {
			display: none;
		}
	}
	&-map {
		margin-right: calc(50% - 50vw);
		margin-left: calc(50% - 50vw);
		&__content{
			overflow: hidden;
			margin-bottom: 4rem;
			// height: 0;
			// padding-bottom: 56.25%;
			// position: relative;
			iframe {
				// position: absolute;
				// left: 0;
				// top: 0;
				height: 45rem;
				width: 100%;
				@media (--mobile) {
					height: 25rem;
				}
			}
		}
		&__text{
			font-size: 1.5rem;
			line-height: 3.2rem;
			width: 98rem;
			margin: 0 auto;
			@media (--mobile) {
				width: 100%;
				padding: 0 3rem;
			}
		}
	}
}