@import url('https://fonts.googleapis.com/css2?family=Shippori+Mincho+B1:wght@400;500;600&display=swap');

.specialFeature{
  // line-height: 1.7;
  // letter-spacing: 0.4em;
  font-family: 'Shippori Mincho B1', serif;
  font-size: 1.6rem;
  @media (--mobile) {
    font-size: 1.4rem;
  }
  p{
    font-weight: 400;
    letter-spacing: 0.1em;
  }

  .parts-ttl{
    font-size: 2rem;
    padding: 1rem 2rem;
    border: 1px solid #000;
    margin-bottom: 5rem;
    display: block;
    text-align: center;
  }

  .ttl01{
    text-align: center;
    font-size: 3.6rem;
    font-weight: 400;

    @media (--mobile) {
      font-size: 2.4rem;
    }

    small{
      font-size: 1.4rem;
      display: block;
      font-family: 'EB Garamond', serif;
      font-weight: 400;
      @media (--mobile) {
        font-size: 1.2rem;
      }
    }
  }

  .ttl02{
    font-size: 3rem;
    font-weight: 400;
    @media (--mobile) {
      font-size: 2rem;
    }
    small{
      margin-bottom: 2rem;
      font-size: 1.4rem;
      display: block;
      font-family: 'EB Garamond', serif;
      font-weight: 400;
      @media (--mobile) {
        font-size: 1.2rem;
      }
    }
  }

  .ttl03{
    font-size: 2.6rem;
    font-weight: 400;
    @media (--mobile) {
      font-size: 2rem;
    }
  }

  .ttl04{
    font-size: 2.6rem;
    font-weight: 400;
    text-align: center;
    @media (--mobile) {
      font-size: 2rem;
    }
    small{
      margin-bottom: 1.2rem;
      font-size: 1.4rem;
      display: block;
      font-family: 'EB Garamond', serif;
      font-weight: 400;
    }
  }

  .ttl05{
    font-size: 2.6rem;
    font-weight: 400;
    @media (--mobile) {
      font-size: 2rem;
    }
    small{
      margin-bottom: 1.2rem;
      font-size: 1.2rem;
      display: block;
      font-family: 'EB Garamond', serif;
      font-weight: 400;
    }
  }

  .ttl06 {
    text-align: center;
    font-size: 3.8rem;
    font-weight: 400;
    @media (--mobile) {
      font-size: 2.5rem;
    }
    small{
      margin-bottom: 1.2rem;
      font-size: 2rem;
      display: block;
      font-weight: 400;
      @media (--mobile) {
        font-size: 1.4rem;
        margin-bottom: 0;
      }
    }
  }

  .ttl07{
    font-size: 2rem;
  }

  .ttl08{
    text-align: center;
    font-size: 2.6rem;
    font-weight: 400;
    margin-bottom: 9rem;
    @media (--mobile) {
      margin-bottom: 5rem;
    }
    span{
      font-size: 1.4rem ;
      font-family: 'EB Garamond', serif;
      display: block;
    }
    small{
      font-size: 1.8rem;
      display: block;
      margin-top: 2rem;
      margin-bottom: 1rem;
      @media (--mobile) {
        margin-bottom: 0;
        margin-top: 1rem;
      }
    }
  }
  /* ==========================================================================
    ボタンパーツ
  ========================================================================== */
  .btn{
    font-size: 1.8rem;
    font-weight: 400;
    border: 2px solid #000 !important;
    padding: 1.3rem 0;
    text-align: center;
    display: block;
    color: #000 !important;
    transition: 0.3s;

    @media (--large) {
      &:hover{
        background-color: #000;
        color: #fff !important;
      }
    }

    @media (--mobile) {
      width: 100% !important;
    }

    &.center{
      margin-left: auto;
      margin-right: auto;
    }
  }

  /* ==========================================================================
    画像スタイル調整
  ========================================================================== */

  .img{
    width: 100%;
    position: relative;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &-side{
      position: absolute;
      top: 0;
      right: -3rem;
      writing-mode:vertical-rl;
      color: #898989;
      font-family: 'EB Garamond', serif;
      @media (--mobile) {
        right: -2rem;
      }
    }
  }


  /* ==========================================================================
    インナーパーツ
  ========================================================================== */
  .inner01{
    width: 98rem;
    margin: 0 auto;
    @media (--mobile) {
      width: auto;
      padding: 0 3rem;
    }
  }
  .inner02{
    width: 118rem;
    margin: 0 auto;
    @media (--mobile) {
      width: auto;
      padding: 0 3rem;
    }

    &.border{
      position: relative;
      &::before{
        position: absolute;
        content: "";
        width: 118rem;
        height: 0.1rem;
        background-color: #D0D0D0;
        transform: translateX(-10rem);
        left: 0;
        top: 0;
        @media (--mobile) {
          width: 100%;
          transform: translateX(0);
        }
      }
    }
  }

  .inner-flex__inner{
    &:nth-child(n + 2){
      @media (--mobile) {
        margin-top: 6rem;
      }
    }

  }

  .inner-flex{
    display: flex;
    justify-content: space-between;

    &.wrap{
      flex-wrap: wrap;
    }
    @media (--mobile) {
      flex-direction: column;
      justify-content: center;
    }


    &.border{
      position: relative;
      &::before,
      &::after{
        position: absolute;
        content: "";
        width: 118rem;
        height: 0.1rem;
        background-color: #D0D0D0;
        transform: translateX(-10rem);
        left: 0;
        @media (--mobile) {
          width: 120%;
          transform: translateX(-3.1rem);
        }
      }
      &::before{
        top: 0;

      }
      &:after{
        bottom: 0;
      }
    }

    &__item{
      width: 54rem;
      position: relative;

      @media (--large) {
        &:hover{
          img{
            transform: scale(1.1);
          }
        }
      }


      @media (--mobile) {
        width: 100%;

        &:nth-child(n + 2){
          margin-top: 4rem !important;
        }
      }


      &:nth-child(n + 3){
        margin-top: 6rem;
        @media (--mobile) {
          margin-top: 0;
        }
      }

      .img{
        height: 36rem;
        overflow: hidden;
        @media (--mobile) {
          height: auto;
        }
        img{
          transition: 0.3s;
        }
      }

      .name{
        font-size: 1.8rem;
        margin-top: 2.6rem;
        margin-bottom: 1.5rem;
      }

      .tag{
        display: flex;
        flex-wrap: wrap;
        a{
          font-size: 1.2rem;
          color: #707070;
          padding: 0.4rem 0.8rem;
          border: 1px solid #707070;
          display: block;
          margin-right: 0.8rem;
          margin-bottom: 1rem;
          position: relative;
          z-index: 10;
          transition: 0.3s;
          &:hover{
            color: #fff;
            background-color: #7B7B7B;
          }
        }
      }
      .link{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }

    &.mt{
      margin-top: 8rem;
      @media (--mobile) {
        margin-top: 4rem !important;
      }
    }
  }

  /* ==========================================================================
    テキストエア
  ========================================================================== */

  .textArea01{
    margin-top: 5rem;
    margin-bottom: 5rem;
    @media (--mobile) {
      margin-top: 3rem;
      margin-bottom: 6rem;
    }
  }
  /* ==========================================================================
    リストパーツ
  ========================================================================== */
  .list01{
    li{
      font-size: 1.6rem;
      padding-bottom: 1.3rem;
      border-bottom: 1px solid #D0D0D0;
      &:nth-child(n + 2){
        margin-top: 2.7rem;
        font-size: 1.4rem;
      }
      span {
        margin-right: 2rem;
        font-family: 'EB Garamond', serif;
        @media (--mobile) {
          margin-right: 1rem;
        }
      }
    }
  }

  .list02{
    position: relative;
    li{
      display: flex;
      position: relative;
      &.free{
        &::before{
          letter-spacing: 0;
          width: 4rem;
          height: 4rem;
          border-radius: 50%;
          position: absolute;
          content: "無料";
          background-color: #171717;
          color: #FFF;
          font-size: 1.2rem;
          left: -50px;
          top: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          @media (--mobile) {
            top: 6.6rem;
            z-index: 4;
            left: -0.1rem;
            z-index: 4;
            // transform: translateY(6.5rem);
          }
        }
      }
    }

    &-step{
      margin-right: 4rem;
      font-size: 1.8rem;
      font-family: 'EB Garamond', serif;
      font-weight: Regular;
      position: relative;
      div{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) translateY(3rem);
        white-space: nowrap;
        font-size: 1.4rem;
        text-align: center;
        border: 1px solid #CECECE;
        padding: 0.8rem 1.5rem;
        background-color: #FFF;
        z-index: 2;
        color: #707070;

        &.first{
          transform: translate(-50%,-50%) translateY(-9rem);
          @media (--mobile) {
            transform: translate(-50%, -50%) translateY(-6rem);
          }
        }
        &.second {
          transform: translate(-50%, -50%) translateY(9rem);
        }
        @media (--mobile) {
          writing-mode: vertical-rl;
          transform: translate(-50%, -50%) translateY(3rem);
          padding: 0.8rem .5rem;
          font-size: 1.2rem;
        }
        span{
          font-weight: bold;
          &.num{
            font-weight: normal;
            @media (--mobile) {
              text-combine-upright: all;
            }
          }
        }
      }
      @media (--mobile) {
        // width: 38%;
        margin-right: 2rem;
      }
      p{
        background-color: #FFFFFF;
        z-index: 2;
        position: relative;
        padding: 1.7rem 0;
        @media (--mobile) {
          padding: 0;
          padding-bottom: 0.5rem;
          font-size: 1.2rem;
          text-align: center;
          margin-left: 0.3rem;
        }
      }
    }

    &-content{
      width: 70rem;
      padding-top: 1.7rem;
      padding-bottom: 5rem;
      &.flex{
        display: flex;
        @media (--mobile) {
          display: block;
        }
      }
      @media (--mobile) {
        width: 100%;
        padding-top: 0;
        padding-bottom: 5rem;
        &.pb-0{
          padding-bottom: 0;
        }
      }
    }

    &-ttl{
      font-size: 2rem;
      color: #171717;
      font-weight: bold;
      margin-bottom: 1.5rem;
      @media (--mobile) {
        margin-bottom: 2rem;
      }
      &.last{
        padding-top: 1.5rem;
      }
      span{
        display: inline-block;
        font-size: 1.2rem;
        font-weight: normal;
        background-color: #EAEAEA;
        // border: 0.1rem solid #171717;
        padding: 0.5rem;
        margin-top: 1rem;
        @media (--mobile) {
          font-size: 1.3rem;
        }
      }
    }

    &-more{
      font-size: 1.6rem;
      padding: 1rem;
      border: 0.1rem solid #D0D0D0;
      position: relative;
    }

    &-txt{
      font-size: 1.5rem;
      line-height: 3.2rem;
      @media (--mobile) {
        margin-top: 1rem;
        display: none;
      }
      @media (--large) {
        img{
          display: none;
        }
      }

      p{
        a{
          border-bottom: 1px solid #000;
        }
      }
    }

    &::after{
      content:"";
      display: inline-block;
      width: 1px;
      height: 100%;
      background-color: #D0D0D0;
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 1;
      transform: translate(-50%,-50%) translateX(-46rem);
      @media (--mobile) {
        transform: translate(-50%, -50%) translateX(-14rem);
      }
    }

    &.w-85{
      &::after{
        height: 85%;
      }
    }

  }

  .list03{
    margin-top: 11rem;
    @media (--mobile) {
      margin-top: 5rem;
    }
    li{
      &:nth-child(n + 2){
        margin-top: 8.3rem;
        @media (--mobile) {
          margin-top: 4rem;
        }
      }
      .list03-ttl{
        font-size: 2rem;
        display: flex;
        align-items: center;
        margin-bottom: 2.8rem;
        span{
          margin-right: 1rem;
          font-size: 1.8rem;
          font-family: 'EB Garamond', serif;
        }
        &.column_sp{
          @media (--mobile) {
            text-align: center;
            flex-direction: column;
          }
        }
      }
    }

  }

    /* ==========================================================================
    ボーダー
  ========================================================================== */

  .border{
    position: relative;
    &::before{
      position: absolute;
      content: "";
      width: 118rem;
      height: 0.1rem;
      background-color: #D0D0D0;
      transform: translateX(-10rem);
      left: 0;
      top: 0;
      @media (--mobile) {
        width: 100%;
        transform: translateX(0);
      }
    }
    &::after{
      position: absolute;
      content: "";
      width: 118rem;
      height: 0.1rem;
      background-color: #D0D0D0;
      transform: translateX(-10rem);
      left: 0;
      bottom: 0;
      @media (--mobile) {
        width: 100%;
        transform: translateX(0);
      }
    }
  }

  .border-top{
    position: relative;
    &::before{
      position: absolute;
      content: "";
      width: 118rem;
      height: 0.1rem;
      background-color: #D0D0D0;
      transform: translateX(-10rem);
      left: 0;
      top: 0;
      @media (--mobile) {
        width: 100%;
        transform: translateX(0);
      }
    }
  }




  /* ==========================================================================
    幅
  ========================================================================== */

  .u-w-all {
    width: 100% !important;
  }

  @for $value from 0 to 100 {
    .u-w-#{$value} {
      width: $value + rem !important;
      @media (--mobile) {
        width: 100% !important;
      }
    }
  }

  @media (--mobile) {
    .u-w-sp-all {
      width: 100% !important;
    }

    @for $value from 0 to 100 {
      .u-w-sp-#{$value} {
        width: $value + rem !important;
      }
    }
  }


  /* ==========================================================================
    余白パーツ
  ========================================================================== */
  .u-m-c {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .u-m-r {
    margin-right: auto !important;
    margin-left: 0 !important;
  }

  /* Top margin */
  @for $value from 0 to 26 {
    .u-mt-#{$value} {
      margin-top: $value + rem !important;
    }
  }

  /* Right margin */
  @for $value from 0 to 26 {
    .u-mr-#{$value} {
      margin-right: $value + rem !important;
    }
  }

  /* Bottom margin */
  @for $value from 0 to 26 {
    .u-mb-#{$value} {
      margin-bottom: $value + rem !important;
    }
  }

  /* Left margin */
  @for $value from 0 to 26 {
    .u-ml-#{$value} {
      margin-left: $value + rem !important;
    }
  }

  @media (--mobile) {
    .u-m-sp-c {
      margin-right: auto !important;
      margin-left: auto !important;
    }

    .u-m-sp-r {
      margin-right: auto !important;
      margin-left: 0 !important;
    }

    /* Top margin */
    @for $value from 0 to 26 {
      .u-mt-sp-#{$value} {
        margin-top: $value + rem !important;
      }
    }

    /* Right margin */
    @for $value from 0 to 26 {
      .u-mr-sp-#{$value} {
        margin-right: $value + rem !important;
      }
    }

    /* Bottom margin */
    @for $value from 0 to 26 {
      .u-mb-sp-#{$value} {
        margin-bottom: $value + rem !important;
      }
    }

    /* Left margin */
    @for $value from 0 to 26 {
      .u-ml-sp-#{$value} {
        margin-left: $value + rem !important;
      }
    }
  }

  /* ==========================================================================
    padding
  ========================================================================== */

  /* Top padding */
  @for $value from 0 to 26 {
    .u-pt-#{$value} {
      padding-top: $value + rem !important;
    }
  }

  /* Right padding */
  @for $value from 0 to 26 {
    .u-pr-#{$value} {
      padding-right: $value + rem !important;
    }
  }

  /* Bottom padding */
  @for $value from 0 to 26 {
    .u-pb-#{$value} {
      padding-bottom: $value + rem !important;
    }
  }

  /* Left padding */
  @for $value from 0 to 26 {
    .u-pl-#{$value} {
      padding-left: $value + rem !important;
    }
  }

  @media (--mobile) {
    /* Top padding */
    @for $value from 0 to 26 {
      .u-pt-sp-#{$value} {
        padding-top: $value + rem !important;
      }
    }

    /* Right padding */
    @for $value from 0 to 26 {
      .u-pr-sp-#{$value} {
        padding-right: $value + rem !important;
      }
    }

    /* Bottom padding */
    @for $value from 0 to 26 {
      .u-pb-sp-#{$value} {
        padding-bottom: $value + rem !important;
      }
    }

    /* Left padding */
    @for $value from 0 to 26 {
      .u-pl-sp-#{$value} {
        padding-left: $value + rem !important;
      }
    }
  }




  .h_after-85{
    &::after{
      height: 85%;
    }
  }
}


.border-b{
  border-bottom: 1px solid #000;
}


