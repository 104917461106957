.p-counseling{
	&-accordion {
		width: 98rem;
		position: relative;
		margin: 0 auto;
		@media (--mobile) {
			width: 100%;
		}

		&__item{
			border-top: 1px solid #D0D0D0;
			&:last-of-type{
				border-bottom: 1px solid #D0D0D0;
			}
		}
		&__content {
			display: none;

			ul{
				li{
					position: relative;
					font-size: 1.5rem;

					&.ttl{
						margin-bottom: 1rem;
					}

					&:last-child{
						margin-bottom: 4rem;
					}
					span{
						font-size: 1.4rem;
						font-family: 'EB Garamond', serif;
						margin-right: 2rem;
					}
					a{
						display: block;
						padding: 0rem 0 3rem 3rem;
						@media (--mobile) {
							padding: 1rem 0rem 1rem 2rem;
						}
					}
					&::before {
						border-right: solid 1px #707070;
						border-top: solid 1px #707070;
						content: "";
						display: block;
						width: 0.8rem;
						height: 0.8rem;
						position: absolute;
						left: 0rem;
						top: 8px;
						transform: rotate(45deg);
						@media (--mobile) {
							width: 0.8rem;
							height: 0.8rem;
							top: 1.7rem;
							right: 2rem;
						}
					}
				}
			}

			&-link{
				display: flex !important;
			}
		}

		&__title {
			color: #333333;
			cursor: pointer;
			font-size: 2.6rem;
			padding: 5rem 0;
			position: relative;
			padding-left: 2rem;
			@media (--mobile) {
				padding: 3rem 3rem 3rem 2rem;
				font-size: 2rem;
			}
			&::after {
				border-right: solid 1px #707070;
				border-top: solid 1px #707070;
				content: "";
				display: block;
				width: 1.8rem;
				height: 1.8rem;
				position: absolute;
				right: 3rem;
				top: 38%;
				transform: rotate(135deg);
				transition: transform .3s ease-in-out, top .3s ease-in-out;

				@media (--mobile) {
					width: 1.2rem;
					height: 1.2rem;
					top: 40%;
					right: 2rem;
				}

			}
			&.open::after {
				top: 45%;
				transform: rotate(-45deg);
			}

		}
	}
}