.p-contact {
  .c-inner {
    display: flex;
    justify-content: center;
    .c-leftTtl{
      strong{
        font-size: 2.6rem;
        margin-bottom: 2rem;
      }
    }
    @media (--mobile) {
      display: block;
      .input,.confirm{
        font-size: 1.5rem;
      }
    }
  }
  &__left {
    padding-right: 13rem;
    span{
      font-size: 1.5rem;
    }
    @media (--mobile) {
      padding-right: 0;
    }
    p {
      font-size: 1.5rem;
      margin-top: 4.2rem;
    }
  }
  &__right {
    margin-top: 9.2rem;
    width: 58rem;
    @media (--mobile) {
      width: 100%;
      margin-top: 5rem;
    }
    dl {
      &:nth-of-type(n + 2) {
        margin-top: 4rem;
      }
      dt {
        display: flex;
        align-items: center;
        font-size: 1.5rem;
        @media (--mobile) {
          font-size: 1.4rem;
        }
        span {
          display: inline-block;
          background: #171717;
          color: #fff;
          font-size: 1.2rem;
          padding: .3rem .5rem;
          margin-left: 1rem;
        }
      }
      dd {
        margin-top: 1.8rem;
        font-size: 1.5rem;
        @media (--mobile) {
          font-size: 1.4rem;
        }
        input, textarea {
          width: 100%;
          display: inline-block;
          border-bottom: 1px solid #707070;
          height: 4.8rem;
          font-size: 1.5rem;
          @media (--mobile) {
            font-size: 1.4rem;
          }
          &::placeholder {
            color: rgba(0, 0, 0, .5);
          }
        }
        textarea {
          height: 16.6rem;
        }

        &.content-select{
          position: relative;
          width: 100%;
          .error{
            position: absolute;
            left: 0;
            bottom: -2.4rem;
          }
        }
        select{
          border: 0px;
          outline: none;
          width: 100%;
        }
      }
    }
    .method {
      display: flex;
      border-bottom: 1px solid #707070;
      padding-bottom: 2rem;
      margin-top: 3rem;
      @media (--mobile) {
        border: none;
        padding-bottom: 0rem;
      }
      label{
        position: relative;

        span{
          margin-left: 4rem;
          margin-right: 4rem;
        }
      }
      input {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        height: 2.5rem;
        width: 2.5rem;
        border-bottom: 0px;
        &::after {
          content: "";
          position: absolute;
          height: 100%;
          width: 100%;
          border: .1rem solid #D0D0D0;
        }
        &::before {
          content: "";
          opacity: 0;
          position: absolute;
          height: 100%;
          width: 100%;
          background: url(../images/common/ic_check.png) no-repeat center center / 1.8rem;
        }
        &:checked {
          &::before {
            opacity: 1;
          }
        }
      }
    }
    .agree {
      position: relative;
      display: flex;
      align-items: center;
      margin-top: 6.5rem;
      font-size: 1.5rem;
      padding-left: 4.5rem;
      .privacy-text{
        padding-left: 4rem;
        @media (--mobile) {
          font-size: 1.3rem;
        }
      }
      .error{
        position: absolute;
        top: 3rem;
        @media (--mobile) {
          top: 2.4rem;
        }
      }
      @media (--mobile) {
        font-size: 1.3rem;
      }
      a {
        text-decoration: underline;
        letter-spacing: .2em;
      }
      input {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-25%);
        height: 2.5rem;
        width: 2.5rem;
        &::after {
          content: "";
          position: absolute;
          height: 100%;
          width: 100%;
          border: .1rem solid #D0D0D0;
        }
        &::before {
          content: "";
          opacity: 0;
          position: absolute;
          height: 100%;
          width: 100%;
          background: url(../images/common/ic_check.png) no-repeat center center / 1.8rem;
        }
        &:checked {
          &::before {
            opacity: 1;
          }
        }
      }
      .mwform-checkbox-field-text {
        display: none;
      }
    }
    .btn_wrap {
      margin-top: 6rem;
      input {
        transition: .5s;
        width: 100%;
        height: 10rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #171717;
        color: #fff;
        padding: 0;
        font-size: 2rem;
        border: .2rem solid #171717;
        transition: .5s background, .5s color;
        &:hover {
          background: #fff;
          color: #171717;
        }
      }
    }
  }
  .privacy-text{
    padding-left: 4rem;
  }
}
.mw_wp_form_complete{
  .p-contact__right{
    margin-top: 0;
    font-size: 1.5rem;
    line-height: 3.2rem;
  }
}
.mw_wp_form_confirm {
  .method {
    border: none;
  }
  .agree {
    display: none;
  }
  .btn_wrap {
    display: flex;
    justify-content: space-between;
    input {
      width: calc(50% - 1rem);
      height: 8rem;
      &[name="submitBack"] {
        background: #707070;
        border: .2rem solid #707070;
        &:hover {
          background: #fff;
          color: #707070;
        }
      }
    }
  }
  .p-contact__left {
    .input {
      display: none;
    }
  }
}

.mw_wp_form_input {
  .p-contact__left {
    .confirm {
      display: none;
    }
  }
}