.p-cost{
	&-price {
		padding-left: 10rem;
		padding-bottom: 16rem;
		margin-bottom: 7rem;
		border-bottom: 1px solid #D0D0D0;
		position: relative;
		@media (--mobile) {
			padding-left: 0;
			padding-bottom: 10rem;
		}
		&__title{
			font-size: 2.6rem;
			margin-bottom: 2.4rem;
			@media (--mobile) {
				text-align: center;
			}
			span{
				font-size: 1.4rem;
				font-family: 'EB Garamond', serif;
				font-weight: Regular;
				display: block;
				margin-bottom: 1.4rem;
			}
		}
		&__text{
			margin-top: 4rem;
			p{
				font-size: 1.5rem;
				line-height: 3.2rem;
			}
			@media (--mobile) {
				margin-top: 2rem;
			}
		}
	}
	&-case{
		display: flex;
		justify-content: space-around;
		padding-bottom: 11rem;
		margin-bottom: 11rem;
		border-bottom: 1px solid #D0D0D0;

		@media (--mobile) {
			flex-direction: column;
			padding-bottom: 6rem;
			margin-bottom: 7rem;
		}
		&__cell{
			width: 40rem;
			position: relative;
			@media (--mobile) {
				width: 100%;
				margin-bottom: 9rem;

				&:last-of-type{
					margin-bottom: 3rem;
				}
			}
		}
		&__title{
			font-size: 2.4rem;
			margin-bottom: 2.5rem;
			line-height: 3rem;
			@media (--mobile) {
				font-size: 2rem;
			}
			span{
				font-size: 1.4rem;
				display: block;
			}
		}

		&__text{
			font-size: 1.5rem;
			line-height: 3.2rem;
		}

		&__block{
			display: flex;
			// justify-content: space-between;
			margin-bottom: 3rem;
			@media (--large) {
				min-height: 9rem;
			}

			&.center{
				align-items: center;
				@media (--large) {
					align-items: flex-start;
					.p-cost-case__txt {
						padding-top: 1rem;
					}
				}
			}
		}
		&__subtitle{
			// width: 40%;
			margin-top: 1.2rem;
			margin-right: 3rem;

			span{
				white-space: nowrap;
				background-color: #171717;
				color: #FFF;
				// border: 0.2rem solid #171717;
				padding: 1rem;
			}
		}
		&__txt{
			// width: calc(100% - 40% - 1rem);
		}
		&__btn{
			position: absolute;
			bottom:0;
			width: 100%;
			left: 50%;
			transform: translate(-50%, 50px);

			&.small{
				width: 33%;
				bottom: 26%;
				@media (--mobile) {
					width: 100%;
					bottom: 85px;
				}
			}


			a{
				border: 0.2rem solid #171717;
				padding: 1rem 2rem;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 1.5rem;
				transition: .5s background, .5s color;

				&:hover{
					background-color: #171717;
					color: #FFF;
				}
			}
		}
	}

	&-flow {
		&__more{
			font-size: 1.6rem;
			padding: 1rem;
			border: 0.1rem solid #D0D0D0;
			position: relative;
			span{
				.icon-plus {
					color: #171717;
					width: 1.5rem;
					height: 1.5rem;
					position: absolute;
					right: 1rem;
					top: 50%;
					transform: translateY(-50%);
				}
				.icon-plus:before,
				.icon-plus:after {
					content: "";
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					width: 1.5rem;
					height: 0.1rem;
					background-color: #171717;
				}
				.icon-plus:after {
					-webkit-transform: rotate(90deg);
					transform: rotate(90deg);
				}
			}
			&.open .icon-plus::after{
				content: none;
			}
		}
		&__about{
			display: flex;
			font-size: 1.6rem;
			margin-bottom: 1rem;
			// border-bottom: 0.1rem solid #707070;
			// padding-bottom: 1rem;
			// display: inline-block;
			@media (--mobile) {
				display: block;
			}
			p{
				margin-bottom: 0!important;
				margin-right: 1rem;
			}
		}
		&__btn{
			font-size: 1.5rem;
			border-bottom: 0.1rem solid #707070;
			padding-bottom: 1rem;
			display: inline-block;
			@media (--mobile) {
				margin-top: 1rem;
			}
			a{
				display: flex;
				align-items: center;

				&:hover {
					svg {
						circle:first-of-type {
							fill: rgba(23,23,23,1);
						}
						path {
							stroke: #FFF;
						}
					}
				}
			}
			svg{
				width: 3rem;
				height: 3rem;
				margin-left: 1rem;
				circle:first-of-type {
					fill: rgba(255,255,255,0);
					transition: .5s fill;
				}
				path{
					stroke: #171717;
				}
				&, * {
					transition: .5s;
				}
			}
		}
		&__title {
			font-size: 2.6rem;
			margin-bottom: 6rem;
			padding-top: 2rem;
			@media (--mobile) {
				margin-bottom: 2.4rem;
				padding-top: 5.4rem;

				&.p-cost-after{
					margin-bottom: 1.5rem !important;
			}
			}


			span{
				font-size: 1.4rem;
				font-family: 'EB Garamond', serif;
				font-weight: Regular;
				display: block;
				margin-bottom: 1.4rem;
				text-align: left;
				@media (--mobile) {
					margin-bottom: 0;
				}
			}
		}
		&__text{
			font-size: 1.5rem;
			line-height: 3.2rem;
			margin-top: 8rem;
			margin-bottom: 8rem;
			text-align: left;
			@media (--mobile) {
				margin-top: 1.5rem;
				margin-bottom: 0rem;
				text-align: left;
			}
		}
		&__contents{
			display: flex;
			justify-content: space-between;
			&:last-child{
				margin-top: 12rem;
				@media (--mobile) {
					margin-top: 0rem;
				}
			}
			@media (--mobile) {
				display: block;
				width: 100%;
			}
		}
		ul{
			position: relative;
			@media (--mobile) {
				width: 100%;
			}
			&.free{
				li{
					position: relative;
					&::before{
						width: 4rem;
						height: 4rem;
						border-radius: 50%;
						position: absolute;
						content: "無料";
						background-color: #171717;
						color: #FFF;
						font-size: 1.2rem;
						left: -50px;
						top: 10px;
						display: flex;
						justify-content: center;
						align-items: center;
						@media (--mobile) {
							top: 6.6rem;
							z-index: 4;
							left: -0.1rem;
							z-index: 4;
							// transform: translateY(6.5rem);
						}
					}
				}
			}
			&::after{
				content:"";
				display: inline-block;
				width: 1px;
				height: 100%;
				background-color: #D0D0D0;
				position: absolute;
				top: 50%;
		    left: 50%;
				z-index: 1;
				transform: translate(-50%,-50%) translateX(-37rem);
				@media (--mobile) {
					transform: translate(-50%, -50%) translateX(-14rem);
				}
			}
			li{
				display: flex;
				// margin-bottom: 5rem;
				@media (--mobile) {
					&.step6 {
						min-height: 20rem;
					}
					&.step8 {
						min-height: 30rem;
					}
					&.step13 {
						min-height: 40rem;
					}
					&.step15{
						min-height: 26rem;
					}
				}
				// @media (--mobile) {
				// 	margin-bottom: 10rem;
				// }
				&:last-child{
					margin-bottom: 0;
				}
				&.turn{
					// padding-bottom: 10rem;
					@media (--mobile) {
						padding-bottom: 0;
						margin-bottom: 0rem;
					}
				}
			}
		}
		&__step{
			margin-right: 4rem;
			font-size: 1.8rem;
			font-family: 'EB Garamond', serif;
			font-weight: Regular;
			position: relative;
			div{
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%) translateY(3rem);
				white-space: nowrap;
				font-size: 1.4rem;
				text-align: center;
				border: 1px solid #CECECE;
				padding: 0.8rem 1.5rem;
				background-color: #FFF;
				z-index: 2;
				color: #707070;

				&.first{
					transform: translate(-50%,-50%) translateY(-9rem);
					@media (--mobile) {
						transform: translate(-50%, -50%) translateY(-6rem);
					}
				}
				&.second {
					transform: translate(-50%, -50%) translateY(9rem);
				}
				@media (--mobile) {
					writing-mode: vertical-rl;
					transform: translate(-50%, -50%) translateY(3rem);
					padding: 0.8rem .5rem;
					font-size: 1.2rem;
				}
				span{
					font-weight: bold;
					&.num{
						font-weight: normal;
						@media (--mobile) {
							text-combine-upright: all;
						}
					}
				}
			}
			@media (--mobile) {
				// width: 38%;
				margin-right: 2rem;
			}
			p{
				background-color: #FFFFFF;
				z-index: 2;
				position: relative;
				padding: 1.7rem 0;
				@media (--mobile) {
					padding: 0;
					padding-bottom: 0.5rem;
					font-size: 1.2rem;
					text-align: center;
					margin-left: 0.3rem;
				}
			}
		}

		.p-cost-flow__step.last{
			background: #FFFFFF;
			padding-top: 1.7rem;
			z-index: 99;
			position: relative;
		}
		.p-cost-flow-ttl{
			text-align: left !important;

		}
		&__content{
			width: 70rem;
			padding-top: 1.7rem;
			padding-bottom: 5rem;
			&.flex{
				display: flex;
				@media (--mobile) {
					display: block;
				}
			}
			@media (--mobile) {
				width: 100%;
				padding-top: 0;
				padding-bottom: 5rem;
				&.pb-0{
					padding-bottom: 0;
				}
			}
		}
		&-contents{
			width: calc(100% - 25.8rem - 1rem);
			margin-right: 1rem;
			@media (--mobile) {
				width: 100%;
				margin-right: 0;
				margin-bottom: 1rem;
			}
		}
		&__img{
			width: 25.8rem;
			@media (--mobile) {
				display: none;
			}
		}
		&__ttl{
			font-size: 2rem;
			color: #171717;
			font-weight: bold;
			margin-bottom: 1.5rem;
			@media (--mobile) {
				margin-bottom: 2rem;
			}
			&.last{
				padding-top: 1.5rem;
			}
			span{
				display: inline-block;
				font-size: 1.2rem;
				font-weight: normal;
				background-color: #EAEAEA;
				// border: 0.1rem solid #171717;
				padding: 0.5rem;
				margin-top: 1rem;
				@media (--mobile) {
					font-size: 1.3rem;
				}
			}
		}
		&__txt{
			font-size: 1.5rem;
			line-height: 3.2rem;
			@media (--mobile) {
				margin-top: 1rem;
				display: none;
			}
			@media (--large) {
				img{
					display: none;
				}
			}
		}
		&__service{
			width: 82rem;
			@media (--mobile) {
				width: 100%;
				// margin-top: -2rem;
			}
			p{
				font-size: 1.5rem;
				line-height: 3.2rem;
				margin-bottom: 3rem;
			}
		}

	}


	.sp-mt-0{
		@media (--mobile) {
			margin-top: 0;
		}
	}
}