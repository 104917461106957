.p-faq{
	&__inner{
		@media (--mobile) {
			padding: 0 1.3em !important;
		}
	}
	&-accordion {
		width: 98rem;
		position: relative;
		margin: 0 auto;
		@media (--mobile) {
			width: 100%;
		}

		&__item{
			// border-top: 1px solid #D0D0D0;
			position: relative;
			margin-top: -1px;
			&::after, &::before {
				position: absolute;
				content: "";
				left: 0;
				top: 0;
				width: 0;
				height: 100%;
				pointer-events: none;
				border-top: 1px solid #D0D0D0;
				border-bottom: 1px solid #D0D0D0;
				transition: 1s;
			}
			&::after {
				width: 100%;
			}
			&::before {
				border-color: #5b5b5b;
				z-index: 1;
			}
			&:hover {
				&::before {
					width: 100%;
				}
			}
			// &:last-of-type{
			// 	border-bottom: 1px solid #D0D0D0;
			// }
		}
		&__content {
			display: none;
		}
		&__content p {
			font-size: 1.5rem;
			line-height: 3.2rem;
			padding: 0rem 8rem 5rem 10rem;
			@media (--mobile) {
				padding: 0rem 2rem 5rem 2rem;
				font-size: 1.4rem;
			}

		}

		&__title {
			color: #333333;
			cursor: pointer;
			font-size: 1.5rem;
			padding: 5rem 0;
			position: relative;
			padding-left: 10rem;
			@media (--mobile) {
				padding: 2.5rem 5rem;
			}

			&::before{
				width: 3.2rem;
				height: 3.6rem;
				position: absolute;
				display: block;
				content: "";
				transform: translateY(-50%);
				background: url("../images/faq/icon-question.svg") no-repeat center center / contain;
				top: 50%;
				left: 3rem;
				@media (--mobile) {
					left: 2rem;
					width: 1.6rem;
					height: 1.8rem;
					background-size: 1.6rem 1.8rem;
				}

			}
			&::after {
				border-right: solid 1px #707070;
				border-top: solid 1px #707070;
				content: "";
				display: block;
				width: 1.8rem;
				height: 1.8rem;
				position: absolute;
				right: 3rem;
				top: 38%;
				transform: rotate(135deg);
				transition: transform .3s ease-in-out, top .3s ease-in-out;

				@media (--mobile) {
					width: 1.2rem;
					height: 1.2rem;
					top: 40%;
					right: 2rem;
				}

			}
			&.open::after {
				top: 45%;
				transform: rotate(-45deg);
			}

		}
	}
}