.barba-leave-active,
.barba-enter-active {
  transition: opacity 450ms ease;
}

/* initial state */
.barba-leave {
  opacity: 1;
}

.barba-enter {
  opacity: 0;
}

/* ending state */
.barba-leave-to {
  opacity: 0;
}

.barba-enter-to {
  opacity: 1;
}