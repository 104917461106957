.p-restaurant.keyword .c-title{
	border-bottom: 1px solid #D0D0D0;
	padding-bottom: 7rem;
	@media (--mobile) {
		padding-bottom: 4rem;
	}
}
.p-restaurant{
	.keyword{
		width: 118rem;
		margin: 10rem auto 0 auto;
		@media (--mobile) {
			width: auto;
	    padding: 0 2rem;
			margin-top: 4rem;
		}

	}
	&-content{
		width: 48rem;
		margin-left: 10rem;
		@media (--mobile) {
			width: 100%;
			margin-left: 0;
		}

		&__text{
			margin-top: 4rem;
			margin-bottom: 8rem;
			font-size: 1.6rem;
			line-height: 3.2rem;
			@media (--mobile) {
				margin-bottom: 4rem;
			}

		}
		&__keyword{
			font-size: 1.6rem;
			margin-bottom: 12rem;
			@media (--mobile) {
				margin-bottom: 8rem;
			}


			h3{
				margin-bottom: 3rem;
				@media (--mobile) {
					margin-bottom: 1.5rem;
				}

			}
			ul{
				display: flex;
				flex-wrap: wrap;
				@media (--mobile) {
					justify-content: flex-start;
					flex-wrap: wrap;
					margin: 0 -2rem;
					margin-top: -1rem;
					padding: 0 2rem;
				}
				li{
					font-size: 1.4rem;
					color: #7B7B7B;
					margin-right: 1.4rem;
					white-space: nowrap;
					@media (--mobile) {
						margin-top: 1rem;
					}
					&:last-child{
						margin-right: 0;
					}
					a{
						display: block;
						position: relative;
						&::before{
							content: "";
							margin: auto;
							position: absolute;
							left: 0;
							bottom: -0.2rem;
							transition: transform .3s, -webkit-transform .3s;
							-webkit-transform: scale(0, 1);
							transform: scale(0, 1);
							-webkit-transform-origin: right center;
							transform-origin: right center;
							width: 100%;
							height: 0.1rem;
							background: #171717;
						}
					}
					a:hover{
						&::before{
							transform: scale(1, 1);
							transform-origin: left center;
						}
					}
				}
			}
		}
	}
	ul.p-restaurant-cell{
		display: flex;
		justify-content: space-between;
    flex-wrap: wrap;
		li.p-restaurant-list{
			width: 53.6rem;
			@media (--mobile) {
				width: 100%;
				margin-bottom: 4rem;
			}

		}
	}

	&-list{
		margin-bottom: 8rem;
		&__img{
			position: relative;
			margin-bottom: 2.4rem;
		}
		&__txt {
			position: absolute;
			right: 0;
			top: 0;
			margin-right: -20px;
			-ms-writing-mode: tb-rl;
			writing-mode: vertical-rl;
			color: #898989;
			font-family: 'EB Garamond', serif;
			font-weight: Regular;
			font-size: 1rem;
		}
		&__title{
			font-size: 1.8rem;
			margin-bottom: 1.7rem;
		}
		ul{
			display: flex;
			li{
				margin-right: 0.8rem;
				font-size: 1.2rem;
				color: #707070;
				border: 1px solid #CECECE;
				a{
					display: block;
					padding: 6px 8px;
				}
			}
		}
	}

	&-voice{
		width: 118rem;
		margin: 12rem auto 0 auto;
		border-top: 1px solid #D0D0D0;
		padding-top: 12rem;
		display: flex;
		justify-content: space-between;
		@media (--mobile) {
			width: 100%;
			margin-top: 6rem;
			padding-top: 6rem;
			display: block;
		}


		&__title{
			font-size: 3.2rem;
			font-family: 'EB Garamond', serif;
			font-weight: Regular;
			@media (--mobile) {
				font-size: 2.5rem;
				text-align: center;
				margin-bottom: 2rem;
			}

			.jp{
				font-size: 1.5rem;
				margin-bottom: 1.4rem;
				display: block;
				@media (--mobile) {
					font-size: 1.2rem;
					margin-bottom: 1rem;
				}

			}
		}
		&__content{
			display: flex;
	    flex-wrap: wrap;
  	  justify-content: flex-end;
		}
		&__cell{
			width: 78rem;
			display: flex;
			border-bottom: 1px solid #D0D0D0;
			padding-bottom: 4rem;
			margin-bottom: 4rem;
			padding: 0 2rem 4rem 2rem;
			@media (--mobile) {
				width: 100%;
				display: block;
			}

		}
		&__cell:nth-child(even){
			flex-direction: row-reverse;
			.p-restaurant-voice__text{
				margin-right: 0rem;
				margin-left: 3rem;
				@media (--mobile) {
					margin-left: 0;
				}

			}
		}
		&__text{
			width: 55rem;
			margin-right: 3rem;
			@media (--mobile) {
				width: 100%;
				margin-right: 0;
				margin-bottom: 3rem;
			}

		}
		&__heading{
			font-size: 2rem;
			margin-bottom: 1.4rem;
		}
		&__txt{
			font-size: 1.6rem;
			line-height: 3.2rem;
			margin-bottom: 1.3rem;
		}
		&__name{
			font-size: 1.6rem;
			span.city{
				font-size: 1.3rem;
				color: #7B7B7B;
				position: relative;
				margin-left: 2rem;
				&::before{
					position: absolute;
					display: block;
					content: "";
					width: 1px;
					height: 1.3rem;
					background-color: #7B7B7B;
					left: -1rem;
					top: 0;
				}
			}
		}
		&__more{
			display: flex;
			justify-content: flex-end;
			font-size: 1.6rem;
			margin-top: -2.2rem;
			@media (--mobile) {
				margin-top: 1rem;
			}

			img{
				margin-left: 1.5rem;
			}
		}
		&__img{
			width: calc(100% - 55rem - 3rem);
			@media (--mobile) {
				width: 100%;
				text-align: center;
			}

		}
	}
	&-keyword{
		width: 118rem;
		margin: 12rem auto 0 auto;
		padding-top: 8rem;
		border-top: 1px solid #D0D0D0;
		@media (--mobile) {
			width: 100%;
			margin-top: 6rem;
			padding-top: 4rem;
		}
		&__title{
			text-align: center;
			font-size: 1.6rem;
			margin-bottom: 3rem;
		}

		&__list{
			ul{
				display: flex;
				justify-content: center;
				li{
					font-size: 1.4rem;
					color: #7B7B7B;
					margin-right: 2.4rem;

					&:last-child{
						margin-right: 0;
					}
				}
			}
		}
	}
}
.p-restaurant.keyword .p-restaurant-voice{
	margin-top: 8rem;
	@media (--mobile) {
		margin-top: 4rem;
	}
}